import { Fragment, useState, useRef, FC } from 'react'
import moment from 'moment'

import EventViewDialog from '../../organisms/calendar/eventViewDialog'

import { getTimeSlots } from '../../../utils/calendar'
import { getDynamicHexColor } from '../../../utils/functions'

const minTime = new Date()
minTime.setHours(6, 0, 0, 0)

const maxTime = new Date()
maxTime.setHours(21, 59, 59, 999)

const DayView: FC<DayViewProps> = ({ currentDate, events }) => {
  const container = useRef<any>(null)
  const containerOffset = useRef<any>(null)
  const [isOpenModal, setOpenModal] = useState<Boolean>(false)
  const [selectedEventDetail, setEventDetail] = useState<any>(null)

  const filterEvents = () => {
    let filteredEvents: any[] = []
    events.forEach((event: any) => {
      if (moment(event.start).format('YYYY-MM-DD') === moment(currentDate).format('YYYY-MM-DD')) {
        filteredEvents.push(event)
      }
    })
    return filteredEvents
  }

  const handleSelect = (event: any) => {
    setOpenModal(true)
    setEventDetail(event)
  }

  return (
    <div className="isolate flex flex-auto overflow-hidden bg-white">
      <div ref={container} className="flex flex-auto flex-col overflow-auto">
        <div className="flex w-full flex-auto">
          <div className="w-14 flex-none bg-white dark:bg-header-dark ring-1 ring-gray-100" />
          <div className="grid flex-auto grid-cols-1 grid-rows-1">
            {/* Horizontal lines */}
            <div
              className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100 dark:divide-revtron-dark3 dark:bg-header-dark"
              style={{ gridTemplateRows: `repeat(${getTimeSlots(minTime, maxTime).length * 2}, minmax(3rem, 1fr))` }}
            >
              <div ref={containerOffset} className="row-end-1 h-7"></div>
              {getTimeSlots(minTime, maxTime).map((item: any, index: number) => {
                return (
                  <Fragment key={index.toString()}>
                    <div>
                      <div className="sticky left-0 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                        {item}
                      </div>
                    </div>
                    <div />
                  </Fragment>
                )
              })}
            </div>

            {/* Events */}
            <ol
              className="col-start-1 col-end-2 row-start-1 grid grid-cols-1"
              style={{ gridTemplateRows: `1.75rem repeat(${getTimeSlots(minTime, maxTime).length * 2 * 6}, minmax(0, 1fr)) auto` }}
            >
              {filterEvents() && filterEvents().length > 0 && filterEvents().map((event: any, idx: number) => {
                const start = moment(event.start)
                const end = moment(event.end)
                let duration = moment.duration(end.diff(start))
                const hours = duration.asHours()
                return (
                  <li
                    key={idx.toString()}
                    className='relative mt-px flex'
                    style={{
                      gridRow: `${2 + ((moment(event.start).hour() + moment(event.start).minute() / 60 - 6 < 0 ? 0 : moment(event.start).hour() + moment(event.start).minute() / 60 - 6)) * 12} / span ${hours * 12}`
                    }}
                  >
                    <a
                      onClick={() => handleSelect(event)}
                      className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg p-2 text-xs leading-5 hover:bg-blue-100"
                      style={getDynamicHexColor(event).style}
                    >
                      <p className="font-semibold text-white">{event.title}</p>
                      <p className="text-white group-hover:text-blue-700">
                        <time dateTime="2022-01-12T06:00">
                          {moment(event.start).format('hh:mm A')} - {moment(event.end).format('hh:mm A')}
                        </time>
                      </p>
                    </a>
                  </li>
                )
              })}
            </ol>
          </div>
        </div>
        <EventViewDialog
          isOpen={isOpenModal}
          setIsOpen={setOpenModal}
          event={selectedEventDetail}
        />
      </div>
    </div>
  )
}

interface DayViewProps {
  currentDate: any
  events: any[]
}

export default DayView
