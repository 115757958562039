import { FC, useRef, useEffect } from 'react'
import * as d3 from 'd3'
import moment from 'moment'

const VerticalBarChart: FC<{ data: any[] }> = ({ data }) => {
  const margin = { top: 20, right: 20, bottom: 50, left: 30 }
  const svgWidth = 995
  const svgHeight = 450
  const width = svgWidth - margin.left - margin.right
  const height = svgHeight - margin.top - margin.bottom
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    draw()
  }, [data])

  const draw = () => {
    d3.select(ref.current).select('svg').remove()

    const svg = d3
      .select(ref.current)
      .append('svg')
      .attr('viewBox', `0 0 ${svgWidth} ${svgHeight}`)
      .attr('width', '100%')
      .attr('height', '100%')
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`)

    const defs = svg.append('defs')
    
    const bgGradient = defs
      .append('linearGradient')
      .attr('id', 'bg-gradient')
      .attr('gradientTransform', 'rotate(90)')
    bgGradient
      .append('stop')
      .attr('stop-color', '#4fd1c58a')
      .attr('offset', '0%')
    bgGradient
      .append('stop')
      .attr('stop-color', '#4fd1c51a')
      .attr('offset', '100%')

    const graphArea = svg
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`)

    const getX = (d: any) => d.x
    const getY = (d: any) => d.y
  
    const x = d3.scaleBand()
      .rangeRound([0, width])
      .domain(data.map(getX))
      .padding(0.1)
  
    const y = d3.scaleLinear()
      .range([height, 0])
      .domain([
        d3.min(data, getY),
        d3.max(data, getY) === 0 ? 100 : d3.max(data, getY)
      ])
      .nice()
  
    const xAxis = d3.axisBottom(x)
    const yAxis = d3.axisLeft(y).ticks(5)

    xAxis
      .tickFormat(x => moment(x).format('MMM'))
      .tickSize(0)

    yAxis
      .tickFormat(y => '$' + y + 'k')

    yAxis.tickSize(-995)
  
    const ax = graphArea
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0, ${height})`)
      .call(xAxis)
  
    // remove x-axis
    ax.select('path').remove()
    ax.selectAll('text')
      .style("alignment-baseline", "middle")
      .style('color', '#6B7280')
      .style('font-size', '0.875rem')
  
    const bx = graphArea
      .append('g')
      .attr('class', 'y-axis')
      .call(yAxis)
  
    // remove y-axis
    bx.select('path').remove()
    // custom y-axis grid
    bx.selectAll('line')
      .style("stroke-width", 0.3)
      .style("opacity", 0.5)
    bx.selectAll('text')
      .style('color', '#6B7280')
      .style('display', d => {
        return d === 0 ? 'none' : ''
      })
      .style('font-size', '0.875rem')
  
    // Bars
    const bar = graphArea
      .selectAll("bar")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "bar")

    // Add rect to bar
    bar.append("rect")
      .style("fill", "url(#bg-gradient)")
      .attr("stroke", "#05D9E8")
      .attr("stroke-width", "1px")
      .attr("x", d => x(getX(d)) || null)
      .attr("y", d => y(getY(d)))
      .attr("width", x.bandwidth())
      .attr("height", d => height - y(getY(d)))

    // Add text label to bar
    bar.append("text")
      .attr("class", "label fill-black dark:fill-white")
      .attr("x", d => (x(d.x) || 0) + 30)
      .attr("y", d => y(d.y) - 10)
      .style("text-anchor", "middle")
      .style('font-size', '0.875rem')
      .style('display', d => {
        return d.y === 0 ? 'none' : ''
      })
      .text(d => `$${Math.round(d.y)}k`)
  }

  return (
    <div ref={ref} />
  )
}

export default VerticalBarChart