import { FC, useCallback, useState } from 'react'
import { useFeatureContext } from '../../hooks/featureContext'
import UsersSection from '../../components/organisms/settings/usersSection'
import TeamsSection from '../../components/organisms/settings/teamsSection'
import CommonTabs from '../../components/common/tabs'
import UsersAndTeams from './UsersAndTeams'
import Integrations from './Integrations'
import AccountMapping from './AccountMapping'
import ContactMapping from './ContactMapping'
import { SettingTabs, SettingTabNameEnum } from './Settings.Utils'

const Settings: FC = () => {
  const { isHrSoft, isNewfront, isNewfrontSandbox, isGigPro, isXyleme, isXima } = useFeatureContext()
  const [tab, setTab] = useState<string>(SettingTabNameEnum.USERS_TEAMS)

  const changeTab = useCallback((v: string) => {
    if (tab !== v) {
      setTab(v)
    }
  }, [tab])

  if (isHrSoft || isGigPro || isNewfront || isNewfrontSandbox || isXyleme || isXima) {
    return (
      <div className='settings'>
        <CommonTabs tabs={SettingTabs} onChangeTab={changeTab} currentTab={tab} />
        {tab === SettingTabNameEnum.USERS_TEAMS && <UsersAndTeams />}
        {tab === SettingTabNameEnum.INTEGRATIONS && <Integrations />}
        {tab === SettingTabNameEnum.ACCOUNT_ENRICHMENT && <AccountMapping />}
        {tab === SettingTabNameEnum.CONTACT_ENRICHMENT && <ContactMapping />}
      </div>
    )
  }
  return (
    <div className='settings'>
      <TeamsSection />
      <UsersSection />
    </div>
  );
}

export default Settings
