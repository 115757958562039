import React, { FC, useEffect, useState } from 'react'

export const ThemeContext = React.createContext('light')

const getInitialTheme = () => {
  if (typeof window !== 'undefined' && window.localStorage) {
      const storedPrefs = window.localStorage.getItem('color-theme')
      if (typeof storedPrefs === 'string') {
        return storedPrefs
      }

      const userMedia = window.matchMedia('(prefers-color-scheme: dark)')
      if (userMedia.matches) {
        return 'dark'
      }
  }
  return 'light' // light theme as the default
}

const ThemeProvider: FC<ThemeProviderProps> = ({ initialTheme, children }) => {
  const [theme, setTheme] = useState(getInitialTheme)

  useEffect(() => {
      rawSetTheme(theme)
  }, [theme])

  const rawSetTheme = (rawTheme: any) => {
    const root = window.document.documentElement
    const isDark = rawTheme === 'dark'

    root.classList.remove(isDark ? 'light' : 'dark')
    root.classList.add(rawTheme)

    localStorage.setItem('color-theme', rawTheme)
  }

  if (initialTheme) {
      rawSetTheme(initialTheme)
  }

  return (
    <ThemeContext.Provider value={{ theme, setTheme } as any}>
      {children}
    </ThemeContext.Provider>
  )
}

interface ThemeProviderProps {
  initialTheme?: any
  children: any
}

export default ThemeProvider
