import { FC, memo } from 'react'

import Spin from '../../../atoms/spin'
import { numberWithCommas } from '../../../../utils/functions'
import { SummaryTileContainer, SummaryTile } from '../../../molecules/summary'
import { TotalActivityByUser } from '../../../../containers/activity/ActivityLookBack/ActivityLookBack.util'

type PassProps = TotalActivityByUser & {
  isLoading: boolean
}

const ActivityLookBackSummary: FC<PassProps> = ({ 
  totalmeetingsCreated,
  totalopportunitiesCreated,
  totalaccountsTouched,
  totalcontactsTouched,
  totalavgContactsPerAccount,
  isLoading
}) => {
  if (isLoading) {
    return (
      <div className='flex justify-center bg-white dark:bg-header-dark shadow p-4 mb-6'>
        <Spin />
      </div>
    )
  }

  return (
    <SummaryTileContainer className='mb-6'>
      <SummaryTile
        label="# Opps Created"
        value={`${numberWithCommas(totalopportunitiesCreated.toFixed(0))}`}
      />
      <SummaryTile
        label="# Meetings Created"
        value={`${numberWithCommas(totalmeetingsCreated.toFixed(0))}`}
      />
      <SummaryTile
        label="Accounts Touched"
        value={`${numberWithCommas(totalaccountsTouched.toFixed(0))}`}
      />
      <SummaryTile
        label="Contacts Touched"
        value={`${numberWithCommas(totalcontactsTouched.toFixed(0))}`}
      />
      <SummaryTile
        label="Avg Contacts per Act"
        value={`${numberWithCommas(totalavgContactsPerAccount.toFixed(0))}`}
      />
    </SummaryTileContainer>
  )
}

export default memo(ActivityLookBackSummary)
