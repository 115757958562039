import Spin from '../../atoms/spin'
import HorizontalBarChart from './horizontalBarChart'

import { useBookingsByOwnerQuery } from '../../../graphql/__generated__/graphql'

const Leaders = () => {
  const {data, loading} = useBookingsByOwnerQuery()

  if (loading) {
    return (
      <div className='flex justify-center bg-white dark:bg-header-dark shadow p-4 mt-6'>
        <Spin />
      </div>
    )
  }

  let renderData: any[] = []
  if (data && data.bookings_by_owner_gql) {
    renderData = data.bookings_by_owner_gql.slice().sort((a, b) => a.bookings - b.bookings).map((item, index) => ({
      y: index + 1,
      x: item.bookings,
      icon: item.photo_url,
      firstName: item.team_member?.split(' ')[0],
      lastName: item.team_member?.split(' ')[1]
    }))
    renderData.sort((a, b) => b.y - a.y)
  }

  return (
    <div className='bg-white dark:bg-header-dark shadow p-4 mt-6'>
      <div className='text-sm dark:text-theme-toggle-icon'>Leader board</div>
      <HorizontalBarChart data={renderData} />
    </div>
  )
}

export default Leaders