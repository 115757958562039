
import moment from 'moment-timezone'
import { toast, ToastOptions } from 'react-toastify'
import { isEmpty, isEqual, xorWith } from 'lodash'

import {sendAlert, alertType} from '../components/molecules/alert'
import { colorsGroup } from './variable'
import { Seniorities, Roles } from '../components/organisms/settings/constant'

const config: ToastOptions = {
  autoClose: 10000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}

export const alert = (type: alertType, message: string) => {
  toast(sendAlert(type, message), config)
}

export const sortCompareFuncByDate = (a: any, b: any, field: string) => {
  const bTimestamp = b[field] ? Date.parse(b[field]) : 0
  const aTimestamp = a[field] ? Date.parse(a[field]) : 0
  if (bTimestamp < aTimestamp) {
    return 1
  }
  if (bTimestamp > aTimestamp) {
    return -1
  }
  return 0
}

export const getAlphaValue = (probability: any) => {
  if (probability < 10) return 0.1
  if (probability < 20) return 0.2
  if (probability < 30) return 0.3
  if (probability < 40) return 0.4
  if (probability < 50) return 0.5
  if (probability < 60) return 0.6
  if (probability < 70) return 0.7
  if (probability < 80) return 0.8
  if (probability < 90) return 0.9
  return 1
}

export const groupBy = (xs: any, key: string) => {
  return xs.reduce(function(rv: any, x: any) {
    (rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

export const numberWithCommas = (x: number | undefined | string) => {
  if (x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  return '0'
}

export const addCommasToStr = (str: string) => {
  if (str) {
    str = str.split(',').join('')
  }
  const numParts = str.toString().split('.')
  numParts[0] = numParts[0] && parseFloat(numParts[0]).toLocaleString()
  return numParts.join('.')
}

export const isInteger = (number: string | number, decimal: number = 2) => {
  return Number.isInteger(Number(number)) ? Number(number) : Number(number).toFixed(decimal)
}

export const calculateDefaultAvtarBg = (name: string) => {
  let hash = 0
  for (let i = 0; i < name.length; i++) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash)
  }

  return hash
}

export const intoRGB = (i: any) => {
  const c = (i & 0x00FFFFFF).toString(16).toUpperCase()
  return '00000'.substring(0, 6 - c.length) + c
}

// Function to Convert Timestamps Properly
export const convertUTCToLocal = (utcDt: string, utcDtFormat?: any) => {
  const toDt = moment.utc(utcDt, utcDtFormat).toDate()
  return moment(toDt).format('YYYY-MM-DD hh:mm:ss A')
}

const gradients = [
  "#AFBCBD",
  "#91A7A9",
  "#769294",
  "#5E8082",
  "#4A6F71",
  "#395F61",
  "#2B5052",
  "#1C3D3F",
  "#112F30",
  "#071F20"
]

export const findColorInGradient = (value: any, min: any, max: any) => {
  const percent = (value - min) / (max - min)
  const colorIndex = Math.floor(percent * 10)
  return gradients[colorIndex > 9 ? 9 : colorIndex]
}

export const transferredItems = (event: any) => {
  if (event && event.calendar_gql_clean && event.calendar_gql_clean.length > 0) {
    return event.calendar_gql_clean.map((item: any) => {
      return {
        ...item,
        title: item.subject || '',
        description: item.opportunity_name || '',
        // start: new Date(item.startdatetime),
        start: new Date(convertUTCToLocal(item.startdatetime)),
        // end: new Date(item.enddatetime),
        end: new Date(convertUTCToLocal(item.enddatetime))
      }
    })
  } else {
    return []
  }
}

export const getDynamicHexColor = (event: any, start?: any, end?: any, isSelected?: any) => {
  const backgroundColor = `#${intoRGB(calculateDefaultAvtarBg(event.meeting_owner))}`
  //const backgroundColor = `rgba(5, 217, 232, ${getAlphaValue(event.probability)})`
  const style = {
    backgroundColor: backgroundColor
  }
  return {
    style: style
  }
}

export const convertNumberToArray = (N: any) => {
  let a = []
  for (let i = 1; i <= N; i++) {
      a.push(i)
  }
  return a
}

export const dataURItoBlob = (dataURI: string) => {
  const byteString = atob(dataURI.split(',')[1])
  const mimeString = dataURI.split(',')[0].split(':')[1].split('')[0]
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], {type: mimeString})
}

export const copyResizedImage = (img: any, imageFile: any) => {
  const imgSize = 200
  const canvas = document.createElement("canvas")

  const ctx: any = canvas.getContext("2d")
  ctx.canvas.width  = imgSize
  ctx.canvas.height = imgSize
  ctx.rect(0, 0, canvas.width, canvas.height)
  ctx.fillStyle = "white"
  ctx.fill()

  // fit
  // const scale = Math.min(canvas.width / img.width, canvas.height / img.height)
  // const x = (canvas.width / 2) - (img.width / 2) * scale
  // const y = (canvas.height / 2) - (img.height / 2) * scale
  
  // fill
  const scale = Math.max(canvas.width / img.width, canvas.height / img.height)
  const x = (canvas.width / 2) - (img.width / 2) * scale
  const y = (canvas.height / 2) - (img.height / 2) * scale

  ctx.beginPath()
  ctx.arc(imgSize/2, imgSize/2, imgSize/2, 0, Math.PI * 2, false)
  ctx.clip()
  ctx.drawImage(img, x, y, img.width * scale, img.height * scale)

  const dataurl = canvas.toDataURL(imageFile.type)
  const blob = dataURItoBlob(dataurl)
  const file = new File([blob], imageFile.name, {type: imageFile.type})
  
  return file
}

export const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(' ')
}

export const convertToMillions = (x: number | undefined | string) => {
  if (x) {
    return Number(x)/1000000 > 1 ? (Number(x)/1000000).toFixed(2) + 'M' : Number(x)
  }
  return 0
}

export const commarize = (x: any) => {
  // Alter numbers larger than 1k
  if (x >= 1e3) {
    const units = ["k", "M", "B", "T"]
    let num: any = 0
    
    // Divide to get SI Unit engineering style numbers (1e3,1e6,1e9, etc)
    let unit = Math.floor(((x).toFixed(0).length - 1) / 3) * 3
    // Calculate the remainder
    if (unit === 3) {
      num = (x / Number('1e' + unit)).toFixed(0)  
    } else {
      num = (x / Number('1e' + unit)).toFixed(2)
    }
    const unitname = units[Math.floor(unit / 3) - 1]
    
    // output number remainder + unitname
    return num + unitname
  }
  
  // return formatted original number
  return x.toLocaleString()
}

export const getUserAvatarByName = (name: string) => {
  const firstName = name.split(' ')[0]
  const lastName = name.split(' ')[1]
  return firstName[0].toUpperCase() + lastName[0].toUpperCase()
}

export const getRandomBg = () => {
  return colorsGroup[Math.floor(Math.random() * colorsGroup.length)]
}

export const getAvatarUrl = (url: string, username: string): string => {
  const defaultPhoto = `https://ui-avatars.com/api/?name=${username}&size=38.135&rounded=true&background=${getRandomBg()}&color=FFFFFF`
  return url.indexOf('default=blank') > - 1 ? defaultPhoto : url
}

export const sortAlphabetically = (a: string | null | undefined, b: string | null | undefined) => {
  if (a === b) {
    return 0;
  }

  // nulls sort after anything else
  if (a === null || a === undefined) {
      return 1;
  }
  if (b === null || b === undefined) {
      return -1;
  }
  return a.toUpperCase() > b.toUpperCase() ? 1 : -1;
}

export const calcMedian = (values: number[]): number => {
  if (values.length === 0) return 0;

  values.sort(function(a, b) {
    return a - b;
  });

  var half = Math.floor(values.length / 2);
  
  if (values.length % 2)
    return values[half];
  
  return (values[half - 1] + values[half]) / 2.0
}

export const isArrayEqual = (x: Object[], y: Object[]): boolean => isEmpty(xorWith(x, y, isEqual));


export const getFilterTextForMinMax = (min?: number, max?: number): string => {
  if (!min && !max) {
    return ''
  }
  if (min && !max) {
    return `Min: ${min}`
  }

  if (!min && max) {
    return `Max: ${max}`
  }

  return `Min: ${min} - Max: ${max}`
}

export const generateRandomId = (length: number): string => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }

  return result;
}

export const websitelink = (domain: string): string => {
  if (domain.includes('http://') || domain.includes('https://')) {
    return domain
  }
  return `http://${domain}`
}

// export const stringToColor = (str: string) => {
//   let hash = 0;
//   for (let i = 0; i < str.length; i++) {
//     hash = str.charCodeAt(i) + ((hash << 5) - hash);
//   }
//   let color = '#';
//   for (let i = 0; i < 3; i++) {
//     let value = (hash >> (i * 8)) & 0xFF;
//     color += ('00' + value.toString(16)).substr(-2);
//   }
//   return color;
// }

export const stringToColor = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const minValue = 80; // minimum value for each RGB component
  const range = 175; // range of values for each RGB component
  const r = Math.max(minValue, (hash & 0xFF) % range + minValue);
  const g = Math.max(minValue, ((hash >> 8) & 0xFF) % range + minValue);
  const b = Math.max(minValue, ((hash >> 16) & 0xFF) % range + minValue);
  return `#${((r << 16) + (g << 8) + b).toString(16).padStart(6, '0')}`;
}


export const hexToRgbA = (hex: string, opacity: string) => {
  let c: any;
  if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
      c= hex.substring(1).split('');
      if(c.length=== 3){
          c= [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c= '0x'+c.join('');
      return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+','+opacity+')';
  }
  throw new Error('Bad Hex');
}

export const getPeopleFieldOptions = (field: string, filters?: string[]): string[] => {
  if (field === 'Seniority') {
    return Seniorities
  }

  if (field === 'Role') {
    return Roles.map(r => r.role)
  }

  if (field === 'Sub Role') {
    if (filters && filters.length > 0) {
      return Roles.filter(r => filters.includes(r.role)).flatMap(r => r.subrole)
    } else {
      return Roles.flatMap(r => r.subrole)
    }
  }

  return []
}
