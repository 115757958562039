import { FC } from 'react'
import { Summary, RevenueSum, MonthRevenue, Leaders } from '../../components/organisms/leaderboard'

const Leaderboard: FC<LeaderboardProps> = () => {

  return (
    <div className='leaderboard dark:text-white'>
      <Summary />
      <div className='flex'>
        <RevenueSum />
        <MonthRevenue />
      </div>
      <Leaders />
    </div>
  )
}

interface LeaderboardProps {
  loading?: boolean,
}

export default Leaderboard
