import { FC, useState } from 'react'

import Categories from '../../components/organisms/accounts/categories'
import Summary from '../../components/organisms/accounts/summary'
import AccountsTable from '../../components/organisms/accounts/accountsTable'

const Accounts: FC<AccountsProps> = () => {
  const [state, setState] = useState<any>({
    name: '',
    owner: [],
    status: []
  })
  
  const variables = {
    account_name: `%${state.name}%`,
    account_owner: state.owner.map((s: any) => s.value),
    status: state.status.map((s: any) => s.value)
  }

  let where: any = {}

  if (state.name) {
    where = {
      ...where,
      account_name: {
        _ilike: `%${state.name}%`
      }
    }
  }

  if (state.owner && state.owner.length !== 0) {
    where = {
      ...where,
      account_owner: {
        _in: state.owner.map((s: any) => s.value)
      }
    }
  }

  if (state.status && state.status.length !== 0) {
    where = {
      ...where,
      account_status_auto__c: {
        _in: state.status.map((s: any) => s.value)
      }
    }
  }

  return (
    <div className='accounts'>
      <Categories
        filters={state}
        setFilters={setState}
      />
      <Summary variables={variables} where={where} />
      <AccountsTable variables={variables} where={where} />
    </div>
  );
}

interface AccountsProps {
  loading?: boolean,
}

export default Accounts
