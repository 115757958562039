import { FC, useEffect, useState } from 'react'
import * as d3 from 'd3'
import moment from 'moment'
import Spin from '../../atoms/spin'

import {
  useMonthlyPipelineQuery,
  useWeeklyPipelineQuery,
} from '../../../graphql/__generated__/graphql'
import { sortCompareFuncByDate, numberWithCommas } from '../../../utils/functions'
import { useFeatureContext } from '../../../hooks/featureContext'

const leadSourceColors: any = {
  'Organic': '#c3f7fe',
  'Organic Search': '#c3f7fe',
  'Direct Traffic': '#c3f7fe',
  'Website Form Book a Demo': '#c3f7fe',
  'Website': '#c3f7fe',
  'Other Campaigns': '#5aecd4',
  'Demo Request': '#c3f7fe',
  'Landing Page': '#c3f7fe',
  'Content': '#c3f7fe',
  '14 Day Trial': '#59e9cf',
  'Customer Outreach': '#59e9cf',
  'Inbound': '#59e9cf',
  'Inbound Call Follow Up': '#59e9cf',
  'Webinar': '#5aecd4',
  'Events': '#5aecd4',
  'Event': '#5aecd4',
  'Event Lead': '#5aecd4',
  'Trade Show': '#5aecd4',
  'Center of Influence': '#59e9cf',
  'Channels': '#59e9cf',
  'Internal Referral': '#59e9cf',
  'Email': '#5aecd4',
  'Email Marketing': '#5aecd4',
  'BrightTALK': '#5aecd4',
  'G2': '#5aecd4',
  'Comparison Site': '#5aecd4',
  'Paid Advertising': '#85EBD9',
  'Paid Search': '#85EBD9',
  'Paid Media': '#85EBD9',
  'Google Natural Search': '#85EBD9',
  'Bing Natural Search': '#85EBD9',
  'Chat Follow Up': '#85EBD9',
  'Referral': '#59e9cf',
  'External Referral': '#59e9cf',
  'Employee Referral': '#59e9cf',
  'Partner': '#59e9cf',
  'Partners': '#59e9cf',
  'PLG Inbound': '#59e9cf',
  'Inbound Call': '#a04bc2',
  'Offline': '#a04bc2',
  'Sales Prospecting': '#a04bc2',
  'Sales Sourced': '#a04bc2',
  'Sales Generated': '#a04bc2',
  'RevOps': '#a04bc2',
  'Cognism CE': '#a04bc2',
  'SDR Outbound': '#a04bc2',
  'BDR': '#a04bc2',
  'Producer Outbound': '#a04bc2',
  'LinkedIn': '#8a3461',
  'Social Media': '#8a3461',
  'AM': '#8a3461',
  'CS': '#8a3461',
  'AE': '#8a3461',
  'GovWin': '#8a3461',
  'Business Development': '#a04bc2',
  'Paid Social': '#8a3461',
  'Outbound': '#a04bc2',
}

const OpportunityOverChart: FC<{ viewType: string }> = ({ viewType }) => {
  const { isHarry } = useFeatureContext()
  const [selectedType, selectType] = useState('')
  const { data: monthlyData, loading: monthlyLoading } = useMonthlyPipelineQuery({
    skip: viewType !== 'monthly'
  })
  const { data: weeklyData, loading: weeklyLoading } = useWeeklyPipelineQuery({
    skip: viewType !== 'weekly'
  })

  const months: any[] = []
  let jan = Date.parse(moment().startOf('year') as any)
  if (isHarry) {
    jan = Date.parse(moment().startOf('year').add(1, 'month') as any)
  }
  months.push(jan)
  for (let i = 1; i <= 11; i++) {
    months.push(Date.parse(moment(jan).add(i, 'month') as any))
  }

  const weeks: any[] = []
  let startYearDate = Date.parse(moment().startOf('year') as any)
  if (isHarry) {
    startYearDate = Date.parse(moment().startOf('year').month(1) as any)
  }
  const startWeekDate = Date.parse(moment().startOf('week').add(1, 'days') as any)
  let loopDate = startWeekDate
  while (loopDate > startYearDate) {
    weeks.push(loopDate)
    loopDate = Date.parse(moment(loopDate).subtract(1, 'weeks') as any)
  }
  weeks.sort((a, b) => a - b)

  let chartData: any[] = []
  let gqlData: any[] = []

  if (viewType === 'monthly') {
    gqlData = monthlyData ? monthlyData.monthly_pipeline_gql_new : []
  } else {
    gqlData = weeklyData ? weeklyData.weekly_pipeline_gql_new : []
  }

  chartData = gqlData.filter((p: any) => moment())
  // chartData = gqlData.filter((p: any) => moment().startOf('year').isSameOrBefore(p.date_trunc))
  if (viewType === 'weekly') {
    const availableWeeks = chartData.map(d => Date.parse(d.date_trunc))
    weeks.forEach(week => {
      if (availableWeeks.indexOf(week) === -1) {
        chartData.push({
          date_trunc: moment(new Date(week)).format('YYYY-MM-DD'),
          amount: 0
        })
      }
    })
  }
  if (viewType === 'monthly') {
    const availableMonths = chartData.map(d => Date.parse(d.date_trunc))
    months.forEach(month => {
      if (availableMonths.indexOf(month) === -1) {
        chartData.push({
          date_trunc: moment(new Date(month)).format('YYYY-MM-DD'),
          amount: 0
        })
      }
    })
  }
  chartData.sort((a, b) => sortCompareFuncByDate(a, b, 'date_trunc'))

  let typeDetails = [
    ...new Set(chartData.map((d) => d.lead_source)) as any,
  ]
  typeDetails = typeDetails.filter(el => el) // remove null or undefined

  const onSelectType = (type: string) => {
    selectType(selectedType === type ? '' : type)
  }

  const TypeDetails = () => {
    return (
      <div className='flex justify-end flex-wrap mt-6'>
        {typeDetails.map((type, index) => {
          let opacity = 1
          if (selectedType) {
            opacity = selectedType === type ? 1 : 0.3
          }
          return (
            <div key={index} className='flex items-center cursor-pointer ml-3 my-1' style={{ opacity }} onClick={() => onSelectType(type)}>
              <div className='w-2.5 h-2.5 rounded-full bg-black' style={{background: leadSourceColors[type]}}></div>
              <div className='text-xxs ml-1'>{type}</div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className='opportunity-over-container'>
      <div className="overflow-hidden rounded-lg bg-white dark:bg-header-dark dark:border-gray-500 px-4 py-5 shadow sm:p-6 mb-6">
        <div className='text-sm dark:text-theme-toggle-icon'>Opportunity Creation Over Time</div>
        {(monthlyLoading || weeklyLoading) ? (
          <div className='flex justify-center'>
            <Spin />
          </div>
        ) : (
          <>
            <TypeDetails />
            <Chart viewType={viewType} data={chartData} selectedType={selectedType} />
          </>
        )}
      </div>
    </div>
  )
}

const Chart: FC<{ data: any[]; viewType: string; selectedType: string; }> = ({ data, viewType, selectedType }) => {
  const width = 995
  const height = 200
  const left = 50
  const right = 20
  const top = 20
  const bottom = 30
  const chartWidth = width - left - right
  const chartHeight = height - top - bottom

  useEffect(() => {
    draw()
  })

  const draw = () => {
    d3.select('.opportunity-over-chart').select('svg').remove()
    d3.select('.opportunity-over-chart').selectAll('.tooltip').remove()
    const svg = d3
      .select('.opportunity-over-chart')
      .append('svg')
      .attr('class', 'max-h-64')
      .attr('viewBox', `0 0 ${chartWidth + left + right} ${chartHeight + top + bottom}`)
      .attr('width', '100%')
      .attr('preserveAspectRatio', 'xMidYMin')
      .on('click', function (e) {
        if (e.target.nodeName !== 'text') {
          // d3.selectAll('.opportunity-type-detail')
          //   .attr('opacity', 1)
          //   .classed('active', false)
        }
      })
      .append('g')
      .attr('transform', `translate(${left},${top})`)

    const dataByDate = d3.group(data, (d) => d.date_trunc)

    const transformedData = []
    let subLeadSources: any[] = []
    for (const [date, originalRows] of dataByDate as any) {
      originalRows.sort((a: any, b: any) => ('' + a.lead_source).localeCompare(b.lead_source))
      const row: any = { date }
      for (const [index, d] of originalRows.entries()) {
        if (d.lead_source) {
          const subLeadSourceValue = `${d.lead_source}_${index}`
          subLeadSources.push(subLeadSourceValue)
          row[subLeadSourceValue] = 1
          row[`${subLeadSourceValue} Detail`] = d
        }
      }
      transformedData.push(row)
    }
    subLeadSources = [...new Set(subLeadSources) as any]
    subLeadSources.sort()

    const xValue = (d: any) => d.date_trunc
    const valueDomain = [...new Set(data.map((d) => xValue(d))) as any]
    let typeDetails = [
      ...new Set(data.map((d) => d.lead_source)) as any,
    ]
    typeDetails = typeDetails.filter(el => el) // remove null or undefined
    const stackedData = d3.stack().keys(subLeadSources)(
      transformedData
    )
    let maxValue = 10;
    if (data.length > 0) {
      maxValue = Math.max(...stackedData.map(stackedItem => {
        return Math.max(...stackedItem.map(item => {
          return isNaN(item[1]) ? 0 : item[1]
        }))
      }))
    }
    maxValue = maxValue <= 0 ? 10 : maxValue;

    const xScale = d3.scaleBand()
      .domain(valueDomain)
      .range([0, chartWidth])
      .padding(3/valueDomain.length)

    svg.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0,${chartHeight})`)
      .call(
        d3.axisBottom(xScale)
          .tickFormat((x: any) => moment(x).format(viewType === 'weekly' ? 'MM/DD' : 'MMM'))
      )

    svg.select('.x-axis').select('path').remove()
    svg.select('.x-axis')
        .selectAll('g')
        .select('line')
        .remove()
    svg.select('.x-axis')
        .selectAll('g')
        .select('text')
        .style('color', '#6B7280')
        .style('display', (d, index) => (viewType === 'monthly' || index % 3 === 0) ? 'block' : 'none')

    const yScale = d3.scaleLinear()
      .domain([maxValue + 2, 0])
      .range([0, chartHeight])
      .nice()

    svg.append("g")
      .attr("class", "grid")
      .attr("class", "xaxis")
      .call(
        d3.axisLeft(yScale)
        .ticks(4)
        .tickSize(-chartWidth)
        .tickPadding(10)
      )
    svg.select('.xaxis').select('path').remove()
    svg.select('.xaxis')
      .selectAll('line')
      .style("stroke", "#555")
      .style("stroke-width", 0.5)
      .style("opacity", 0.5)
    svg.select('.xaxis')
      .selectAll('g')
      .select('text')
      .style('color', '#6B7280')
      .style('display', d => {
        return d === 0 ? 'none' : ''
      })

    const div = d3.select(".opportunity-over-chart").append("div")
      .attr("class", "tooltip bg-chart-tooltip border border-revtron-red")
      .style("opacity", 0)

    svg.append('g')
      .selectAll('g')
      // Enter in the stack data = loop key per key = group per group
      .data(stackedData)
      .join('g')
      .attr('fill', (d: any): any => leadSourceColors[d.key.split('_')[0]])
      .attr("class", (d: any): any => `opportunity-type-detail ${d.key.split('_')[0].toLowerCase().replaceAll(' ', '-')}`)
      .attr('opacity', (d) => {
        const currentTypeDetail = d.key.split('_')[0]
        let opacity = 1
        if (selectedType) {
          opacity = selectedType === currentTypeDetail ? 1 : 0.1
        }
        return opacity
      })
      .selectAll('rect')
      // enter a second time = loop subgroup per subgroup to add all rectangles
      .data((d) => d)
      .join('rect')
      .attr('x', (d, i): any => xScale(d.data.date as any))
      .attr('y', (d) => yScale(isNaN(d[1]) ? 0 : d[1]))
      .attr('height', (d) => isNaN(d[1]) ? 0 : yScale(d[0]) - yScale(d[1]))
      .attr('width', xScale.bandwidth())
      .attr('stroke', '#b839c7')
      .attr('stroke-width', .3)
      .on('mouseover', function (event, d) {
        const typeData: any = Object.values(d.data)[d[0]*2 + 2]
        d3.select(this).attr('opacity', 0.75)
        div.transition()
          .duration(200)
          .style("opacity", 1)
        div.html(`
            <div class="text-chart-tooltip-text ">
              <p>Opportunity: ${typeData.opportunity_name}</p>
              <p>Owner: ${typeData.owner_name}</p>
              <p>Stage: ${typeData.stagename}</p>
              <p>Amount: ${numberWithCommas(typeData.amount)}</p>
              <p>LeadSource: ${typeData.lead_source}</p>
            </div>
          `)
          .style("left", (event.offsetX - 80) + "px")
          .style("top", (event.offsetY - 60) + "px")
      })
      .on('mouseout', function (d) {
        d3.select(this).attr('opacity', 1)
        div.transition()
          .duration(500)
          .style("opacity", 0)
      })
  }

  return (
    <div className="opportunity-over-chart relative" />
  )
}

export default OpportunityOverChart;