import { FC } from 'react'
import moment from 'moment'

import Spin from '../../../atoms/spin'
import AreaChart from './areaChart'

import { useAccountExpandedRowQuery } from '../../../../graphql/__generated__/graphql'

const ExpandedRow: FC<ExpandedRowProps> = ({ accountId }) => {
  const { data: gqlData, loading } = useAccountExpandedRowQuery({
    variables: { account_id: accountId },
    fetchPolicy: 'cache-first'
  })

  const data: any = gqlData && gqlData.account_module_expansion_gql[0]

  if (loading) {
    return (
      <div className='w-full flex justify-center items-center my-4'>
        <Spin />
      </div>
    )
  }

  return (
    <div className='flex items-center justify-around py-6'>
      <div className=''>
        <div className='dark:text-table-text'>
          {moment(data.daily_engagement_aggregate.aggregate.max.activity_date).format('YYYY-MM-DD')}
        </div>
        <div className=''>Last engaged</div>
      </div>
      <div className=''>
        <div className='dark:text-table-text'>{data.number_of_contacts}</div>
        <div className=''>Contacts</div>
      </div>
      <div className=''>
        <div className='dark:text-table-text'>{data.open_opp_count}</div>
        <div className=''>Opportunities</div>
      </div>
      <div className=''>
        <AreaChart data={data.daily_engagement} />
      </div>
    </div>
  )
}

interface ExpandedRowProps {
  accountId: any
}

export default ExpandedRow
