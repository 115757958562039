import { FC, useRef, useEffect } from 'react'
import * as d3 from 'd3'
import { omit } from 'lodash'
import { ICPFilters } from '../../../@types/module'
import { numberWithCommas } from '../../../utils/functions'

interface HistogramChartProps {
  data: any[]
  color: string
  type: 'employeeCount' | 'ageOfBusiness' | 'annualRevenue'
  xFormat?: Function
  xUnit?: number
  filters: ICPFilters
  setFilters: Function
}

const HistogramChart: FC<HistogramChartProps> = ({ data, color, xFormat, xUnit = 1, type, filters, setFilters }) => {
  const ref = useRef<HTMLDivElement>(null)
  let normalize: any = undefined // whether to normalize values to a total of 100%
  let marginTop: any = 20 // top margin, in pixels
  let marginRight: any = 40 // right margin, in pixels
  let marginBottom: any = 60 // bottom margin, in pixels
  let marginLeft: any = 20 // left margin, in pixels
  let width: any = 640 // outer width of chart, in pixels
  let height: any = 300 // outer height of chart, in pixels
  let insetLeft: any = 1 // inset left edge of bar
  let insetRight: any = 1 // inset right edge of bar
  // let yFormat: any = undefined // a format specifier string for the y-axis

  useEffect(() => {
    if (data.length > 0) {
      draw()
    }
  }, [data])

  const draw = () => {
    d3.select(ref.current).select('svg').remove()
    d3.select(ref.current).selectAll('.tooltip').remove()

    const svg = d3
      .select(ref.current)
      .append('svg')
      .attr('viewBox', `0 0 ${width} ${height}`)
      .attr('width', '100%')
      .attr('height', '100%')
      .append('g')
      .attr('transform', `translate(${marginLeft/2},${marginTop})`)

    const I = d3.range(data.length)
    const tooltip = d3.select(ref.current).append("div").attr('class', 'tooltip whitespace-nowrap')
    const isSelected = (d: any) => JSON.stringify(filters[type]) === JSON.stringify([(d.x0 || 0)*xUnit, (d.x1 || 0)*xUnit])

    const thresholds = data.length > 50 ? 50 : data.length
    // Compute bins.
    const bins = d3.bin().thresholds(thresholds).value(i => data[i])(I)

    const Y = Array.from(bins, I => d3.sum(I, i => 1))
    if (normalize) {
      const total = d3.sum(Y)
      for (let i = 0; i < Y.length; ++i) Y[i] /= total
    }
  
    // Compute default domains.
    const xDomain: any = [bins[0].x0, bins[bins.length - 1].x1]
    const yDomain: any = [0, d3.max(Y)]
  
    // Construct scales and axes.
    const xScale = d3.scaleLinear(xDomain, [marginLeft, width - marginRight]).nice()
    const yScale = d3.scaleLinear(yDomain, [height - marginBottom, marginTop]).nice()
    const xAxis = d3.axisBottom(xScale).tickSize(2).tickPadding(8).tickFormat((d: any) => xFormat ? xFormat(d) : d)
    // const yAxis = d3.axisLeft(yScale).tickSize(2).tickPadding(10).tickFormat((d: any) => yFormat ? yFormat(d) : d)
  
    // svg.append("g")
    //     .attr("transform", `translate(${marginLeft},0)`)
    //     .call(yAxis)
    //     .call(g => g.select(".domain").remove())
    //     .call(g => g.selectAll(".tick line").clone()
    //         .attr("x2", width - marginLeft - marginRight)
    //         .attr("stroke-opacity", 0.1))
    //         .style('color', '#6B7280')
    //         .style('font-size', '0.875rem')
  
    svg.append("g")
        .attr("fill", color)
      .selectAll("rect")
      .data(bins)
      .join("rect")
        .attr("x", (d: any) => xScale(d.x0) + insetLeft)
        .attr("width", (d: any) => Math.max(0, xScale(d.x1) - xScale(d.x0) - insetLeft - insetRight))
        .attr("y", (d, i) => yScale(Y[i]))
        .attr("height", (d, i) => yScale(0) - yScale(Y[i]))
        .attr('class', (d) => {
          let classes = 'cursor-pointer stroke-gray-600 hover:stroke-revtron-cyan '
          if (filters && filters[type]) {
            classes += isSelected(d) ? '!stroke-revtron-cyan' : ''
          }
          return classes
        })
        .style("stroke-width", 0.5)
        .attr('opacity', (d) => {
          let opacity = 1
          if (filters && filters[type]) {
            opacity = isSelected(d) ? 1 : 0.3
          }
          return opacity
        })
        .on('mouseover', function (event, d) {
          d3.select(this).attr('opacity', 0.7)
          tooltip.transition()
            .duration(200)
            .style("opacity", 1)
          tooltip.html(`
            <div class="bg-white border p-2">
              <p>Count: ${d.length}</p>
              <p>Min: ${d.x0 ? numberWithCommas(d.x0 * xUnit) : 0}</p>
              <p>Max: ${d.x1 ? numberWithCommas(d.x1 * xUnit) : 0}</p>
            </div>
          `)
          .style("left", (event.offsetX) + "px")
          .style("top", (event.offsetY) + "px")
        })
        .on('mouseout', function (e, d) {
          let opacity = 1
          if (filters && filters[type]) {
            opacity = isSelected(d) ? 1 : 0.3
          }
          d3.select(this).attr('opacity', opacity)
          tooltip.transition().duration(500).style("opacity", 0)
        })
        .on('click', function (e, d) {
          if (isSelected(d)) {
            setFilters(omit(filters, type))
          } else {
            setFilters({...filters, [type]: [(d.x0 || 0)*xUnit, (d.x1 || 0)*xUnit]})
          }
        })
  
    svg.append("g")
        .attr("transform", `translate(0,${height - marginBottom})`)
        .call(xAxis)
        .call(g => g.select(".domain").remove())
        .call(g => g.selectAll(".tick line").clone()
            .attr("y2", -(height - marginTop - marginBottom))
            .attr("stroke-opacity", 0.25))
            .style('color', '#6B7280')
            .style('font-size', '0.875rem')
  }

  return (
    <div ref={ref} className='relative' />
  )
}

export default HistogramChart
