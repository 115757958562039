import { FC } from 'react'
import moment from 'moment'
import { sortCompareFuncByDate } from '../../../../utils/functions'
import StackedBarChart from './stackedBarChart'

const DrillItem: FC<DrillItemProps> = ({ data }) => {
  let chartData: any[] = []
  const startDate = moment().subtract(3, 'months')
  const endDate = moment().add(1, 'month')
  const tickValues = []
  let val = moment().subtract(3, 'months')
  while (val < endDate) {
    tickValues.push(Date.parse(moment(val).format('MM/DD/YYYY')))
    val = val.add(1, 'weeks')
  }
  tickValues.push(Date.parse(moment(endDate).format('MM/DD/YYYY')))

  if (data) {
    data.sort((a:any, b:any) => sortCompareFuncByDate(a, b, 'createddate'))
    chartData = data.filter((p: any) => moment(p.createddate).isSameOrAfter(startDate) && moment(p.createddate).isSameOrBefore(endDate))
  }

  return (
    <div className='w-full flex items-center'>
      <p className='w-52 text-xs whitespace-pre-line'>{data && data.length > 0 && data[0].contact_name}</p>
      <p className='w-52 text-xs whitespace-pre-line'>{data && data.length > 0 && data[0].contact_title}</p>
      <StackedBarChart tickValues={tickValues} data={chartData} />
    </div>
  )
}

interface DrillItemProps {
  data: any
}

export default DrillItem
