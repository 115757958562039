import { useEffect, useCallback, useMemo, useState } from 'react'
import { getAccountRecModuleData, getCrmEventData, getQueryVariables } from './AccountRec.gigpro.util'
import { useAccountRecModuleforGigProLazyQuery } from '../../../graphql/__generated__/gigpro_graphql'
import { useSendDataToCrmEventsMutation } from '../../../graphql/__generated__/graphql'
import { alert } from '../../../utils/functions'
import { useAccountTable } from '../../../components/organisms/account-table/hook'

const useGraphQLForGigpro = () => {
  const [isSendToCrmLoading, setSendToCrmLoading] = useState<boolean>(false)
  const [loadAccountRecModuleData, { data: accountRecModuleData, loading: accountRecModuleLoading }] = useAccountRecModuleforGigProLazyQuery()
  const [sendAccountRecsToCrm] = useSendDataToCrmEventsMutation()

  const {
    pageNumber,
    pageSize,
    accountMappingsData,
    loggedinUser,
    filters,
    revTronFields,
    ...rest
  } = useAccountTable(true)
  
  useEffect(() => {
    const variables = getQueryVariables(pageNumber, pageSize, filters)
    loadAccountRecModuleData({ variables })
  }, [pageNumber, pageSize, filters, loadAccountRecModuleData])

  const accountRecData = useMemo(() => {
    if (accountRecModuleData) {
      return getAccountRecModuleData(accountRecModuleData)
    }
    return { bussinessDetails: [], totalRecordsCount: 0 }
  }, [accountRecModuleData])

  const sendToCRM = useCallback((selectedIds: number[]) => {
    if (selectedIds.length > 0) {
      const crmEventsData = getCrmEventData(selectedIds, accountRecModuleData, accountMappingsData, loggedinUser)
      if (crmEventsData.length > 0) {
        setSendToCrmLoading(true)

        sendAccountRecsToCrm({ variables: { crmEventsData } })
          .then(() => {
            // Load AccountRecModuleData again with current pageNumber, pageSize, and filters
            const variables = getQueryVariables(pageNumber, pageSize, filters)
            variables.where['yelp_id'] = { _nin: selectedIds }
            return loadAccountRecModuleData({ variables })
          })
          .then(() => {
            setSendToCrmLoading(false)
            alert('success', 'Successfully Sent to CRM' )
          })
          .catch((err) => {
            setSendToCrmLoading(false)
            alert('error', err?.message || 'Something went wrong')
          })
      }
    }
  }, [accountRecModuleData, accountMappingsData, loggedinUser, sendAccountRecsToCrm, pageNumber, pageSize, filters, loadAccountRecModuleData])

  return {
    fields: revTronFields,
    accountRecData,
    loading: accountRecModuleLoading,
    pageNumber,
    pageSize,
    filters,
    sendToCRM,
    isSendToCrmLoading,
    ...rest
  }
}

export default useGraphQLForGigpro