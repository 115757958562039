import { FC, useRef, useEffect } from 'react'
import * as d3 from 'd3'

import { colorsGroup } from '../../../utils/variable'
import { numberWithCommas } from '../../../utils/functions'

const getRandomBg = () => {
  return colorsGroup[Math.floor(Math.random() * colorsGroup.length)]
}

const HorizontalBarChart: FC<{ data: any[] }> = ({ data }) => {
    const margin = { top: 20, right: 50, bottom: 50, left: 30 }
    const svgWidth = 995
    const svgHeight = 50 * (data.length === 0 ? 2 : data.length) + margin.top + margin.bottom
    const width = svgWidth - margin.left - margin.right
    const height = svgHeight - margin.top - margin.bottom
    const barWidth = 30
    const ref = useRef<HTMLDivElement>(null)

    useEffect(() => {
      draw()
    }, [data])

    const draw = () => {
      d3.select(ref.current).select('svg').remove()
      d3.select(ref.current).selectAll('.tooltip').remove()

      const svg = d3
        .select(ref.current)
        .append('svg')
        .attr('viewBox', `0 0 ${svgWidth} ${svgHeight}`)
        .attr('width', '100%')
        .attr('height', '100%')
        .append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`)

      const defs = svg.append('defs')
      
      const bgGradient = defs
        .append('linearGradient')
        .attr('id', 'bg-gradient-vertical')
      bgGradient
        .append('stop')
        .attr('stop-color', '#4fd1c51a')
        .attr('offset', '0%')
      bgGradient
        .append('stop')
        .attr('stop-color', '#4fd1c58a')
        .attr('offset', '100%')

      // Add X axis
      var xScale = d3.scaleLinear()
        .domain([0, d3.max(data, d => d.x) || 10000])
        .range([ 0, width])
      svg.append("g")
        .attr('class', 'x-axis')
        .attr("transform", "translate(0," + height + ")")
        .call(
          d3.axisBottom(xScale)
            .tickSize(-height) // set x-axis grid width by using tick size)
        )

      svg.select('.x-axis').select('path').remove()
      svg.select('.x-axis')
        .selectAll('g')
        .select('line')
        .style("stroke-width", 0.3)
        .style("opacity", 0.5)
      svg.select('.x-axis')
        .selectAll('g')
        .select('text')
        .style('color', '#6B7280')
        .style("text-anchor", "start")

      // Y axis
      var yScale = d3.scaleBand()
        .range([ 0, height ])
        .domain(data.map(d => d.y))
        .padding(.3)
      
      svg.append("g")
        .attr("class", "y-axis")
        .call(d3.axisLeft(yScale))

      svg.select('.y-axis').select('path').remove()
      svg.select('.y-axis')
        .selectAll('line')
        .remove()
      svg.select('.y-axis')
        .selectAll('text')
        .remove()

      const div = d3.select(ref.current).append("div")
        .attr("class", "tooltip bg-chart-tooltip border border-revtron-cyan")
        .style("opacity", 0)

      // Bars
      const bar = svg.selectAll("myRect")
            .data(data)
            .enter()
            .append("g")
            .attr("class", "bar")

      // Add rect to bar
      bar.append("rect")
        .style("fill", "url(#bg-gradient-vertical)")
        .attr("stroke", "#05D9E8")
        .attr("stroke-width", "1px")
        .attr("x", 0 )
        .attr("y", d => yScale(d.y) || null)
        .attr("width", d => xScale(d.x))
        .attr("height", barWidth)
        .attr("fill", "#69b3a288")

      // Add image label to bar
      let imgDef = bar.append("defs").attr("id", "imgdefs")
      imgDef.append("clipPath")
        .attr("id", (d, i) => `clip-circle_${i}`)
        .append("circle")
        .attr("r", barWidth / 2)
        .attr("cx", d => xScale(d.x) + 10 + barWidth / 2)
        .attr("cy", d => (yScale(d.y) || 0) + barWidth / 2)
      bar.append("image")
        .attr("x", d => xScale(d.x) + 10)
        .attr("y", d => yScale(d.y) || 0)
        .attr("height", barWidth)
        .attr("width", barWidth)
        .attr("xlink:href", d => {
          const defaultPhoto = `https://ui-avatars.com/api/?name=${d.firstName}+${d.lastName}&size=38.135&rounded=true&background=${getRandomBg()}&color=FFFFFF`
          return (!d.icon || d.icon.indexOf('default=blank') > - 1) ? defaultPhoto : d.icon
        })
        .attr("clip-path", (d, i) => `url(#clip-circle_${i})`)
        .on('mouseover', function(event, d) {
          const userName = d.firstName + ' ' + d.lastName
          const amount = d.x
          d3.select(this).attr('opacity', 0.75)
          div.transition()
            .duration(200)
            .style("opacity", 1)
          div.html(`
            <div class="text-chart-tooltip-text">
              <p>User name: ${userName}</p>
              <p>Amount: $${numberWithCommas(amount)}</p>
            </div>
          `)
          .style("left", (event.x - 100) + "px")
          .style("top", (event.y - 60) + "px")
        })
        .on('mouseout', function (d) {
          d3.select(this).attr('opacity', 1)
          div.transition()
            .duration(500)
            .style("opacity", 0)
        })
    }

    return (
      <div className='w-full' ref={ref} />
    )
}

export default HorizontalBarChart