import { useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { useResetPassword } from '@nhost/react'

import AuthWrapper from '../../components/templates/authWrapper'
import TextInput from '../../components/molecules/textInput'

const ResetPassword = () => {
  const [email, setEmail] = useState<string>('')
  const { resetPassword, isLoading, isSent, isError, error } = useResetPassword()
  const navigate = useNavigate()

  const handleOnSubmit = async (e: any) => {
    e.preventDefault()

    try {
      const result = await resetPassword(email, {
        redirectTo: '/settings/change-password'
      })

      if (result && result.isSent) {
        navigate('/sign-in')
      }
    } catch (err) {
      console.log('Error::', err)
    }
  }

  return (
    <AuthWrapper>
      <div className='flex justify-center items-center'>
        <div className="w-full max-w-lg">
          <p className='text-center mt-36 text-revtron-dark4 text-sm'>
            Forgot your password?
          </p>
          <h1 className="mt-12 mb-36 text-center text-4xl font-semibold dark:text-revtron-gray1">Recover your password</h1>
          <div className="sm:rounded-xl sm:shadow-md sm:border border-theme-toggle-white-bg dark:border-revtron-dark3 bg-auth-background dark:bg-auth-background-dark backdrop-blur px-4 sm:px-8 py-12 flex flex-col items-center">
            <form onSubmit={handleOnSubmit} className="w-full">
              <div className="mt-6 w-full flex flex-col items-center space-y-6">
                <TextInput
                  type="email"
                  placeholder="Email address"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required
                  error={isError}
                />
              </div>

              {isError ? (
                <p className="mt-4 text-red-400">
                  {error?.message}
                </p>
              ) : null}

              <p className='mt-4 dark:text-table-head'>
                Enter your email and we will send you password recovery instructions
              </p>

              <button
                type="submit"
                className="mt-6 w-full bg-auth-btn-gradient font-medium inline-flex justify-center items-center rounded-md p-3 text-header-dark bg-blue-600 hover:bg-blue-500 focus:outline-none focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed  disabled:hover:bg-blue-600 disabled:hover:border-bg-600 transition-colors"
              >
                {isLoading ? 'Loading...' : 'Send instructions'}
              </button>
              <p className="sm:mt-8 text-gray-500 text-center">
                Already have an account?{' '}
                <Link
                  to="/sign-in"
                  className="text-blue-600 hover:text-blue-500 hover:underline hover:underline-offset-1 transition"
                >
                  Sign in
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </AuthWrapper>
  )
}

export default ResetPassword