export const FLAG_NAMES = {
  HARRY_QUARTERS: 'harri-quarters',
  XIMA_FLAG: 'xima-flag',
  HRSOFT_FLAG: 'hrsoft-flag',
  GIGPRO_FLAG: 'gigpro-flag',
  PRODUCTPLAN_FLAG: 'productplan-flag',
  XYLEME_FLAG: 'xyleme-flag',
  NEWFRONT_FLAG: 'newfront-flag',
  IN_DEVELOPMENT_FLAG: 'in-development',
  NEWFRONT_SANDBOX_FLAG: 'newfront-sandbox-flag',
}

export const DOMAIN_FLAGS = {
  HARRI: 'harri',
  XIMA: 'xima',
  HRSOFT: 'hrsoft',
  GIGPRO: 'gigpro',
  PRODUCTPLAN: 'productplan',
  XYLEME: 'xyleme',
  NEWFRONT: 'newfront',
  NEWFRONT_SANDBOX: 'nf-sandbox',
}

export const START_MONTH_OF_FISCAL_YEAR = 'Feb'

export const DEFAULT_FIELD_MAPPINGS = [
  {
    id: 1,
    revtron_field: 'natural_name',
    crm_field: 'Name',
    is_active: false,
    method: 'Fill',
    isNew: true
  },
  {
    id: 2,
    revtron_field: 'website',
    crm_field: 'Website',
    is_active: false,
    method: 'Fill',
    isNew: true
  },
  {
    id: 3,
    revtron_field: 'phone',
    crm_field: 'Phone',
    is_active: false,
    method: 'Fill',
    isNew: true
  },
  {
    id: 4,
    revtron_field: 'city',
    crm_field: 'BillingCity',
    is_active: false,
    method: 'Fill',
    isNew: true
  },
  {
    id: 5,
    revtron_field: 'state',
    crm_field: 'BillingState',
    is_active: false,
    method: 'Fill',
    isNew: true
  },
  {
    id: 6,
    revtron_field: 'country',
    crm_field: 'BillingCountry',
    is_active: false,
    method: 'Fill',
    isNew: true
  },
  {
    id: 7,
    revtron_field: 'industry',
    crm_field: 'Industry',
    is_active: false,
    method: 'Fill',
    isNew: true
  },
  {
    id: 8,
    revtron_field: 'employee_counts',
    crm_field: 'NumberOfEmployees',
    is_active: false,
    method: 'Fill',
    isNew: true
  },
]

export const DEFAULT_FIELD_MAPPINGS_GIGPRO = [
  {
    id: 1,
    revtron_field: 'name',
    crm_field: 'Name',
    is_active: false,
    method: 'Fill',
    isNew: true
  },
  {
    id: 2,
    revtron_field: 'website',
    crm_field: 'Website',
    is_active: false,
    method: 'Fill',
    isNew: true
  },
  {
    id: 3,
    revtron_field: 'phone',
    crm_field: 'Phone',
    is_active: false,
    method: 'Fill',
    isNew: true
  },
  {
    id: 4,
    revtron_field: 'billingcity',
    crm_field: 'BillingCity',
    is_active: false,
    method: 'Fill',
    isNew: true
  },
  {
    id: 5,
    revtron_field: 'billingstate',
    crm_field: 'BillingState',
    is_active: false,
    method: 'Fill',
    isNew: true
  },
  {
    id: 6,
    revtron_field: 'billingcountry',
    crm_field: 'BillingCountry',
    is_active: false,
    method: 'Fill',
    isNew: true
  },
]
