import { FC } from 'react'
import { Switch as HeadlessSwitch } from '@headlessui/react'
import { classNames } from '../../../utils/functions'

type SwitchProps = {
  enabled: boolean
  onChange: (v: boolean) => void
}

const Switch: FC<SwitchProps> = ({ enabled, onChange }) => {

  return (
    <HeadlessSwitch
      checked={enabled}
      onChange={onChange}
      className="group relative inline-flex h-5 w-10 flex-shrink-0 cursor-pointer items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-revtron-cyan focus:ring-offset-2"
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'bg-revtron-cyan' : 'bg-gray-500 dark:bg-gray-700',
          'pointer-events-none absolute mx-auto h-4 w-9 rounded-full transition-colors duration-200 ease-in-out'
        )}
      />
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none absolute left-0 inline-block h-5 w-5 transform rounded-full border border-gray-200 bg-white shadow ring-0 transition-transform duration-200 ease-in-out'
        )}
      />
    </HeadlessSwitch>
  )
}

export default Switch
