import { FC, useCallback } from 'react'
import { convertNumberToArray } from '../../../utils/functions'


interface PaginationProps {
  total: number
  current: number
  pageSize: number
  onChange: (page: number, pageSize: number) => void
  showInfo?: boolean
}

const Pagination: FC<PaginationProps> = ({ total, current, pageSize, showInfo = false, onChange }) => {
  const pageLength = convertNumberToArray(Math.ceil(total / pageSize)).length

  const onPageChange = useCallback((c: number, ps: number) => {
    if (c !== current || ps !== pageSize) {
      onChange(c, ps)
    }
  }, [current, onChange, pageSize])

  return (
    <div className={`flex flex-row ${showInfo ? 'justify-between' : 'justify-end'} items-center my-8`}>
      {showInfo && <div className="text-gray-500">Showing {current} to {pageLength} of {total} </div>}
      <nav aria-label='page navigation example'>
        <ul className='inline-flex items-center -space-x-px'>
          {total > 0 && (
            <li>
              <button
                disabled={current === 1}
                className='min-h-10 hover:cursor-pointer block py-2 px-3 ml-0 leading-tight text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-revtron-dark1 dark:border-neutral-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                onClick={() => onPageChange(current - 1, pageSize)}
              >
                <span className='sr-only'>Previous</span>
                <svg aria-hidden='true' className='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path fillRule='evenodd' d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z' clipRule='evenodd'></path></svg>
              </button>
            </li>
          )}
          {(pageLength > 0 && pageLength <= 9) && (
            <>
              {convertNumberToArray(Math.ceil(total / pageSize)).map((pageNum: number, index) => {
                return (
                  <li key={index.toString()}>
                    <button
                      onClick={() => onPageChange(pageNum, pageSize)}
                      className={`py-2 px-3 min-h-10 leading-tight dark:border-neutral-700 border ${pageNum === current ? 'z-10 text-blue-600 bg-blue-50 border-blue-300 hover:bg-blue-100 hover:text-blue-700 dark:bg-gray-700 dark:text-white' : 'text-gray-500 bg-white border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-revtron-dark1 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'}`}>
                      {pageNum}
                    </button>
                  </li>
                )
              })}
            </>
          )}
          {pageLength >=10 && (
            <>
              {convertNumberToArray(Math.ceil(total / pageSize)).filter((pageNum: any) => pageNum <= 3).map((pageNum: any, index) => {
                return (
                  <li key={index.toString()}>
                    <button
                      onClick={() => onPageChange(pageNum, pageSize)}
                      className={`py-2 px-3 min-h-10 leading-tight dark:border-neutral-700 border ${pageNum === current ? 'z-10 text-blue-600 bg-blue-50 border-blue-300 hover:bg-blue-100 hover:text-blue-700 dark:bg-gray-700 dark:text-white' : 'text-gray-500 bg-white border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-revtron-dark1 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'}`}>
                      {pageNum}
                    </button>
                  </li>
                )
              })}
              {current === 4 && (
                <li>
                  <button
                    onClick={() => onPageChange(4, pageSize)}
                    className={`py-2 px-3 min-h-10 leading-tight dark:border-neutral-700 border ${4 === current ? 'z-10 text-blue-600 bg-blue-50 border-blue-300 hover:bg-blue-100 hover:text-blue-700 dark:bg-gray-700 dark:text-white' : 'text-gray-500 bg-white border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-revtron-dark1 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'}`}>
                    4
                  </button>
                </li>
              )}
              {(current >= 5 && current <= pageLength - 4) ? (
                <>
                  <li>
                    <button
                      className='py-2 px-3 min-h-10 leading-tight dark:border-neutral-700 border text-gray-500 bg-white border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-revtron-dark1 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'>
                      ...
                    </button>
                  </li>
                  {convertNumberToArray(Math.ceil(total / pageSize)).map((pageNum, index) => {
                    if (pageNum === current) {
                      return (
                        <li key={index.toString()}>
                          <button
                            onClick={() => onPageChange(pageNum, pageSize)}
                            className={`py-2 px-3 min-h-10 leading-tight dark:border-neutral-700 border ${pageNum === current ? 'z-10 text-blue-600 bg-blue-50 border-blue-300 hover:bg-blue-100 hover:text-blue-700 dark:bg-gray-700 dark:text-white' : 'text-gray-500 bg-white border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-revtron-dark1 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'}`}>
                            {current}
                          </button>
                        </li>
                      )  
                    } else {
                      return null
                    }
                  })}
                  <li>
                    <button
                      className='py-2 px-3 min-h-10 leading-tight dark:border-neutral-700 border text-gray-500 bg-white border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-revtron-dark1 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'>
                      ...
                    </button>
                  </li>
                </>
              ) : (
                <li>
                  <button
                    className='py-2 px-3 min-h-10 leading-tight dark:border-neutral-700 border text-gray-500 bg-white border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-revtron-dark1 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'>
                    ...
                  </button>
                </li>
              )}
              {current === pageLength - 3 && (
                <li>
                  <button
                    onClick={() => onPageChange(4, pageSize)}
                    className={`py-2 px-3 min-h-10 leading-tight dark:border-neutral-700 border ${pageLength - 3 === current ? 'z-10 text-blue-600 bg-blue-50 border-blue-300 hover:bg-blue-100 hover:text-blue-700 dark:bg-gray-700 dark:text-white' : 'text-gray-500 bg-white border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-revtron-dark1 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'}`}>
                    {pageLength - 3}
                  </button>
                </li>
              )}
              {convertNumberToArray(Math.ceil(total / pageSize)).filter((pageNum: any) => pageNum >= pageLength - 2).map((pageNum: any, index) => {
                return (
                  <li key={index.toString()}>
                    <button
                      onClick={() => onPageChange(pageNum, pageSize)}
                      className={`py-2 px-3 min-h-10 leading-tight dark:border-neutral-700 border ${pageNum === current ? 'z-10 text-blue-600 bg-blue-50 border-blue-300 hover:bg-blue-100 hover:text-blue-700 dark:bg-gray-700 dark:text-white' : 'text-gray-500 bg-white border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-revtron-dark1 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'}`}>
                      {pageNum}
                    </button>
                  </li>
                )
              })}
            </>
          )}
          {total > 0 && (
            <li>
              <button
                disabled={current === pageLength}
                className='min-h-10 hover:cursor-pointer block py-2 px-3 leading-tight text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-revtron-dark1 dark:border-neutral-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                onClick={() => onPageChange(current + 1, pageSize)}
              >
                <span className='sr-only'>Next</span>
                <svg aria-hidden='true' className='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path fillRule='evenodd' d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z' clipRule='evenodd'></path></svg>
              </button>
            </li>
          )}
        </ul>
      </nav>
    </div>
  )
}

export default Pagination