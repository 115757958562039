import { FC } from 'react'

import ShadowCard from '../../templates/shadowCard'
import Progress from '../../molecules/progressBar'

import { useTeamActivityQuery } from '../../../graphql/__generated__/graphql'
import { isInteger } from '../../../utils/functions'

type keyType = 'calls_out' |
'calls_actuals' |
'calls_target' |
'emails_sent' |
'email_actuals' |
'email_targets' |
'meetings' |
'opportunities' |
'opportunity_targets' |
'opportunity_actuals' |
'social' |
'social_actuals' |
'social_target'

const TeamActivity: FC = () => {
  const { data: teamActivity, loading: teamLoading } = useTeamActivityQuery()

  const getPercentage = (key: keyType) => {
    if (teamActivity && teamActivity.team_activity_gql && teamActivity.team_activity_gql.length > 0) {
      return isInteger(teamActivity.team_activity_gql[0][key])
    }
  }

  const getLeftValue = (key: keyType) => {
    if (teamActivity && teamActivity.team_activity_gql) {
      return teamActivity.team_activity_gql[0][key]
    }
  }

  const getSumData = (key: keyType) => {
    if (teamActivity && teamActivity.team_activity_gql && teamActivity.team_activity_gql.length > 0) {
      return isInteger(teamActivity.team_activity_gql[0][key])
    }
  }

  return (
    <ShadowCard label='Team Activity' loading={teamLoading} className='mt-5'>
      <div className='bg-white dark:bg-revtron-dark2 rounded-lg p-4'>
        <div className='percent-item mb-2.5'>
          <div className='detail'>
            <p className='name text-gray-500 dark:text-gray-400 text-xs'>Opportunities created</p>
          </div>
          <Progress
            percent={getPercentage('opportunities') || 0 as any}
            strokeColor='#00BF9A'
          />
          <div className='text-xs team-and-target text-theme-toggle-icon flex justify-end mr-32'>
            <span>{getLeftValue('opportunity_actuals')}</span>&nbsp;|&nbsp;<span>{getSumData('opportunity_targets')}</span>
          </div>
        </div>
        <div className='percent-item mb-2.5'>
          <div className='detail'>
            <p className='name text-gray-500 dark:text-gray-400 text-xs'>Emails Sent</p>
          </div>
          <Progress
            percent={getPercentage('emails_sent') || 0 as any}
            strokeColor='#BA54F5'
          />
          <div className='text-xs team-and-target text-theme-toggle-icon flex justify-end mr-32'>
            <span>{getLeftValue('email_actuals')}</span>&nbsp;|&nbsp;<span>{getSumData('email_targets')}</span>
          </div>
        </div>
        <div className='percent-item mb-2.5'>
          <div className='detail'>
            <p className='name text-gray-500 dark:text-gray-400 text-xs'>Calls Made</p>
          </div>
          <Progress
            percent={getPercentage('calls_out') || 0 as any}
            strokeColor='#BA54F5'
          />
          <div className='text-xs team-and-target text-theme-toggle-icon flex justify-end mr-32'>
            <span>{getLeftValue('calls_actuals')}</span>&nbsp;|&nbsp;<span>{getSumData('calls_target')}</span>
          </div>
        </div>
        <div className='percent-item'>
          <div className='detail'>
            <p className='name text-gray-500 dark:text-gray-400 text-xs'>Social</p>
          </div>
          <Progress
            percent={getPercentage('social') || 0 as any}
            strokeColor='#E14ECA'
          />
          <div className='text-xs team-and-target text-theme-toggle-icon flex justify-end mr-32'>
            <span>{getLeftValue('social_actuals')}</span>&nbsp;|&nbsp;<span>{getSumData('social_target')}</span>
          </div>
        </div>
      </div>
    </ShadowCard>
  )
}

export default TeamActivity
