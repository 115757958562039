import { FC } from 'react'

import Spin from '../../atoms/spin'
import { SummaryTileContainer, SummaryTile } from '../../molecules/summary'

import { useLeaderboardSummaryQuery, useSalesVeloBreakdownQuery } from '../../../graphql/__generated__/graphql'
import { numberWithCommas, isInteger } from '../../../utils/functions'

const LeaderboardSummary: FC = () => {
  const { data: summaryQueryData, loading } = useLeaderboardSummaryQuery()
  const { data: veloData, loading: veloloading } = useSalesVeloBreakdownQuery()
  const summaryData: any = summaryQueryData ? summaryQueryData.leaderboard_summary_gql_aggregate.aggregate : null

  let new_business_win_rate_2 = 0
  let sales_cycle_2 = 0
  let new_business_deal_size = 0
  if (veloData) {
    const salesveloData = veloData.revtron_reporting_sales_velocity_details_gql
    new_business_win_rate_2 = salesveloData.find((v: any) => v.type === "New Business Win Rate")?.stat || 0
    sales_cycle_2 = salesveloData.find((v: any) => v.type === "Sales Cycle")?.stat || 0
    new_business_deal_size = salesveloData.find((v: any) => v.type === "Net New Avg. Deal Value")?.stat || 0
  }

  if (loading || veloloading) {
    return (
      <div className='flex justify-center bg-white dark:bg-header-dark shadow p-4 mb-6'>
        <Spin />
      </div>
    )
  }

  if (!summaryData) {
    return null
  }

  return (
    <SummaryTileContainer className='mb-6'>
      <SummaryTile
        label="Total Bookings"
        value={`$${numberWithCommas(isInteger(summaryData.sum.licenses__c || 0, 0))}`}
      />
      <SummaryTile
        label="# of Wins"
        value={summaryData.count || 0}
      />
      <SummaryTile
        label="Avg. Deal Size"
        value={`$${numberWithCommas(new_business_deal_size.toFixed(0))}`}
      />
      <SummaryTile
        label="Avg. Sales Cycle"
        value={`${numberWithCommas(sales_cycle_2.toFixed(0))}`}
      />
      <SummaryTile
        label="Win Rate"
        value={`${numberWithCommas(new_business_win_rate_2.toFixed(0))}%`}
      />
    </SummaryTileContainer>
  )
}

export default LeaderboardSummary