import { Fragment, useEffect, useRef, FC, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import moment from 'moment'

import EventViewDialog from '../../organisms/calendar/eventViewDialog'

import { dayNames, dayNamesForMobile, getTimeSlots } from '../../../utils/calendar'
import { getDynamicHexColor } from '../../../utils/functions'

const minTime = new Date()
minTime.setHours(6, 0, 0, 0)

const maxTime = new Date()
maxTime.setHours(21, 59, 59, 999)

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const WeekView: FC<WeekViewProps> = ({ currentDate, days, events }) => {
  const container = useRef<any>(null)
  const containerNav = useRef<any>(null)
  const containerOffset = useRef<any>(null)
  const [isOpenModal, setOpenModal] = useState<Boolean>(false)
  const [selectedEventDetail, setEventDetail] = useState<any>(null)

  // useEffect(() => {
  //   // Set the container scroll position based on the current time.
  //   const currentMinute = new Date().getHours() * 60
  //   container.current.scrollTop =
  //     ((container.current.scrollHeight - containerNav.current.offsetHeight - containerOffset.current.offsetHeight) *
  //       currentMinute) /
  //     1440
  // }, [])

  const filterEvents = () => {
    let filteredEvents: any[] = []
    if (days && days.length > 0) {
      events.forEach((event: any) => {
        if (moment(event.start).isAfter(days[0]) && moment(event.start).isBefore(days[6])) {
          filteredEvents.push(event)
        }
      })
    }
    return filteredEvents
  }

  const separateEvents = () => {
    const allEvents = filterEvents()
    if (days && days.length > 0) {
      return days.map((day: any, dayIdx: number) => {
        let dayEvents: any[] = []
        if (allEvents && allEvents.length > 0) {
          allEvents.forEach((event: any, eventIdx: number) => {
            if (moment(event.start).format('YYYY-MM-DD') === moment(day).format('YYYY-MM-DD')) {
              dayEvents.push(event)
            }
          })
        }
        return dayEvents
      })
    }
    return []
  }

  const getColStartNum = (event: any) => {
    if (moment(event.start).format('YYYY-MM-DD') === moment(days[0]).format('YYYY-MM-DD')) {
      return 'sm:col-start-1'
    } else if (moment(event.start).format('YYYY-MM-DD') === moment(days[1]).format('YYYY-MM-DD')) {
      return 'sm:col-start-2'
    } else if (moment(event.start).format('YYYY-MM-DD') === moment(days[2]).format('YYYY-MM-DD')) {
      return 'sm:col-start-3'
    } else if (moment(event.start).format('YYYY-MM-DD') === moment(days[3]).format('YYYY-MM-DD')) {
      return 'sm:col-start-4'
    } else if (moment(event.start).format('YYYY-MM-DD') === moment(days[4]).format('YYYY-MM-DD')) {
      return 'sm:col-start-5'
    } else if (moment(event.start).format('YYYY-MM-DD') === moment(days[5]).format('YYYY-MM-DD')) {
      return 'sm:col-start-6'
    } else if (moment(event.start).format('YYYY-MM-DD') === moment(days[6]).format('YYYY-MM-DD')) {
      return 'sm:col-start-7'
    }
    return 'sm:col-start-1'
  }

  const handleSelect = (event: any) => {
    setOpenModal(true)
    setEventDetail(event)
  }

  return (
    <div ref={container} className="isolate flex flex-auto flex-col overflow-auto bg-white">
      <div style={{ width: '165%' }} className="flex max-w-full flex-none flex-col sm:max-w-none md:max-w-full">
        <div
          ref={containerNav}
          className="sticky top-0 z-30 flex-none bg-white dark:bg-header-dark shadow ring-1 ring-black dark:ring-revtron-dark3 ring-opacity-5 sm:pr-8"
        >
          <div className="grid grid-cols-7 text-sm leading-6 text-gray-500 sm:hidden">
            {days.map((day: any, index: number) => {
              return (
                <button key={index.toString()} type="button" className="flex flex-col items-center pt-2 pb-3">
                  {dayNamesForMobile[index]} <span className="mt-1 flex h-8 w-8 items-center justify-center font-semibold text-gray-900 dark:text-gray-400">{moment(day).format('DD')}</span>
                </button>
              )
            })}
          </div>

          <div className="-mr-px hidden grid-cols-7 divide-x divide-gray-100 dark:divide-revtron-dark3 border-r border-gray-100 dark:border-revtron-dark3 text-sm leading-6 text-gray-500 dark:text-gray-400 sm:grid">
            <div className="col-end-1 w-14" />
            {days.map((day: any, index: number) => {
              return (
                <div key={index.toString()} className="flex items-center justify-center py-3">
                  <span>
                    {dayNames[index]} <span className="items-center justify-center font-semibold text-gray-900 dark:text-gray-400">{moment(day).format('DD')}</span>
                  </span>
                </div>
              )
            })}
          </div>
        </div>
        <div className="flex flex-auto">
          <div className="sticky left-0 z-10 w-14 flex-none bg-white dark:bg-header-dark ring-1 ring-gray-100 dark:ring-revtron-dark3" />
          <div className="grid flex-auto grid-cols-1 grid-rows-1">
            {/* Horizontal lines */}
            <div
              className="col-start-1 col-end-2 row-start-1 grid divide-y divide-gray-100 dark:divide-revtron-dark3 dark:bg-header-dark"
              style={{ gridTemplateRows: `repeat(${getTimeSlots(minTime, maxTime).length * 2}, minmax(3rem, 1fr))` }}
            >
              <div ref={containerOffset} className="row-end-1 h-7"></div>
              {getTimeSlots(minTime, maxTime).map((item: any, index: number) => {
                return (
                  <Fragment key={index.toString()}>
                    <div>
                      <div className="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">
                        {item}
                      </div>
                    </div>
                    <div />
                  </Fragment>
                )
              })}
            </div>

            {/* Vertical lines */}
            <div className="col-start-1 col-end-2 row-start-1 hidden grid-cols-7 grid-rows-1 divide-x divide-gray-100 dark:divide-revtron-dark3 sm:grid sm:grid-cols-7">
              <div className="col-start-1 row-span-full" />
              <div className="col-start-2 row-span-full" />
              <div className="col-start-3 row-span-full" />
              <div className="col-start-4 row-span-full" />
              <div className="col-start-5 row-span-full" />
              <div className="col-start-6 row-span-full" />
              <div className="col-start-7 row-span-full" />
              <div className="col-start-8 row-span-full w-8" />
            </div>

            {/* Events */}
            <ol
              className="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-7 sm:pr-8"
              style={{ gridTemplateRows: `1.75rem repeat(${getTimeSlots(minTime, maxTime).length * 2 * 6}, minmax(0, 1fr)) auto` }}
            >
              {filterEvents() && filterEvents().length > 0 && filterEvents().map((event: any, index: number) => {
                const start = moment(event.start)
                const end = moment(event.end)
                let duration = moment.duration(end.diff(start))
                const hours = duration.asHours()
                return (
                  <li
                    key={index.toString()}
                    className={`relative mt-px flex ${getColStartNum(event)}`}
                    style={{
                      gridRow: `${2 + ((moment(event.start).hour() + moment(event.start).minute() / 60 - 6 < 0 ? 0 : moment(event.start).hour() + moment(event.start).minute() / 60 - 6)) * 12} / span ${hours * 12}`
                    }}
                  >
                    <a
                      onClick={() => handleSelect(event)}
                      className="group absolute inset-1 flex flex-col overflow-y-auto rounded-lg p-2 text-xs leading-5 hover:bg-blue-100"
                      style={getDynamicHexColor(event).style}
                    >
                      <p className="font-semibold  text-white">{event.title}</p>
                      <p className="text-white group-hover:text-blue-700">
                        <time dateTime="2022-01-12T06:00">
                          {moment(event.start).format('hh:mm A')} - {moment(event.end).format('hh:mm A')}
                        </time>
                      </p>
                    </a>
                  </li>
                )
              })}
            </ol>
          </div>
        </div>
        <EventViewDialog
          isOpen={isOpenModal}
          setIsOpen={setOpenModal}
          event={selectedEventDetail}
        />
      </div>
    </div>
  )
}

interface WeekViewProps {
  currentDate: any
  days: any[]
  events: any[]
}

export default WeekView
