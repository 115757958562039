import { FC } from 'react'
import clsx from 'clsx'

import {
  BadgeCheckIcon,
  CashIcon,
  CurrencyDollarIcon,
} from "@heroicons/react/outline"

import SalesforceLogoSmall from "../../molecules/logos/SalesforceLogoSmall"
import HubspotLogoSmall from '../../molecules/logos/HubspotLogoSmall'
import OutreachLogoSmall from "../../molecules/logos/OutreachLogoSmall"

import { DataConnectionActionType } from "../../../containers/settings/Settings.Utils"

const comingSoonDataConnections = [
  {
    title: "Salesforce",
    subtitle: "Connect to create live data pipelines.",
    titleForeground: "bg-revtron-black",
    href: "#",
    icon: SalesforceLogoSmall,
    iconForeground: "text-revtron-black",
    iconBackground: "bg-revtron-teal",
  },
  // {
  //   title: "HubSpot",
  //   subtitle: "Coming soon.",
  //   titleForeground: "bg-revtron-black",
  //   href: "#",
  //   icon: HubspotLogoSmall,
  //   iconForeground: "text-revtron-black",
  //   iconBackground: "bg-revtron-teal",
  // },
  {
    title: "Outreach",
    subtitle: "Coming soon.",
    titleForeground: "bg-revtron-black",
    href: "#",
    icon: OutreachLogoSmall,
    iconForeground: "text-revtron-black",
    iconBackground: "bg-revtron-teal",
  },
  // {
  //   title: "Groove",
  //   subtitle: "Coming soon.",
  //   titleForeground: "bg-revtron-black",
  //   href: "#",
  //   icon: BadgeCheckIcon,
  //   iconForeground: "text-revtron-black",
  //   iconBackground: "bg-revtron-teal",
  // },
  // {
  //   title: "Stripe",
  //   subtitle: "Coming soon.",
  //   titleForeground: "bg-revtron-black",
  //   href: "#",
  //   icon: CashIcon,
  //   iconForeground: "text-revtron-black",
  //   iconBackground: "bg-revtron-teal",
  // },
  // {
  //   title: "Xero",
  //   subtitle: "Coming soon.",
  //   titleForeground: "bg-revtron-black",
  //   href: "#",
  //   icon: CurrencyDollarIcon,
  //   iconForeground: "text-revtron-black",
  //   iconBackground: "bg-revtron-teal",
  // },
]

const IntegrationList = () => {
  return (
    <div className="rounded-lg bg-gray-200 dark:bg-header-dark overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
      {comingSoonDataConnections.map((action, actionIdx) => {
        return (
          <IntegrationItem
            key={actionIdx}
            action={action}
          />
        )
      })}
    </div>
  )
}

const IntegrationItem: FC<{ action: DataConnectionActionType }> = ({ action }) => {
  return (
    <div className='relative group bg-white dark:bg-revtron-dark2 p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-revtron-cyan cursor-pointer'>
      <div>
        <span
          className={clsx(
            action.iconBackground,
            action.iconForeground,
            "rounded-lg inline-flex p-3 ring-4 ring-white"
          )}
        >
          {<action.icon className="h-6 w-6" aria-hidden="true" />}
        </span>
      </div>
      <div className="mt-8">
        <h3 className="text-lg font-medium dark:text-neutral-300">
          <a
            target="_blank"
            rel="noreferrer"
            className="focus:outline-none"
            {...(action.href && {
              href: action.href,
              target: "_blank",
              rel: "noreferrer",
            })}
          >
            {/* Extend touch target to entire panel */}
            <span className="absolute inset-0" aria-hidden="true" />
            {action.title}
          </a>
        </h3>
        <p className="mt-2 text-sm text-gray-500 dark:text-neutral-300">
          <a
            target="_blank"
            rel="noreferrer"
            {...(action.href && {
              href: action.href,
              target: "_blank",
              rel: "noreferrer",
            })}
            className="focus:outline-none"
          >
            <span className="absolute inset-0" aria-hidden="true" />
            {action.subtitle}
          </a>
        </p>
      </div>
      <span
        className="pointer-events-none absolute top-6 right-6 text-revtron-blue group-hover:text-revtron-cyan"
        aria-hidden="true"
      >
        <svg
          className="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 24 24"
        >
          <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
        </svg>
      </span>
    </div>
  )
}

export default IntegrationList