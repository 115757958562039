import { useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { useChangePassword } from '@nhost/react'

import AuthWrapper from '../../components/templates/authWrapper'
import TextInput from '../../components/molecules/textInput'

const ChangePassword = () => {
  const [pass, setPass] = useState<string>('')
  const [confirmPass, setConfirmPass] = useState<string>('')
  const navigate = useNavigate()
  const { changePassword, isSuccess, isError, error } = useChangePassword()

  const handleOnSubmit = async (e: any) => {
    e.preventDefault()

    try {
      if (!!pass && pass === confirmPass) {
        const result = await changePassword(pass)
        if (result && result.isSuccess) {
          navigate('/sign-in')
        }
      }
    } catch (err) {
      console.log('Error::', err)
    }
  }

  return (
    <AuthWrapper>
      <div className='flex justify-center items-center'>
        <div className="w-full max-w-lg">
          <p className='text-center mt-36 text-revtron-dark4 text-sm'>
            Forgot your password?
          </p>
          <h1 className="mt-12 mb-36 text-center text-4xl font-semibold dark:text-revtron-gray1">
            Create a new password
          </h1>
          <div className="sm:rounded-xl sm:shadow-md sm:border border-theme-toggle-white-bg dark:border-revtron-dark3 bg-auth-background dark:bg-auth-background-dark backdrop-blur px-4 sm:px-8 py-12 flex flex-col items-center">
            <form onSubmit={handleOnSubmit} className="w-full">
              <div className="mt-6 w-full flex flex-col items-center space-y-6">
                <TextInput
                  type="password"
                  placeholder="Enter new Password"
                  value={pass}
                  onChange={e => setPass(e.target.value)}
                  required
                />
              </div>

              <div className="mt-6 w-full flex flex-col items-center space-y-6">
                <TextInput
                  type="password"
                  placeholder="Re-enter new Password"
                  value={confirmPass}
                  onChange={e => setConfirmPass(e.target.value)}
                  required
                />
              </div>

              <p className='mt-4 dark:text-table-head'>
                must contain uppercase letters;
              </p>
              <p className='dark:text-table-head'>
                must contain lowercase letters;
              </p>
              <p className='dark:text-table-head'>
                must contain numbers;
              </p>
              <p className='dark:text-table-head'>
                must contain special characters.
              </p>

              <button
                type="submit"
                className="mt-6 w-full bg-auth-btn-gradient font-medium inline-flex justify-center items-center rounded-md p-3 text-header-dark bg-blue-600 hover:bg-blue-500 focus:outline-none focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed  disabled:hover:bg-blue-600 disabled:hover:border-bg-600 transition-colors"
              >
                Save
              </button>

              <button
                onClick={() => navigate('/sign-in')}
                className="mt-6 w-full border border-table-text dark:border-revtron-dark3 text-revtron-dark4 dark:text-theme-toggle-white-bg font-medium inline-flex justify-center items-center rounded-md p-3 text-header-dark focus:outline-none focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed  disabled:hover:bg-blue-600 disabled:hover:border-bg-600 transition-colors"
              >
                Cancel
              </button>
            </form>
          </div>
        </div>
      </div>
    </AuthWrapper>
  )
}

export default ChangePassword
