import { FC } from 'react'

const SearchInput: FC<SearchInputProps> = ({ className, value, onChange, onBlur, onKeyDown, placeholder }) => {
  return (
    <input
      className={`text-select-text dark:text-white dark:bg-revtron-dark1 border border-table-text dark:border-revtron-dark3 rounded-md focus:outline-none ${className || ''}`}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
    />
  )
}

interface SearchInputProps {
  className?: string
  value: any
  onChange: (e: any) => void
  onBlur?: () => void
  onKeyDown?: (e: any) => void
  placeholder?: string
}

export default SearchInput
