import { useEffect, useState, useCallback } from 'react'
import { isEqual } from 'lodash'

import { getIcpModuleData } from './ICP.hrsoft.util'
import { ICPFilters } from '../../../@types/module'
import { useIcpModuleLazyQuery, order_by } from '../../../graphql/__generated__/graphql'

const useGraphQLForHrsoft = () => {
  const [filters, setFilters] = useState<ICPFilters>({})
  const [loadIcpModuleData, { data, loading }] = useIcpModuleLazyQuery()

  const changeFilters = useCallback((_filters: ICPFilters) => {
    if (!isEqual(_filters, filters)) {
      setFilters(_filters)
    }
  }, [filters])

  useEffect(() => {
    if (!data) {
      loadIcpModuleData({
        variables: {
          where: { "to_revtron": { "crm_id": { "_is_null": false } } , "account_module_static": { "account_id": {"_is_null": false } } },
          orderBy: { "account_module_static": { "predct_proba": order_by.desc_nulls_last } },
          limit: 1000
        }
      })
    }
  }, [data, loadIcpModuleData])

  return {
    filters,
    setFilters: changeFilters,
    icpModuleData: getIcpModuleData(data, filters),
    isLoading: loading,
  }
}

export default useGraphQLForHrsoft