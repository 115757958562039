import { FC } from 'react'

import Spin from '../../../components/atoms/spin'
import Summary from '../../../components/organisms/icp/summary'
import Charts from '../../../components/organisms/icp/charts'
import Industry from '../../../components/organisms/icp/industry'
import AccountsTable from '../../../components/organisms/icp/accountsTable'
import Geomap from '../../../components/organisms/icp/geomap'
import useGraphQL from './ICP.default.graphql'
import useGraphQLForHarri from './ICP.harri.graphql'
import useGraphQLForGigPro from './ICP.gigPro.graphql'
import useGraphQLForHrsoft from './ICP.hrsoft.graphql'
import { ICPFilters, ICPSummary, ICPChartData, ICPTreemapChartData, GeoMapData, AccountRecord } from '../../../@types/module'


import { useFeatureContext } from '../../../hooks/featureContext'

type ComponentBodyProps = {
  summaryData: ICPSummary
  chartData: ICPChartData
  treemapData: {
    industry: ICPTreemapChartData
    companyType: ICPTreemapChartData
  }
  geoMapData: GeoMapData
  bussinessDetails: AccountRecord[]
  isGeoMapEnabled?: boolean
  isLoading: boolean
  filters: ICPFilters,
  setFilters: (filters: ICPFilters) => void
}

const ComponentBody: FC<ComponentBodyProps> = ({
  summaryData,
  chartData,
  treemapData,
  geoMapData,
  isGeoMapEnabled = false,
  bussinessDetails,
  isLoading,
  ...rest
}) => {
  if (isLoading) {
    return (
      <div className='flex justify-center bg-white dark:bg-revtron-dark2 shadow rounded-lg p-7 mb-6'>
        <Spin />
      </div>
    )
  }

  return (
    <div className='icp-content'>
      <Summary data={summaryData} />
      <Charts data={chartData} {...rest} />
      <Industry data={treemapData} {...rest} />
      {isGeoMapEnabled && (
        <Geomap data={geoMapData} {...rest} />
      )}
      <AccountsTable data={bussinessDetails} />
    </div>
  )
}

const HarriComponent: FC = () => {
  const { icpModuleData, ...rest } = useGraphQLForHarri()

  return (
    <ComponentBody {...icpModuleData} {...rest} />
  )
}

const GigProComponent: FC = () => {
  const { icpModuleData, ...rest } = useGraphQLForGigPro()

  return (
    <ComponentBody {...icpModuleData} {...rest} />
  )
}

const HrsoftComponent: FC = () => {
  const { icpModuleData, ...rest } = useGraphQLForHrsoft()

  return (
    <ComponentBody {...icpModuleData} {...rest} isGeoMapEnabled />
  )
}

const DefaultComponent: FC = () => {
  const { icpModuleData, ...rest } = useGraphQL()
  return (
    <ComponentBody {...icpModuleData} {...rest} isGeoMapEnabled />
  )
}

const ICP: FC = () => {
  const { isHarry, isGigPro, isHrSoft, isNewfrontSandbox } = useFeatureContext()
  
  if (isHarry) {
    return <HarriComponent />
  } else if (isGigPro) {
    return <GigProComponent />
  } else if (isHrSoft || isNewfrontSandbox) {
    return <HrsoftComponent />
  } else {
    return <DefaultComponent />
  }
}

export default ICP
