import { FC } from 'react'

import UsersSection from '../../components/organisms/settings/usersSection'
import TeamsSection from '../../components/organisms/settings/teamsSection'

const UsersAndTeams: FC = () => {
  return (
    <div className='users-teams px-4 sm:px-6 lg:px-8'>
      <TeamsSection />
      <UsersSection />
    </div>
  );
}

export default UsersAndTeams
