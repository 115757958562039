import React, { FC, useState, useEffect } from 'react'

import Spin from '../../../atoms/spin'
import ExpandedRow from './expandedRow'
import CustomTable from '../../../molecules/table'

import { isInteger } from '../../../../utils/functions'

import { useAccountListQuery } from '../../../../graphql/__generated__/graphql'
import usePrevious from '../../../../utils/usePrevHook'

const AccountsTable: FC<AccountsTableProps> = ({ variables, where }) => {
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(50)
  const { data, loading } = useAccountListQuery({
    variables: {
      where,
      ...variables,
      offset: (pageNumber - 1) * pageSize,
      limit: pageSize
    },
    fetchPolicy: 'cache-first'
  })
  const prevValues = usePrevious(variables)

  useEffect(() => {
    if (variables && JSON.stringify(variables) !== JSON.stringify(prevValues)) {
      setPageNumber(1)
    }
  }, [variables, prevValues])

  const columns = [
    {
      title: 'Account Name',
      dataIndex: 'account_name',
      sorter: (a: any, b: any) => a.account_name && a.account_name.localeCompare(b.account_name)
    },
    {
      title: 'Account Owner',
      dataIndex: 'account_owner',
      sorter: (a: any, b: any) => a.account_owner && a.account_owner.localeCompare(b.account_owner)
    },
    {
      title: 'City',
      dataIndex: 'billingcity',
      sorter: (a: any, b: any) => a.billingcity && a.billingcity.localeCompare(b.billingcity)
    },
    {
      title: 'State',
      dataIndex: 'billingstate',
      sorter: (a: any, b: any) => a.billingstate && a.billingstate.localeCompare(b.billingstate)
    },
    {
      title: 'Status',
      dataIndex: 'account_status_auto__c',
      sorter: (a: any, b: any) => a.account_status_auto__c && a.account_status_auto__c.localeCompare(b.account_status_auto__c)
    },
    {
      title: 'Effort',
      dataIndex: 'effort',
      align: 'center',
      sorter: (a: any, b: any) => a.effort - b.effort
    },
    {
      title: 'Engagement',
      dataIndex: 'engagement',
      align: 'center',
      sorter: (a: any, b: any) => a.engagement - b.engagement
    },
    {
      title: 'ICP Score',
      dataIndex: 'predct_proba',
      align: 'center',
      sorter: (a: any, b: any) => a.predct_proba - b.predct_proba,
      render: (_: any, row: any) => {
        const styles: any = {}
        const bgColor = row.predct_proba * 100 === 100 ? `rgb(168, 85, 247)` : `rgba(5, 217, 232, ${row.predct_proba})`
        const textColor = row.predct_proba * 100 === 100 ? `white` : `black`
        styles.backgroundColor = bgColor
        styles.color = textColor
        return (
          <div style={styles} className='rounded-full dark:text-table-text font-semibold leading-5 text-center'>
            {Math.round(isInteger(row.predct_proba * 100) as any) + '%'}
          </div>
        )
      }
    }
  ]

  const accountsData: any[] = (data && data.account_module_static) || []

  let totalCounts: any = 0
  if (data && data.account_module_static_aggregate) {
    totalCounts = data.account_module_static_aggregate.aggregate && data.account_module_static_aggregate.aggregate.count
  }

  if (loading) {
    return (
      <div className='w-full flex justify-center items-center my-4'>
        <Spin />
      </div>
    )
  }

  return (
    <div>
      <CustomTable
        columns={columns}
        dataSource={accountsData}
        pagination={{
          total: totalCounts,
          current: pageNumber,
          pageSize,
          onChange: (page: any, pageSize: any) => {
            setPageNumber(page)
            setPageSize(pageSize)
          }
        }}
        expandable={{
          expandedRowRender: (record: any) => {
            return <ExpandedRow accountId={record.account_id} />
          },
          rowExpandable: (record: any) => record.name !== 'Not Expandable'
        }}
      />
    </div>
  )
}

interface AccountsTableProps {
  variables: any
  where: any
}

export default AccountsTable
