import { FC, Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import TextField from '../../molecules/textField'
import Select from '../../molecules/select'
import { useCreateTeamMutation } from '../../../graphql/__generated__/graphql'
import { teamEmptyState } from './constant'
import { alert } from '../../../utils/functions'

const teamTypes = [
  {
    name: 'Sales Development (SDR)',
    value: 'Sales Development (SDR)'
  },
  {
    name: 'Closing Business (AE)',
    value: 'Closing Business (AE)'
  },
  {
    name: 'Account Management (AM)',
    value: 'Account Management (AM)'
  }
]

const CreateTeamModal: FC<CreateTeamModalProps> = ({ isOpen, closeModal }) => {
  const [createTeam, { data, loading, error }] = useCreateTeamMutation()
  const [state, setState] = useState(teamEmptyState)
  const onCloseModal = () => {
    setState(teamEmptyState)
    closeModal()
  }
  const onCreateTeam = () => {
    if (state.teamName) {
      createTeam({
        variables: {
          name: state.teamName,
          type: state.teamType.value,
          opportunity_target: Number(state.opportunities),
          email_target: Number(state.emailsSent),
          call_target: Number(state.calls),
          social_target: Number(state.social)
        }
      }).then((res) => {
        alert('success', 'Successful in creating a team!')
        onCloseModal()
      }).catch((err) => {
        alert('error', 'Failed in creating a team.')
      })
    }
  }
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onCloseModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black dark:bg-gray-700 !bg-opacity-30" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all dark:bg-header-dark">
                <div className='px-4 py-6 border-b border-gray-300 dark:border-neutral-700'>
                  <div className='flex justify-between items-center mb-2'>
                    <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-200">Create New Team</h3>
                    <XIcon className='w-4 cursor-pointer dark:text-gray-200' onClick={onCloseModal} />
                  </div>
                  <div className="text-sm font-medium leading-6 text-gray-500 dark:text-gray-400">Invite new users and set goals for this team</div>
                </div>
                <div className="px-4 py-6 border-b border-gray-300 dark:border-neutral-700">
                  <TextField
                    className='mb-6'
                    label="Team name"
                    placeholder="Select team"
                    id="team-name"
                    name="team-name"
                    type="text"
                    value={state.teamName}
                    onChange={(value: any) => setState({...state, teamName: value})}
                  />
                  <Select
                    className='mb-6'
                    placeholder="Select type"
                    label="Team type"
                    list={teamTypes}
                    selected={state.teamType}
                    onSelect={e => setState({...state, teamType: e})}
                  />
                  <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-200 mb-4">Weekly Activity Targets</h3>
                  <div className='flex mb-4'>
                    <TextField
                      className='flex-1 mr-4'
                      label="Opportunities Created"
                      placeholder="0"
                      id="opportunities-created"
                      name="opportunities-created"
                      type="number"
                      value={state.opportunities}
                      onChange={(value: any) => setState({...state, opportunities: value})}
                    />
                    <TextField
                      className='flex-1'
                      label="Emails Sent"
                      placeholder="0"
                      id="emails-sent"
                      name="emails-sent"
                      type="number"
                      value={state.emailsSent}
                      onChange={(value: any) => setState({...state, emailsSent: value})}
                    />
                  </div>
                  <div className='flex mb-4'>
                    <TextField
                      className='flex-1 mr-4'
                      label="Calls Made"
                      placeholder="0"
                      id="calls-made"
                      name="calls-made"
                      type="number"
                      value={state.calls}
                      onChange={(value: any) => setState({...state, calls: value})}
                    />
                    <TextField
                      className='flex-1'
                      label="Social"
                      placeholder="0"
                      id="social"
                      name="social"
                      type="number"
                      value={state.social}
                      onChange={(value: any) => setState({...state, social: value})}
                    />
                  </div>
                </div>

                <div className="px-4 py-6">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent bg-cyan-400 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-cyan-500 disabled:bg-gray-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={onCreateTeam}
                    disabled={!state.teamName}
                  >
                    Create Team
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

interface CreateTeamModalProps {
  isOpen: boolean
  closeModal: () => void
}

export default CreateTeamModal
