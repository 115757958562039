import { FC, useState, useCallback, useEffect, memo } from 'react'
import moment from 'moment'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'

import { DateRangePickerProps } from '../../../@types/common/DatePicker'

const MonthlyPicker: FC<DateRangePickerProps> = ({ dateRange, onDateRangeChange }) => {
  const [year, setYear] = useState(moment().year())
  const [hoverValue, setHoverValue] = useState<number | null>(null)

  useEffect(() => {
    if (year < dateRange.start.year() || year > dateRange.end.year()) {
      setYear(dateRange.start.year())
    }
  }, [dateRange.start, dateRange.end, year])

  const setStateHandler = useCallback((month: number) => {
    if (moment(dateRange.start).year() === moment(dateRange.end).year() && moment(dateRange.start).month() === moment(dateRange.end).month()) {
      if (moment(dateRange.start).isAfter(moment(`${month+1}.01.${year}`))) {
        // set startDate with the selected month
        onDateRangeChange({
          start: moment(`${month+1}.01.${year}`),
          end: dateRange.end.endOf('month')
        })
      } else {
        // set endDate with the selected month
        onDateRangeChange({
          start: dateRange.start.startOf('month'),
          end: moment(`${month+1}.01.${year}`).endOf('month')
        })
      }
    } else {
      // set start and end dates with the selected month
      onDateRangeChange({
        start: moment(`${month+1}.01.${year}`),
        end: moment(`${month+1}.01.${year}`).endOf('month')
      })
    }
  }, [dateRange.start, dateRange.end, year, onDateRangeChange])

  return (
    <div className="text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 xl:col-start-9">
      <div className="flex items-center text-gray-900">
        <button
          type="button"
          className="flex flex-none items-center justify-center border border-table-text dark:border-revtron-gray2 rounded p-1.5 text-gray-400 hover:text-gray-500"
          onClick={() => setYear(year-1)}
        >
          <span className="sr-only">Previous year</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <div className="flex-auto text-base text-black dark:text-white">{year}</div>
        <button
          type="button"
          className="flex flex-none items-center justify-center border border-table-text dark:border-revtron-gray2 rounded p-1.5 text-gray-400 hover:text-gray-500"
          onClick={() => setYear(year+1)}
        >
          <span className="sr-only">Next year</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-3 grid grid-cols-3 text-sm gap-1">
        {Array.from({ length: 12 }, (value, index) => index).map((item, index) => {
          const isCurrentMonth = moment().isSame(moment().year(year).month(index))
          const isSelectedMonth = moment(dateRange.start).isSameOrBefore(moment(dateRange.start).year(year).month(index)) && moment(dateRange.end).isSameOrAfter(moment(dateRange.end).year(year).month(index))
          let isSelectedMonthByHover = false
          if (moment(dateRange.start).isSame(moment(dateRange.end).startOf('month')) && hoverValue !== null) {
            const hoverDate = moment(dateRange.start).year(year).month(hoverValue)
            if (hoverDate.isBefore(dateRange.start)) {
              isSelectedMonthByHover = moment(hoverDate).isSameOrBefore(moment(dateRange.start).year(year).month(index)) && moment(dateRange.start).isSameOrAfter(moment(dateRange.end).year(year).month(index))
            } else {
              isSelectedMonthByHover = moment(dateRange.start).isBefore(moment(dateRange.start).year(year).month(index)) && moment(hoverDate).isSameOrAfter(moment(dateRange.end).year(year).month(index))
            }
          }
          return (
            <button
              key={index}
              type="button"
              className={`
                py-2 hover:bg-gray-100 dark:hover:bg-revtron-gray2 focus:z-10 rounded
                ${isCurrentMonth && 'bg-gray-200 dark:bg-gray-500'}
                ${isSelectedMonth && 'bg-cyan-200 dark:bg-cyan-500'}
                ${isSelectedMonthByHover && 'bg-cyan-50 dark:bg-cyan-800'}
              `}
              onClick={() => setStateHandler(index)}
              onMouseEnter={() => setHoverValue(index)}
              onMouseLeave={() => setHoverValue(null)}
            >
              <div className='mx-auto flex h-5 items-center justify-center rounded-full'>
                {moment().month(item).format('MMMM')}
              </div>
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default memo(MonthlyPicker)
