import { FC } from "react"
import { Tabs } from "../../../@types/common/Tab"
import { classNames } from "../../../utils/functions"

type TabsProps = {
  tabs: Tabs[]
  currentTab: string
  onChangeTab: (v: string) => void
}

const CommonTabs: FC<TabsProps> = ({ tabs, currentTab, onChangeTab }) => {
  return (
    <div className="tabs mb-10">
      <div className="lg:hidden">
        <label htmlFor="selected-tab" className="sr-only">
          Select a tab
        </label>
        <select
          id="selected-tab"
          name="selected-tab"
          onChange={(e) => onChangeTab(e.target.value)}
          className="mt-1 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-purple-500 sm:text-sm sm:leading-6"
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden lg:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <span
                key={tab.name}
                className={classNames(
                  currentTab === tab.name
                    ? 'border-revtron-cyan1 dark:border-revtron-cyan text-revtron-cyan1 dark:text-revtron-cyan'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-300 dark:hover:border-gray-300 dark:hover:text-gray-700',
                  'whitespace-nowrap cursor-pointer border-b-2 py-4 px-1 text-sm font-medium'
                )}
                onClick={() => onChangeTab(tab.name)}
              >
                <p className="flex flex-row justify-center items-center">
                  <tab.icon width={25} />
                  {tab.name}
                </p>
              </span>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}

export default CommonTabs
