import * as React from 'react'

const OutreachLogoSmall = (props: React.ComponentProps<'svg'>): JSX.Element => {
  return (
    <svg width="24" height="24" viewBox="0 0 71 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M31.5751 0C42.4487 0 58.8789 3.40313 66.5272 11.2072C68.4648 13.1859 71 17.0615 71 22.7374C71 32.8287 63.0315 48.3115 54.233 57.2917C49.6843 61.9337 42.4487 68 32.7158 68C23.201 68 15.9346 61.4058 13.831 59.2584C5.7724 51.0349 0 38.5528 0 26.9142C0 19.5704 2.05141 13.1474 7.28546 7.80889C12.1329 2.86326 20.4856 0 31.5751 0ZM45.0337 40.2447C48.7049 36.4969 49.6938 31.8815 49.6938 29.3412C49.6938 27.4998 49.2195 24.798 46.9618 22.4963C43.4993 18.9631 39.6645 17.9653 35.4929 17.9653C30.209 17.9653 26.6588 19.6692 24.2943 22.0842C22.4398 23.9762 20.8366 26.8852 20.8366 31.0186C20.8366 35.1496 22.9947 39.1409 25.3473 41.539C27.5197 43.7563 30.9347 45.3976 34.3735 45.3976C37.8123 45.3976 41.3625 43.9925 45.0337 40.2447Z" fill="#01012B"/>
    </svg>
  )
}

export default OutreachLogoSmall;