import { FC } from 'react'

import ShadowCard from '../../templates/shadowCard'
import CustomTable from '../../molecules/table'

import { useOppDetailQuery } from '../../../graphql/__generated__/graphql'
import { numberWithCommas, convertUTCToLocal } from '../../../utils/functions'
import { useFeatureContext } from '../../../hooks/featureContext'

const OopDetail: FC = () => {
  const { data } = useOppDetailQuery()
  const { isHarry } = useFeatureContext()

  const columns = [
    {
      title: 'Name (Opportunity)',
      dataIndex: 'opportunity_name',
      sorter: (a: any, b: any) => a.opportunity_name.toUpperCase() > b.opportunity_name.toUpperCase() ? 1 : -1,
      render: (text: string) => {
        return (
          <p className='opportunity-name'>{text}</p>
        )
      }
    },
    {
      title: 'Creator (Opportunity)',
      dataIndex: 'created_by',
      sorter: (a: any, b: any) => a.created_by.toUpperCase() > b.created_by.toUpperCase() ? 1 : -1
    },
    {
      title: 'Owner (Opportunity)',
      dataIndex: 'opportunity_owner',
      sorter: (a: any, b: any) => a.opportunity_owner.toUpperCase() > b.opportunity_owner.toUpperCase() ? 1 : -1
    },
    {
      title: 'Stage Name',
      dataIndex: 'stage',
      sorter: (a: any, b: any) => a.stage.toUpperCase() > b.stage.toUpperCase() ? 1 : -1
    },
    {
      title: 'Lead Source',
      dataIndex: 'leadsource',
      sorter: (a: any, b: any) => a.leadsource.toUpperCase() > b.leadsource.toUpperCase() ? 1 : -1
    },
    {
      title: 'Created Date (Opportunity)',
      dataIndex: 'createddate',
      sorter: (a: any, b: any) => (a.createddate < b.createddate) ? -1 : ((a.createddate > b.createddate) ? 1 : 0),
      render: (text: string) => {
        return (
          <p>{convertUTCToLocal(text)}</p>
        )
      }
    },
    // {
    //   title: 'Close Date',
    //   dataIndex: 'closedate',
    //   sorter: {
    //     compare: (a, b) => (a.closedate < b.closedate) ? -1 : ((a.closedate > b.closedate) ? 1 : 0),
    //     multiple: 7
    //   },
    //   render: (text) => {
    //     return (
    //       <p>{convertUTCToLocal(text)}</p>
    //     )
    //   }
    // },
    {
      title: isHarry ? 'MRR' : 'ARR',
      dataIndex: 'amount',
      sorter: (a: any, b: any) => a.amount - b.amount,
      render: (text: number) => {
        return (
          <p className='amount'>{`$${numberWithCommas(Math.round(text)) || 0}`}</p>
        )
      }
    }
  ]

  const loadData = () => {
    if (data?.opp_detail_gql) {
      return [...data?.opp_detail_gql].sort((a: any, b: any) => b.amount - a.amount)
    }
    return []
  }

  return (
    <ShadowCard label='Opportunity Detail' className='mt-5'>
      <CustomTable
        columns={columns}
        dataSource={loadData()}
      />
    </ShadowCard>
  )
}

export default OopDetail
