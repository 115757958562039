import { NavigationType } from "../layouts/Layout.type"
import {
  DefaultNavigations,
  HarriNavigations,
  ProductPlanNavigations,
  XylemeNavigations,
  HrsoftNavigations,
  NewfrontNavigations,
  GigProNavigations,
  NFSandboxNavigations,
  XimaNavigations,
} from "../layouts/Layout.util"
export const SubDomains: {
  [key: string]: {
    subdomain: string,
    region: string,
    title: string,
    navigations: NavigationType[]
  }
} = {
  "absencesoft": {
    subdomain: process.env.REACT_APP_ABSENCESOFT_SUBDOMAIN,
    region: process.env.REACT_APP_ABSENCESOFT_REGION,
    title: 'Absencesoft',
    navigations: DefaultNavigations,
  },
  "xyleme": {
    subdomain: process.env.REACT_APP_XYLEME_SUBDOMAIN,
    region: process.env.REACT_APP_XYLEME_REGION,
    title: 'Xyleme',
    navigations: XylemeNavigations,
  },
  "productplan": {
    subdomain: process.env.REACT_APP_PRODUCTPLAN_SUBDOMAIN,
    region: process.env.REACT_APP_PRODUCTPLAN_REGION,
    title: 'ProductPlan',
    navigations: ProductPlanNavigations,
  },
  "hrsoft": {
    subdomain: process.env.REACT_APP_HRSOFT_SUBDOMAIN,
    region: process.env.REACT_APP_HRSOFT_REGION,
    title: 'HRSoft',
    navigations: HrsoftNavigations,
  },
  "newfront": {
    subdomain: process.env.REACT_APP_NEWFRONT_SUBDOMAIN,
    region: process.env.REACT_APP_NEWFRONT_REGION,
    title: 'Newfront',
    navigations: NewfrontNavigations,
  },
  "xima": {
    subdomain: process.env.REACT_APP_XIMA_SUBDOMAIN,
    region: process.env.REACT_APP_XIMA_REGION,
    title: 'Xima',
    navigations: XimaNavigations,
  },
  "harri": {
    subdomain: process.env.REACT_APP_HARRI_SUBDOMAIN,
    region: process.env.REACT_APP_HARRI_REGION,
    title: 'Harri',
    navigations: HarriNavigations,
  },
  "gigpro": {
    subdomain: process.env.REACT_APP_GIGPRO_SUBDOMAIN,
    region: process.env.REACT_APP_GIGPRO_REGION,
    title: 'Gigpro',
    navigations: GigProNavigations,
  },
  "nf-sandbox": {
    subdomain: process.env.REACT_APP_NEWFRONT_SANDBOX_SUBDOMAIN,
    region: process.env.REACT_APP_NEWFRONT_SANDBOX_REGION,
    title: 'Newfront-Sandbox',
    navigations: NFSandboxNavigations,
  },
}

export const DefaultDomainConfig: {
  subdomain: string,
  region: string,
  title: string,
  navigations: NavigationType[]
} = {
  subdomain: process.env.REACT_APP_NHOST_SUBDOMAIN,
  region: process.env.REACT_APP_NHOST_REGION,
  title: '',
  navigations: DefaultNavigations
}

export const GTM_ID = 'GTM-KCR6ML6'

export const colorsGroup = ['005678', 'FF2A6D', 'D1F7FF', '05D9E8', '01012B', '85EBD9']

// always add the environment variables to vercel and then deploy to get a new app live 