import { FC } from 'react'

import Spin from '../../atoms/spin'
import { SummaryTileContainer, SummaryTile } from '../../molecules/summary'
import { LeaderboardSummaryData } from '../../../containers/leaderboard_v1/Leaderboard.util'

import { numberWithCommas, isInteger } from '../../../utils/functions'

type LeaderboardSummaryProps = {
  isLoading: boolean
  data: LeaderboardSummaryData
  salesVelocityPage?: boolean
}

const LeaderboardSummary: FC<LeaderboardSummaryProps> = ({ isLoading, data, salesVelocityPage }) => {
  if (isLoading) {
    return (
      <div className='flex justify-center bg-white dark:bg-header-dark shadow p-4 mb-6'>
        <Spin />
      </div>
    )
  }

  return (
    <SummaryTileContainer className='mb-6'>
      <SummaryTile
        label={salesVelocityPage ? 'QTR Sales Velocity' : 'Total Bookings'}
        value={`$${numberWithCommas(isInteger(data.totalBookings.toFixed(0)))}`}
      />
      <SummaryTile
        label="# of Wins"
        value={numberWithCommas(isInteger(data.totalCounts))}
      />
      <SummaryTile
        label="Win Rate"
        value={`${numberWithCommas(data.salesVelocityFactors.New_Business_Win_Rate.toFixed(0))}%`}
      />
      {salesVelocityPage && (<SummaryTile
        label="Opps in 90 Day Pipeline"
        value={`${numberWithCommas(data.salesVelocityFactors.Ninety_Day_Pipeline.toFixed(0))}`}
      />)}
      <SummaryTile
        label="Avg. Deal Size"
        value={`$${numberWithCommas(data.salesVelocityFactors.Net_New_Avg_Deal_Value.toFixed(0))}`}
      />
      <SummaryTile
        label="Avg. Sales Cycle"
        value={`${numberWithCommas(data.salesVelocityFactors.Sales_Cycle.toFixed(0))}`}
      />
    </SummaryTileContainer>
  )
}

export default LeaderboardSummary
