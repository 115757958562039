import { FC } from 'react'
import { withApollo } from '@apollo/client/react/hoc'

const ApolloWrapper: FC<ApolloWrapperProps> = ({ element: Element, client, ...rest }) => {
  return <Element client={client} {...rest} />
}

interface ApolloWrapperProps {
  element?: any;
  client?: any;
}

export default withApollo(ApolloWrapper) as any;
