/* eslint-disable react-hooks/rules-of-hooks */
import { FC, useMemo } from 'react'
import Spin from '../../atoms/spin'
import HorizontalBarChart from './horizontalBarChart'
import { BoardData } from '../../../containers/leaderboard_v1/Leaderboard.util'

type LeadersProps = {
  data: BoardData[]
  isLoading: boolean
  salesVelocityPage?: boolean
}

const Leaders: FC<LeadersProps> = ({ isLoading, data, salesVelocityPage }) => {
  if (isLoading) {
    return (
      <div className='flex justify-center bg-white dark:bg-header-dark shadow p-4 mt-6'>
        <Spin />
      </div>
    )
  }

  const renderData = useMemo(() => {
    return data.map((item, index: number) => ({
      y: index + 1,
      x: Number(item.bookings.toFixed(0)),
      icon: item.photo_url,
      firstName: item.team_member.split(' ')[0],
      lastName: item.team_member.split(' ')[1]
    }))
  }, [data])

  return (
    <div className='bg-white dark:bg-header-dark shadow p-4 mt-6'>
      <div className='text-sm dark:text-theme-toggle-icon'>Leader board</div>
      <HorizontalBarChart data={renderData} salesVelocityPage={salesVelocityPage} />
    </div>
  )
}

export default Leaders
