import { FC } from 'react'
import moment from 'moment'

import Spin from '../../atoms/spin'
import CustomTable from '../../molecules/table'

import { numberWithCommas, convertUTCToLocal } from '../../../utils/functions'

const MeetingAccountDetail: FC<MeetingAccountDetailProps> = ({
  data,
  loading,
  startTime,
  endTime,
  totalCounts,
  pageNumber,
  pageSize,
  setPageNumber,
  setPageSize
}) => {

  if (loading) {
    return (
      <div className='w-full flex justify-center items-center my-4'>
        <Spin />
      </div>
    )
  }

  let filterdData = []

  if (data && data.calendar_gql_clean) {
    filterdData =  data.calendar_gql_clean.filter((item: any) => {
      const itemStartTime = new Date(item.startdatetime)
      const itemEndTime = new Date(item.enddatetime)
      return itemStartTime.getTime() > startTime && itemEndTime.getTime() < endTime
    })
    filterdData = filterdData.slice(10 * (pageNumber - 1), 10 * pageNumber + 1)
  }

  const columns = [
    {
      title: 'Meeting owner',
      dataIndex: 'meeting_owner',
      sorter: (a: any, b: any) => a.meeting_owner.toUpperCase() > b.meeting_owner.toUpperCase() ? 1 : -1
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      sorter: (a: any, b: any) => a.subject.toUpperCase() > b.subject.toUpperCase() ? 1 : -1
    },
    {
      title: 'Stage name',
      dataIndex: 'stagename',
      sorter: (a: any, b: any) => a.stagename.toUpperCase() > b.stagename.toUpperCase() ? 1 : -1
    },
    {
      title: 'Opportunity name',
      dataIndex: 'opportunity_name',
      sorter: (a: any, b: any) => a.opportunity_name.toUpperCase() > b.opportunity_name.toUpperCase() ? 1 : -1
    },
    {
      title: 'Account name',
      dataIndex: 'account_name',
      sorter: (a: any, b: any) => a.account_name.toUpperCase() > b.account_name.toUpperCase() ? 1 : -1
    },
    {
      title: 'Start date time',
      dataIndex: 'startdatetime',
      render: (text: any) => {
        return (
          <p className='start-date'>
            {moment(new Date(convertUTCToLocal(text))).format('MM/DD/YYYY hh:mm:ss A')}
          </p>
        )
      },
      sorter: (a: any, b: any) => (a.startdatetime < b.startdatetime) ? -1 : ((a.startdatetime > b.startdatetime) ? 1 : 0)
    },
    {
      title: 'End date time',
      dataIndex: 'enddatetime',
      render: (text: any) => {
        return (
          <p className='end-date'>
            {moment(new Date(convertUTCToLocal(text))).format('MM/DD/YYYY hh:mm:ss A')}
          </p>
        )
      },
      sorter: (a: any, b: any) => (a.enddatetime < b.enddatetime) ? -1 : ((a.enddatetime > b.enddatetime) ? 1 : 0)
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (text: any) => {
        return (
          <p className='amount'>
            {`$${numberWithCommas(Math.round(text)) || 0}`}
          </p>
        )
      },
      sorter: (a: any, b: any) => (a.amount < b.amount) ? -1 : ((a.amount > b.amount) ? 1 : 0)
    }
  ]

  return (
    <div className='meeting-accounts-table mt-8'>
      <CustomTable
        columns={columns}
        dataSource={filterdData}
        pagination={{
          total: totalCounts,
          current: pageNumber,
          pageSize,
          onChange: (page: any, pageSize: any) => {
            setPageNumber(page)
            setPageSize(pageSize)
          }
        }}
      />
    </div>
  )
}

interface MeetingAccountDetailProps {
  data: any
  loading: boolean
  startTime: any
  endTime: any
  totalCounts: any
  pageNumber: any
  setPageNumber: Function
  pageSize: Number
  setPageSize: Function
}

export default MeetingAccountDetail
