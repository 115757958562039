import * as React from 'react'

const SalesforceLogoSmall = (props: React.ComponentProps<'svg'>): JSX.Element => {
  return (
    <svg width="24" height="24" viewBox="0 0 128 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M53.2766 9.82565C57.4005 5.52864 63.142 2.86373 69.4919 2.86373C77.9329 2.86373 85.2974 7.57059 89.2192 14.558C92.6273 13.0352 96.3997 12.1882 100.369 12.1882C115.593 12.1882 127.936 24.6384 127.936 39.9958C127.936 55.355 115.593 67.8052 100.369 67.8052C98.5108 67.8052 96.6947 67.6194 94.9388 67.2642C91.4851 73.4247 84.9039 77.5869 77.35 77.5869C74.1878 77.5869 71.1969 76.8564 68.5338 75.5577C65.0328 83.7929 56.8759 89.5671 47.3693 89.5671C37.4693 89.5671 29.0319 83.3029 25.7932 74.5176C24.3779 74.8181 22.9115 74.9748 21.4069 74.9748C9.61976 74.9748 0.0639648 65.3206 0.0639648 53.4096C0.0639648 45.4276 4.35733 38.4584 10.7364 34.7297C9.42303 31.7078 8.69259 28.3726 8.69259 24.8661C8.69259 11.1681 19.8131 0.0640259 33.5293 0.0640259C41.5823 0.0640259 48.7391 3.8929 53.2766 9.82565Z" fill="#01012B"/>
    </svg>
  )
}

export default SalesforceLogoSmall;