import { FC } from 'react'
import CustomTable from '../../molecules/table'

import Spin from '../../atoms/spin'

import { groupBy, addCommasToStr, isInteger } from '../../../utils/functions'
import { useFeatureContext } from '../../../hooks/featureContext'

const StageTable: FC<StageTableProps> = ({ data, loading }) => {
  const { isHarry } = useFeatureContext()

  if (loading) {
    return (
      <div className='w-full flex justify-center items-center my-4'>
        <Spin />
      </div>
    )
  }

  const columns = [
    {
      title: 'Stage Name',
      dataIndex: 'stage'
    },
    {
      title: '% Prob.',
      dataIndex: 'probability',
      render: (pro: any) => pro + '%'
    },
    {
      title: '# of Opps',
      dataIndex: 'opp_count'
    },
    {
      title: 'Services $',
      dataIndex: 'services_amount',
      render: (amount: any) => '$' + addCommasToStr(amount.toString())
    },
    {
      title: isHarry ? 'MRR' : 'ARR',
      dataIndex: 'amount_sum',
      render: (arr: any) => '$' + addCommasToStr(isInteger(arr).toString())
    },
    {
      title: '% of Total',
      dataIndex: 'opp_percent',
      render: (total: any) => total + '%'
    }
  ]

  const dataSource: any[] = []
  if (data && data.pipeline_opp_detail_ml_gql) {
    const groupedData = groupBy(data.pipeline_opp_detail_ml_gql, 'stage')
    Object.keys(groupedData).forEach(stageName => {
      const stageData = groupedData[stageName]
      const avgProb = Math.round(stageData.reduce((sum: any, cur: any) => sum + cur.probability, 0) / stageData.length)
      const amountSum = stageData.reduce((sum: any, cur: any) => sum + cur.amount, 0)
      const servicesAmountSum = stageData.reduce((sum: any, cur: any) => sum + cur.services_amount, 0)
      const oppPercent = isInteger(stageData.length / data.pipeline_opp_detail_ml_gql.length * 100)
      dataSource.push({
        stage: stageName,
        probability: avgProb,
        opp_count: stageData.length,
        services_amount: servicesAmountSum,
        amount_sum: amountSum,
        opp_percent: oppPercent
      })
    })
    dataSource.sort((a, b) => a.probability - b.probability)
  }

  return (
    <div className='stage-table-content w-full xl:w-1/2 my-4 mr-4'>
      <CustomTable
        className='table-fixed whitespace-normal'
        columns={columns}
        dataSource={dataSource}
        pagination={false}
      />
    </div>
  )
}

interface StageTableProps {
  data: any
  loading: boolean
}

export default StageTable
