import _ from 'lodash'
import {
  order_by,
  AccountRecModuleQuery,
  CrmAccountMappingsQuery,
  LoggedinUserQuery
} from '../../../graphql/__generated__/graphql'
import { AccountRecord, CrmEventRecord } from '../../../@types/module'
import { AccountFilter } from '../../../@types/common/AccountTable'

export const getAccountRecModuleData = (data: AccountRecModuleQuery) => {
  // Getting data for detail table in ICP
  const bussinessDetails: AccountRecord[] = data.accountsRec.map(e => {
    return {
      uuid: e.revtron_id || 0,
      annualRevenue: e.to_revtron_companies?.revenue || 0,
      name: e.to_revtron_companies?.natural_name || '',
      domain: e.to_revtron_companies?.website || '',
      city: e.to_revtron_companies?.city || '',
      empCount: e.to_revtron_companies?.employee_counts || '',
      predictProba: e.predict_proba || 0,
      companyType: e.to_revtron_companies?.type || '',
      state: e.to_revtron_companies?.state || '',
      country: e.to_revtron_companies?.country || '',
      foundingYear: e.to_revtron_companies?.founded_year || 0,
      industry: e.to_revtron_companies?.industry || '',
      specialities: e.to_revtron_companies?.tags || '',
    }
  })

  return { bussinessDetails, totalRecordsCount: data.totalRecords.aggregate?.count || 0 }
}

export const getCrmEventData = (
  selectedIds: number[],
  data?: AccountRecModuleQuery,
  mappings?: CrmAccountMappingsQuery,
  loggedinUser?: LoggedinUserQuery
) => {
  const accountRec: CrmEventRecord[] = []
  const fieldMappings = mappings?.crm_account_mappings
  const crmUser = loggedinUser?.user
  const selectedData = _.chain(data?.accountsRec)
    .filter(e => !!e.to_revtron_companies && !!e.revtron_id && selectedIds.includes(e.revtron_id))
    .map(e => ({
      revtron_company_id: e.revtron_id,
      company: e.to_revtron_companies as any
    }))
  .value()

  if (selectedData.length === 0 || !fieldMappings || fieldMappings.length === 0 || !crmUser) {
    return []
  }

  selectedData.forEach(d => {
    type companyKeyType = keyof typeof d.company
    const records: {[key: string]: any} = {}

    Object.keys(d.company).forEach((key) => {
      const index = fieldMappings.findIndex(m => m.revtron_field === key)
      if (index >= 0) {
        const crm_field = fieldMappings[index].crm_field
        records[crm_field] = d.company[key as companyKeyType]
      }
    })

    if (!_.isEmpty(records)) {
      accountRec.push({
        revtron_user_id: crmUser.revtron_user_id,
        revtron_company_id: d.revtron_company_id,
        crm_user_id: crmUser.crm_users?.id || null,
        records: [records],
        sobject: 'Account',
      })
    }
  })

  return accountRec
}

export const getQueryVariables = (pageNumber: number, pageSize: number, filters: AccountFilter[]) => {
  const variables = {
    where: {
      crm_id: { _is_null: true },
      to_revtron_companies: {
        _not: { to_send_to_crm_events: {} }
      } as any
    } as any,
    orderBy: { "predict_proba": order_by.desc },
    limit: pageSize,
    offset: (pageNumber - 1) * pageSize,
  }

  filters.forEach((filter) => {
    if (filter.type === 'text') {
      variables.where.to_revtron_companies[filter.fieldName] = {
        _ilike: `%${filter.query}%`
      }
    }
    if (filter.type === 'number') {
      const min = !!filter.min && filter.min > 0 ? filter.min : 0
      const max = !!filter.max && filter.max > 0 ? filter.max : 0
      if (min && max) {
        variables.where.to_revtron_companies[filter.fieldName] = {
          _gte: min,
          _lte: max
        }
      } else if (min && !max) {
        variables.where.to_revtron_companies[filter.fieldName] = {
          _gte: min,
        }
      } else if (!min && max) {
        variables.where.to_revtron_companies[filter.fieldName] = {
          _lte: max
        }
      }
    }
  })

  return variables
}
