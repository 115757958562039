import { FC, useEffect, useRef } from 'react'
import * as d3 from 'd3'

const AreaChart: FC<{ data: any[] }> = ({ data }) => {
  const chartData = data.map(e => ({ x: e.activity_date, y: e.engagement + 1 }))
  const width = 995
  const height = 200
  const left = 0
  const right = 0
  const top = 0
  const bottom = 0
  const chartWidth = width - left - right
  const chartHeight = height - top - bottom
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    draw()
  })

  const draw = () => {
    d3.select(ref.current).select('svg').remove()

    const svg = d3
      .select(ref.current)
      .append('svg')
      .attr('viewBox', `0 0 ${chartWidth + left + right} ${chartHeight + top + bottom}`)
      .attr('width', '100%')
      .attr('height', '100%')
      .append('g')
      .attr('transform', `translate(${left},${top})`)

    const xScale = d3
      .scaleTime()
      .domain(
        d3.extent(chartData, (d) => {
          return new Date(d.x)
        }) as [Date, Date]
      )
      .range([0, chartWidth])

    const yScale = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(chartData, (d) => {
          return d.y
        }),
      ] as number[])
      .range([chartHeight, 0])

    svg.append('linearGradient')
      .attr("id", "area-gradient")
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("x2", 0).attr("y2", yScale(0))
      .attr("x1", 0).attr("y1", yScale(d3.max(chartData, (d) => d.y)))
      .selectAll("stop")						
      .data([
        {offset: "0%", color: "#85ebd980", opacity: 0.54},
        {offset: "100%", color: "#05d9e800", opacity: 0.1},
      ])
      .enter().append("stop")			
      .attr("offset", function(d) { return d.offset })
      .attr("stop-color", function(d) { return d.color })
      .attr("stop-opacity", function(d) { return d.opacity })

    svg
      .append('path')
      .datum(chartData)
      .attr("class", "area")
      .style('fill', 'url(#area-gradient)')
      .attr(
        'd',
        // @ts-ignore
        d3
          .area()
          .curve(d3.curveBasis)
          .x((d) => {
            return xScale(new Date((d as any).x))
          })
          .y0(yScale(0))
          .y1((d) => {
            return yScale((d as any).y)
          })
      )
    svg.append('path')
      .datum(chartData)
      .style('fill', 'transparent')
      .attr('stroke', '#85EBD9')
      .attr('stroke-width', 3)
      .attr(
        'd',
        // @ts-ignore
        d3
          .line()
          .curve(d3.curveBasis)
          .x((d) => {
            return xScale(new Date((d as any).x))
          })
          .y((d) => {
            return yScale((d as any).y)
          })
      )
  }

  return (
    <div ref={ref} />
  )
}

export default AreaChart
