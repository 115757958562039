import React, { useState, FC, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { useUserId } from '@nhost/react'
import { useCreateInviteMutation } from '../../../graphql/__generated__/graphql'
import TextField from '../../molecules/textField'
import Select from '../../molecules/select'
import { alert } from '../../../utils/functions'


const userTypes = [
  {
    name: 'Admin',
    value: 'Admin'
  },
  {
    name: 'Owner',
    value: 'Owner'
  },
  {
    name: 'Member',
    value: 'Member'
  }
]


const InviteUserModal: FC<InviteUserModalProps> = ({ isOpen, closeModal }) => {
  const [createInvite, { data, loading, error }] = useCreateInviteMutation()
  const userId = useUserId() 
  const [state, setState] = useState<any>({
    email: '',
    firstName: '',
    lastName: '',
    userType: {
      name: '',
      value: '',
    },
  })

  const onCloseModal = () => {
    setState({
      email: '',
      firstName: '',
      lastName: '',
      userType: {
        name: '',
        value: '',
      },
    })
    closeModal()
  }

  const handleFormSubmit = async (e: any) => {
    e.preventDefault()

    try {
      // const result = await signInEmailPasswordless(state.email)
      const result = await createInvite({
        variables: {
          invitee_email: state.email,
          inviter_id: userId
        }
      })
      alert('success', 'Successful in inviting a new user!')
      closeModal()
    } catch (err) {
      alert('error', 'Failed in inviting a new user.')
      closeModal()
    }
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onCloseModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black dark:bg-gray-700 !bg-opacity-30" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all dark:bg-header-dark">
                <div className='px-4 py-6 border-b border-gray-300 dark:border-neutral-700'>
                  <div className='flex justify-between items-center mb-2'>
                    <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-200">Invite New Member</h3>
                    <XIcon className='w-4 cursor-pointer dark:text-gray-200' onClick={onCloseModal} />
                  </div>
                  {/* <div className="text-sm font-medium leading-6 text-gray-500 dark:text-gray-400">Invite new users for this platform</div> */}
                </div>
                <div className="px-4 py-6 border-b border-gray-300 dark:border-neutral-700">
                  {/* <TextField
                    className='mb-6'
                    label="First Name"
                    placeholder="First Name"
                    id="first-name"
                    name="first-name"
                    type="text"
                    value={state.firstName}
                    onChange={(value: any) => setState({...state, firstName: value})}
                  />
                  <TextField
                    className='mb-6'
                    label="Last Name"
                    placeholder="Last Name"
                    id="last-name"
                    name="last-name"
                    type="text"
                    value={state.lastName}
                    onChange={(value: any) => setState({...state, lastName: value})}
                  /> */}
                  <TextField
                    className='mb-6'
                    label="E-mail"
                    placeholder="E-mail"
                    id="user-email"
                    name="user-email"
                    type="text"
                    value={state.email}
                    onChange={(value: any) => setState({...state, email: value})}
                  />
                  {/* <Select
                    className='mb-6'
                    placeholder="Select type"
                    label="User type"
                    list={userTypes}
                    selected={state.userType}
                    onSelect={e => setState({...state, userType: e})}
                  /> */}
                </div>

                <div className="px-4 py-6">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent bg-cyan-400 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-cyan-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={handleFormSubmit}
                  >
                    Invite User
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

interface InviteUserModalProps {
  isOpen: boolean
  closeModal: () => void
}

export default InviteUserModal
