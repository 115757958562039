import { FC, memo, useState, useCallback, useEffect } from 'react'
import { SearchIcon, XIcon } from '@heroicons/react/outline'
import { ColoredButton } from '../../common/buttons'
import PageSelectionDropdown from '../../common/dropdown/PageSelectionDropdown'
import CommandPalette from './CommandPalette'
import { getFilterTextForMinMax } from '../../../utils/functions'
import { TablePagination, TableFilter } from '../../../@types/common/AccountTable'

type TableHeaderProps = {
  changeNameFilter: (q: string) => void
  headerActionDisabled: boolean
  headerActionLoading: boolean
  onTableHeaderActionClick: () => void
  headerActionText: string
} & TablePagination & TableFilter

const TableHeader: FC<TableHeaderProps> = (props) => {
  const [isPaletteOpen, setPaletteOpen] = useState(false)

  const handlePaletteVisible = useCallback((status: boolean) => {
        setPaletteOpen(status)
  }, [])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'k' && (event.metaKey || event.ctrlKey)) {
        setPaletteOpen(true)
      }
  }
  document.addEventListener('keydown', handleKeyDown)
  return () => {
    document.removeEventListener('keydown', handleKeyDown)
  }
}, [])

  return (
    <div className="mb-6">
      <div className="flex flex-row justify-between">
        <div className="inline-flex items-center">
          <div className="relative rounded-md shadow-sm mr-2">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="search"
              name="search"
              id="searchFilter"
              className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 dark:text-gray-300 bg-transparent ring-1 ring-inset ring-sidebar-border dark:ring-revtron-dark3 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-revtron-dark3 dark:focus:ring-gray-500 sm:text-sm sm:leading-6"
              placeholder="Search"
              onChange={e => props.changeNameFilter(e.target.value)}
            />
          </div>
          <ColoredButton title="Add Filter" onButtonClick={() => handlePaletteVisible(true)} variant="primary" />
        </div>
        <div className="flex flex-row items-center">
          <ColoredButton
            disabled={props.headerActionDisabled || props.headerActionLoading}
            title={props.headerActionText}
            onButtonClick={props.onTableHeaderActionClick}
            variant="primary"
            isLoading={props.headerActionLoading}
          />
          <div className="font-semibold text-gray-600 ml-8">
            Show: <PageSelectionDropdown pageSize={props.pageSize} onPageSizeSelect={(pz) => props.changePagination(props.pageNumber, pz)} />
          </div>
        </div>
      </div>
      {props.filters.length > 0 && (
        <div className="flex flex-row flex-wrap items-end mt-6">
          {props.filters.map(filter => (
            <div key={filter.fieldName} className="w-56 mb-2 mr-2">
              <label htmlFor={filter.fieldName} className="block text-sm font-medium leading-6 text-gray-900 dark:text-revtron-white">
                {filter.label}
              </label>
              <div className="mt-2 flex rounded-md shadow-sm">
                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                  <input
                    type="text"
                    name={filter.fieldName}
                    id={filter.fieldName}
                    className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-1.5 text-gray-900 dark:text-gray-300 bg-transparent ring-1 ring-inset ring-sidebar-border dark:ring-revtron-dark3 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-revtron-dark3 dark:focus:ring-gray-500 sm:text-sm sm:leading-6"
                    defaultValue={filter.type === 'text' ? filter.query : getFilterTextForMinMax(filter.min, filter.max)}
                    readOnly
                  />
                </div>
                <button
                  type="button"
                  className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-sidebar-border dark:ring-revtron-dark3 hover:bg-gray-50 dark:hover:bg-revtron-gray2"
                  onClick={() => props.removeFilter(filter)}
                >
                  <XIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                </button>
              </div>
            </div>
          ))}
          <div className='mb-2.5'>
            <ColoredButton title='Remove Filters' onButtonClick={props.clearAllFilters} variant="error"  />
          </div>
        </div>
      )}
      <CommandPalette fields={props.fields} open={isPaletteOpen} handlePaletteOpen={handlePaletteVisible} addFilter={props.addFilter} />
    </div>
  )
}

export default memo(TableHeader)
