import { FC } from 'react'

import Spin from '../../atoms/spin'
import { SummaryTileContainer, SummaryTile } from '../../molecules/summary'

import { numberWithCommas } from '../../../utils/functions'

import {
  useAggregateAccountsCountQuery,
  // useAggregateEngagedCountQuery,
  // useAggregateColdCountQuery,
} from '../../../graphql/__generated__/graphql'

const Summary: FC<SummaryProps> = ({ variables, where }) => {
  const { data: accountsData, loading: accountsLoading } = useAggregateAccountsCountQuery({
    variables: {
      where
    },
    fetchPolicy: 'cache-first'
  })
  // const { data: engagedData, loading: engagedLoading } = useAggregateEngagedCountQuery({
  //   variables: {
  //     where: {
  //       ...where,
  //       engagement: {_gt: "0"}
  //     }
  //   },
  //   fetchPolicy: 'cache-first'
  // })
  // const { data: coldData, loading: coldLoading } = useAggregateColdCountQuery({
  //   variables: {
  //     where: {
  //       ...where,
  //       engagement: {_eq: "0"}
  //     }
  //   },
  //   fetchPolicy: 'cache-first'
  // })

  const accountsCount: any = accountsData && accountsData.account_module_static_aggregate.aggregate && accountsData.account_module_static_aggregate.aggregate.count
  // const engagedCount: any = engagedData && engagedData.account_module_gql_aggregate.aggregate && engagedData.account_module_gql_aggregate.aggregate.count
  // const coldCount: any = coldData && coldData.account_module_gql_aggregate.aggregate && coldData.account_module_gql_aggregate.aggregate.count

  if (accountsLoading) {
    return (
      <div className='w-full flex justify-center items-center my-4'>
        <Spin />
      </div>
    )
  }

  return (
    <div className='accounts-summary-content my-6'>
      <SummaryTileContainer>
        <SummaryTile
          label="Total # of Accounts"
          value={numberWithCommas(accountsCount) || 0}
        />
        {/* <SummaryTile
          label="# Engaged"
          value={numberWithCommas(engagedCount) || 0}
        />
        <SummaryTile
          label="# Not Engaged"
          value={numberWithCommas(coldCount) || 0}
        /> */}
      </SummaryTileContainer>
    </div>
  )
}

interface SummaryProps {
  variables: any
  where: any
}

export default Summary
