import { FC, useState } from 'react'
import CustomSelect from '../../components/molecules/select'
import TotalBookingsSummary from '../../components/organisms/overview/totalBookingsSummary'
import BookingsOverChart from '../../components/organisms/overview/bookingsOverChart'
import TotalEffortSummary from '../../components/organisms/overview/totalEffortSummary'
import EffortOverChart from '../../components/organisms/overview/effortOverChart'
import OpportunityOverChart from '../../components/organisms/overview/opportunityOverChart'
import EngagementOverChart from '../../components/organisms/overview/engagementOverChart'
import TotalEngagementSummary from '../../components/organisms/overview/totalEngagementSummary'
import TotalOpportunitySummary from '../../components/organisms/overview/totalOpportunitySummary'

const optionsList = [
  { name: 'Weekly', value: 'weekly' },
  { name: 'Monthly', value: 'monthly' },
]

const Overview: FC<OverviewProps> = () => {
  const [viewType, setViewType] = useState({
    name: 'Weekly',
    value: 'weekly'
  })

  return (
    <div className='max-w-8xl overview dark:text-white relative'>
      <CustomSelect
        className='w-36 ml-auto mb-3'
        list={optionsList}
        selected={viewType}
        onSelect={setViewType}
      />
      <TotalBookingsSummary />
      <BookingsOverChart viewType={viewType.value} />
      <TotalEffortSummary />
      <EffortOverChart viewType={viewType.value} />
      <TotalEngagementSummary />
      <EngagementOverChart viewType={viewType.value} />
      <TotalOpportunitySummary />
      <OpportunityOverChart viewType={viewType.value} />
    </div>
  )
}

interface OverviewProps {
  client?: any
}

export default Overview
