import { FC } from 'react'
import moment from 'moment'

import Spin from '../../atoms/spin'
import VerticalBarChart from './verticalBarChart'

import { useMonthlyBookingsQuery } from '../../../graphql/__generated__/graphql'
import { sortCompareFuncByDate } from '../../../utils/functions'
import { useFeatureContext } from '../../../hooks/featureContext'

const MonthRevenue: FC = () => {
  const {data, loading} = useMonthlyBookingsQuery()
  const { isHarry } = useFeatureContext()

  if (loading) {
    return (
      <div className='flex-1 flex justify-center bg-white dark:bg-header-dark shadow p-4 ml-6'>
        <Spin />
      </div>
    )
  }

  const renderMaxValue = () => {
    if (data && data.monthly_bookings_gql) {
      return Math.max.apply(Math, data.monthly_bookings_gql.map((item) => Math.round(item.sum / 1000)))
    }
  }

  const chartData: any[] = []
  const months = []
  const jan = moment().startOf('year')
  months.push(jan)
  for (let i = 1; i <= 11; i++) {
    months.push(moment(jan).set('month', i))
  }

  if (data && data.monthly_bookings_gql) {
    let monthRevenues = data.monthly_bookings_gql
    monthRevenues = monthRevenues.slice().sort((a, b) => sortCompareFuncByDate(a, b, 'date_trunc'))
    if (isHarry) {
      monthRevenues.forEach(d => {
        chartData.push({
          x: Date.parse(moment(d.date_trunc).format('MM/DD/YYYY')),
          y: d.sum / 1000,
          date: moment(d.date_trunc).format('MMM')
        })
      })
    } else {
      months.forEach(month => {
        let selectedMonth = moment(month)
        const matched = monthRevenues.find(e => moment(e.date_trunc).isSame(selectedMonth))
        if (matched) {
          chartData.push({
            x: Date.parse(moment(month).format('MM/DD/YYYY')),
            y: matched.sum / 1000,
            date: moment(month).format('MMM')
          })
        } else {
          chartData.push({
            x: Date.parse(moment(month).format('MM/DD/YYYY')),
            y: 0,
            date: moment(month).format('MMM')
          })
        }
      })
    }
  }

  return (
    <div className='flex-1 bg-white dark:bg-header-dark p-4 shadow ml-6'>
      <div className='text-sm dark:text-theme-toggle-icon'>Monthly Revenues</div>
      {/* <div className='text-lg font-semibold'>Max ${numberWithCommas(isInteger(renderMaxValue() || 0))}k</div> */}
      <VerticalBarChart data={chartData} />
    </div>
  )
}

export default MonthRevenue