import React, { useEffect } from 'react'
import { NhostApolloProvider } from '@nhost/react-apollo'
import { NhostProvider } from '@nhost/react'
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react'

import Layout from './layouts'
import AppWithContext from './hooks/featureContext'

import { nhost } from './utils/nhost'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const gb = new GrowthBook({
  apiHost: process.env.REACT_APP_GROWTHBOOK_API_HOST,
  clientKey: process.env.REACT_APP_GROWTHBOOK_CLIENT_KEY,
  // Enable easier debugging of feature flags during development
  enableDevMode: true,
  trackingCallback: (experiment, result) => {
    // TODO: Use your real analytics tracking system
    console.log("Viewed Experiment", {
      experimentId: experiment.key,
      variationId: result.variationId
    });
  }
})

const App = () => {
  useEffect(() => {
    // Load features from the GrowthBook API
    gb.loadFeatures()
  }, [])

  return (
    <GrowthBookProvider growthbook={gb}>
      <NhostProvider nhost={nhost}>
        <NhostApolloProvider nhost={nhost}>
          <AppWithContext>
            <Layout />
            <ToastContainer theme="colored" />
          </AppWithContext>
        </NhostApolloProvider>
      </NhostProvider>
    </GrowthBookProvider>
  )
}

export default App
