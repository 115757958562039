import _ from 'lodash'
import { IcpModuleLegacyQuery } from '../../../graphql/__generated__/graphql'
import { calcMedian } from '../../../utils/functions'
import { ICPAccountLegacy, ICPChartData, ICPFilters, ICPSummary, ICPTreemapChartData, AccountRecord, GeoMapData, RevtronCompanyLegacy, TreemapChart, } from '../../../@types/module'

export const getFormatter = (maxValue: number, formatPrefix: string = '') => {
  if (maxValue >= 10000000000) {
    return {unitValue: 1000000000, formatter: (v: number) => `${formatPrefix}${v}B`}
  }
  if (maxValue >= 10000000) {
    return {unitValue: 1000000, formatter: (v: number) => `${formatPrefix}${v}M`}
  }
  if (maxValue >= 10000) {
    return {unitValue: 1000, formatter: (v: number) => `${formatPrefix}${v}K`}
  } else {
    return {unitValue: 1, formatter: (v: number) => formatPrefix + v}
  }
}

export const getIcpModuleData = (data?: IcpModuleLegacyQuery, filters?: ICPFilters) => {
  const allData: ICPAccountLegacy[] = _.chain(data?.account).map(a => ({
    Id: a.id || '',
    Name: a.name || '',
    Website: a.website || '',
    AnnualRevenue: a.annualrevenue ? Number(a.annualrevenue) : 0,
    NumberOfEmployees: a.numberofemployees ? Number(a.numberofemployees) : 0,
    revtron_companies: _.get(a, 'to_revtron.to_revtron_company', []) as RevtronCompanyLegacy[],
    predict_proba: _.get(a, 'account_module_static.predct_proba', 0)
  })).value()

  let filteredData = _.cloneDeep(allData)

  // Filter by employee count
  const getFilterdDataByEmployeeCount = (sourceData: ICPAccountLegacy[]) => {
    if (!filters?.employeeCount) {
      return sourceData
    }
    const min = filters.employeeCount[0]
    const max = filters.employeeCount[1]
    return sourceData.filter(e => {
      if (e.revtron_companies.length === 0) {
        return false
      }
      const empCount = e.revtron_companies[0].MOST_RECENT_EMPLOYEE_COUNT
      return empCount >= min && empCount <= max
    })
  }

  // Filter by business age
  const getFilterdDataByBusinessAge = (sourceData: ICPAccountLegacy[]) => {
    if (!filters?.ageOfBusiness) {
      return sourceData
    }
    const min = filters.ageOfBusiness[0]
    const max = filters.ageOfBusiness[1]
    return sourceData.filter(e => {
      if (e.revtron_companies.length === 0) {
        return false
      }
      const age = new Date().getFullYear() - Number(e.revtron_companies[0].FOUNDINGYEAR)
      return age >= min && age <= max
    })
  }
  
  // Filter by annaul revenue
  const getFilterdDataByAnnualRevenue = (sourceData: ICPAccountLegacy[]) => {
    if (!filters?.annualRevenue) {
      return sourceData
    }
    const min = filters.annualRevenue[0]
    const max = filters.annualRevenue[1]
    return sourceData.filter(e => e.AnnualRevenue >= min && e.AnnualRevenue <= max)
  }

  // Filter by company type
  const getFilterdDataByCompanyType = (sourceData: ICPAccountLegacy[]) => {
    if (!filters?.companyType) {
      return sourceData
    }
    return sourceData.filter(e => {
      if (e.revtron_companies.length === 0 || !e.revtron_companies[0].COMPANYTYPE) {
        return false
      }
      return e.revtron_companies[0].COMPANYTYPE.toLowerCase() === filters.companyType?.toLowerCase()
    })
  }
  
  // Filter by industry
  const getFilterdDataBySubCategory = (sourceData: ICPAccountLegacy[]) => {
    if (!filters?.subCategory) {
      return sourceData
    }
    return _.chain(sourceData).filter(e => {
      if (e.revtron_companies.length === 0 || !e.revtron_companies[0].SUB_INDUSTRY_CATEGORY_NAME) {
        return false
      }
      return e.revtron_companies[0].SUB_INDUSTRY_CATEGORY_NAME.toLowerCase() === filters.subCategory?.toLowerCase()
    }).value()
  }

  // Filter by state
  const getFilterdDataByState = (sourceData: ICPAccountLegacy[]) => {
    if (!filters?.state) {
      return sourceData
    }
    return _.chain(sourceData).filter(e => {
      if (e.revtron_companies.length === 0 || !e.revtron_companies[0].STATE) {
        return false
      }
      return e.revtron_companies[0].STATE.toLowerCase() === filters.state?.toLowerCase()
    }).value()
  }

  filteredData = getFilterdDataByEmployeeCount(filteredData)
  filteredData = getFilterdDataByBusinessAge(filteredData)
  filteredData = getFilterdDataByAnnualRevenue(filteredData)
  filteredData = getFilterdDataByState(filteredData)
  // Save data
  let filteredTreemapData = _.cloneDeep(filteredData)
  filteredData = getFilterdDataByCompanyType(filteredData)
  filteredData = getFilterdDataBySubCategory(filteredData)

  const revtronCompanies = _.chain(allData).map(e => e.revtron_companies).flatten().value()
  const filteredRevtronCompanies = _.chain(filteredData).map(e => e.revtron_companies).flatten().value()

  // Getting data for summary in ICP
  const icpScoreArr = filteredData.map(f => f.predict_proba * 100)
  const employeeCountArr = filteredRevtronCompanies.map(f => f.MOST_RECENT_EMPLOYEE_COUNT)
  const businessAgeArr = filteredRevtronCompanies.map(f => new Date().getFullYear() - f.FOUNDINGYEAR)
  const annualRevenueArr = filteredData.map(f => f.AnnualRevenue)

  const summaryData: ICPSummary = {
    businessCount: filteredRevtronCompanies.length,
    averageICPScore: calcMedian(icpScoreArr),
    averageEmployeeCount: calcMedian(employeeCountArr),
    averageBusinessAge: calcMedian(businessAgeArr),
    averageAnnualRevenue: calcMedian(annualRevenueArr),
  }

  // Getting data for histogram charts in ICP
  const isFilteredByHistogram = !!filters?.employeeCount || !!filters?.ageOfBusiness || !!filters?.annualRevenue
  const revtronCompaniesForHistogram = isFilteredByHistogram ? revtronCompanies : filteredRevtronCompanies
  const dataForHistogram = isFilteredByHistogram ? allData : filteredData
  const maxEmployeeCount = revtronCompaniesForHistogram.reduce((max: number, d) => max < d.MOST_RECENT_EMPLOYEE_COUNT ? d.MOST_RECENT_EMPLOYEE_COUNT : max, 0)
  const maxAnnualRevenue = dataForHistogram.reduce((max: number, d) => max < d.AnnualRevenue ? d.AnnualRevenue : max, 0)
  const numberOfEmployees = revtronCompaniesForHistogram.map(d => d.MOST_RECENT_EMPLOYEE_COUNT / getFormatter(maxEmployeeCount).unitValue)
  const meanNumberOfEmployees = _.mean(numberOfEmployees)
  const annualRevenue = dataForHistogram.map(d => d.AnnualRevenue / getFormatter(maxAnnualRevenue).unitValue)
  const meanAnnualRevenue = _.mean(annualRevenue)
  const ageOfBusiness = revtronCompaniesForHistogram.map(d => new Date().getFullYear() - d.FOUNDINGYEAR)
  const meanAgeOfBusiness = _.mean(ageOfBusiness)
  const chartData: ICPChartData = {
    maxEmployeeCountFormatter: getFormatter(maxEmployeeCount).formatter,
    employeeCountUnit: getFormatter(maxEmployeeCount).unitValue,
    maxAnnualRevenueFormatter: getFormatter(maxAnnualRevenue).formatter,
    annualRevenueUnit: getFormatter(maxAnnualRevenue).unitValue,
    ageOfBusiness: ageOfBusiness.filter(d => d < meanAgeOfBusiness),
    numberOfEmployees: numberOfEmployees.filter(d => d < meanNumberOfEmployees),
    annualRevenue: annualRevenue.filter(d => d < meanAnnualRevenue),
  }

  // Getting data for treemap chart in ICP
  const filteredRevtronCompaniesForTreemap = _.chain(filteredTreemapData).map(e => e.revtron_companies).flatten().value()
  const isFilteredByCategoryTreemap = !!filters?.subCategory
  const revtronCompaniesForCategoryTreemap = isFilteredByCategoryTreemap ? filteredRevtronCompaniesForTreemap : filteredRevtronCompanies
  const categories = _.uniq(revtronCompaniesForCategoryTreemap.map(d => d.SUB_INDUSTRY_CATEGORY_NAME))
  const industryData: ICPTreemapChartData = {
    name: 'industry',
    children: categories.map(cat => ({
      name: cat,
      value: revtronCompaniesForCategoryTreemap.filter(r => r.SUB_INDUSTRY_CATEGORY_NAME === cat).length
    })).sort((a: TreemapChart, b: TreemapChart) => b.value - a.value)
  }
  const isFilteredByCompanyTypeTreemap = !!filters?.companyType
  const revtronCompaniesForCompanyTypeTreemap = isFilteredByCompanyTypeTreemap ? filteredRevtronCompaniesForTreemap : filteredRevtronCompanies
  const companyTypes = _.uniq(revtronCompaniesForCompanyTypeTreemap.map(d => d.COMPANYTYPE))
  const companyTypesData: ICPTreemapChartData = {
    name: 'industry',
    children: companyTypes.map(type => ({
      name: type,
      value: revtronCompaniesForCompanyTypeTreemap.filter(r => r.COMPANYTYPE === type).length
    })).sort((a: TreemapChart, b: TreemapChart) => b.value - a.value)
  }
  const treemapData = {
    industry: industryData,
    companyType: companyTypesData
  }

  // Getting data for geo map
  const dataGroupByState = _.groupBy(filteredData, d => d.revtron_companies[0].STATE)
  const dataGroupByCountry = _.groupBy(filteredData, d => d.revtron_companies[0].COUNTRY)
  const geoMapData: GeoMapData = {
    us: [],
    world: []
  }
  Object.keys(dataGroupByCountry).forEach((country) => {
    let companies = 0
    let employees = 0
    let amount = 0
    dataGroupByCountry[country].forEach((d: ICPAccountLegacy) => {
      companies += 1
      employees += d.NumberOfEmployees
      amount += d.AnnualRevenue
    })
    geoMapData.world.push({location: country, companies, employees, amount})
  })
  Object.keys(dataGroupByState).forEach((state) => {
    let companies = 0
    let employees = 0
    let amount = 0
    dataGroupByState[state].forEach((d: ICPAccountLegacy) => {
      companies += 1
      employees += d.NumberOfEmployees
      amount += d.AnnualRevenue
    })
    geoMapData.us.push({location: state, companies, employees, amount})
  })

  let accounts = data ? data.account as ICPAccountLegacy[] : []

  // Getting data for detail table in ICP
  let bussinessDetails: AccountRecord[] = filteredData.map(e => {
    const revtron_company = e.revtron_companies[0]
    return {
      uuid: revtron_company.ID,
      annualRevenue: e.AnnualRevenue,
      name: revtron_company.INFORMALNAME,
      domain: revtron_company.WEBSITE,
      city: revtron_company.CITY,
      empCount: revtron_company.MOST_RECENT_EMPLOYEE_COUNT,
      predictProba: e.predict_proba,
      companyType: revtron_company.COMPANYTYPE,
      state: revtron_company.STATE,
      country: revtron_company.COUNTRY,
      foundingYear: revtron_company.FOUNDINGYEAR,
      industry: revtron_company.SUB_INDUSTRY_CATEGORY_NAME,
      specialities: revtron_company.SPECIALTIES,
    }
  })

  return { summaryData, chartData, treemapData, geoMapData, accounts, bussinessDetails }
}

