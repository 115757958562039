import { useState, FC, useEffect } from 'react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import * as dates from 'date-arithmetic'
import moment from 'moment'

import Spin from '../../atoms/spin'

import MonthView from './month-view'
import WeekView from './week-view'
import DayView from './day-view'
import AgendaView from './agenda-view'

import { visibleDays, visibleDaysForWeek, visibleDaysForAgenda } from '../../../utils/calendar'
import { transferredItems } from '../../../utils/functions'

type calendarViewType = 'month' | 'week' | 'day' | 'agenda'

const CustomCalendar: FC<CustomCalendarProps> = ({ data, loading }) => {
  const [viewType, setViewType] = useState<calendarViewType>('week')
  const [date, setDate] = useState<any>(new Date())
  const [point, setPoint] = useState<number>(0)

  useEffect(() => {
    if (viewType === 'month') {
      setDate(dates.month(new Date(), point))
    } else if (viewType === 'week') {
      setDate(dates.add(new Date(), point, 'week'))
    } else if (viewType === 'day') {
      setDate(dates.add(new Date(), point, 'day'))
    } else if (viewType === 'agenda') {
      // setDate(dates.add(new Date(), point, 'month'))
      setDate(dates.add(new Date(), point, 'week'))
    }
  }, [point])

  const handleDate = (order: string) => {
    if (order === 'prev') {
      setPoint(point - 1)
    } else if (order === 'next') {
      setPoint(point + 1)
    }
  }

  const handleViewType = (type: calendarViewType) => {
    setViewType(type)
    setPoint(0)
    setDate(new Date())
  }

  const renderDate = () => {
    if (viewType === 'month') {
      return moment(date).format('MMMM YYYY')
    } else if (viewType === 'week') {
      return `${moment(visibleDaysForWeek(date)[0]).format('MMM DD')} - ${moment(visibleDaysForWeek(date)[6]).format('MMM DD')}`
    } else if (viewType === 'day') {
      return moment(date).format('dddd MMM DD')
    } else if (viewType === 'agenda') {
      return `${moment(visibleDaysForAgenda(date)[0]).format('MM/DD/YYYY')} - ${moment(visibleDaysForAgenda(date)[visibleDaysForAgenda(date).length - 1]).format('MM/DD/YYYY')}`
    }
    return moment(date).format('MMMM YYYY')
  }

  const handleSelectedDate = (date: any) => {
    setDate(date)
    setViewType('day')
  }

  const renderContent = () => {
    switch (viewType) {
      case 'month':
        return (
          <MonthView
            currentDate={date}
            setCurrentDate={handleSelectedDate}
            days={visibleDays(date)}
            events={transferredItems(data)}
          />
        )
      case 'week':
        return (
          <WeekView
            currentDate={date}
            days={visibleDaysForWeek(date)}
            events={transferredItems(data)}
          />
        )
      case 'day':
        return (
          <DayView
            currentDate={date}
            events={transferredItems(data)}
          />
        )
      case 'agenda':
        return (
          <AgendaView
            days={visibleDaysForAgenda(date)}
            events={transferredItems(data)}
          />
        )
      default:
        return (
          <MonthView
            currentDate={date}
            setCurrentDate={handleSelectedDate}
            days={visibleDays(date)}
            events={transferredItems(data)}
          />
        )
    }
  }

  if (loading) {
    return (
      <div className='w-full flex justify-center items-center my-4'>
        <Spin />
      </div>
    )
  }

  return (
    <div className='h-0 min-h-[768px]'>
      <div className="flex h-full flex-col mt-5">
        <header className="flex flex-none items-center justify-between border-b border-gray-200 dark:border-revtron-dark3 py-4 bg-white dark:bg-header-dark">
          <div className="flex items-center rounded-md shadow-sm md:items-stretch">
            <button
              type="button"
              onClick={() => setDate(new Date())}
              className="hidden rounded-md border border-gray-300 dark:border-revtron-dark3 bg-white dark:bg-header-dark px-3.5 text-sm font-medium text-gray-700 dark:text-gray-400 hover:bg-gray-50 hover:text-gray-900 focus:relative md:block mr-1"
            >
              Today
            </button>
            <button
              type="button"
              onClick={() => handleDate('prev')}
              className="flex items-center justify-center rounded-md border border-gray-300 dark:border-revtron-dark3 bg-white dark:bg-header-dark py-2 pl-3 pr-4 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50 mr-1"
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden" />
            <button
              type="button"
              onClick={() => handleDate('next')}
              className="flex items-center justify-center rounded-md border border-gray-300 dark:border-revtron-dark3 bg-white dark:bg-header-dark py-2 pl-4 pr-3 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:px-2 md:hover:bg-gray-50"
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
          <h1 className="text-lg font-semibold text-gray-900 dark:text-gray-300">
            <time dateTime={moment(date).format('YYYY-MM')}>{renderDate()}</time>
          </h1>
          <div className="flex items-center">
            <button
              className={`px-4 py-2 mr-2 rounded-md ${viewType === 'month' ? 'bg-revtron-teal dark:bg-revtron-dark3 dark:text-gray-300' : 'dark:text-gray-400 bg-white dark:bg-header-dark'}`}
              onClick={() => handleViewType('month')}
            >
              Month
            </button>
            <button
              className={`px-4 py-2 mr-2 rounded-md ${viewType === 'week' ? 'bg-revtron-teal dark:bg-revtron-dark3 dark:text-gray-300' : 'dark:text-gray-400 bg-white dark:bg-header-dark'}`}
              onClick={() => handleViewType('week')}
            >
              Week
            </button>
            <button
              className={`px-4 py-2 mr-2 rounded-md ${viewType === 'day' ? 'bg-revtron-teal dark:bg-revtron-dark3 dark:text-gray-300' : 'dark:text-gray-400 bg-white dark:bg-header-dark'}`}
              onClick={() => handleViewType('day')}
            >
              Day
            </button>
            <button
              className={`px-4 py-2 mr-2 rounded-md ${viewType === 'agenda' ? 'bg-revtron-teal dark:bg-revtron-dark3 dark:text-gray-300' : 'dark:text-gray-400 bg-white dark:bg-header-dark'}`}
              onClick={() => handleViewType('agenda')}
            >
              Agenda
            </button>
          </div>
        </header>
        {renderContent()}
      </div>
    </div>
  )
}

interface CustomCalendarProps {
  data: any
  loading: boolean
}

export default CustomCalendar
