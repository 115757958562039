import { FC, useState, memo } from 'react'
import moment from 'moment'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { DateRangePickerProps } from '../../../@types/common/DatePicker'

const arrayRange = (start: number, stop: number, step: number) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
  )

const YearlyPicker: FC<DateRangePickerProps> = ({ dateRange, onDateRangeChange }) => {
  const currentYear = moment().year()
  const startYear = currentYear - 11
  const endYear = currentYear
  const [duration, setDuration] = useState([startYear, endYear])
  const [hoverValue, setHoverValue] = useState<any>(null)
  const years = arrayRange(duration[0], duration[1], 1)


  const setStateHandler = (year: number) => {
    if (moment(dateRange.start).year() === moment(dateRange.end).year()) {
      if (moment(dateRange.start).isAfter(moment(`$01.01.${year}`))) {
        // set startDate with the selected year
        onDateRangeChange({
          start: moment(`01.01.${year}`),
          end: dateRange.end.endOf('year')
        })
      } else {
        // set endDate with the selected year
        onDateRangeChange({
          start: dateRange.start.startOf('year'),
          end: moment(`$12.31.${year}`).endOf('month')
        })
      }
    } else {
      // set start and end dates with the selected year
      onDateRangeChange({
        start: moment(`01.01.${year}`),
        end: moment(`12.31.${year}`)
      })
    }
  }

  return (
    <div className="text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 xl:col-start-9">
      <div className="flex items-center text-gray-900">
        <button
          type="button"
          className="flex flex-none items-center justify-center border border-table-text dark:border-revtron-gray2 rounded p-1.5 text-gray-400 hover:text-gray-500"
          onClick={() => setDuration([duration[0]-12, duration[1]-12])}
        >
          <span className="sr-only">Previous year</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <div className="flex-auto text-base text-black dark:text-white">{duration[0]} - {duration[1]}</div>
        <button
          type="button"
          className="flex flex-none items-center justify-center border border-table-text dark:border-revtron-gray2 rounded p-1.5 text-gray-400 hover:text-gray-500"
          onClick={() => setDuration([duration[0]+12, duration[1]+12])}
        >
          <span className="sr-only">Next year</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-3 grid grid-cols-3 text-sm gap-1">
        {years.map((year, index) => {
          const isCurrentYear = moment().isSame(moment().year(year))
          const isSelectedYear = moment(dateRange.start).isSameOrBefore(moment(dateRange.start).year(year)) && moment(dateRange.end).isSameOrAfter(moment(dateRange.end).year(year))
          let isSelectedYearByHover = false
          if (moment(dateRange.start).isSame(moment(dateRange.end).startOf('year')) && hoverValue !== null) {
            const hoverDate = moment(dateRange.start).year(hoverValue)
            if (hoverDate.isBefore(dateRange.start)) {
              isSelectedYearByHover = moment(hoverDate).isSameOrBefore(moment(dateRange.start).year(year)) && moment(dateRange.start).isSameOrAfter(moment(dateRange.end).year(year))
            } else {
              isSelectedYearByHover = moment(dateRange.start).isBefore(moment(dateRange.start).year(year)) && moment(hoverDate).isSameOrAfter(moment(dateRange.end).year(year))
            }
          }
          return (
            <button
              key={index}
              type="button"
              className={`
                py-2 hover:bg-gray-100 dark:hover:bg-revtron-gray2 focus:z-10 rounded
                ${isCurrentYear && 'bg-gray-200 dark:bg-gray-500'}
                ${isSelectedYear && 'bg-cyan-200 dark:bg-cyan-500'}
                ${isSelectedYearByHover && 'bg-cyan-50 dark:bg-cyan-800'}
              `}
              onClick={() => setStateHandler(year)}
              onMouseEnter={() => setHoverValue(year)}
              onMouseLeave={() => setHoverValue(null)}
            >
              <div className='mx-auto flex h-5 items-center justify-center rounded-full'>
                {year}
              </div>
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default memo(YearlyPicker)
