import { FC, useEffect, useRef } from 'react'
import * as d3 from 'd3'
import moment from 'moment'
const typeColors = ['#0398B0', '#005678', '#012C52', '#01012B']

const StackedBarChart: FC<{ data: any[]; tickValues: any[] }> = ({ data, tickValues }) => {
  const width = 995
  const height = 260
  const left = 50
  const right = 20
  const top = 40
  const bottom = 30
  const chartWidth = width - left - right
  const chartHeight = height - top - bottom
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    draw()
  })

  const draw = () => {

    d3.select(ref.current).select('svg').remove()
    d3.select(ref.current).selectAll('.tooltip').remove()
    const svg = d3
      .select(ref.current)
      .append('svg')
      .attr('viewBox', `0 0 ${chartWidth + left + right} ${chartHeight + top + bottom}`)
      .attr('width', '100%')
      .attr('height', '100%')
      .append('g')
      .attr('transform', `translate(${left},${top})`)

    const dataByDate = d3.group(data, (d) => d.createddate)

    const transformedData = []
    for (const [date, originalRows] of dataByDate as any) {
      const row: any = { date }
      for (const d of originalRows) {
        row[d.task_id] = 1
        row[`${d.task_id} Detail`] = d
      }
      transformedData.push(row)
    }
  
    let taskSubTypes = [
      ...new Set(data.map((d) => d.tasksubtype)) as any,
    ]
    taskSubTypes.sort((a, b) => a.localeCompare(b))
    let taskIds = [
      ...new Set(data.map((d) => d.task_id)) as any,
    ]
    taskIds = taskIds.filter(el => el) // remove null or undefined
    const stackedData = d3.stack().keys(taskIds)(
      transformedData
    )

    let maxValue = 1
    if (data.length > 0) {
      maxValue = Math.max(...stackedData.map(stackedItem => {
        return Math.max(...stackedItem.map(item => {
          return isNaN(item[1]) ? 0 : item[1]
        }))
      }))
    }

    const xScale = d3.scaleTime()
      .domain([new Date(tickValues[0]), new Date(tickValues[tickValues.length - 1])])
      .range([0, chartWidth])

    svg.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0,${chartHeight})`)
      .call(
        d3.axisBottom(xScale)
          .tickFormat((x: any) => moment(x).format('MM/DD'))
      )

    svg.select('.x-axis').select('path').remove()
    svg.select('.x-axis')
        .selectAll('g')
        .select('line')
        .remove()
    svg.select('.x-axis')
        .selectAll('g')
        .select('text')
        .style('color', '#6B7280')
        // .style('display', (d, index) => (viewType === 'monthly' || index % 3 === 0) ? 'block' : 'none')

    const yScale = d3.scaleLinear()
      .domain([Math.ceil(maxValue / Math.pow(10, maxValue.toString().length - 1)) * Math.pow(10, maxValue.toString().length - 1), 0])
      .range([0, chartHeight])

    svg.append("g")
      .attr("class", "grid")
      .attr("class", "xaxis")
      .call(
        d3.axisLeft(yScale)
        .tickSize(-chartWidth)
      )
    svg.select('.xaxis').select('path').remove()
    svg.select('.xaxis')
      .selectAll('line')
      .style("stroke-dasharray", "5 5")
      .style("stroke-width", 0.5)
      .style("opacity", 0.5)
    svg.select('.xaxis')
      .selectAll('g')
      .select('text')
      .style('color', '#6B7280')

    const div = d3.select(ref.current).append("div")
      .attr("class", "tooltip bg-chart-tooltip")
      .style("opacity", 0)

    svg.append('g')
      .selectAll('g')
      // Enter in the stack data = loop key per key = group per group
      .data(stackedData)
      .join('g')
      .attr("class", (d: any): any => `pipeline-type-detail ${d.key.toLowerCase().replaceAll(' ', '-')}`)
      .selectAll('rect')
      // enter a second time = loop subgroup per subgroup to add all rectangles
      .data((d) => d)
      .join('rect')
      .attr('x', (d, i): any => xScale(new Date(d.data.date) as any))
      .attr('y', (d) => yScale(isNaN(d[1]) ? 0 : d[1]))
      .attr('height', (d) => isNaN(d[1]) ? 0 : yScale(d[0]) - yScale(d[1]))
      .attr('width', 20)
      .attr('stroke', '#ff2a6d')
      .attr('stroke-width', 1)
      .attr('fill', (d: any): any => {
        const typeData: any = Object.values(d.data)[d[0]*2 + 2]
        return typeData ? typeColors[taskSubTypes.indexOf(typeData.tasksubtype)] : '#0398B0'
      })
      .on('mouseover', function (event, d) {
        const typeData: any = Object.values(d.data)[d[0]*2 + 2]
        d3.select(this).attr('opacity', 0.75)
        div.transition()
          .duration(200)
          .style("opacity", .9)
        div.html(`
            <div class="text-chart-tooltip-text ">
              <p>Type: ${typeData.type}</p>
              <p>User Name: ${typeData.user_name}</p>
              <p>Account Name: ${typeData.account_name}</p>
              <p>Subject: ${typeData.subject}</p>
              <p>Date: ${moment(typeData.createddate).format('MM/DD/YYYY')}</p>
            </div>
          `)
          .style("left", (event.offsetX - 80) + "px")
          .style("top", (event.offsetY - 30) + "px")
      })
      .on('mouseout', function (d) {
        d3.select(this).attr('opacity', 1)
        div.transition()
          .duration(500)
          .style("opacity", 0)
      })
  }

  return (
    <div ref={ref} className="flex-1 relative" />
  )
}

export default StackedBarChart;
