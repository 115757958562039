import { FC } from 'react'
import moment from 'moment'

import Spin from '../../atoms/spin'
import LineChart from './lineChart'
import { isInteger, numberWithCommas } from '../../../utils/functions'
import { DailyRevenueData } from '../../../containers/leaderboard_v1/Leaderboard.util'
import { VerticalBarChartData } from '../../../@types/Chart'

type RevenueSumProps = {
  data: DailyRevenueData[]
  isLoading: boolean
}

const RevenueSum: FC<RevenueSumProps> = ({ data: revenues, isLoading }) => {
  if (isLoading) {
    return (
      <div className='flex-1 flex justify-center bg-white dark:bg-header-dark shadow p-4'>
        <Spin />
      </div>
    )
  }

  const chartData: VerticalBarChartData[] = []
  let maxRevenue: number = 0
  const startDate = new Date(new Date().getFullYear(), 0, 1)
  const currentDate = new Date()

  if (revenues.length === 0) {
    chartData.push({
      x: Date.parse(startDate.toISOString()),
      date: moment(startDate.toISOString()).format('MM/DD'),
      y: 0
    })
    chartData.push({
      x: Date.parse(currentDate.toISOString()),
      date: moment(currentDate.toISOString()).format('MM/DD'),
      y: 0
    })
  } else if (revenues.length === 1) {
    const oneDate = new Date(revenues[0].day)
    if (startDate.getTime() !== oneDate.getTime() && currentDate.getTime() !== oneDate.getTime()) {
      chartData.push({
        x: Date.parse(startDate.toISOString()),
        date: moment(startDate.toISOString()).format('MM/DD'),
        y: 0
      })
      chartData.push({
        x: Date.parse(revenues[0].day),
        date: moment(revenues[0].day).format('MM/DD'),
        y: revenues[0].revenue
      })
      chartData.push({
        x: Date.parse(currentDate.toISOString()),
        date: moment(currentDate.toISOString()).format('MM/DD'),
        y: revenues[0].revenue
      })
    } else if (startDate.getTime() === oneDate.getTime()) {
      chartData.push({
        x: Date.parse(revenues[0].day),
        date: moment(revenues[0].day).format('MM/DD'),
        y: revenues[0].revenue
      })
      chartData.push({
        x: Date.parse(currentDate.toISOString()),
        date: moment(currentDate.toISOString()).format('MM/DD'),
        y: revenues[0].revenue
      })
    } else if (currentDate.getTime() === oneDate.getTime()) {
      chartData.push({
        x: Date.parse(startDate.toISOString()),
        date: moment(startDate.toISOString()).format('MM/DD'),
        y: 0
      })
      chartData.push({
        x: Date.parse(revenues[0].day),
        date: moment(revenues[0].day).format('MM/DD'),
        y: revenues[0].revenue
      })
    }
    maxRevenue = revenues[0].revenue
  } else {
    revenues.forEach((r: any) => {
      chartData.push({
        x: Date.parse(r.day),
        date: moment(r.day).format('MM/DD'),
        y: r.revenue
      })
      maxRevenue = maxRevenue < r.revenue ? r.revenue : maxRevenue
    })
  }

  return (
    <div className='flex-1 bg-white dark:bg-header-dark p-4 shadow'>
      <div className='text-sm dark:text-theme-toggle-icon'>Running Sum of Revenues</div>
      <div className='text-lg font-semibold'>${numberWithCommas(isInteger(maxRevenue))}</div>
      <LineChart data={chartData} />
    </div>
  )
}

export default RevenueSum
