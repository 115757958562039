import { FC, useState } from 'react'

import EventCalendar from '../../components/organisms/calendar/eventCalendar'
import MeetingAccountDetail from '../../components/organisms/calendar/meetingAccountDetail'
import MultipleSelect from '../../components/molecules/multipleSelect'
import CustomCalendar from '../../components/molecules/calendar'

import {
  useCalendarCleanQuery,
  useCalendarMeetingOwnerQuery,
  useCalendarStageNameQuery,
} from '../../graphql/__generated__/graphql'
import { calculateDefaultAvtarBg, intoRGB } from '../../utils/functions'

const currentDate = new Date()
const startDate = currentDate.getDate() - currentDate.getDay()
const startDay = new Date(new Date().setDate(startDate))
startDay.setHours(0, 0, 0, 0)
startDay.toISOString()
const startTimeObj = new Date(startDay)
const endDate = startDate + 6
const endDay = new Date(new Date().setDate(endDate))
endDay.setHours(23, 59, 59, 999)
endDay.toISOString()
const endTimeObj = new Date(endDay)


const Calendar: FC<CalendarProps> = () => {
  const [viewMode, setViewMode] = useState<string>('week')
  const [startTime, setStartTime] = useState<any>(startTimeObj.getTime())
  const [endTime, setEndTime] = useState<any>(endTimeObj.getTime())
  const [selectedStageName, setSelectedStageName] = useState<any>(null)
  const [selectedMeetingOwner, setSelectedMeetingOwner] = useState<any>(null)
  const stageDropDownData = useCalendarStageNameQuery()
  const meetingDropDownData = useCalendarMeetingOwnerQuery()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const dataForStageColor = useCalendarCleanQuery({
    variables: { where: {} }
  })
  
  let allStages: any[] = []
  let allEvents: any[] = []

  if (stageDropDownData && stageDropDownData.data && stageDropDownData.data.calendar_stagename_gql) {
    allStages = stageDropDownData.data.calendar_stagename_gql.map((item) => item.stagename)
  }

  if (meetingDropDownData && meetingDropDownData.data && meetingDropDownData.data.calendar_meeting_owner_gql) {
    allEvents = meetingDropDownData.data.calendar_meeting_owner_gql.map((item) => item.meeting_owner)
  }

  let where = {}

  if (selectedStageName && selectedStageName.length > 0) {
    where = {
      ...where,
      stagename: {
        _in: selectedStageName.map((s: any) => s.value)
      }
    }
  }

  if (selectedMeetingOwner && selectedMeetingOwner.length > 0) {
    where = {
      ...where,
      meeting_owner: {
        _in: selectedMeetingOwner.map((s: any) => s.value)
      }
    }
  }

  const { data, loading } = useCalendarCleanQuery({
    variables: {
      where: {
        ...where,
        // startdatetime: {
        //   _gte: new Date(startTime),
        // },
        // enddatetime: {
        //   _lte: new Date(endTime)
        // },
      },
      // offset: (pageNumber - 1) * pageSize,
      // limit: pageSize
    }
  })

  const getArrayForStageColors = () => {
    const stageColorArr: any[] = []
    const dataArr = (dataForStageColor && dataForStageColor.data && dataForStageColor.data.calendar_gql_clean && dataForStageColor.data.calendar_gql_clean.length > 0 &&
    dataForStageColor.data.calendar_gql_clean) || []
    dataArr && dataArr.forEach((item) => {
      if (stageColorArr.length > 0 && stageColorArr.find((stageItem) => stageItem.meeting_owner === item.meeting_owner)) {
        return
      }
      stageColorArr.push({
        meeting_owner: item.meeting_owner
      })
    })
    stageColorArr.sort((a, b) => a.meeting_owner - b.meeting_owner)
    return stageColorArr.map((item, index) => {
      return (
        <div className='legend-item flex items-center mr-2' key={index.toString()}>
          <div
            className='legend-bg w-4 h-4 mr-1 rounded-2xl'
            style={{ backgroundColor: `#${intoRGB(calculateDefaultAvtarBg(item.meeting_owner))}` }}
          />
          <p className='legend-title dark:text-revtron-white'>{item.meeting_owner}</p>
        </div>
      )
    })
  }

  let totalCounts: any = 0
  if (data && data.calendar_gql_clean_aggregate) {
    totalCounts = data.calendar_gql_clean_aggregate.aggregate && data.calendar_gql_clean_aggregate.aggregate.count
  }

  return (
    <div className='calendar'>
      <div className='calendar-container'>
        <div className='filter-btn-group flex justify-end mb-8'>
          <MultipleSelect
            className='w-6/12'
            value={selectedMeetingOwner}
            onChange={(value) => setSelectedMeetingOwner(value)}
            options={allEvents.map(event => ({label: event, value: event}))}
            placeholder='Event Owner'
          />
          <MultipleSelect
            className='w-6/12 ml-2'
            value={selectedStageName}
            onChange={(value) => setSelectedStageName(value)}
            options={allStages.map(stage => ({label: stage, value: stage}))}
            placeholder='Stage Name'
          />
        </div>
        <div className='bg-white dark:bg-header-dark rounded-md p-3'>
          <div className='stage-color flex flex-wrap mb-4'>
            {getArrayForStageColors()}
          </div>
          <CustomCalendar data={data} loading={loading} />
        </div>
      </div>
      <MeetingAccountDetail
        totalCounts={totalCounts}
        data={data}
        loading={loading}
        startTime={startTime}
        endTime={endTime}
        pageNumber={pageNumber}
        pageSize={pageSize}
        setPageNumber={setPageNumber}
        setPageSize={setPageSize}
      />
    </div>
  );
}

interface CalendarProps {
  loading?: boolean
}

export default Calendar
