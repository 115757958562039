import { FC, memo, useState, useEffect, useCallback } from 'react'
import { SearchIcon, XIcon } from '@heroicons/react/outline'
import { ColoredButton } from '../../../common/buttons'
import CommandPalette from './PplCommandPalette'
import { getFilterTextForMinMax } from '../../../../utils/functions'
import { PeopleTableFilter } from '../../../../@types/module'

type TableHeaderProps = {
  pageSize: number
  pageNumber: number
  changePagination: (pn: number, pz: number) => void
  nameFilter: string
  changeFilter: (f: string) => void
  isSubmitting: boolean
  submitDisabled: boolean
  onClickSubmit: () => void
} & PeopleTableFilter

const TableHeader: FC<TableHeaderProps> = (props) => {
  const [isPaletteOpen, setPaletteOpen] = useState(false)

  const handlePaletteVisible = useCallback((status: boolean) => {
    setPaletteOpen(status)
  }, [])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'k' && (event.metaKey || event.ctrlKey)) {
        setPaletteOpen(true)
      }
    }
    document.addEventListener('keydown', handleKeyDown)
    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <div className='prospector-company'>
      <div className="flex flex-row justify-between">
        <div className="inline-flex items-center">
          <div className="relative rounded-md shadow-sm mr-2">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              type="search"
              name="search"
              id="searchFilter"
              className="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 dark:text-gray-300 bg-transparent ring-1 ring-inset ring-sidebar-border dark:ring-revtron-dark3 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-revtron-dark3 dark:focus:ring-gray-500 sm:text-sm sm:leading-6"
              placeholder="Search"
              onChange={e => props.changeFilter(e.target.value)}
            />
          </div>
          <ColoredButton title="Add Filter" onButtonClick={() => handlePaletteVisible(true)} variant="primary" />
        </div>
        <div className="flex flex-row items-center">
          <ColoredButton
            disabled={props.submitDisabled || props.isSubmitting}
            title='Send to CRM'
            onButtonClick={props.onClickSubmit}
            variant="primary"
            isLoading={props.isSubmitting}
          />
        </div>
      </div>
      {props.filters.length > 0 && (
        <div className="flex flex-row flex-wrap items-end mt-6">
          {props.filters.map(filter => {
            let filterValueText = ''
            if (filter.type === 'text') filterValueText = filter.query
            if (filter.type === 'number') filterValueText = getFilterTextForMinMax(filter.min, filter.max)
            if (filter.type === 'multi') filterValueText = filter.multi.join(',')

            return (
              <div key={filter.fieldName} className="w-56 mb-2 mr-2">
                <label htmlFor={filter.fieldName} className="block text-sm font-medium leading-6 text-gray-900 dark:text-revtron-white">
                  {filter.label}
                </label>
                <div className="mt-2 flex rounded-md shadow-sm">
                  <div className="relative flex flex-grow items-stretch focus-within:z-10">
                    <input
                      type="text"
                      name={filter.fieldName}
                      id={filter.fieldName}
                      className="block w-full rounded-none rounded-l-md border-0 py-1.5 pl-1.5 text-gray-900 dark:text-gray-300 bg-transparent ring-1 ring-inset ring-sidebar-border dark:ring-revtron-dark3 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-revtron-dark3 dark:focus:ring-gray-500 sm:text-sm sm:leading-6"
                      value={filterValueText}
                      onChange={() => null}
                      readOnly
                    />
                  </div>
                  <button
                    type="button"
                    className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-sidebar-border dark:ring-revtron-dark3 hover:bg-gray-50 dark:hover:bg-revtron-gray2"
                    onClick={() => props.removeFilter(filter)}
                  >
                    <XIcon className="-ml-0.5 h-5 w-5 text-gray-400" aria-hidden="true" />
                  </button>
                </div>
              </div>
            )
          })}
          <div className='mb-2.5'>
            <ColoredButton title='Remove Filters' onButtonClick={props.clearAllFilters} variant="error"  />
          </div>
        </div>
      )}
      <CommandPalette fields={props.fields} open={isPaletteOpen} handlePaletteOpen={handlePaletteVisible} addFilter={props.addFilter} filters={props.filters} />
    </div>
  )
}

export default memo(TableHeader)
