import { FC } from 'react'

type ButtonProps = {
  title: string,
  classnames?: string, 
  onButtonClick: () => void, 
  onKeyHandler?: (e: React.KeyboardEvent<HTMLButtonElement>) => void, 
  disabled?: boolean
  Icon?: (props: React.ComponentProps<'svg'>) => JSX.Element
  variant: 'primary' | 'secondary' | 'error'
  isLoading?: boolean
}

const ColoredButton: FC<ButtonProps> = ({ title, Icon, disabled = false, variant, isLoading = false, onButtonClick, onKeyHandler }) => {
  const disabledColor = disabled ? 'disabled:opacity-50' : ''
  let bgColor = 'bg-revtron-cyan1'
  let textColor = 'text-white'
  let hoverColor = 'hover:bg-revtron-cyan'

  bgColor = variant === 'primary' ? 'bg-revtron-cyan1' : 'bg-revtron-red-gray1'
  textColor = variant === 'primary' ? 'text-white' : 'text-revtron-red1'
  hoverColor = disabled ? '' : variant === 'primary' ? 'hover:bg-revtron-cyan' : 'hover:bg-revtron-red-gray2'
  
  if (variant === 'secondary') {
    bgColor = 'bg-revtron-red-gray1'
    textColor = 'text-revtron-red1'
    hoverColor = disabled ? '' : 'hover:bg-revtron-red-gray2'
  }
  
  if (variant === 'error') {
    bgColor = 'bg-revtron-red-gray1'
    textColor = 'text-revtron-red1'
    hoverColor = disabled ? '' : 'hover:bg-revtron-red-gray2'
  }

  return (
    <button
      disabled={disabled}
      type="button"
      onClick={onButtonClick}
      className={`inline-flex items-center ${bgColor} ${textColor} ${hoverColor} ${disabledColor} justify-center rounded-md px-4 py-2 text-sm shadow-sm font-medium  sm:w-auto`}
      onKeyDown={onKeyHandler}
    >
      {isLoading ? (
        <>
          <svg className="w-5 h-5 mr-3 -ml-1 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
          </svg>
          Loading...
        </>
      ) : (
        <>
          {Icon && <Icon className="mr-1 h-5 w-5 text-neutral-400 dark:text-neutral-200" aria-hidden="true" />}
          {title}
        </>
      )}
    </button>
  )
}

export default ColoredButton
