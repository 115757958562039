import _ from 'lodash'

import { IcpModuleForGigProQuery } from '../../../graphql/__generated__/gigpro_graphql'
import { calcMedian } from '../../../utils/functions'
import { getFormatter } from './ICP.default.util'
import { ICPFilters, ICPSummary, ICPChartData, ICPTreemapChartData, TreemapChart, GeoMapData, AccountRecord, YelpICP, RevtronAccount } from '../../../@types/module'



export const getIcpModuleDataForGigPro = (data?: IcpModuleForGigProQuery, filters?: ICPFilters) => {
  const allData = data?.account
  let filteredData = _.cloneDeep(allData)

  // Filter by employee count
  const getFilterdDataByEmployeeCount = (sourceData: any) => {
    if (!filters?.employeeCount) {
      return sourceData
    }
    const min = filters.employeeCount[0]
    const max = filters.employeeCount[1]
    return _.chain(sourceData).filter(e => {
      const yelp_icp = _.get(e, 'to_yelp_icp') as YelpICP
      const empCount  = yelp_icp.rating
      return (empCount || 0) >= min && (empCount || 0) <= max
    }).value()
  }

  // Filter by business age
  const getFilterdDataByBusinessAge = (sourceData: any) => {
    if (!filters?.ageOfBusiness) {
      return sourceData
    }
    const min = filters.ageOfBusiness[0]
    const max = filters.ageOfBusiness[1]
    return _.chain(sourceData).filter(e => {
      const yelp_icp = _.get(e, 'to_yelp_icp') as YelpICP
      const age = Number(yelp_icp.review_count)
      return age >= min && age <= max
    }).value()
  }
  
  // Filter by annaul revenue
  const getFilterdDataByAnnualRevenue = (sourceData: any) => {
    if (!filters?.annualRevenue) {
      return sourceData
    }
    const min = filters.annualRevenue[0]
    const max = filters.annualRevenue[1]
    return _.chain(sourceData).filter(e => {
      const yelp_icp = _.get(e, 'to_yelp_icp') as YelpICP
      const revenue = Number(yelp_icp.price)
      return revenue >= min && revenue <= max
    }).value()
  }

  // Filter by company type
  const getFilterdDataByCompanyType = (sourceData: any) => {
    if (!filters?.companyType) {
      return sourceData
    }
    return _.chain(sourceData).filter(e => {
      const yelp_icp = _.get(e, 'to_yelp_icp') as YelpICP
      return yelp_icp.vertical === filters.companyType
    }).value()
  }
  
  // Filter by industry
  const getFilterdDataBySubCategory = (sourceData: any) => {
    if (!filters?.subCategory) {
      return sourceData
    }
    return _.chain(sourceData).filter(e => {
      const yelp_icp = _.get(e, 'to_yelp_icp') as YelpICP
      return yelp_icp.first_yelp_category === filters.subCategory
    }).value()
  }

  filteredData = getFilterdDataByEmployeeCount(filteredData)
  filteredData = getFilterdDataByBusinessAge(filteredData)
  filteredData = getFilterdDataByAnnualRevenue(filteredData)
  // Save data
  let filteredTreemapData = _.cloneDeep(filteredData)
  filteredData = getFilterdDataByCompanyType(filteredData)
  filteredData = getFilterdDataBySubCategory(filteredData)

  const gigProAccounts = _.chain(allData).flatten().value() as RevtronAccount[]
  const filteredGigProAccounts = _.chain(filteredData).flatten().value() as RevtronAccount[]
  const filteredGigProAccountsForTreemap = _.chain(filteredTreemapData).flatten().value() as RevtronAccount[]

  // Getting data for summary in ICP
  const icpScoreArr = _.chain(filteredData).map((d) => _.get(d, 'to_yelp_icp.predct_proba', 0) * 100).value()
  const employeeCountArr = filteredGigProAccounts.map((d) => _.get(d, 'to_yelp_icp.rating', 0)) as number[]
  const businessAgeArr = filteredGigProAccounts.map((d) => Number(_.get(d, 'to_yelp_icp.review_count', 0)))
  const annualRevenueArr = _.chain(filteredData).map((d) => _.get(d, 'to_yelp_icp.price', 0)).value()

  const summaryData: ICPSummary = {
    businessCount: filteredGigProAccounts.length,
    averageICPScore: calcMedian(icpScoreArr),
    averageEmployeeCount: calcMedian(employeeCountArr),
    averageBusinessAge: calcMedian(businessAgeArr),
    averageAnnualRevenue: calcMedian(annualRevenueArr),
  }

  // Getting data for histogram charts in ICP
  const isFilteredByHistogram = !!filters?.employeeCount || !!filters?.ageOfBusiness || !!filters?.annualRevenue
  const revtronCompaniesForHistogram = isFilteredByHistogram ? gigProAccounts : filteredGigProAccounts
  const dataForHistogram = isFilteredByHistogram ? allData : filteredData
  const maxEmployeeCount = revtronCompaniesForHistogram.reduce((max: number, d) => {
    const yelp_icp = _.get(d, 'to_yelp_icp') as YelpICP
    return max < (yelp_icp.rating || 0) ? (yelp_icp.rating || 0) : max
  }, 0) as number
  const maxAnnualRevenue = _.chain(dataForHistogram).reduce((max: number, d) => {
    const yelp_icp = _.get(d, 'to_yelp_icp') as YelpICP
    return max < (yelp_icp.price || 0) ? (yelp_icp.price || 0) : max
  }, 0).value()
  const numberOfEmployees = revtronCompaniesForHistogram.map(d => {
    const yelp_icp = _.get(d, 'to_yelp_icp') as YelpICP
    return (yelp_icp.rating || 0) / getFormatter(maxEmployeeCount).unitValue
  })
  const meanNumberOfEmployees = _.mean(numberOfEmployees)
  const annualRevenue = _.chain(dataForHistogram).map(d => {
    const yelp_icp = _.get(d, 'to_yelp_icp') as YelpICP
    return (yelp_icp.price || 0) / getFormatter(maxAnnualRevenue).unitValue
  }).value()
  const meanAnnualRevenue = _.mean(annualRevenue)
  const ageOfBusiness = revtronCompaniesForHistogram.map(d => Number(d?.to_yelp_icp?.review_count))
  const meanAgeOfBusiness = _.mean(ageOfBusiness)
  const chartData: ICPChartData = {
    maxEmployeeCountFormatter: getFormatter(maxEmployeeCount).formatter,
    employeeCountUnit: getFormatter(maxEmployeeCount).unitValue,
    maxAnnualRevenueFormatter: getFormatter(maxAnnualRevenue).formatter,
    annualRevenueUnit: getFormatter(maxAnnualRevenue).unitValue,
    ageOfBusiness: ageOfBusiness.filter(d => d < meanAgeOfBusiness),
    numberOfEmployees: numberOfEmployees.filter(d => d < meanNumberOfEmployees),
    annualRevenue: annualRevenue.filter(d => d < meanAnnualRevenue),
  }

  // Getting data for treemap chart in ICP
  const isFilteredByCategoryTreemap = !!filters?.subCategory
  const gigProAccountsForCategoryTreemap = isFilteredByCategoryTreemap ? filteredGigProAccountsForTreemap : filteredGigProAccounts
  const categories = _.uniq(gigProAccountsForCategoryTreemap.map(d => d?.to_yelp_icp?.first_yelp_category))
  const industryData: ICPTreemapChartData = {
    name: 'industry',
    children: categories.map(cat => ({
      name: cat,
      value: gigProAccountsForCategoryTreemap.filter(r => r.to_yelp_icp?.first_yelp_category === cat).length
    })).sort((a: TreemapChart, b: TreemapChart) => b.value - a.value)
  }
  const isFilteredByCompanyTypeTreemap = !!filters?.companyType
  const gigProAccountsForCompanyTypeTreemap = isFilteredByCompanyTypeTreemap ? filteredGigProAccountsForTreemap : filteredGigProAccounts
  const companyTypes = _.uniq(gigProAccountsForCompanyTypeTreemap.map(d => d.to_yelp_icp?.vertical))
  const companyTypesData: ICPTreemapChartData = {
    name: 'industry',
    children: companyTypes.map(type => ({
      name: type,
      value: gigProAccountsForCompanyTypeTreemap.filter(r => r.to_yelp_icp?.vertical === type).length
    })).sort((a: TreemapChart, b: TreemapChart) => b.value - a.value)
  }
  const treemapData = {
    industry: industryData,
    companyType: companyTypesData
  }

  // Getting data for geo map
  const dataGroupByState = _.groupBy(allData as RevtronAccount[], (d) => d.to_yelp_icp?.billingstate)
  const geoMapData: GeoMapData = {
    us: [],
    world: []
  }
  Object.keys(dataGroupByState).forEach((state) => {
    let companies = 0
    let employees = 0
    let amount = 0
    dataGroupByState[state].forEach((d: RevtronAccount) => {
      companies += 1
      employees += d.to_yelp_icp?.rating || 0
      amount += d.to_yelp_icp?.price || 0
    })
    geoMapData.us.push({location: state, companies, employees, amount})
  })

  let accounts = data ? data.account as RevtronAccount[] : []

  // Getting data for detail table in ICP
  let bussinessDetails: AccountRecord[] = _.chain(filteredData).map(e => {
    const custom_account = _.get(e, 'to_yelp_icp') as YelpICP
    return {
      uuid: e.to_yelp_icp,
      annualRevenue: custom_account.price as number,
      name: e.name as string,
      domain: custom_account.website as string,
      city: custom_account.billingcity as string,
      empCount: custom_account.rating,
      predictProba: _.get(e, 'to_yelp_icp.predct_proba', 0),
      companyType: custom_account.vertical as string,
      state: custom_account.billingstate as string,
      country: custom_account.billingcountry as string,
      foundingYear: custom_account.review_count as number,
      industry: custom_account.first_yelp_category as string,
      specialities: custom_account.yelp_categories as string,
    }
  }).value()

  return { summaryData, chartData, treemapData, geoMapData, accounts, bussinessDetails }
}
