import { FC, Fragment, useState } from 'react'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import { SearchIcon, UserAddIcon } from '@heroicons/react/solid'
import { UsersIcon } from '@heroicons/react/outline'
import { ChevronRightIcon } from '@heroicons/react/solid'

import { useAllCrmUsersSubscription, useInsertTeamMemberMutation } from '../../../graphql/__generated__/graphql'
import { alert } from '../../../utils/functions'

const AddMemberModal: FC<AddMemberModalProps> = ({ team, isOpen, closeModal }) => {
  const [query, setQuery] = useState('')
  const {data, loading, error} = useAllCrmUsersSubscription()
  const [insertTeamMember, { data: data2, loading: loading2, error: error2 }] = useInsertTeamMemberMutation()
  const [selectedPerson, setSelectedPerson] = useState<any>(null)
  const filteredMembers = (query && data) ? data.crm_users.filter((user: any) => user.name.toLowerCase().search(query) > -1) : []

  const addUserToMember = () => {
    insertTeamMember({
      variables: {
        user_id: selectedPerson.revtron_users.id,
        team_id: team.id
      }
    }).then(() => {
      alert('success', 'Successful in adding a member!')
      closeModal()
    }).catch(() => {
      alert('error', 'Failed in adding a member.')
    })
  }

  return (
    <Transition.Root show={isOpen} as={Fragment} afterLeave={() => setQuery('')} appear>
      <Dialog as="div" className="relative z-50" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-3xl transform divide-y divide-gray-200 dark:divide-neutral-700 overflow-hidden rounded-xl bg-white dark:bg-header-dark shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <Combobox value={selectedPerson} onChange={setSelectedPerson}>
                {({ activeOption }: {activeOption: any}) => (
                  <>
                    <div className="relative">
                      <SearchIcon
                        className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <Combobox.Input
                        className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 dark:text-gray-200 placeholder-gray-400 focus:ring-0 sm:text-sm"
                        placeholder="Search"
                        onChange={(event) => setQuery(event.target.value)}
                      />
                    </div>

                    {(query && filteredMembers.length > 0) && (
                      <Combobox.Options as="div" static hold className="flex divide-x divide-gray-200 dark:divide-neutral-700">
                        <div className={`max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4 ${activeOption && 'sm:h-96'}`}>
                          <div className="-mx-2 text-sm text-gray-900 dark:text-gray-200">
                            {filteredMembers.map((person: any, index: any) => (
                              <Combobox.Option
                                as="div"
                                key={index}
                                value={person}
                                className={({ active }) => `flex cursor-default select-none items-center rounded-md p-2 ${active && 'bg-gray-100 dark:bg-revtron-dark1'}`}
                              >
                                {({ active }) => (
                                  <>
                                    <img
                                      className="h-6 w-6 flex-none rounded-full bg-gray-300"
                                      src={person.revtron_users ? person.revtron_users.avatarUrl : ''}
                                      alt=""
                                    />
                                    <span className="ml-3 flex-auto truncate">{person.name}</span>
                                    {active && (
                                      <ChevronRightIcon
                                        className="ml-3 h-5 w-5 flex-none text-gray-400"
                                        aria-hidden="true"
                                      />
                                    )}
                                  </>
                                )}
                              </Combobox.Option>
                            ))}
                          </div>
                        </div>

                        {selectedPerson && (
                          <div className="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-200 dark:divide-neutral-700 overflow-y-auto sm:flex">
                            <div className="flex-none p-6 text-center">
                              <img src={selectedPerson.avatarUrl} alt="" className="mx-auto h-16 w-16 rounded-full bg-gray-300" />
                              <h2 className="mt-3 text-base text-gray-900 dark:text-gray-200">{selectedPerson.name}</h2>
                              <div className="text-sm text-gray-500">{selectedPerson.role}</div>
                            </div>
                            <div className="p-6">
                              <dl className="flex items-center text-sm mb-2">
                                <dt className="w-20 text-gray-500">Email:</dt>
                                <dd className="text-gray-900 dark:text-gray-200">{selectedPerson.email}</dd>
                              </dl>
                              <button
                                type="button"
                                className="w-full inline-flex justify-center rounded-md border border-transparent bg-cyan-400 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-cyan-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 mt-8"
                                onClick={addUserToMember}
                              >
                                <UserAddIcon
                                  className="mr-1 h-5 w-5 text-gray-900"
                                  aria-hidden="true"
                                />
                                Add Member
                              </button>
                            </div>
                          </div>
                        )}
                      </Combobox.Options>
                    )}

                    {query !== '' && filteredMembers.length === 0 && (
                      <div className="py-14 px-6 text-center text-sm sm:px-14">
                        <UsersIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                        <p className="mt-4 font-semibold text-gray-900">No people found</p>
                        <p className="mt-2 text-gray-500">
                          We couldn’t find any users with that email address.
                        </p>
                      <button
                        type="button"
                        className="inline-flex items-center justify-center mt-6 px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-revtron-black bg-revtron-cyan hover:bg-revtron-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-revtron-cyan"
                      >
                        Invite New User
                      </button>
                    </div>
                    )}
                  </>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

interface AddMemberModalProps {
  team: any
  isOpen: boolean
  closeModal: () => void
}

export default AddMemberModal
