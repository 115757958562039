import { FC, useState } from 'react'
import { Link, Navigate } from 'react-router-dom'
import { useSignInEmailPassword } from '@nhost/react'

import AuthWrapper from '../../components/templates/authWrapper'
import TextInput from '../../components/molecules/textInput'

const SignIn: FC<SignInProps> = () => {
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const {
    signInEmailPassword,
    isLoading,
    isSuccess,
    needsEmailVerification,
    isError,
  } = useSignInEmailPassword()

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    signInEmailPassword(email, password);
  }

  if (isSuccess) {
    return <Navigate to="/" replace={true} />
  }

  const disableForm = isLoading || needsEmailVerification

  return (
    <AuthWrapper>
      <div className='flex justify-center items-center'>
        <div className="w-full max-w-lg">
          <p className='text-center my-36 text-revtron-dark4 text-sm'>Log in to your account</p>
          <div className="sm:rounded-lg sm:shadow-md sm:border border-theme-toggle-white-bg dark:border-revtron-dark3 bg-auth-background dark:bg-auth-background-dark backdrop-blur px-4 sm:px-8 py-12 flex flex-col items-center">
            {needsEmailVerification ? (
              <p className="mt-12 text-center">
                Please check your mailbox and follow the verification link to verify
                your email.
              </p>
            ) : (
              <form onSubmit={handleOnSubmit} className="w-full">
                <div className="mt-6 w-full flex flex-col items-center space-y-6">
                  <TextInput
                    type="email"
                    placeholder="Username"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                    error={isError}
                    disabled={disableForm}
                  />
                  <TextInput
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    required
                    error={isError}
                    disabled={disableForm}
                  />
                </div>

                {isError ? (
                  <p className="mt-4 text-red-400">
                    Incorrect password or username.
                  </p>
                ) : null}
                
                <div className='flex justify-end'>
                </div>

                <button
                  type="submit"
                  className="mt-6 w-full bg-auth-btn-gradient font-medium inline-flex justify-center items-center rounded-md p-3 text-header-dark focus:outline-none focus:ring-4 focus:ring-purple-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed  disabled:hover:bg-purple-600 disabled:hover:border-bg-600 transition-colors"
                >
                  {isLoading ? 'Loading...' : 'Log in'}
                </button>
                <p className="text-gray-500 flex justify-end">
                  <Link
                    to="/reset-password"
                    className="mt-4 text-revtron-dark4 hover:text-revtron-cyan hover:underline hover:underline-offset-1 transition"
                  >
                    Forgot your password?
                  </Link>
                </p>
              </form>
            )}
          </div>
        </div>
      </div>
    </AuthWrapper>
  )
}

interface SignInProps {}

export default SignIn
