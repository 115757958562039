import _ from 'lodash'
import moment from 'moment'
import { ObjectType } from '../../@types/common/Object'
import { SalesVelocityDataByOwnerQuery } from '../../graphql/__generated__/graphql'

export type LeaderboardSummaryData = {
  totalBookings: number
  totalCounts: number
  salesVelocityFactors: {
    New_Business_Win_Rate: number
    Ninety_Day_Pipeline: number
    Sales_Cycle: number
    Net_New_Avg_Deal_Value: number
  }
}

export type MonthlyRevenueData = {
  amount: number
  date: string
}

export type DailyRevenueData = {
  revenue: number
  day: string
}

export type Opportunity = {
  Id: string
  Name: string
  Type: string
  StageName: string
  ForecastCategoryName: string
  CloseDate: string
  CreatedDate: string
  Amount: number
  LeadSource: string
  IsClosed: boolean
  IsWon: boolean
}

export type BoardData = {
  userId: string
  team_member: string
  photo_url: string
  bookings: number
}

export type PlayerCardData = {
  uuid: string
  photoUrl: string
  name: string
  teamName: string
  Quarterly_Sales_Velocity: number
  Number_Of_Wins: number
  Avg_Deal_Size: number
  Avg_Sales_Cycle: number
  Win_Rate: number
  Ninety_Day_Pipeline: number
}

export type WinsTableData = {
  ownerId: string
  oppName: string
  oppOwner: string
  leadsource: string
  age: number
  closedate: string
  arr: number
}

const groupBy = (objectArray: ObjectType[], ...properties: string[]) => {
  return [...Object.values(objectArray.reduce((accumulator, object) => {
    const key = JSON.stringify(properties.map((x) => object[x] || null))

    if (!accumulator[key]) {
      accumulator[key] = []
    }
    accumulator[key].push(object);
    return accumulator;
  }, {}))] as Opportunity[][];
}

const getRunningTotal = (nums: DailyRevenueData[]): DailyRevenueData[] => {
  for(let i = 0; i < nums.length - 1; i++) {
    let temp = nums[i].revenue + nums[i+1].revenue; 
    nums[i+1].revenue = temp; 
  }
  return nums;
}

export const getBookingsDataByOwner = (data?: SalesVelocityDataByOwnerQuery) => {
  const opportunities = _.chain(data?.crm_users)
    .map('to_opp')
    .flatten()
    .filter(opp => opp.Amount > 0)
    .value() as Opportunity[]
  
  const opportunitiesByMonth = opportunities.map(opp => ({
    ...opp,
    CloseDate: moment(opp.CloseDate).startOf('month').format('YYYY-MM')
  }))

  const opportunitiesByDay = opportunities.map(opp => ({
    ...opp,
    CloseDate: moment(opp.CloseDate).format('YYYY-MM-DD')
  }))

  // Summary Data
  const winRates = _.chain(data?.win_rate).value() || []
  const avg_deal_value = _.chain(data?.net_new_avg).get('aggregate.avg.Amount', 0).value()
  const sales_cycle = _.chain(data?.net_new_avg).get('nodes').map(cycle => moment(cycle.CloseDate).diff(moment(cycle.CreatedDate), 'days')).mean().value()
  const ninety_days_pipeline = _.chain(data?.ninety_days_pipeline).get('aggregate.count', 0).value()
  const win_rate = winRates.length > 0 ? winRates[0].win_rate : 0

  const summaryData: LeaderboardSummaryData = {
    totalBookings: opportunitiesByMonth.reduce((sum: number, cur) => sum + cur.Amount, 0),
    totalCounts: opportunitiesByMonth.length,
    salesVelocityFactors: {
      New_Business_Win_Rate: win_rate,
      Ninety_Day_Pipeline: ninety_days_pipeline,
      Sales_Cycle: sales_cycle,
      Net_New_Avg_Deal_Value: avg_deal_value,
    }
  }

  // Running Sum of Revenue Data
  const revenuesByClosedateDay = groupBy(opportunitiesByDay, 'CloseDate')
  const dailyRevenueData: DailyRevenueData[] = revenuesByClosedateDay.map((opps) => ({
    day: opps[0].CloseDate,
    revenue: opps.reduce((sum, cur) => sum + cur.Amount, 0)
  })).sort((a: DailyRevenueData, b: DailyRevenueData) => {
    const bTimestamp = Date.parse(a.day)
    const aTimestamp = Date.parse(b.day)
    if (bTimestamp > aTimestamp) {
      return 1
    }
    if (bTimestamp < aTimestamp) {
      return -1
    }
    return 0
  })
  const revenueRunningTotals = [{ day: moment().startOf('year').format('YYYY-MM-DD'), revenue: 0 }].concat(getRunningTotal(dailyRevenueData))

  // Monthly Revenue Data
  const revenuesByClosedateMonth = groupBy(opportunitiesByMonth, 'CloseDate')
  const montlyRevenueData: MonthlyRevenueData[] = revenuesByClosedateMonth.map((opps) => ({
    date: opps[0].CloseDate,
    amount: opps.reduce((sum, cur) => sum + cur.Amount, 0)
  })).sort((a: MonthlyRevenueData, b: MonthlyRevenueData) => {
    const bTimestamp = Date.parse(a.date)
    const aTimestamp = Date.parse(b.date)
    if (bTimestamp < aTimestamp) {
      return 1
    }
    if (bTimestamp > aTimestamp) {
      return -1
    }
    return 0
  })

  // Leader Board Data
  const boardData: BoardData[] = _.chain(data?.crm_users)
    .map(opp => ({
      userId: opp.id || '',
      team_member: opp.name || '',
      photo_url: _.get(opp, 'revtron_users.avatarUrl', ''),
      bookings: _.flatten(opp.to_opp).reduce((sum: number, cur) => sum + cur.Amount, 0)
    }))
    .filter(opp => opp.bookings > 0)
    .sort((a, b) => b.bookings - a.bookings)
    .value()
  
  return { summaryData, dailyRevenueData: revenueRunningTotals, montlyRevenueData, boardData }
}

export const getSalesVelocityDataByOwner = (data?: SalesVelocityDataByOwnerQuery) => {
  const opportunities = _.chain(data?.crm_users)
    .map('to_opp')
    .flatten()
    .filter(opp => opp.Amount > 0)
    .value() as Opportunity[]

  // Summary Data
  const winRates = _.chain(data?.win_rate).value() || []
  const net_new_avg_deal_value = _.chain(data?.net_new_avg).get('aggregate.avg.Amount', 0).value()
  const sales_cycle = _.chain(data?.net_new_avg).get('nodes').map(cycle => moment(cycle.CloseDate).diff(moment(cycle.CreatedDate), 'days')).mean().value()
  const ninety_days_pipeline = _.chain(data?.ninety_days_pipeline).get('aggregate.count', 0).value()
  const new_business_win_rate = winRates.length > 0 ? winRates[0].win_rate : 0
  const dailySalesVelocity = new_business_win_rate / 100 * ninety_days_pipeline * net_new_avg_deal_value / sales_cycle
  const quarterlySalesVelocity = dailySalesVelocity * 90

  const summaryData: LeaderboardSummaryData = {
    totalBookings: quarterlySalesVelocity,
    totalCounts: opportunities.length,
    salesVelocityFactors: {
      New_Business_Win_Rate: new_business_win_rate,
      Ninety_Day_Pipeline: ninety_days_pipeline,
      Sales_Cycle: sales_cycle,
      Net_New_Avg_Deal_Value: net_new_avg_deal_value,
    }
  }

  // Player Card Data
  const playerCardData: PlayerCardData[] = _.chain(data?.crm_users)
    .map(opp => {
      const Avg_Sales_Cycle = _.chain(opp.to_opp).flatten().map(t => moment(t.CloseDate).diff(moment(t.CreatedDate), 'days')).mean().value()
      const Ninety_Day_Pipeline = _.get(opp, 'pipeline.aggregate.ninety_day_pipeline', 0)
      const Win_Rate = opp.to_win_rates.length > 0 ? opp.to_win_rates[0].win_rate : 0
      const Avg_Deal_Size = _.get(opp, 'avg.aggregate.avg.avg_deal_size', 0)
      const dailySalesVelocity = Avg_Sales_Cycle === 0 ? 0 : Win_Rate / 100 * Ninety_Day_Pipeline * Avg_Deal_Size / Avg_Sales_Cycle
      const quarterlySalesVelocity = dailySalesVelocity * 90

      return {
        uuid: opp.id || '',
        photoUrl: _.get(opp, 'revtron_users.avatarUrl', ''),
        name: opp.name || '',
        teamName: _.get(opp, 'revtron_users.revtron_team.to_teams.name', ''),
        Quarterly_Sales_Velocity: quarterlySalesVelocity,
        Number_Of_Wins: _.get(opp, 'avg.aggregate.number_of_wins', 0),
        Avg_Deal_Size,
        Avg_Sales_Cycle,
        Win_Rate,
        Ninety_Day_Pipeline,
      }
    })
    .filter(opp => opp.Quarterly_Sales_Velocity > 0)
    .sort((a, b) => b.Quarterly_Sales_Velocity - a.Quarterly_Sales_Velocity)
    .value()
  
  // Leaders Board Data
  const boardData: BoardData[] = playerCardData.filter(pc => pc.Quarterly_Sales_Velocity > 0).map(pc => ({
    userId: pc.uuid,
    team_member: pc.name,
    photo_url: pc.photoUrl,
    bookings: pc.Quarterly_Sales_Velocity
  }));

  const winsTableData: WinsTableData[] = []
  _.chain(data?.crm_users)
    .map(crm => ({
      id: crm.id,
      owner: crm.name,
      opps: _.flatten(crm.to_opp)
    }))
    .value()
    .forEach(p => {
      p.opps.forEach(o => {
        if (o.Amount > 0) {
          winsTableData.push({
            ownerId: p.id || '',
            oppName: o.Name || '',
            oppOwner: p.owner || '',
            leadsource: o.LeadSource || '',
            age: moment(o.CloseDate).diff(moment(o.CreatedDate), 'days'),
            closedate: o.CloseDate,
            arr: o.Amount
          })
        }
      })
    })
  
  return { summaryData, boardData, playerCardData, winsTableData }
}
