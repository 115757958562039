import { FC, useState, memo, useEffect } from 'react'
import { Popover } from '@headlessui/react'

import { DateRangeType } from '../../../@types/common/DatePicker'
import DailyPicker from './DailyPicker'
import MonthlyPicker from './MonthlyPicker'
import QuarterlyPicker from './QuarterlyPicker'
import YearlyPicker from './YearlyPicker'

const DatePicker: FC<PassProps> = ({ dateFilter, applyDateFilter, classNames }) => {
  const [viewType, setViewType] = useState('month')
  const [dateRange, setDateRange] = useState<DateRangeType>({
    start: dateFilter.start,
    end: dateFilter.end
  })

  useEffect(() => {
    if (dateFilter) {
      setDateRange({ start: dateFilter.start, end: dateFilter.end })
    }
  }, [dateFilter])

  return (
    <Popover className={`text-black dark:text-white border border-table-text dark:border-revtron-dark3 bg-white dark:bg-revtron-dark1 rounded-md relative ${classNames}`}>
      <Popover.Button className='w-full'>{dateRange.start.format('MM/DD/YYYY')} - {dateRange.end.format('MM/DD/YYYY')}</Popover.Button>

      <Popover.Panel className="absolute top-11 right-0 text-sm bg-white dark:bg-revtron-dark2 border border-table-text dark:border-revtron-dark3 z-30 shadow-lg rounded-md p-4">
        <div className='flex justify-between mb-4'>
          <div
            className={`border border-table-text dark:border-revtron-gray2 rounded px-4 py-1.5 mr-2 cursor-pointer ${viewType === 'days' && 'bg-cyan-100 dark:bg-cyan-500'}`}
            onClick={() => setViewType('days')}
          >
            Days
          </div>
          <div
            className={`border border-table-text dark:border-revtron-gray2 rounded px-4 py-1.5 mr-2 cursor-pointer ${viewType === 'month' && 'bg-cyan-100 dark:bg-cyan-500'}`}
            onClick={() => setViewType('month')}
          >
            Month
          </div>
          <div
            className={`border border-table-text dark:border-revtron-gray2 rounded px-4 py-1.5 mr-2 cursor-pointer ${viewType === 'quarter' && 'bg-cyan-100 dark:bg-cyan-500'}`}
            onClick={() => setViewType('quarter')}
          >
            Quarter
          </div>
          <div
            className={`border border-table-text dark:border-revtron-gray2 rounded px-4 py-1.5 cursor-pointer ${viewType === 'year' && 'bg-cyan-100 dark:bg-cyan-500'}`}
            onClick={() => setViewType('year')}
          >
            Year
          </div>
        </div>
        {viewType === 'days' && <DailyPicker dateRange={dateRange} onDateRangeChange={setDateRange} />}
        {viewType === 'month' && <MonthlyPicker dateRange={dateRange} onDateRangeChange={setDateRange} />}
        {viewType === 'quarter' && <QuarterlyPicker dateRange={dateRange} onDateRangeChange={setDateRange} />}
        {viewType === 'year' && <YearlyPicker dateRange={dateRange} onDateRangeChange={setDateRange} />}
        <div className='flex justify-between mt-4'>
          <div
            className='flex-1 text-center border border-table-text dark:border-revtron-gray2 rounded px-4 py-1.5 mr-2 cursor-pointer'
            onClick={() => null}
          >
            Clear
          </div>
          <div
            className='flex-1 text-center border border-table-text dark:border-revtron-gray2 rounded px-4 py-1.5 bg-teal-300 dark:bg-teal-500 cursor-pointer'
            onClick={() => applyDateFilter(dateRange)}
          >
            Apply
          </div>
        </div>
      </Popover.Panel>
    </Popover>
  )
}

interface PassProps {
  classNames: string
  dateFilter: DateRangeType
  applyDateFilter: (dateRange: DateRangeType) => void
}

export default memo(DatePicker)
