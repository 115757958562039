import { FC, memo, useCallback } from 'react'
import { ChevronDoubleRightIcon, TrashIcon } from '@heroicons/react/outline'
import Dropdown from '../../../components/common/dropdown'
import Switch from '../../../components/common/switch'
import { FieldMapping, ACTION_OPTIONS } from '../../../containers/settings/Settings.Utils'
import { DropdownOption } from '../../../@types/common/Dropdown'

type FieldMappingItemProps = {
  mapping: FieldMapping
  crmFields: DropdownOption[]
  revtronFeilds: DropdownOption[]
  updateMapping: (m: FieldMapping, action: 'UPDATE' | 'REMOVE') => void
}

const FieldMappingItem: FC<FieldMappingItemProps> = ({ mapping, crmFields, revtronFeilds, updateMapping }) => {
  const onOptionChanged = useCallback((type: 'crm_field' | 'revtron_field' | 'method', value: string) => {
    if (mapping[type] !== value) {
      mapping[type] = value
      updateMapping(mapping, 'UPDATE')
    }
  }, [mapping, updateMapping])

  const onSwitchChanged = useCallback((v: boolean) => {
    if (mapping.is_active !== v) {
      mapping.is_active = v
      updateMapping(mapping, 'UPDATE')
    }
  }, [mapping, updateMapping])

  return (
    <div className='flex flex-row justify-between items-center mb-8'>
      <Dropdown
        value={mapping.revtron_field}
        options={revtronFeilds}
        onSelect={(v) => onOptionChanged('revtron_field', v)}
        classnames="w-72"
      />
      <ChevronDoubleRightIcon width={25} className="text-theme-toggle-icon" />
      <Dropdown
        value={mapping.crm_field}
        options={crmFields}
        onSelect={(v) => onOptionChanged('crm_field', v)}
        classnames="w-72"
      />
      <Switch enabled={mapping.is_active} onChange={onSwitchChanged} />
      <Dropdown
        value={mapping.method}
        options={ACTION_OPTIONS}
        onSelect={(v) => onOptionChanged('method', v)}
        classnames="w-32"
      />
      <div className='cursor-pointer' onClick={() => updateMapping(mapping, 'REMOVE')}>
        <TrashIcon width={25} className="text-theme-toggle-icon hover:text-revtron-dark3" />
      </div>
    </div>
  )
}


export default memo(FieldMappingItem)