import { FC } from 'react'
import { useBookingsDataByOwnerGraphQL } from './Leaderboard.graphql'
import { Summary, RevenueSum, MonthRevenue, Leaders } from '../../components/organisms/leaderboard_v1'

const Leaderboard: FC = () => {
  const {
    isLoading,
    salesVelocityData: {
      summaryData,
      dailyRevenueData,
      montlyRevenueData,
      boardData
    }
  } = useBookingsDataByOwnerGraphQL()

  return (
    <div className='leaderboard dark:text-white'>
      <Summary data={summaryData} isLoading={isLoading} />
      <div className='flex'>
        <RevenueSum data={dailyRevenueData} isLoading={isLoading} />
        <MonthRevenue data={montlyRevenueData} isLoading={isLoading} />
      </div>
      <Leaders data={boardData} isLoading={isLoading} />
    </div>
  )
}

export default Leaderboard
