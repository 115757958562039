const EMAILABLE_API_HOST = process.env.REACT_APP_EMAILABLE_API_HOST as string
const EMAILABLE_API_KEY = process.env.REACT_APP_EMAILABLE_API_KEY as string

const verifyBatchOfEmailsUrl = `${EMAILABLE_API_HOST}/v1/batch`
const verifyEmailUrl = `${EMAILABLE_API_HOST}/v1/verify`

export type EmailValidation = {
  accept_all: boolean
  did_you_mean: string
  disposable: boolean
  domain: string
  duration: number
  email: string
  first_name: string
  free: boolean
  full_name: string
  gender: string
  last_name: string
  mailbox_full: boolean
  mx_record: string
  no_reply: boolean
  reason: string
  role: boolean
  score: number
  smtp_provider: string
  state: string
  tag: string
  user: string
}

const verifyEmail = async (email: string) => {
  try {
    const response = await fetch(`${verifyEmailUrl}?email=${email}&api_key=${EMAILABLE_API_KEY}`)
    return await response.json()
  } catch (error) {
    throw error
  }
}

const verifyBatchOfEmails = async (emails: string[]) => {
  try {
    const response = await fetch(verifyBatchOfEmailsUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        emails: emails.join(','),
        api_key: EMAILABLE_API_KEY
      }),
    })
    return await response.json()
  } catch (error) {
    throw error
  }
}

const getVerificationStatusOfBatch = async (batchId: string) => {
  try {
    const response = await fetch(`${verifyBatchOfEmailsUrl}?id=${batchId}&api_key=${EMAILABLE_API_KEY}`)
    return await response.json()
  } catch (error) {
    throw error
  }
}

export const getEmailValidations = async (emails: string[]) => {
  return new Promise<EmailValidation[]>(async (resolve, reject) => {
    if (emails.length > 1) {
      let intervalId: any = null
      const batchStatus = await verifyBatchOfEmails(emails)
      intervalId = setInterval(async () => {
        await getVerificationStatusOfBatch(batchStatus.id)
          .then(res => {
            if (res.total_counts && res.total_counts.total === res.total_counts.processed) {
              clearInterval(intervalId)
              resolve(res.emails)
            }
          }).catch(err => {
            clearInterval(intervalId)
            reject(err)
          })
      }, 2000)
    } else {
      verifyEmail(emails[0])
        .then(res => {
          resolve([res])
        }).catch(err => {
          reject(err)
        })
    }
  })
}
