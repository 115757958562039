import { FC } from 'react'

import ShadowCard from '../../templates/shadowCard'
import CustomTable from '../../molecules/table'

const EffortDetail: FC<EffortDetailProps> = ({ data, loading }) => {
  const columnsForEffortDetail = [
    {
      title: 'NAME',
      dataIndex: 'name',
      sorter: (a: any, b: any) => a.name - b.name ? 1 : -1
    },
    {
      title: 'TOTAL EFFORT',
      dataIndex: 'weekly_effort',
      sorter: (a: any, b: any) => a.weekly_effort - b.weekly_effort
    },
    {
      title: 'TOTAL ENGAGEMENT',
      dataIndex: 'weekly_engagement',
      sorter: (a: any, b: any) => a.weekly_engagement - b.weekly_engagement
    },
    {
      title: 'EMAILS SENT',
      dataIndex: 'emails_sent',
      sorter: (a: any, b: any) => a.emails_sent - b.emails_sent
    },
    {
      title: 'EMAIL OPENS',
      dataIndex: 'email_opens',
      sorter: (a: any, b: any) => a.email_opens - b.email_opens
    },
    {
      title: 'EMAIL RESPONSES',
      dataIndex: 'email_responses',
      sorter: (a: any, b: any) => a.email_responses - b.email_responses,
    },
    {
      title: 'CALLS MADE',
      dataIndex: 'calls_out',
      sorter: (a: any, b: any) => a.calls_out - b.calls_out
    },
    {
      title: 'CALLS ANSWERED',
      dataIndex: 'call_answered',
      sorter: (a: any, b: any) => a.call_answered - b.call_answered
    },
    {
      title: 'SOCIAL',
      dataIndex: 'social',
      sorter: (a: any, b: any) => a.social - b.social
    }
  ]

  const loadData = () => {
    if (data?.weekly_activity_vs_targets_gql) {
      return [...data?.weekly_activity_vs_targets_gql].sort((a: any, b: any) => b.weekly_engagement - a.weekly_engagement)
    }
    return []
  }

  return (
    <ShadowCard label='EFFORT & ENGAGEMENT DETAIL' loading={loading} className='mt-5'>
      <CustomTable
        columns={columnsForEffortDetail}
        dataSource={loadData()}
        pagination={false}
      />
    </ShadowCard>
  )
}

interface EffortDetailProps {
  data: any
  loading: boolean
}

export default EffortDetail
