import { FC, Fragment, memo } from 'react'
import { AccountRecord } from '../../../@types/module'
import { AccountTableProps } from '../../../@types/common/AccountTable';
import TableRecord from './TableRecord';
import TableRecordProspector from './TableRecordProspector';
import Spin from '../../atoms/spin'
import { useFeatureContext } from '../../../hooks/featureContext'

type TableBodyProps = Pick<AccountTableProps, 'loading' | 'tableData'> & {
  selectable: string
  selectedRows: any[]
  onCheckboxChange: () => void
  onRowSelect: (id: any) => void
  isAccountRec: boolean
}

const TableBody: FC<TableBodyProps> = ({
  loading,
  tableData,
  selectedRows,
  selectable,
  onCheckboxChange,
  onRowSelect,
  isAccountRec
}) => {
  const { isGigPro } = useFeatureContext()

  return (
    <div className="icp-accounts mt-8 mb-8">
      <div className="rounded-lg shadow text-black dark:text-white bg-white dark:bg-revtron-dark2 flex items-center p-6 mb-3">
        <div className='mr-6 p-1'>
          <input
            id="check_box_handler"
            aria-describedby="row-selectable"
            name="row"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-revtron-cyan1 focus:ring-revtron-cyan1"
            checked={selectable === 'checked'}
            onChange={onCheckboxChange}
          />
        </div>
        <div className='relative flex-1 max-w-[280px] p-1'>
          Name
          {selectedRows.length > 0 && <div className='absolute text-xs mt-1 text-revtron-dark4'>Selected {selectedRows.length} records</div>}
        </div>
        <div className='flex-1 p-1'>Domain</div>
        <div className='w-40 p-1'>City</div>
        <div className='w-40 p-1'>State</div>
        <div className='w-32 p-1'>
          { isAccountRec ? isGigPro ? "Rating" : "Emp.Count" : "Emp.Count" }
        </div>
        {/* <div className='w-20 uppercase p-1'>
          { isGigPro ? "Rvws" : "Age" }
        </div> */}
        <div className='w-48 p-1'>
          { isAccountRec ? isGigPro ? "Price" : "Revenue" : "Revenue" }
        </div>
        <div className='w-32 p-1'>ICP Score</div>
      </div>
      {loading && (
        <div className='flex justify-center bg-white dark:bg-revtron-dark2 shadow rounded-lg p-7 mb-6'>
          <Spin />
        </div>
      )}
      {!loading && tableData.length === 0 && (
        <div className='flex justify-center bg-white dark:bg-revtron-dark2 shadow rounded-lg p-7 mb-6 text-gray-500 dark:text-gray-500'>
          No records
        </div>
      )}
      {tableData.length > 0 && tableData.map((detail: AccountRecord, index: number) => (
        <Fragment key={index.toString()}>
          {isAccountRec
          ? <TableRecord
              detail={detail}
              index={index + 1}
              selectedRows={selectedRows}
              onRowSelect={onRowSelect}
            />
          : <TableRecordProspector
              detail={detail}
              index={index + 1}
              selectedRows={selectedRows}
              onRowSelect={onRowSelect}
            />
          }
        </Fragment>
      ))}
    </div>
  )
}

export default memo(TableBody)
