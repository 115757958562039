import { FC, useEffect, useRef } from 'react'
import * as d3 from 'd3'
import moment from 'moment'

const LineChart: FC<{ data: any[] }> = ({ data }) => {
  const width = 995
  // const height = 450
  const height = 420
  const left = 90
  const right = 20
  const top = 20
  const bottom = 50
  const chartWidth = width - left - right
  const chartHeight = height - top - bottom
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    draw()
  })

  const draw = () => {
    d3.select(ref.current).select('svg').remove()

    const svg = d3
      .select(ref.current)
      .append('svg')
      .attr('viewBox', `0 0 ${chartWidth + left + right} ${chartHeight + top + bottom}`)
      .attr('width', '100%')
      .attr('height', '100%')
      .append('g')
      .attr('transform', `translate(${left},${top})`)

    let xDomain: any = d3.extent(data, (d) => {
      return new Date(d.x)
    }) as [Date, Date]
    if (data.length < 2) {
      xDomain = [
        Date.parse(moment().startOf('year') as any),
        Date.parse(moment() as any)
      ]
    }

    const xScale = d3
      .scaleTime()
      .domain(xDomain)
      .range([0, chartWidth])

    svg.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0, ${chartHeight + 10})`)
      .call(
        d3
          .axisBottom(xScale)
          .tickFormat(x => moment(x.toString()).format('MM/DD'))
          .tickSize(-chartHeight) // set x-axis grid width by using tick size
        )

    // remove x-axis and added x-axis grid
    svg.select('.x-axis').select('path').remove()
    svg.select('.x-axis')
      .selectAll('g')
      .select('line')
      .remove()
      .style("stroke-dasharray", "5 5")
      .style("stroke-width", 0.5)
      .style("opacity", 0.5)

    // set styles for x-axis tick label
    svg.select('.x-axis')
      .selectAll('g')
      .select('text')
      .style('color', '#6B7280')
      .style('font-size', '0.875rem')

    let maxValue = 1000
    if (data.length > 0) {
      maxValue = d3.max(data, (d) => {
        return d.y
      })
    }
    maxValue = maxValue <= 0 ? 1000 : maxValue

    const yScale = d3
      .scaleLinear()
      .domain([0, maxValue] as number[])
      .range([chartHeight, 0])

    svg.append("g")
      .attr("class", "grid")
      .attr("class","xaxis")
      .attr("class", "y-axis")
      .call(
        d3.axisLeft(yScale)
        .ticks(5)
        .tickSize(-chartWidth)
        .tickPadding(10)
      )
    svg.select('.xaxis').select('path').remove()
    svg.select('xaxis')

    // svg.append("g")
    //   .attr("class", "y-axis")
    //   .call(d3.axisLeft(yScale))

    // remove y-axis and added y-axis grid
    // svg.select('.y-axis').select('path').remove()
    // svg.select('.y-axis')
    //   .selectAll('line')
    //   .remove()

    svg.select('.y-axis').select('path').remove()
    svg.select('.y-axis')
      .selectAll('line')
      .style("stroke", "#555")
      .style("stroke-width", 0.5)
      .style("opacity", 0.5)
      // .remove()

    // set styles for y-axis tick label
    svg.select('.y-axis')
      .selectAll('g')
      .select('text')
      .style('color', '#6B7280')
      .style('font-size', '0.875rem')
      .style('display', d => {
        return d === 0 ? 'none' : ''
      })

    svg.append('linearGradient')
      .attr("id", "area-gradient")
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("x2", 0).attr("y2", yScale(0))
      .attr("x1", 0).attr("y1", yScale(d3.max(data, (d) => d.y)))
      .selectAll("stop")						
      .data([
        {offset: "0%", color: "#4FD1C5", opacity: 0.54},
        {offset: "50%", color: "#4FD1C5", opacity: 0.1},
      ])
      .enter().append("stop")			
      .attr("offset", function(d) { return d.offset })
      .attr("stop-color", function(d) { return d.color })
      .attr("stop-opacity", function(d) { return d.opacity })

    svg
      .append('path')
      .datum(data)
      .attr("class", "area")
      .style('fill', 'url(#area-gradient)')
      .attr(
        'd',
        // @ts-ignore
        d3
          .area()
          // .curve(d3.curveBasis)
          .x((d) => {
            return xScale(new Date((d as any).x))
          })
          .y0(yScale(0))
          .y1((d) => {
            return yScale((d as any).y)
          })
      )
    svg.append('path')
      .datum(data)
      .style('fill', 'transparent')
      .attr('stroke', '#85EBD9')
      .attr('stroke-width', 3)
      .attr(
        'd',
        // @ts-ignore
        d3
          .line()
          // .curve(d3.curveBasis)
          .x((d) => {
            return xScale(new Date((d as any).x))
          })
          .y((d) => {
            return yScale((d as any).y)
          })
      )
  }

  return (
    <div ref={ref} />
  )
}

export default LineChart
