import React, { FC, useState } from 'react'
import moment from 'moment'

import EventViewDialog from '../../organisms/calendar/eventViewDialog'

import { getDynamicHexColor } from '../../../utils/functions'

const AgendaView: FC<AgendaViewProps> = ({ days, events }) => {
  const [isOpenModal, setOpenModal] = useState<Boolean>(false)
  const [selectedEventDetail, setEventDetail] = useState<any>(null)

  const arrangeDays = (): any[] => {
    if (days && days.length > 0) {
      return days.map((day: any) => {
        let eventsData: any[] = []
        events && events.length > 0 && events.forEach((event: any) => {
          if (moment(day).format('YYYY-MM-DD') === moment(new Date(event.start)).format('YYYY-MM-DD')) {
            eventsData.push({
              id: event.meeting_key,
              name: event.title,
              time: moment(event.startdatetime).format('HHH'),
              datetime: event.startdatetime,
              bgColor: getDynamicHexColor(event),
              event: event
            })
          }
        })
        return {
          date: moment(day).format('YYYY-MM-DD'),
          events: eventsData.sort((a, b) => {
            return a.event.start - b.event.start && a.event.end - b.event.end
          })
        }
      }).filter((day: any) => day.events.length !== 0).sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf())
    }
    return []
  }

  const relevantEvents = () => {
    let array: any[] = []
    if (arrangeDays() && arrangeDays().length > 0) {
      arrangeDays().map((day: any) => day.events).forEach((event) => {
        array.push(...event)
      })
    }
    return array.sort((a: any, b: any) => {
      return a.event.start - b.event.start && a.event.end && b.event.end
    })
  }

  const handleSelect = (event: any) => {
    setOpenModal(true)
    setEventDetail(event)
  }

  const renderTableBody = () => {
    let displayDate: any = null
    const result = relevantEvents()?.map((event: any, eventIdx: number) => {
      let rowspan = 0
      // const rowData = arrangeDays().find((d: any) => moment(d.date).startOf('date').isSame(moment(event.datetime).startOf('date')))
      const rowData = arrangeDays().find((d: any) => d.date === moment(event.event.start).format('YYYY-MM-DD'))
      const rowDate = rowData.date
      const rowCount = rowData.events.length
      if (displayDate !== rowDate) {
        displayDate = rowDate
        rowspan = rowCount
      }
      return (
        <tr key={eventIdx}>
          {rowspan !== 0 && (
            <td
              rowSpan={rowspan}
              className='w-28 text-center'
              style={rowData.events && rowData.events.length > 0 && rowData.events[0].bgColor.style}
            >
              {moment(event.datetime).format('ddd MMM DD')}
            </td>
          )
          }
          <td className='w-48 text-center' style={event.bgColor.style}>{moment(event.event.start).format('HH:mm a')} - {moment(event.event.end).format('HH:mm a')}</td>
          <td style={event.bgColor.style} onClick={() => handleSelect(event.event)}>
            {event.event.title}
          </td>
        </tr>
      )
    })
    return result
  }

  if (relevantEvents().length === 0) {
    return <p>There are no events in this range.</p>
  }

  return (
    // <div className='revtron-agenda-view isolate flex flex-auto overflow-auto bg-white'>
    <div className='revtron-agenda-view isolate flex overflow-auto bg-white'>
      <table className='rbc-agenda-table w-full border-separate border-spacing-2 border border-white dark:border-header-dark bg-gray-200'>
        <thead>
          <tr>
            <th className='bg-white dark:bg-header-dark dark:text-white'>Date</th>
            <th className='bg-white dark:bg-header-dark dark:text-white'>Time</th>
            <th className='bg-white dark:bg-header-dark dark:text-white'>Event</th>
          </tr>
        </thead>
        <tbody>
          {renderTableBody()}
        </tbody>
      </table>
      <EventViewDialog
        isOpen={isOpenModal}
        setIsOpen={setOpenModal}
        event={selectedEventDetail}
      />
    </div>
  )
}

interface AgendaViewProps {
  days: any[]
  events: any[]
}

export default AgendaView
