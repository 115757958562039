import { CheckCircleIcon, InformationCircleIcon, XCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import { XIcon } from '@heroicons/react/outline'

export declare type alertType = 'info' | 'success' | 'error' | 'warning'

const alertSettings = {
  success: {
    icon: CheckCircleIcon,
    bgColor: 'bg-green-50',
    hoverColor: 'bg-green-100',
    iconColor: 'text-green-400',
    textColor: 'text-green-800',
    color: 'green'
  },
  info: {
    icon: InformationCircleIcon,
    bgColor: 'bg-blue-50',
    hoverColor: 'bg-blue-100',
    iconColor: 'text-blue-400',
    textColor: 'text-blue-800',
    color: 'blue'
  },
  error: {
    icon: XCircleIcon,
    bgColor: 'bg-red-50',
    hoverColor: 'bg-red-100',
    iconColor: 'text-red-400',
    textColor: 'text-red-800',
    color: 'red'
  },
  warning: {
    icon: ExclamationCircleIcon,
    bgColor: 'bg-yellow-50',
    hoverColor: 'bg-yellow-100',
    iconColor: 'text-yellow-400',
    textColor: 'text-yellow-800',
    color: 'yellow'
  },
}

export const sendAlert = (type: alertType, message: string) => {
  const Icon = alertSettings[type].icon
  const {bgColor, iconColor, textColor, hoverColor} = alertSettings[type]

  return (
    <div className={`rounded-md ${bgColor} p-4`}>
      <div className="flex">
        <div className="flex-shrink-0">
          <Icon className={`h-5 w-5 ${iconColor}`} aria-hidden="true" />
        </div>
        <div className="ml-3">
          <p className={`text-sm font-medium ${textColor}`}>{message}</p>
        </div>
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button
              type="button"
              className={`inline-flex rounded-md ${bgColor} p-1.5 ${iconColor} hover:${hoverColor}`}
            >
              <span className="sr-only">Dismiss</span>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
