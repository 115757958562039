import { useState, useEffect, useCallback, useMemo } from 'react'
import { getContactRecModuleData, getCrmEventData, getQueryVariables } from './ContactRec.util'
import {
  pdl_people_data_insert_input,
  useSendDataToCrmEventsMutation, 
  useCrmContactMappingsLazyQuery,
  useSavePeopleDataMutation
} from '../../../graphql/__generated__/graphql'
import { CrmEventRecord } from '../../../@types/module'
import { useContactRecModuleLazyQuery, useSaveEmailValidationDataMutation } from '../../../graphql/__generated__/gigpro_graphql'
import { alert } from '../../../utils/functions'
import { useContactTable } from '../../../components/organisms/prospector/contact-table/hook'
import { PeopleTableCommandPaletteFields } from '../Prospector.default.utils'
import { bulkRetrievePeople, getEmailValidations, EmailValidation } from '../../../services'

const useGraphQL = () => {
  const [isSendToCrmLoading, setSendToCrmLoading] = useState<boolean>(false)
  const [loadContactRecModuleData, { data: contactRecModuleData, loading: contactRecModuleLoading }] = useContactRecModuleLazyQuery()
  const [sendContactsToCrm] = useSendDataToCrmEventsMutation()
  const [loadContactMappings, { data: contactMappingsData }] = useCrmContactMappingsLazyQuery()
  const [savePeopleDataToCrm] = useSavePeopleDataMutation()
  const [saveEmailValidationData] = useSaveEmailValidationDataMutation()

  const {
    pageNumber,
    pageSize,
    accountMappingsData,
    loggedinUser,
    filters,
    ...rest
  } = useContactTable(true)

  useEffect(() => {
    const variables = getQueryVariables(pageNumber, pageSize, filters)
    loadContactRecModuleData({ variables })
    loadContactMappings()
  }, [pageNumber, pageSize, filters, loadContactRecModuleData, loadContactMappings])

  const contactRecData = useMemo(() => {
    if (contactRecModuleData) {
      return getContactRecModuleData(contactRecModuleData)
    }
    return { contactDetails: [], totalRecordsCount: 0 }
  }, [contactRecModuleData])

  const sendToCRM = useCallback(async (selectedIds: any[]) => {
    if (selectedIds.length > 0) {
        setSendToCrmLoading(true)

        bulkRetrievePeople(selectedIds)
          .then(async data => {
            const promises = []
            const { eventsData, pplData } = getCrmEventData(contactRecData.contactDetails, data, contactMappingsData, loggedinUser)
            let eventsDataToSave: CrmEventRecord[] = []
            let pplDataToSave: pdl_people_data_insert_input[] = []
            let deliverableEmails: string[] = []
            // Mutating email_validation
            if (pplData.length > 0) {
              const emails = pplData.map(ppl => ppl.work_email || '')
              const emailValidations: EmailValidation[] = await getEmailValidations(emails)
              promises.push(saveEmailValidationData({ variables: { validations: emailValidations } }))
              deliverableEmails = emailValidations.filter(emailValid => emailValid.state === 'deliverable').map(emailValid => emailValid.email)
            }
            // Filtering eventsData, pplData by deliverable email
            if (deliverableEmails.length > 0) {
              eventsDataToSave = eventsData.filter(ed => ed.records[0].Email && deliverableEmails.indexOf(ed.records[0].Email))
              pplDataToSave = pplData.filter(pd => pd.work_email && deliverableEmails.indexOf(pd.work_email) > -1)
            } else {
              throw new Error('No deliverable emails among selected contacts');
            }
            // Mutating pdl_people_data
            if (pplDataToSave.length > 0) {
              promises.push(savePeopleDataToCrm({ variables: { people: pplDataToSave } }))
            }
            // Mutating send_to_crm_events
            if (eventsDataToSave.length > 0) {
              promises.push(sendContactsToCrm({ variables: { crmEventsData: eventsDataToSave } }))
            }
            return Promise.all(promises)
          })
          .then(() => {
            // Load contactRecModuleData again with current pageNumber, pageSize, and filters
            const variables = getQueryVariables(pageNumber, pageSize, filters)
            variables.where['id'] = { _nin: selectedIds }
            return loadContactRecModuleData({ variables })
          })
          .then(() => {
            setSendToCrmLoading(false)
            alert('success', 'Successfully Sent to CRM' )
          })
          .catch((err) => {
            setSendToCrmLoading(false)
            alert('error', err?.message || 'Something went wrong')
          })
    }
  }, [contactMappingsData, contactRecData, filters, loadContactRecModuleData, loggedinUser, pageNumber, pageSize, sendContactsToCrm, savePeopleDataToCrm])

  return {
    fields: PeopleTableCommandPaletteFields,
    contactRecData,
    loading: contactRecModuleLoading,
    pageNumber,
    pageSize,
    filters,
    sendToCRM,
    isSendToCrmLoading,
    ...rest
  }
}

export default useGraphQL
