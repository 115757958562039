import { useFeatureIsOn, useFeature } from '@growthbook/growthbook-react'

export type InDevelopmentFlagType = {
  accounts: boolean
  activity_insights: boolean
  activity_weekly: boolean
  calendar: boolean
  icp: boolean
  bookings: boolean
  sales_velocity: boolean
  overview: boolean
  pipeline: boolean
  account_recommendations: boolean
}

export function usePipelineFilter(name: string) {
  const enabled = useFeatureIsOn(name)

  return enabled
}

export function useInDevelopmentFlag(name: string) {
  return useFeature<InDevelopmentFlagType>(name)
}
