import { Navigate, useLocation } from 'react-router-dom'
import { useAuthenticationStatus } from '@nhost/react'
import LightLogo from '../assets/images/light-logo.svg'
import DarkLogo from '../assets/images/dark-logo.svg'

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const { isAuthenticated, isLoading } = useAuthenticationStatus()
  const location = useLocation()

  if (isLoading) {
    return (
      <div className="h-screen bg-white dark:bg-revtron-dark3 text-gray-900 dark:text-white">
        <div className="text-center revtron-logo-loading"  >
          <img src={DarkLogo} className='hidden dark:block w-64 h-auto' alt='RevTron' />
          <img src={LightLogo} className='dark:hidden w-64 h-auto' alt='RevTron' />
          <h2 className="text-lg font-semibold text-black dark:text-white mt-3">Loading user data...</h2>
        </div>
      </div>
    )
  }

  if (!isAuthenticated) {
    return <Navigate to='/sign-in' state={{ from: location }} replace />
  }

  return children
}

export default PrivateRoute
