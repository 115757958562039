import { FC, Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import moment from 'moment'

import { convertUTCToLocal } from '../../../utils/functions'
import { useFeatureContext } from '../../../hooks/featureContext'

const EventViewDialog: FC<EventViewDialogProps> = ({isOpen, setIsOpen, event}) => {
  const { isHarry } = useFeatureContext()

  if (!event) {
    return null
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => setIsOpen(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto flex items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="max-w-xl bg-revtron-white dark:bg-revtron-dark2 dark:text-white rounded-xl shadow-xl transform transition-all p-6">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 dark:text-white mb-4"
                >
                  {event.subject}
                </Dialog.Title>
                <div className='my-1'>
                  {`${moment(new Date(convertUTCToLocal(event.startdatetime))).format('MM/DD/YYYY hh:mm A')} -- ${moment(new Date(convertUTCToLocal(event.enddatetime))).format('MM/DD/YYYY hh:mm A')}`}
                </div>
                <div className='my-1'>
                  Owner: {event.meeting_owner}
                </div>
                <div className='my-1'>
                  Opportunity: {event.opportunity_name}
                </div>
                <div className='my-1'>
                  Account: {event.account_name}
                </div>
                <div className='my-1'>
                  {isHarry ? 'MRR' : 'ARR'}: {event.amount.toString()}
                </div>

                <div className="mt-2 text-right">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md bg-revtron-cyan px-4 py-2 text-sm font-medium text-revtron-black hover:brightness-110 focus:outline-none"
                    onClick={() => setIsOpen(false)}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
  )
}

interface EventViewDialogProps {
  isOpen: any
  setIsOpen: Function
  event: any
}

export default EventViewDialog
