import { useEffect, useState, useCallback } from 'react'
import moment from 'moment'
import { useSalesVelocityDataByOwnerLazyQuery } from '../../graphql/__generated__/graphql'
import { getSalesVelocityDataByOwner, getBookingsDataByOwner } from './Leaderboard.util'
import { Opportunity_bool_exp, win_rate_totals_bool_exp } from '../../graphql/__generated__/graphql'
import { useFeatureContext } from '../../hooks/featureContext'

const oppWhere: Opportunity_bool_exp = {
  "IsWon": { "_eq": true },
  "Type": { "_neq": "Renewal" },
  "CloseDate": { "_gte": moment().startOf('year').format('YYYY-MM-DD') }
}
const pipelineWhere: Opportunity_bool_exp = {
  "IsClosed": { "_eq": false },
  "IsDeleted": { "_eq": false },
  "Type": { "_eq": "New Business" },
  "CloseDate": { "_lte": moment().add(90, 'days').startOf('day').format('YYYY-MM-DD') }
}
const netNewAvgWhere: Opportunity_bool_exp = {
  "IsWon": { "_eq": true },
  "IsDeleted": { "_eq": false },
  "Type": { "_eq": "New Business" },
  "CloseDate": { "_gt": moment().subtract(6, 'months').startOf('day').format('YYYY-MM-DD') }
}
const winRateWhere: win_rate_totals_bool_exp = { "type": { "_eq": "New Business" } }

export const useBookingsDataByOwnerGraphQL = () => {
  const [loadSalesVelcityData, { data: bookingsData, loading }] = useSalesVelocityDataByOwnerLazyQuery()
  const { isHarry } = useFeatureContext()

  useEffect(() => {
    if (isHarry) {
      oppWhere.CloseDate = { "_gte": moment().startOf('year').add(1, 'month').format('YYYY-MM-DD') }
      // oppWhere.Type = { "_eq": "New Sale" }
      pipelineWhere.Type = { "_eq": "New Sale" }
      netNewAvgWhere.Type = { "_eq": "New Sale" }
      winRateWhere.type = { "_eq": "New Sale" }
    }

    loadSalesVelcityData({
      variables: {
        oppWhere,
        pipelineWhere,
        individualWinRateWhere: winRateWhere,
        winRateWhere,
        netNewAvgWhere,
      }
    })
  }, [isHarry, bookingsData, loadSalesVelcityData])

  return {
    salesVelocityData: getBookingsDataByOwner(bookingsData),
    isLoading: loading,
  }
}

export const useSalesVelocityDataByOwnerGraphQL = () => {
  const [loadSalesVelcityData, { data: salesVelocityData, loading }] = useSalesVelocityDataByOwnerLazyQuery()
  const [oppId, setOppId] = useState<string>('')
  const { isHarry } = useFeatureContext()

  const onSelectOpp = useCallback((id: string) => {
    if (id !== oppId) {
      setOppId(id)
    } else {
      setOppId("")
    }
  }, [oppId])

  useEffect(() => {
    if (isHarry) {
      oppWhere.CloseDate = { "_gte": moment().startOf('year').add(1, 'month').format('YYYY-MM-DD') }
      // oppWhere.Type = { "_eq": "New Sale" }
      pipelineWhere.Type = { "_eq": "New Sale" }
      netNewAvgWhere.Type = { "_eq": "New Sale" }
      winRateWhere.type = { "_eq": "New Sale" }
    }

    loadSalesVelcityData({
      variables: {
        oppWhere,
        pipelineWhere,
        individualWinRateWhere: winRateWhere,
        winRateWhere,
        netNewAvgWhere,
      }
    })
  }, [isHarry, salesVelocityData, loadSalesVelcityData])

  return {
    salesVelocityData: getSalesVelocityDataByOwner(salesVelocityData),
    isLoading: loading,
    oppId,
    onSelectOpp
  }
}
