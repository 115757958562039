import { useMemo, useState, useCallback } from 'react'
import moment from 'moment'
import { useActivityByUserLazyQuery } from '../../../graphql/__generated__/graphql'
import { DateRangeType } from '../../../@types/common/DatePicker'
import { getActivityData } from './ActivityLookBack.util'

const TASK_CATEGORIES = [1,2,3,7]

const useGraphQL = () => {
  const [dateFilter, setDateFilter] = useState<DateRangeType>({
    start: moment().startOf('month'),
    end: moment().endOf('month')
  })

  const [loadActivityByUserData, { data: activityByUserData, loading: isActivityByUserDataLoading }] = useActivityByUserLazyQuery()

  const onDateFilterChange = useCallback((dateRange: DateRangeType) => {
    setDateFilter(dateRange)
  }, [])

  useMemo(() => {
    if (dateFilter.start && dateFilter.end) {
      const _where = {
        _gte: dateFilter.start.format('YYYY-MM-DD'),
        _lte: dateFilter.end.format('YYYY-MM-DD')
      }

      loadActivityByUserData({
        variables: {
          where: { createddate: _where },
          where2: { createddate: _where, isdeleted: { _eq: false } },
          where3: {
            _and: [
              {
                activitydate: _where
              },
              {
                task_categorization: { _in: TASK_CATEGORIES }
              }
            ] 
          }
        }
      })
    }
  }, [
    dateFilter.start,
    dateFilter.end,
    loadActivityByUserData
  ])

  return {
    dateFilter,
    onDateFilterChange,
    activityByUserData: getActivityData(activityByUserData),
    isActivityByUserDataLoading,
  }
}

export default useGraphQL
