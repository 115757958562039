import { useEffect, useState, useCallback, useMemo } from 'react'
import { useUserId } from '@nhost/react'
import { isEqual } from 'lodash'
import {
  order_by,
  pdl_people_preview_insert_input,
  pdl_people_data_insert_input,
  useCrmContactMappingsLazyQuery,
  useLoggedinUserLazyQuery,
  useProspectorModuleLazyQuery,
  useSavePeopleDataMutation,
  useSavePeoplePreviewDataMutation,
  useSendDataToCrmEventsMutation,
} from '../../graphql/__generated__/graphql'
import {
  useProspectorModuleForGigProLazyQuery,
  usePdlPeopleToExcludeLazyQuery,
  useSaveEmailValidationDataMutation,
} from '../../graphql/__generated__/gigpro_graphql'
import { getCrmEventData, getProspectorModuleData, getProspectorModuleGigproData, getPersonData } from './Prospector.default.utils'
import { ProspectorTabName, getCrmAccountId, PeopleTableCommandPaletteFields } from './Prospector.default.utils'
import { findPeopleService, bulkRetrievePeople, getEmailValidations, EmailValidation } from '../../services'
import { useAccountTable } from '../../components/organisms/account-table/hook'
import { PDLPerson, PDLQuery, PeopleFilter, IPersonResponse, CrmEventRecord } from '../../@types/module'
import { alert, generateRandomId } from '../../utils/functions'
import { useFeatureContext } from '../../hooks/featureContext'

export const useProspectorDefaultModule = () => {
  const [tab, setTab] = useState<string>(ProspectorTabName.COMPANY)
  const [pdlQuery, setPdlQuery] = useState<PDLQuery | null>(null)
  const [peopleFilters, setPeopleFilters] = useState<PeopleFilter[]>([])
  const [isFindPeopleLoading, setFindPeopleLoading] = useState<boolean>(false)
  const [scrollToken, setScrollToken] = useState<string>('')
  const [peopleData, setPeopleData] = useState<PDLPerson[]>([])
  const [totalCountsPeopleData, setTotalCountsPeopleData] = useState<number>(0)
  const [submittingPeopleDataToCRM, setSubmittingPeopleDataToCRM] = useState<boolean>(false)

  const revtronUserId = useUserId()
  const { isGigPro } = useFeatureContext()
  const { pageNumber, pageSize, crmFields, filters, ...rest } = useAccountTable()

  // Company Table Data
  const [loadProspectorModuleData, { data: ProspectorModuleData, loading: prospectorModuleLoading }] = useProspectorModuleLazyQuery()
  const [loadProspectorModuleGigproData, { data: ProspectorModuleGigproData, loading: prospectorModuleGigproLoading }] = useProspectorModuleForGigProLazyQuery()

  // People Table Data
  const [loadPeopleDataToBeExcluded] = usePdlPeopleToExcludeLazyQuery()
  const [savePeopleDataToCrm] = useSavePeopleDataMutation()
  const [savePeoplePreviewDataToCrm] = useSavePeoplePreviewDataMutation()
  const [saveEmailValidationData] = useSaveEmailValidationDataMutation()
  const [sendContactsToCrm] = useSendDataToCrmEventsMutation()
  const [loadContactMappings, { data: contactMappingsData }] = useCrmContactMappingsLazyQuery()
  const [getLoggedinUser, { data: loggedinUser }] = useLoggedinUserLazyQuery()

  // Load Prospector with filters
  useEffect(() => {
    if (isGigPro) {
      const variables = {
        where: {
          BillingCity: {_is_null: false},
          BillingState: {_is_null: false},
          BillingCountry: {_is_null: false},
          Website: {_is_null: false}
        } as any,
        orderBy: { "account_module_static": { "predct_proba": order_by.desc_nulls_last } },
        limit: pageSize,
        offset: (pageNumber - 1) * pageSize,
      }
  
      filters.forEach((filter) => {
        if (filter.type === 'text') {
          variables.where[filter.fieldName] = {
            _ilike: `%${filter.query}%`
          }
        }
        if (filter.type === 'number') {
          const min = !!filter.min && filter.min > 0 ? filter.min : 0
          const max = !!filter.max && filter.max > 0 ? filter.max : 0
          if (min && max) {
            variables.where[filter.fieldName] = {
              _gte: min,
              _lte: max
            }
          } else if (min && !max) {
            variables.where[filter.fieldName] = {
              _gte: min,
            }
          } else if (!min && max) {
            variables.where[filter.fieldName] = {
              _lte: max
            }
          }
        }
      })

      loadProspectorModuleGigproData({ variables })
    } else {
      const variables = {
        where: {
          to_revtron: { crm_id: { _is_null: false } },
          IsDeleted: {_eq: false},
        } as any,
        orderBy: { account_module_static: { predct_proba: order_by.desc } },
        limit: pageSize,
        offset: (pageNumber - 1) * pageSize,
      }
  
      filters.forEach((filter) => {
        if (filter.type === 'text') {
          variables.where[filter.fieldName] = {
            _ilike: `%${filter.query}%`
          }
        }
        if (filter.type === 'number') {
          const min = !!filter.min && filter.min > 0 ? filter.min : 0
          const max = !!filter.max && filter.max > 0 ? filter.max : 0
          if (min && max) {
            variables.where[filter.fieldName] = {
              _gte: min,
              _lte: max
            }
          } else if (min && !max) {
            variables.where[filter.fieldName] = {
              _gte: min,
            }
          } else if (!min && max) {
            variables.where[filter.fieldName] = {
              _lte: max
            }
          }
        }
      })

      loadProspectorModuleData({ variables })
    }
  }, [pageNumber, pageSize, filters, loadProspectorModuleData, loadProspectorModuleGigproData, isGigPro])

  const { companyTableData, totalRowsCount } = useMemo(() => {
    if (isGigPro) {
      if (ProspectorModuleGigproData) {
        return getProspectorModuleGigproData(ProspectorModuleGigproData)
      }
    } else {
      if (ProspectorModuleData) {
        return getProspectorModuleData(ProspectorModuleData)
      }
    }
    return { companyTableData: [], totalRowsCount: 0 }
  }, [ProspectorModuleData, ProspectorModuleGigproData, isGigPro])

  // Load loggedIn user & contact mappings
  useEffect(() => {
    if (revtronUserId) {
      getLoggedinUser({ variables: { userId: revtronUserId } })
      loadContactMappings()
    }
  }, [revtronUserId, getLoggedinUser, loadContactMappings])

  // Update PDL query whenenver filters are changed
  useEffect(() => {
    if (pdlQuery) {
      let _query = { ...pdlQuery }
      if (peopleFilters.length > 0) {
        peopleFilters.forEach((filter) => {
          if (filter.type === 'text') {
            _query[filter.fieldName] = filter.query
          }
          if (filter.type === 'number') {
            const min = !!filter.min && filter.min > 0 ? filter.min : 0
            const max = !!filter.max && filter.max > 0 ? filter.max : 0
            if (min && max) {
              _query[filter.fieldName] = {
                _gte: min,
                _lte: max
              }
            } else if (min && !max) {
              _query[filter.fieldName] = {
                _gte: min,
              }
            } else if (!min && max) {
              _query[filter.fieldName] = {
                _lte: max
              }
            }
          }
          if (filter.type === 'multi') {
            _query[filter.fieldName] = filter.multi
          }
        })
      } else {
        // Remove dynamic filters
        _query = {
          job_company_website: pdlQuery.job_company_website,
          location_locality: pdlQuery.location_locality,
          location_name: pdlQuery.location_name,
          scroll_token: pdlQuery.scroll_token,
          mapping: pdlQuery.mapping,
        }
      }

      if (!isEqual(_query, pdlQuery)) {
        setPdlQuery(_query)

        applyPdlQuery(_query)
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peopleFilters])

  // Switch between Company Table and People Data
  const changeTab = useCallback((v: string) => {
    if (tab !== v) {
      setTab(v)
    }
  }, [tab])

  // Set inital PDL query
  const findPeople = useCallback((selectedIds: any[]) => {
    if (selectedIds.length > 0) {
      const query: PDLQuery = {
        job_company_website: [],
        location_locality: [],
        location_name: [],
        mapping: []
      }

      companyTableData.filter(t => selectedIds.includes(t.uuid)).forEach(data => {
        if (!!data.domain && !!data.city && !!data.state && !!data.country && !!data.crmId) {
          let domainOnly = data.domain.replace('http://', '').replace('https://', '').replace('www.', '').toLowerCase()
          // Remove if last char in the domain is '/'
          if (domainOnly.charAt(domainOnly.length - 1) === '/') {
            domainOnly = domainOnly.replace(/.$/, '')
          }
          query.job_company_website.push(domainOnly)

          if (isGigPro) {
            const location_name = `${data.city}, ${data.state}, ${data.country}`.toLowerCase()
            query.location_name.push(location_name)

            const locationIden = `${data.city}${data.state}${data.country}`.replace(/\s/g, '').toLowerCase()
            query.mapping.push({ uniqueKey: `${domainOnly}_${locationIden}`, crmAccountId: data.crmId, isGigpro: true })
          } else {
            query.mapping.push({ uniqueKey: domainOnly, crmAccountId: data.crmId, isGigpro: false })
          }
        }
      })
      applyPdlQuery(query)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyTableData, isGigPro])

  const applyPdlQuery = (query: PDLQuery) => {
    setFindPeopleLoading(true)
    setPdlQuery(query)

    let personData: IPersonResponse[] = []
    let scrollToken: string = ''
    let totalRecords: number = 0

    // Find persons from PDL
    findPeopleService(query)
      .then(res => {
        const { data, scroll_token, total } = res
        personData = data.map(item => ({ ...item, crmAccountId: getCrmAccountId(query, item) })).filter(item => !!item.crmAccountId)
        scrollToken = scroll_token 
        totalRecords = total

        const pdl_ids: string[] = []
        data.forEach(p => {
          if (!!p.id) pdl_ids.push(p.id)
        })

        // Add a random ID - you can force a query instead of cached data.
        pdl_ids.push(generateRandomId(10))

        // Load people preview data records that exist in pdl_people_data
        return loadPeopleDataToBeExcluded({
          variables: {
            where: { id: { _in: pdl_ids } }
          }
        }) 
      })
      .then(({ data }) => {
        const excludingIds = data?.exclude.map(p => p.id) || []
        const tableData = getPersonData(personData).map(p => ({
          ...p,
          isInCRM: excludingIds.includes(p.id)
        }))
        const previewData = personData.filter(p => !!p.id && !excludingIds.includes(p.id))

        if (query.scroll_token) {
          setPeopleData([...peopleData, ...tableData])
        } else {
          setPeopleData(tableData)
        }
        
        setScrollToken(scrollToken)
        setTotalCountsPeopleData(totalRecords)
        setFindPeopleLoading(false)

        changeTab(ProspectorTabName.PEOPLE)

        const postData = previewData.map((d: Partial<IPersonResponse>) => {
          const crm_account_id = d.crmAccountId
          delete d.crmAccountId

          return ({
            ...d,
            crm_account_id,
            revtron_user_id: revtronUserId
          })
        }) as pdl_people_preview_insert_input[]
        return savePeoplePreviewDataToCrm({ variables: { people: postData } })
      })
      .catch(error => {
        alert('error', error?.message || 'Something went wrong!')
        setPeopleData([])
        setScrollToken('')
        setTotalCountsPeopleData(0)
        setFindPeopleLoading(false)
      })
  }

  // Command Pallete: add a filter to people table
  const addPeopleFilter = useCallback((filter: PeopleFilter) => {
    const _filters = [...peopleFilters]
    const index = _filters.findIndex(f => f.fieldName === filter.fieldName)
    if (index < 0) {
      _filters.push(filter)
    } else {
      _filters[index] = filter
    }
    if (!isEqual(_filters, peopleFilters)) {
      setPeopleFilters(_filters)
    }
  }, [peopleFilters])

  // Command Pallete: remove a filter to people table
  const removePeopleFilter = useCallback((filter: PeopleFilter) => {
    const _filters = [...peopleFilters]
    const index = _filters.findIndex(f => f.fieldName === filter.fieldName)
    if (index >= 0) {
      _filters.splice(index, 1)
      setPeopleFilters(_filters)
    }
  }, [peopleFilters])

  // Command Pallete: clear all people filters
  const clearAllPeopleFilters = useCallback(() => {
    setPeopleFilters([])
  }, [])

  // PDL: load more
  const loadMorePeople = useCallback(() => {
    if (scrollToken && pdlQuery) {
      const _cloned = { ...pdlQuery }
      _cloned.scroll_token = scrollToken
      
      setPdlQuery(_cloned)
      applyPdlQuery(_cloned)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollToken, pdlQuery])

  // Check if Load more enabled
  const isLoadMoreEnabled = useMemo(() => {
    if (peopleData.length === 0 || totalCountsPeopleData === 0)
      return false

    return peopleData.length < totalCountsPeopleData
  }, [totalCountsPeopleData, peopleData])

  // Submit people contacts to CRM
  const submitPeopleDataToCrm = useCallback((selectedIds: string[]) => {
    if (selectedIds.length > 0) {
      setSubmittingPeopleDataToCRM(true)
      bulkRetrievePeople(selectedIds)
        .then(async data => {
          const promises = []
          const { eventsData, pplData } = getCrmEventData(data, pdlQuery, contactMappingsData, loggedinUser)
          let eventsDataToSave: CrmEventRecord[] = []
          let pplDataToSave: pdl_people_data_insert_input[] = []
          let deliverableEmails: string[] = []
          // Mutating email_validation
          if (pplData.length > 0) {
            const emails = pplData.map(ppl => ppl.work_email || '')
            const emailValidations: EmailValidation[] = await getEmailValidations(emails)
            promises.push(saveEmailValidationData({ variables: { validations: emailValidations } }))
            deliverableEmails = emailValidations.filter(emailValid => emailValid.state === 'deliverable').map(emailValid => emailValid.email)
          }
          // Filtering eventsData, pplData by deliverable email
          if (deliverableEmails.length > 0) {
            eventsDataToSave = eventsData.filter(ed => ed.records[0].Email && deliverableEmails.indexOf(ed.records[0].Email))
            pplDataToSave = pplData.filter(pd => pd.work_email && deliverableEmails.indexOf(pd.work_email) > -1)
          } else {
            throw new Error('No deliverable emails among selected contacts');
          }
          // Mutating pdl_people_data
          if (pplDataToSave.length > 0) {
            promises.push(savePeopleDataToCrm({ variables: { people: pplDataToSave } }))
          }
          // Mutating send_to_crm_events
          if (eventsDataToSave.length > 0) {
            promises.push(sendContactsToCrm({ variables: { crmEventsData: eventsDataToSave } }))
          }
          return Promise.all(promises)
        })
        .then(() => {
          const pdl_ids = peopleData.map(p => p.id)
          // Add a random ID - you can force a query instead of cached data.
          pdl_ids.push(generateRandomId(10))

          // reload people data to be excluded
          return loadPeopleDataToBeExcluded({
            variables: {
              where: { id: { _in: pdl_ids } }
            }
          })
        })
        .then(({ data }) => {
          const excludingIds = data?.exclude.map(p => p.id) || []
          const newPeopleData = peopleData.map(p => ({
            ...p,
            isInCRM: excludingIds.includes(p.id)
          }))
          setPeopleData(newPeopleData)
          alert('success', 'Successfully Submitted!' )
          setSubmittingPeopleDataToCRM(false)
        })
        .catch(error => {
          alert('error', error?.message || 'Something went wrong!')
          setSubmittingPeopleDataToCRM(false)
        })
    }
  }, [contactMappingsData, loadPeopleDataToBeExcluded, loggedinUser, pdlQuery, peopleData, savePeopleDataToCrm, sendContactsToCrm])
  
  return {
    tab,
    changeTab,
    companyTableData,
    companyTableTotalRowsCount: totalRowsCount,
    loading: prospectorModuleLoading || prospectorModuleGigproLoading,
    findPeople,
    pageNumber,
    pageSize,
    companyFields: crmFields,
    filters,
    peopleFields: PeopleTableCommandPaletteFields,
    peopleFilters,
    addPeopleFilter,
    removePeopleFilter,
    clearAllPeopleFilters,
    isFindPeopleLoading,
    loadMorePeople,
    isLoadMoreEnabled,
    peopleData,
    isSubmitting: submittingPeopleDataToCRM,
    submitPeopleData: submitPeopleDataToCrm,
    ...rest,
  }
}