import { FC } from 'react'
import { ContactRecord } from '../../../../@types/module/Prospector.types'
import { MailIcon, PhoneIcon,  } from '@heroicons/react/outline'
import { LinkedinIcon, FacebookIcon, TwitterIcon , SalesforceIcon } from '../../../common/Icons/SocialIcons'
import { getUserAvatarByName } from '../../../../utils/functions'

interface TableRecordProps {
  detail: ContactRecord,
  index: number,
  selectedRows: string[],
  onRowSelect: (id: any) => void,
}

const TableRecord: FC<TableRecordProps> = ({
  detail,
  selectedRows,
  onRowSelect
}) => {
  return (
    <div className="rounded-lg shadow text-black dark:text-white bg-white dark:bg-revtron-dark2 flex items-center p-6 mb-3">
      <div className='mr-6 p-1'>
        <input
          aria-describedby="row-selectable"
          name="row"
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-revtron-cyan1 focus:ring-revtron-cyan1"
          checked={selectedRows.findIndex(id => detail.id === id) > -1}
          onChange={() => onRowSelect(detail.id)}
        />
      </div>
      <div className='flex-1 p-1 inline-flex items-center'>
        <div
          className='w-10 h-10 rounded-full flex justify-center items-center mr-2'
          style={{
            backgroundColor: `#a845c0`
          }}
        >
          <span>{getUserAvatarByName(detail.fullName)}</span>
        </div>
        {detail.fullName}
      </div>
      <div className='flex-1 p-1'>{detail.jobTitle}</div>
      <div className='flex-1 p-1'>{detail.jobCompanyName}</div>
      <div className='w-52 p-1 inline-flex items-center'>
        {/* <SalesforceIcon className={`fill-current w-6 h-6 mr-3 ${!!detail.isInCRM ? 'text-revtron-cyan' : 'text-gray-500'}`} /> */}
        <MailIcon className={`flex-shrink-0 w-6 h-6 mr-3 ${detail.isEmail ? 'stroke-revtron-cyan' : 'stroke-gray-500'}`} />
        <PhoneIcon className={`flex-shrink-0 w-5 h-5 mr-3 ${detail.isPhone ? 'stroke-revtron-cyan' : 'stroke-gray-500'}`} />
        <LinkedinIcon className={`fill-current w-5 h-5 mr-3 ${detail.isLinkedin ? 'text-revtron-cyan' : 'text-gray-500'}`} />
        <FacebookIcon className={`fill-current w-4.5 h-4.5 mr-3 ${detail.isFacebook ? 'text-revtron-cyan' : 'text-gray-500'}`} />
        <TwitterIcon className={`fill-current w-5 h-5 mr-3 ${detail.isTwitter ? 'text-revtron-cyan' : 'text-gray-500'}`} />
      </div>
    </div>
  )
}

export default TableRecord
