import { FC, memo } from 'react'
import { PDLPerson } from '../../../../@types/module'
import { ColoredButton } from '../../../common/buttons'
// import TableRecord from './TableRecord'
import { PeopleTableProps } from '../../../../@types/module'
import { MailIcon, PhoneIcon,  } from '@heroicons/react/outline'
import { LinkedinIcon, FacebookIcon, TwitterIcon , SalesforceIcon } from '../../../common/Icons/SocialIcons'
import { getUserAvatarByName } from '../../../../utils/functions'
import Spin from '../../../atoms/spin'

type TableBodyProps = Omit<PeopleTableProps, 'isSubmitting' | 'submitPeopleData'> & {
  selectable: string
  selectedRows: string[]
  onRowSelect: (id: string) => void
  onCheckboxChange: () => void
}

const TableBody: FC<TableBodyProps> = ({
  peopleData,
  loadMorePeople,
  loading,
  isLoadMoreEnabled,
  selectable,
  selectedRows,
  onRowSelect,
  onCheckboxChange
}) => {
  return (
    <div className="icp-accounts mt-8 mb-8">
      <div className="rounded-lg shadow text-black dark:text-white bg-white dark:bg-revtron-dark2 flex items-center p-6 mb-3">
        <div className='mr-6 p-1'>
          <input
            id="ppl_check_box_handler"
            aria-describedby="row-selectable"
            name="row"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-revtron-cyan1 focus:ring-revtron-cyan1"
            checked={selectable === 'checked'}
            onChange={onCheckboxChange}
          />
        </div>
        <div className='flex-1 p-1'>Name</div>
        <div className='flex-1 p-1'>Title</div>
        <div className='flex-1 p-1'>Company</div>
        <div className='flex-1 p-1'>Contacts</div>
      </div>
      {peopleData.length > 0 ? (
        peopleData.map((record: PDLPerson, index: number) => (
          // <TableRecord record={record} index={index + 1} key={record.id} />
          <div key={record.id} className="rounded-lg shadow text-black dark:text-white bg-white dark:bg-revtron-dark2 flex items-center p-6 mb-3">
            <div className='mr-6 p-1'>
              <input
                aria-describedby="row-selectable"
                name="row"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-revtron-cyan1 focus:ring-revtron-cyan1 disabled:bg-revtron-dark3 disabled:border-revtron-dark1 disabled:cursor-not-allowed"
                checked={!record.isInCRM && selectedRows.findIndex(id => record.id === id) > -1}
                onChange={() => onRowSelect(record.id)}
                disabled={!!record.isInCRM}
              />
            </div>
            <div className='flex-1 p-1 inline-flex items-center'>
              <div
                className='w-10 h-10 rounded-full flex justify-center items-center mr-2'
                style={{
                  backgroundColor: `#a845c0`
                }}
              >
                <span>{getUserAvatarByName(record.fullName)}</span>
              </div>
              {record.fullName}
            </div>
            <div className='flex-1 p-1'>{record.profession}</div>
            <div className='flex-1 p-1'>{record.company}</div>
            <div className='flex-1 p-1 inline-flex items-center'>
              <SalesforceIcon className={`fill-current w-6 h-6 mr-3 ${!!record.isInCRM ? 'text-revtron-cyan' : 'text-gray-500'}`} />
              <MailIcon className={`flex-shrink-0 w-6 h-6 mr-3 ${record.isEmail ? 'stroke-revtron-cyan' : 'stroke-gray-500'}`} />
              <PhoneIcon className={`flex-shrink-0 w-5 h-5 mr-3 ${record.isPhone ? 'stroke-revtron-cyan' : 'stroke-gray-500'}`} />
              <LinkedinIcon className={`fill-current w-5 h-5 mr-3 ${record.isLinkedin ? 'text-revtron-cyan' : 'text-gray-500'}`} />
              <FacebookIcon className={`fill-current w-4.5 h-4.5 mr-3 ${record.isFacebook ? 'text-revtron-cyan' : 'text-gray-500'}`} />
              <TwitterIcon className={`fill-current w-5 h-5 mr-3 ${record.isTwitter ? 'text-revtron-cyan' : 'text-gray-500'}`} />
            </div>
          </div>
        ))
      ) : (
        <>
          {loading ? (
            <div className='flex justify-center bg-white dark:bg-revtron-dark2 shadow rounded-lg p-7 mb-6'>
              <Spin />
            </div>
          ): (
            <div className='rounded-lg shadow text-black dark:text-white bg-revtron-gray1 dark:bg-neutral-800 p-6 text-center'>
              No data
            </div>
          )}
        </>
      )}
      {isLoadMoreEnabled && (
        <div className="text-center mt-6">
          <ColoredButton title="Show More" onButtonClick={loadMorePeople} variant="primary" isLoading={loading} />
        </div>
      )}
    </div>
  )
}

export default memo(TableBody)
