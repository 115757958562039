import { FC, useMemo, useState, useCallback } from 'react'

import ShadowCard from '../../../templates/shadowCard'
import CustomTable from '../../../molecules/table'
import { convertUTCToLocal } from '../../../../utils/functions'
import { MeetingType } from '../../../../containers/activity/ActivityLookBack/ActivityLookBack.util'
import { sortAlphabetically } from '../../../../utils/functions'

type MeetingTableProps = {
  meetings: MeetingType[]
  ownerId?: string 
}

const MeetingTable: FC<MeetingTableProps> = ({ meetings, ownerId }) => {
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const data = useMemo(() => {
    if (!!ownerId) {
      setPageNumber(1)
      return meetings.filter(m => m.ownerid === ownerId)
    }
    return meetings
  }, [meetings, ownerId])

  const paginatedData = useMemo(() => {
    return data.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
  }, [pageNumber, pageSize, data])

  const onPaginationChange = useCallback((pageNumber: number, pageSize: number) => {
    setPageNumber(pageNumber)
    setPageSize(pageSize)
  }, [])

  const columns = [
    {
      title: 'Meeting Creator',
      dataIndex: 'creator',
      sorter: (a: MeetingType, b: MeetingType) => sortAlphabetically(a.creator, b.creator)
    },
    {
      title: 'Meeting Subject',
      dataIndex: 'subject',
      sorter: (a: MeetingType, b: MeetingType) => sortAlphabetically(a.subject, b.subject)
    },
    {
      title: 'Created Date',
      dataIndex: 'createddate',
      sorter: (a: MeetingType, b: MeetingType) => (a.createddate < b.createddate) ? -1 : ((a.createddate > b.createddate) ? 1 : 0),
      render: (text: string) => {
        return (
          <p>{convertUTCToLocal(text)}</p>
        )
      }
    },
    {
      title: 'Activity Date',
      dataIndex: 'activitydatetime',
      sorter: (a: MeetingType, b: MeetingType) => (a.activitydatetime < b.activitydatetime) ? -1 : ((a.activitydatetime > b.activitydatetime) ? 1 : 0),
      render: (text: string) => {
        return (
          <p>{convertUTCToLocal(text)}</p>
        )
      }
    },
    {
      title: 'End Date',
      dataIndex: 'enddatetime',
      sorter: (a: MeetingType, b: MeetingType) => (a.enddatetime < b.enddatetime) ? -1 : ((a.enddatetime > b.enddatetime) ? 1 : 0),
      render: (text: string) => {
        return (
          <p>{convertUTCToLocal(text)}</p>
        )
      }
    },
  ]

  return (
    <ShadowCard label='Meetings Created' className='mt-5'>
      <CustomTable
        columns={columns}
        dataSource={paginatedData}
        pagination={{
          total: data.length,
          current: pageNumber,
          pageSize,
          onChange: onPaginationChange
        }}
      />
    </ShadowCard>
  )
}

export default MeetingTable
