import React, { FC, useState } from 'react'
import { EyeIcon, EyeOffIcon } from '@heroicons/react/outline'

const TextInput: FC<TextInputProps> = ({
  type = 'text',
  label = '',
  labelClassName = '',
  placeholder = '',
  error,
  value,
  onChange,
  required,
  disabled,
  ...props
}) => {
  const [isLockEye, setLockEye] = useState(true)
  return (
    <div className="w-full flex flex-col relative">
      {label && (
        <label className={`text-gray-700 font-medium text-sm mb-1 ${labelClassName}`}>
          {label}
        </label>
      )}
      <input
        type={isLockEye ? type : 'text'}
        value={value}
        onChange={onChange}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        className={`w-full shadow-sm rounded-md p-3 text-form-input dark:text-revtron-gray1 bg-white dark:bg-revtron-dark1 border border-table-text dark:border-revtron-dark3 focus:border-blue-500 focus:ring-blue-500 focus:outline-none focus:ring-4 focus:ring-opacity-20 transition disabled:opacity-50 disabled:cursor-not-allowed read-only:opacity-50 read-only:cursor-not-allowed read-only:focus:border-gray-300 read-only:focus:ring-0 ${error ? 'text-red-400 dark:text-red-400 border-red-400 dark:border-red-400' : ''}`}
        {...props}
      />
      {error && (
        <div className='absolute top-4 right-3'>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="rgb(248, 113, 113)" className="w-4 h-4">
            <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm8.706-1.442c1.146-.573 2.437.463 2.126 1.706l-.709 2.836.042-.02a.75.75 0 01.67 1.34l-.04.022c-1.147.573-2.438-.463-2.127-1.706l.71-2.836-.042.02a.75.75 0 11-.671-1.34l.041-.022zM12 9a.75.75 0 100-1.5.75.75 0 000 1.5z" clipRule="evenodd" />
          </svg>
        </div>
      )}
      {!error && type === 'password' && (
        <div
          className='absolute hover:cursor-pointer bottom-4 right-3'
          onClick={() => setLockEye(!isLockEye)}
        >
          {
            isLockEye ? (
              <EyeIcon className="w-5 h-5 text-gray-500" />
            ) : (
              <EyeOffIcon className="w-5 h-5 text-gray-500" />
            )
          }
        </div>
      )}
    </div>
  )
}

interface TextInputProps {
  type?: string
  label?: string
  labelClassName?: string
  placeholder?: string
  value: string
  onChange: (e: any) => void
  required?: boolean
  disabled?: boolean
  error?: boolean
}

export default TextInput