import { FC, Key, useState } from 'react'
import { UserAddIcon } from '@heroicons/react/solid';
import EditTeamModal from './editTeamModal'
import AddMemberModal from './addMemberModal'

const TeamCard: FC<TeamCardProps> = ({ team }) => {
  const [editTeamModalOpened, toggleEditTeamModal] = useState(false)
  const [openAddMemberModal, setOpenAddMemberModal] = useState(false);

  return (
    <div className="overflow-hidden rounded bg-white dark:bg-header-dark dark:border-gray-500 shadow">
      <div className='p-4'>
        <div className="text-base text-neutral-600 dark:text-revtron-white font-medium mb-2">{team.name}</div>
        <div className="text-xs font-medium text-gray-700 dark:text-gray-200 my-1">Weekly Activity Targets</div>
        <div className='flex justify-between my-2'>
          <div className='text-xs text-gray-400'>Opportunity Creation Target</div>
          <div className='text-xs text-gray-700 font-medium dark:text-gray-200'>{team.opportunity_target}</div>
        </div>
        <div className='flex justify-between my-2'>
          <div className='text-xs text-gray-400'>Email Send Target</div>
          <div className='text-xs text-gray-700 font-medium dark:text-gray-200'>{team.email_target}</div>
        </div>
        <div className='flex justify-between my-2'>
          <div className='text-xs text-gray-400'>Call Target</div>
          <div className='text-xs text-gray-700 font-medium dark:text-gray-200'>{team.call_target}</div>
        </div>
        <div className='flex justify-between my-2'>
          <div className='text-xs text-gray-400'>Social Activity Target</div>
          <div className='text-xs text-gray-700 font-medium dark:text-gray-200'>{team.social_target}</div>
        </div>
      </div>
      <div className='p-4 border-t dark:border-neutral-700'>
        <div className='h-8 flex justify-between items-center mb-3'>
          <div className='flex -space-x-2 overflow-visible'>
            {team.to_members.slice(0, 4).map((member: any, index: Key) => (
              <img
                key={index}
                className="inline-block h-8 w-8 bg-gray-100 dark:bg-neutral-700 rounded-full ring-2 ring-white dark:ring-header-dark"
                src={member.to_users?.avatarUrl}
                alt=""
              />
            ))}
          </div>
          <div className="text-xs font-medium text-gray-700 dark:text-gray-200">{team.to_members.length} members</div>
        </div>
        <div className='flex items-center'>
          <button 
            className="flex-1 inline-flex items-center justify-center rounded-md px-4 py-1.5 text-xs text-neutral-500 dark:text-gray-400 font-medium border border-neutral-400 dark:border-neutral-700 bg-white dark:bg-header-dark focus:outline-none hover:bg-gray-50 sm:w-auto mr-3"
            onClick={() => setOpenAddMemberModal(true)}
          >
            <UserAddIcon
              className="mr-1 h-4 w-4 text-neutral-400"
              aria-hidden="true"
            />
            Add Member
          </button>
          <button 
            className="inline-flex items-center justify-center rounded-md px-4 py-1.5 text-xs text-neutral-500 dark:text-gray-400 font-medium border border-neutral-400 dark:border-neutral-700 bg-white dark:bg-header-dark focus:outline-none hover:bg-gray-50 sm:w-auto"
            onClick={() => toggleEditTeamModal(true)}
          >
            Edit
          </button>
        </div>
      </div>

      <AddMemberModal team={team} isOpen={openAddMemberModal} closeModal={() => setOpenAddMemberModal(false)} />
      <EditTeamModal
        team={team}
        isOpen={editTeamModalOpened}
        closeModal={() => toggleEditTeamModal(false)}
        openAddMemberModal={() => {
          toggleEditTeamModal(false)
          setTimeout(() => {
            setOpenAddMemberModal(true)
          }, 500)
        }}
      />
    </div>
  )
}

interface TeamCardProps {
  team: any
}

export default TeamCard
