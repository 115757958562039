import { FC, useState, useEffect } from 'react'
import moment from 'moment'

import Categories from '../../components/organisms/pipeline/categories'
import EngagementTable from '../../components/organisms/pipeline/engagementTable'
import Scatter from '../../components/organisms/pipeline/scatter'
import StageTable from '../../components/organisms/pipeline/stageTable'
import Summary from '../../components/organisms/pipeline/summary'

import { useFeatureContext } from '../../hooks/featureContext'
import { START_MONTH_OF_FISCAL_YEAR } from '../../utils/constants'

import {
  useOppDetailEngagementAllQuery,
  useOppDetailEngagementFilterQuery,
  useBookedAggregateQuery,
  usePipeAggregateQuery,
  useWeightedAggregateQuery,
} from '../../graphql/__generated__/graphql'

const Pipeline: FC<PipelineProps> = () => {
  const { isHarry, isXima } = useFeatureContext()
  const [state, setState] = useState<any>({
    stage: null,
    forecast: null,
    owner: null,
    type: null,
    custom_filter_1: null,
    date: [moment().startOf('quarter'), moment().endOf('quarter')]
  })
  const [selectedIds, setSelectedIds] = useState<any[]>([])

  const { data: all, loading: allLoading } = useOppDetailEngagementAllQuery({
    fetchPolicy: 'cache-first'
  })

  useEffect(() => {
    if (isHarry) {
      const startDateOfFiscalYear = moment().month(START_MONTH_OF_FISCAL_YEAR).startOf('month')
      let startDateOfQuarter = startDateOfFiscalYear
      let endDateOfQuarter = moment(startDateOfQuarter).add(2, 'month').endOf('month')
      while (endDateOfQuarter.isBefore(moment())) {
        startDateOfQuarter = moment(startDateOfQuarter).add(3, 'month')
        endDateOfQuarter = moment(startDateOfQuarter).add(2, 'month').endOf('month')
      }
      setState({ ...state, date: [startDateOfQuarter, endDateOfQuarter] })
    }
  }, [isHarry])

  let where = {}

  if (state.stage && state.stage.length !== 0) {
    where = {
      ...where,
      stage: {
        _in: state.stage.map((s: any) => s.value)
      }
    }
  }

  if (state.type && state.type.length !== 0) {
    where = {
      ...where,
      type: {
        _in: state.type.map((s: any) => s.value)
      }
    }
  }

  if (state.forecast && state.forecast.length !== 0) {
    where = {
      ...where,
      forecast_category: {
        _in: state.forecast.map((s: any) => s.value)
      }
    }
  }

  if (state.owner && state.owner.length !== 0) {
    where = {
      ...where,
      opportunity_owner: {
        _in: state.owner.map((s: any) => s.value)
      }
    }
  }

  if (state.custom_filter_1 && state.custom_filter_1.length !== 0) {
    where = {
      ...where,
      custom_filter_1: {
        _in: state.custom_filter_1.map((s: any) => s.value)
      }
    }
  }

  if (state.date[0] !== null && state.date[1] !== null) {
    where = {
      ...where,
      closedate: {
        _gte: new Date(moment(state.date[0]).format('YYYY/MM/DD')),
        _lte: new Date(moment(state.date[1]).format('YYYY/MM/DD'))
      }
    }
  }

  const isNotSetDefaultDate = Object.keys(where).length === 0
  
  const { data, loading } = useOppDetailEngagementFilterQuery({
    variables: { where },
    fetchPolicy: 'cache-first',
    skip: isNotSetDefaultDate
  })
  const { data: bookedData, loading: bookedLoading } = useBookedAggregateQuery({
    variables: {
      where: {
        ...where,
        iswon: { _eq: true }
      }
    },
    fetchPolicy: 'cache-first',
    skip: isNotSetDefaultDate
  })
  const { data: pipeData, loading: pipeLoading } = usePipeAggregateQuery({
    variables: {
      where: {
        ...where,
        isclosed: { _eq: false }
      }
    },
    fetchPolicy: 'cache-first',
    skip: isNotSetDefaultDate
  })
  const { data: weightedData, loading: weightedLoading } = useWeightedAggregateQuery({
    variables: { where },
    fetchPolicy: 'cache-first',
    skip: isNotSetDefaultDate
  })

  const addIds = (id: any) => {
    const ids: any[] = [...selectedIds]
    const index = ids.indexOf(id)
    if (index > -1) {
      ids.splice(index, 1)
    } else {
      ids.push(id)
    }
    setSelectedIds(ids)
  }

  return (
    <div className='pipeline'>
      <Categories
        filters={state}
        setFilters={setState}
        data={all}
        loading={allLoading}
        ximaEnabled={isXima}
        harriEnabled={isHarry}
      />
      <Summary
        bookedData={bookedData}
        bookedLoading={bookedLoading}
        pipeData={pipeData}
        pipeLoading={pipeLoading}
        weightedData={weightedData}
        weightedLoading={weightedLoading}
      />
      <div className='table-chart flex flex-col xl:flex-row'>
        <StageTable data={data} loading={loading} />
        <Scatter
          all={all}
          data={data}
          ids={selectedIds}
          addIds={addIds}
          loading={loading}
        />
      </div>
      <EngagementTable data={data} ids={selectedIds} loading={loading} />
    </div>
  );
}

interface PipelineProps {
  loading?: boolean
}

export default Pipeline
