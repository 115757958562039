import { FC } from 'react'

import DrillItem from './drillItem'
import Spin from '../../../atoms/spin'

import { usePipelineActivityDrillDownQuery } from '../../../../graphql/__generated__/graphql'
import { groupBy } from '../../../../utils/functions'

const ActivityDrill: FC<ActivityDrillProps> = ({ accountId }) => {
  const { data: activityDrill, loading: activityDrillLoading } = usePipelineActivityDrillDownQuery({
    variables: { account_id: accountId },
    fetchPolicy: 'cache-first'
  })

  let dataGroupedByContactName: any = {}
  if (activityDrill && activityDrill.pipeline_activity_drill_down_gql) {
    dataGroupedByContactName = groupBy(activityDrill.pipeline_activity_drill_down_gql, 'contact_name')
  }

  if (activityDrillLoading) {
    return (
      <div className='w-full flex justify-center items-center my-4'>
        <Spin />
      </div>
    )
  }

  return (
    <div className='w-full bg-white dark:bg-header-dark p-6'>
      <div className='flex items-center'>
        <p className='w-52'>Contact Name</p>
        <p className='w-52'>Title</p>
      </div>
      {Object.values(dataGroupedByContactName).map((item, index) => <DrillItem key={index.toString()} data={item} />)}
    </div>
  )
}

interface ActivityDrillProps {
  accountId: any
}

export default ActivityDrill
