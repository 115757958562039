import { momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import * as dates from 'date-arithmetic'

const localizer: any = momentLocalizer(moment)

const MILLI = {
  seconds: 1000,
  minutes: 1000 * 60,
  hours: 1000 * 60 * 60,
  day: 1000 * 60 * 60 * 24
}

const MONTHS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]

const firstVisibleDay = (date: any) => {
  const firstOfMonth = dates.startOf(date, 'month')
  return dates.startOf(firstOfMonth, 'week', localizer.startOfWeek())
}

const lastVisibleDay = (date: any) => {
  const endOfMonth = dates.endOf(date, 'month')
  return dates.endOf(endOfMonth, 'week', localizer.startOfWeek())
}

export const visibleDays = (date: any) => {
  let current = firstVisibleDay(date),
      last = lastVisibleDay(date),
      days = []

  while (dates.lte(current, last, 'day')) {
    days.push(current)
    current = dates.add(current, 1, 'day')
  }

  return days
}

export const visibleDaysForAgenda = (date: any) => {
  // let current = date,
  //     last = dates.add(date, 1, 'month'),
  //     days = []

  // while (dates.lt(current, last, 'day')) {
  //   days.push(current)
  //   current = dates.add(current, 1, 'day')
  // }

  // return days
  const firstOfWeek = localizer.startOfWeek()
  let start = dates.startOf(date, 'week', firstOfWeek)
  const end = dates.endOf(date, 'week', firstOfWeek)
  let days = []
  while (dates.lte(start, end, 'day')) {
    days.push(start)
    start = dates.add(start, 1, 'day')
  }

  return days
}

export const visibleDaysForWeek = (date: any) => {
  const firstOfWeek = localizer.startOfWeek()
  let start = dates.startOf(date, 'week', firstOfWeek)
  const end = dates.endOf(date, 'week', firstOfWeek)
  let days = []
  while (dates.lte(start, end, 'day')) {
    days.push(start)
    start = dates.add(start, 1, 'day')
  }

  return days
}

export const getTimeSlots = (startDate: any, endDate: any) => {
  const result: any = []
  let start = moment(startDate)
  const end = moment(endDate)
  while(start.isSameOrBefore(end)) {
    result.push(start.format('hA'))
    start = start.add(1, 'h')
  }

  return result
}

export const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

export const dayNamesForMobile = ['S', 'M', 'T', 'W', 'T', 'F', 'S']

export default MILLI
