import _ from 'lodash'

import { IcpModuleForHarriQuery } from '../../../graphql/__generated__/harri_graphql'
import { calcMedian } from '../../../utils/functions'
import { getFormatter } from './ICP.default.util'
import { ICPFilters, ICPSummary, ICPChartData, ICPTreemapChartData, TreemapChart, GeoMapData, AccountRecord } from '../../../@types/module'

type CustomAccount = {
  type2__c?: string
  concept_type__c?: string
  franchisee__c?: boolean
  franchise_name__c?: string
  locations__c?: any
  segment__c?: string
  fair_workweek__c?: boolean
  operating_structure_2__c?: string
  account_type__c?: string
}

type RevtronAccount = {
  id?: string
  name?: string
  website?: string
  billingcity?: string
  billingstate?: string
  billingcountry?: string
  annualrevenue?: any
  numberofemployees?: number
  account_to_custom_account?: CustomAccount
}

export const getIcpModuleDataForHarri = (data?: IcpModuleForHarriQuery, filters?: ICPFilters) => {
  const allData = data?.account
  let filteredData = _.cloneDeep(allData)

  // Filter by employee count
  const getFilterdDataByEmployeeCount = (sourceData: any) => {
    if (!filters?.employeeCount) {
      return sourceData
    }
    const min = filters.employeeCount[0]
    const max = filters.employeeCount[1]
    return _.chain(sourceData).filter(e => {
      const empCount = e.numberofemployees
      return (empCount || 0) >= min && (empCount || 0) <= max
    }).value()
  }

  // Filter by business age
  const getFilterdDataByBusinessAge = (sourceData: any) => {
    if (!filters?.ageOfBusiness) {
      return sourceData
    }
    const min = filters.ageOfBusiness[0]
    const max = filters.ageOfBusiness[1]
    return _.chain(sourceData).filter(e => {
      const custom_account = _.get(e, 'account_to_custom_account') as CustomAccount
      const age = Number(custom_account.locations__c)
      return age >= min && age <= max
    }).value()
  }
  
  // Filter by annaul revenue
  const getFilterdDataByAnnualRevenue = (sourceData: any) => {
    if (!filters?.annualRevenue) {
      return sourceData
    }
    const min = filters.annualRevenue[0]
    const max = filters.annualRevenue[1]
    return _.chain(sourceData).filter(e => e.annualrevenue >= min && e.annualrevenue <= max).value()
  }

  // Filter by company type
  const getFilterdDataByCompanyType = (sourceData: any) => {
    if (!filters?.companyType) {
      return sourceData
    }
    return _.chain(sourceData).filter(e => {
      const custom_account = _.get(e, 'account_to_custom_account') as CustomAccount
      return custom_account.type2__c === filters.companyType
    }).value()
  }
  
  // Filter by industry
  const getFilterdDataBySubCategory = (sourceData: any) => {
    if (!filters?.subCategory) {
      return sourceData
    }
    return _.chain(sourceData).filter(e => {
      const custom_account = _.get(e, 'account_to_custom_account') as CustomAccount
      return custom_account.concept_type__c === filters.subCategory
    }).value()
  }

  filteredData = getFilterdDataByEmployeeCount(filteredData)
  filteredData = getFilterdDataByBusinessAge(filteredData)
  filteredData = getFilterdDataByAnnualRevenue(filteredData)
  // Save data
  let filteredTreemapData = _.cloneDeep(filteredData)
  filteredData = getFilterdDataByCompanyType(filteredData)
  filteredData = getFilterdDataBySubCategory(filteredData)

  const harriAccounts = _.chain(allData).flatten().value() as RevtronAccount[]
  const filteredHarriAccounts = _.chain(filteredData).flatten().value() as RevtronAccount[]
  const filteredHarriAccountsForTreemap = _.chain(filteredTreemapData).flatten().value() as RevtronAccount[]

  // Getting data for summary in ICP
  const icpScoreArr = _.chain(filteredData).map((d) => _.get(d, 'account_module_static.predct_proba', 0) * 100).value()
  const employeeCountArr = filteredHarriAccounts.map((d) => d.numberofemployees) as number[]
  const businessAgeArr = filteredHarriAccounts.map((d) => Number(d?.account_to_custom_account?.locations__c))
  const annualRevenueArr = _.chain(filteredData).map((d) => _.get(d, 'annualrevenue', 0)).value()

  const summaryData: ICPSummary = {
    businessCount: filteredHarriAccounts.length,
    averageICPScore: calcMedian(icpScoreArr),
    averageEmployeeCount: calcMedian(employeeCountArr),
    averageBusinessAge: calcMedian(businessAgeArr),
    averageAnnualRevenue: calcMedian(annualRevenueArr),
  }

  // Getting data for histogram charts in ICP
  const isFilteredByHistogram = !!filters?.employeeCount || !!filters?.ageOfBusiness || !!filters?.annualRevenue
  const revtronCompaniesForHistogram = isFilteredByHistogram ? harriAccounts : filteredHarriAccounts
  const dataForHistogram = isFilteredByHistogram ? allData : filteredData
  const maxEmployeeCount = revtronCompaniesForHistogram.reduce((max: number, d) => max < (d.numberofemployees || 0) ? (d.numberofemployees || 0) : max, 0) as number
  const maxAnnualRevenue = _.chain(dataForHistogram).reduce((max: number, d) => max < d.annualrevenue ? d.annualrevenue : max, 0).value()
  const numberOfEmployees = revtronCompaniesForHistogram.map(d => (d.numberofemployees || 0) / getFormatter(maxEmployeeCount).unitValue)
  const meanNumberOfEmployees = _.mean(numberOfEmployees)
  const annualRevenue = _.chain(dataForHistogram).map(d => d.annualrevenue / getFormatter(maxAnnualRevenue).unitValue).value()
  const meanAnnualRevenue = _.mean(annualRevenue)
  const ageOfBusiness = revtronCompaniesForHistogram.map(d => Number(d?.account_to_custom_account?.locations__c))
  const meanAgeOfBusiness = _.mean(ageOfBusiness)
  const chartData: ICPChartData = {
    maxEmployeeCountFormatter: getFormatter(maxEmployeeCount).formatter,
    employeeCountUnit: getFormatter(maxEmployeeCount).unitValue,
    maxAnnualRevenueFormatter: getFormatter(maxAnnualRevenue).formatter,
    annualRevenueUnit: getFormatter(maxAnnualRevenue).unitValue,
    ageOfBusiness: ageOfBusiness.filter(d => d < meanAgeOfBusiness),
    numberOfEmployees: numberOfEmployees.filter(d => d < meanNumberOfEmployees),
    annualRevenue: annualRevenue.filter(d => d < meanAnnualRevenue),
  }

  // Getting data for treemap chart in ICP
  const isFilteredByCategoryTreemap = !!filters?.subCategory
  const harriAccountsForCategoryTreemap = isFilteredByCategoryTreemap ? filteredHarriAccountsForTreemap : filteredHarriAccounts
  const categories = _.uniq(harriAccountsForCategoryTreemap.map(d => d?.account_to_custom_account?.concept_type__c))
  const industryData: ICPTreemapChartData = {
    name: 'industry',
    children: categories.map(cat => ({
      name: cat,
      value: harriAccountsForCategoryTreemap.filter(r => r.account_to_custom_account?.concept_type__c === cat).length
    })).sort((a: TreemapChart, b: TreemapChart) => b.value - a.value)
  }
  const isFilteredByCompanyTypeTreemap = !!filters?.companyType
  const harriAccountsForCompanyTypeTreemap = isFilteredByCompanyTypeTreemap ? filteredHarriAccountsForTreemap : filteredHarriAccounts
  const companyTypes = _.uniq(harriAccountsForCompanyTypeTreemap.map(d => d.account_to_custom_account?.type2__c))
  const companyTypesData: ICPTreemapChartData = {
    name: 'industry',
    children: companyTypes.map(type => ({
      name: type,
      value: harriAccountsForCompanyTypeTreemap.filter(r => r.account_to_custom_account?.type2__c === type).length
    })).sort((a: TreemapChart, b: TreemapChart) => b.value - a.value)
  }
  const treemapData = {
    industry: industryData,
    companyType: companyTypesData
  }

  // Getting data for geo map
  const dataGroupByState = _.groupBy(allData as RevtronAccount[], (d) => d.billingstate)
  const geoMapData: GeoMapData = {
    us: [],
    world: []
  }
  Object.keys(dataGroupByState).forEach((state) => {
    let companies = 0
    let employees = 0
    let amount = 0
    dataGroupByState[state].forEach((d: RevtronAccount) => {
      companies += 1
      employees += d.numberofemployees || 0
      amount += d.annualrevenue || 0
    })
    geoMapData.us.push({location: state, companies, employees, amount})
  })

  let accounts = data ? data.account as RevtronAccount[] : []

  // Getting data for detail table in ICP
  let bussinessDetails: AccountRecord[] = _.chain(filteredData).map(e => {
    const custom_account = _.get(e, 'account_to_custom_account') as CustomAccount
    return {
      uuid: e.id,
      annualRevenue: e.annualrevenue as number,
      name: e.name as string,
      domain: e.website as string,
      city: e.billingcity as string,
      empCount: e.numberofemployees as number,
      predictProba: _.get(e, 'account_module_static.predct_proba', 0),
      companyType: custom_account.type2__c as string,
      state: e.billingstate as string,
      country: e.billingcountry as string,
      foundingYear: custom_account.locations__c,
      industry: custom_account.concept_type__c as string,
      specialities: custom_account.franchise_name__c as string,
    }
  }).value()

  return { summaryData, chartData, treemapData, geoMapData, accounts, bussinessDetails }
}
