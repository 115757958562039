import { FC, useState } from 'react'

import Spin from '../../atoms/spin'
import MultipleSelect from '../../molecules/multipleSelect'
import SearchInput from '../../molecules/searchInput'

import {
  useAccountOwnerListQuery,
  useAccountCustomStatusListQuery,
} from '../../../graphql/__generated__/graphql'

const Categories: FC<CategoriesProps> = ({ filters, setFilters }) => {
  const [accountName, setAccountName] = useState<any>((filters.name || ''))

  const { data: ownersGql, loading: ownerLoading } = useAccountOwnerListQuery({
    fetchPolicy: 'cache-first'
  })
  const { data: customstatusGql, loading: statusLoading } = useAccountCustomStatusListQuery({
    fetchPolicy: 'cache-first'
  })

  let owners: any[] = []
  let statuses: any[] = []

  if (ownersGql && ownersGql.account_module_static) {
    owners = ownersGql.account_module_static.map((acc: any) => ({ value: acc.account_owner, label: acc.account_owner }))
  }
  if (customstatusGql && customstatusGql.account_module_static) {
    statuses = customstatusGql.account_module_static.map((acc: any) => ({ value: acc.account_status_auto__c, label: acc.account_status_auto__c }))
  }

  if (ownerLoading || statusLoading) {
    return (
      <div className='w-full flex justify-center items-center my-4'>
        <Spin />
      </div>
    )
  }

  return (
    <div className='account-categories-container'>
      <div className='w-full flex flex-col lg:flex-row'>
        <SearchInput
          className='w-full p-2 my-1'
          value={accountName}
          onChange={(e) => setAccountName(e.target.value)}
          onBlur={() => setFilters({ ...filters, name: accountName })}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              setFilters({ ...filters, name: accountName })
            }
          }}
          placeholder='Account Name'
        />
        <MultipleSelect
          className='w-full ml-3'
          value={filters.owner}
          onChange={(value) => setFilters({ ...filters, owner: value })}
          options={owners}
          placeholder='Account Owner'
        />
        <MultipleSelect
          className='w-full ml-3'
          value={filters.status}
          onChange={(value: any[]) => setFilters({ ...filters, status: value })}
          options={statuses}
          placeholder='Account Status'
        />
      </div>
    </div>
  )
}

interface CategoriesProps {
  filters: any
  setFilters: (val: any) => void

}

export default Categories
