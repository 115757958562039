import { NhostClient } from '@nhost/react'

import { SubDomains, DefaultDomainConfig } from './variable'

const suburl = window.location.host.split('.')[0]

const { subdomain, region } = SubDomains[suburl] || DefaultDomainConfig

const nhost = new NhostClient({ subdomain, region })
const storage = nhost.storage

export { nhost, storage }
