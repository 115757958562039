import { FC, useState } from 'react'
import { UserAddIcon } from '@heroicons/react/outline'
import TextField from '../../molecules/textField'

import { useUserData } from '@nhost/react'
import { useCreateInviteMutation } from '../../../graphql/__generated__/graphql'

import { alert } from '../../../utils/functions'

const InviteMember: FC = () => {
  const user = useUserData()
  const [createInvite, { data, loading, error }] = useCreateInviteMutation()
  const [email, setEmail] = useState('')

  const onInviteUser = async () => {
    if (user && email) {
      createInvite({
        variables: {
          invitee_email: email,
          inviter_id: user.id
        }
      }).then(result => {
        console.log('Invite sent to new user!', result)
        alert('success', 'Successful in inviting new user!')
        setEmail('')
      }).catch(err => {
        console.error('Error inviting new user', err)
        alert('error', 'Failed in inviting new user.')
        setEmail('')
      })
    }
  }

  return (
    <div className="flex-1 rounded-lg bg-white dark:bg-header-dark dark:border-gray-500 shadow z-10">
      <div className='px-4 py-6 border-b border-gray-300 dark:border-neutral-700'>
        <div className='flex justify-between items-center mb-2'>
          <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-200">Invite new user</h3>
        </div>
        <div className="text-sm font-medium leading-6 text-gray-500 dark:text-gray-400">
          Invite a new user to your team, enter their email and they will receive an invite to join your instance of RevTron.
        </div>
      </div>
      <div className="px-4 py-6 dark:border-neutral-700">
        <TextField
          className='mb-6'
          label="E-mail"
          placeholder="Example denis@e-mail.com"
          type="text"
          value={email}
          onChange={(value: any) => setEmail(value)} id={''} name={''}
        />
        <button
          type="button"
          className="w-full inline-flex justify-center items-center rounded-md border border-transparent bg-cyan-400 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-cyan-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
          onClick={onInviteUser}
        >
          <UserAddIcon
            className="mr-1 h-4 w-4 text-gray-900"
            aria-hidden="true"
          />
          Send an invite
        </button>
      </div>
    </div>
  )
}

export default InviteMember
