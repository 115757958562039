import React, { FC, useContext } from 'react'
import { MoonIcon } from '@heroicons/react/solid'

import { ThemeContext } from '../../layouts/themeContext'

import DarkLogo from '../../assets/images/dark-logo.svg'
import LightLogo from '../../assets/images/light-logo.svg'

const AuthWrapper: FC<AuthWrapperProps> = ({ children }) => {
  const { theme, setTheme } = useContext<any>(ThemeContext)

  return (
    <div className='auth-wrapper h-screen relative dark:bg-revtron-dark1 bg-auth-center-light dark:bg-auth-center-dark bg-no-repeat bg-center bg-contain flex flex-col items-center'>
      <button
        onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
        className='absolute top-4 right-12 shadow-md bg-revtron-gray1 dark:bg-header-dark w-9 h-9 rounded-2xl flex justify-center items-center'
      >
        <MoonIcon className="w-5 h-5 dark:text-revtron-green" aria-hidden="true" />
      </button>
      <div className='revtron-logo flex justify-center items-center pt-20'>
        <img src={DarkLogo} className='hidden dark:block' alt='' />
        <img src={LightLogo} className='dark:hidden' alt='' />
      </div>
      <div className='self-stretch flex-1'>
        {children}
      </div>
      <div className='bottom-section w-full'>
        <div className='flex justify-between items-center border-t border-theme-toggle-white-bg dark:border-header-dark px-28 py-16'>
          <div className=''>
            <img src={DarkLogo} className='hidden dark:block' alt='' />
            <img src={LightLogo} className='dark:hidden' alt='' />
            <p className='text-revtron-dark4 dark:text-theme-toggle-icon'>
              Step into the next era of GTM
            </p>
          </div>
          <div className='flex items-center'>
            <div className='w-8 h-8 rounded-2xl bg-header-dark flex justify-center items-center'>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#05D9E8" className="w-4 h-4">
                <path d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z" />
                <path d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z" />
              </svg>
            </div>
            <div className='ml-2'>
              <p className='text-chart-tooltip-text dark:text-revtron-gray1 font-bold'>
                E-mail
              </p>
              <p className='text-revtron-dark4 dark:text-theme-toggle-icon'>
                operations@revtron.ai
              </p>
            </div>
          </div>
        </div>
        <div className='flex justify-between items-center border-t border-theme-toggle-white-bg dark:border-header-dark px-28 py-6'>
          <p className='text-theme-toggle-icon dark:text-revtron-dark4'>
            Copyright &copy; {new Date().getFullYear()} RevTron, Inc. All rights reserved.
          </p>
          <div className='text-theme-toggle-icon dark:text-revtron-dark4'>
            <div className="flex gap-x-6">
              <a href="https://revtron-terms.s3.amazonaws.com/RevTron+-+Master+Services+Agreement.pdf" target="_blank" rel="noopener noreferrer">
                MSA ~ Terms
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

interface AuthWrapperProps {
  children: any
}

export default AuthWrapper
