import { Fragment, FC, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import * as dates from 'date-arithmetic'
import moment from 'moment'

import EventViewDialog from '../../organisms/calendar/eventViewDialog'

import { getDynamicHexColor } from '../../../utils/functions'

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const MonthView: FC<MonthViewProps> = ({ currentDate, setCurrentDate, days, events }) => {
  const [isOpenModal, setOpenModal] = useState<Boolean>(false)
  const [selectedEventDetail, setEventDetail] = useState<any>(null)

  const arrangeDays = () => {
    const currentMonth = dates.month(currentDate)
    if (days && days.length > 0) {
      return days.map((day: any) => {
        let eventsData: any[] = []
        events && events.length > 0 && events.forEach((event: any) => {
          if (moment(day).format('YYYY-MM-DD') === moment(new Date(event.start)).format('YYYY-MM-DD')) {
            eventsData.push({
              id: event.meeting_key,
              name: event.title,
              time: moment(event.startdatetime).format('HHH'),
              datetime: event.startdatetime,
              bgColor: getDynamicHexColor(event),
              event: event
            })
          }
        })
        return {
          date: moment(day).format('YYYY-MM-DD'),
          isCurrentMonth: currentMonth === dates.month(new Date(day)),
          events: eventsData
        }
      })
    }
    return []
  }

  const handleSelect = (event: any) => {
    setOpenModal(true)
    setEventDetail(event)
  }

  return (
    <div className="lg:flex lg:h-full lg:flex-col">
      <div className="shadow ring-1 ring-black ring-opacity-5 lg:flex lg:flex-auto lg:flex-col">
        <div className="grid grid-cols-7 gap-px border-b border-gray-300 dark:border-revtron-dark3 bg-gray-200 dark:bg-revtron-dark3 text-center text-xs font-semibold leading-6 text-gray-700 lg:flex-none">
          <div className="dark:text-gray-400 bg-white dark:bg-header-dark py-2">
            S<span className="sr-only sm:not-sr-only">un</span>
          </div>
          <div className="dark:text-gray-400 bg-white dark:bg-header-dark py-2">
            M<span className="sr-only sm:not-sr-only">on</span>
          </div>
          <div className="dark:text-gray-400 bg-white dark:bg-header-dark py-2">
            T<span className="sr-only sm:not-sr-only">ue</span>
          </div>
          <div className="dark:text-gray-400 bg-white dark:bg-header-dark py-2">
            W<span className="sr-only sm:not-sr-only">ed</span>
          </div>
          <div className="dark:text-gray-400 bg-white dark:bg-header-dark py-2">
            T<span className="sr-only sm:not-sr-only">hu</span>
          </div>
          <div className="dark:text-gray-400 bg-white dark:bg-header-dark py-2">
            F<span className="sr-only sm:not-sr-only">ri</span>
          </div>
          <div className="dark:text-gray-400 bg-white dark:bg-header-dark py-2">
            S<span className="sr-only sm:not-sr-only">at</span>
          </div>
        </div>
        <div className="flex bg-gray-200 dark:bg-revtron-dark3 text-xs leading-6 text-gray-700 lg:flex-auto">
          <div className="hidden w-full lg:grid lg:grid-cols-7 lg:gap-px">
            {arrangeDays().map((day: any) => {
              return (
                <div
                  key={day.date}
                  className={classNames(
                    day.isCurrentMonth ? 'bg-white dark:bg-header-dark' : 'bg-gray-50 dark:bg-header-dark text-gray-500',
                    'relative py-2 px-3 h-32'
                  )}
                >
                  <time
                    dateTime={day.date}
                    className={
                      day.isToday
                        ? 'flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 font-semibold text-white dark:text-gray-300'
                        : 'dark:text-gray-300'
                    }
                  >
                    {day.date.split('-').pop().replace(/^0/, '')}
                  </time>
                  {day.events.length > 0 && (
                    <ol className="mt-2">
                      {day.events.slice(0, 2).map((event: any) => (
                        <li key={event.id} style={event.bgColor.style} className='rounded p-1'>
                          <a className="group flex" onClick={() => handleSelect(event.event)}>
                            <p className="flex-auto truncate font-medium group-hover:text-indigo-600 text-white">
                              {event.name}
                            </p>
                            <time
                              dateTime={event.datetime}
                              className="ml-3 hidden flex-none group-hover:text-indigo-600 xl:block text-white"
                            >
                              {event.time}
                            </time>
                          </a>
                        </li>
                      ))}
                      {day.events.length > 2 && (
                        <li
                          className="text-gray-500 dark:text-gray-300 hover:cursor-pointer"
                          onClick={() => setCurrentDate(new Date(day.date.replace(/-/g, '\/')))}
                        >
                          + {day.events.length - 2} more
                        </li>
                      )}
                    </ol>
                  )}
                </div>
              )
            })}
          </div>
        </div>
        <EventViewDialog
          isOpen={isOpenModal}
          setIsOpen={setOpenModal}
          event={selectedEventDetail}
        />
      </div>
    </div>
  )
}

interface MonthViewProps {
  days: any[]
  currentDate: any
  setCurrentDate: (date: any) => void
  events: any[]
}

export default MonthView
