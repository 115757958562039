import { UserIcon, OfficeBuildingIcon, RssIcon, GlobeAltIcon } from '@heroicons/react/outline'
import { CrmAccountFieldsQuery, CrmContactFieldsQuery } from '../../graphql/__generated__/graphql'
import _ from 'lodash'
import { Tabs } from '../../@types/common/Tab'
import { DropdownOption } from '../../@types/common/Dropdown'

export enum SettingTabNameEnum {
  USERS_TEAMS = 'Users & Teams',
  INTEGRATIONS = 'Integrations',
  ACCOUNT_ENRICHMENT = 'Account Enrichment',
  CONTACT_ENRICHMENT = 'Contact Enrichment'
}

export enum FieldMappingType {
  ACCOUNT = 'Account',
  CONTACT = 'Contact'
}

export type FieldMapping = {
  id: number
  crm_field: string
  revtron_field: string
  is_active: boolean
  method: string
  isNew: boolean
}

export const SettingTabs: Tabs[] = [
  { name: SettingTabNameEnum.USERS_TEAMS, icon: UserIcon },
  { name: SettingTabNameEnum.INTEGRATIONS, icon: RssIcon },
  { name: SettingTabNameEnum.ACCOUNT_ENRICHMENT, icon: OfficeBuildingIcon },
  { name: SettingTabNameEnum.CONTACT_ENRICHMENT, icon: GlobeAltIcon },
]

export const ACTION_OPTIONS = [
  {
    label: 'Fill',
    value: 'Fill',
  },
  {
    label: 'Overwrite',
    value: 'Overwrite',
  }
]

export type DataConnectionActionType = {
  title: string,
  subtitle: string,
  titleForeground: string,
  href: string,
  icon: (props: React.ComponentProps<'svg'>) => JSX.Element,
  iconForeground: string,
  iconBackground: string,
}

export const getCrmAccountFields = (data?: CrmAccountFieldsQuery) => {
  const crmFields: DropdownOption[] = 
    _.chain(data?.account?.fields).filter(field => field.type.kind === 'SCALAR').map(field => ({ label: field.name, value: field.name })).value()
  const revtronFields: DropdownOption[] =
    _.chain(data?.revtronCompanies?.fields).filter(field => field.type.kind === 'SCALAR').map(field => ({ label: field.name, value: field.name })).value()

  return { crmFields, revtronFields }
}

export const getCrmContactFields = (data?: CrmContactFieldsQuery) => {
  const crmFields: DropdownOption[] = 
    _.chain(data?.contact?.fields).filter(field => field.type.kind === 'SCALAR').map(field => ({ label: field.name, value: field.name })).value()
  const revtronFields: DropdownOption[] =
    _.chain(data?.pdlPeopleData?.fields).filter(field => field.type.kind === 'SCALAR').map(field => ({ label: field.name, value: field.name })).value()

  return { crmFields, revtronFields }
}
