import { FC } from 'react'
import TreemapChart from './treemapChart'
import { ICPTreemapData, ICPFilters } from '../../../@types/module'
interface IndustryMapProps {
  data: ICPTreemapData
  filters: ICPFilters
  setFilters: Function
}

const IndustryMap: FC<IndustryMapProps> = ({ data, filters, setFilters }) => {
  return (
    <div className="grid grid-cols-1 xl:grid-cols-3 grid-flow-row gap-5">
      <div className="">
        <TreemapChart
          shape="portrait"
          data={data.companyType}
          filterField="companyType"
          filters={filters}
          setFilters={setFilters}
          filterEnabled={!filters.subCategory} // company type treemap can be filterable when category treemap is not filtered
        />
      </div>
      <div className="col-span-2">
        <TreemapChart
          shape="landscape"
          data={data.industry}
          filterField="subCategory"
          filters={filters}
          setFilters={setFilters}
          filterEnabled={!filters.companyType} // category treemap can be filterable when company type treemap is not filtered
        />
      </div>
    </div>
  )
}

export default IndustryMap
