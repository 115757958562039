import { FC, useState, useCallback, useEffect, memo } from 'react'
import moment, { Moment } from 'moment'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { DateRangePickerProps } from '../../../@types/common/DatePicker'

const DailyPicker: FC<DateRangePickerProps> = ({ dateRange, onDateRangeChange }) => {
  const [month, setMonth] = useState(moment().startOf('month'))
  const [hoverValue, setHoverValue] = useState<Moment | null>(null)
  let startDate = moment(month).startOf('month').startOf('week')
  const endDate = moment(month).endOf('month').endOf('week')
  const days = []
  while(moment(startDate).isSameOrBefore(moment(endDate))) {
    days.push(startDate)
    startDate = moment(startDate).add(1, 'day')
  }

  useEffect(() => {
    setMonth(moment(dateRange.end).startOf('month'))
  }, [dateRange.end])

  const setStateHandler = useCallback((date: Moment) => {
    if (moment(dateRange.start).isSame(moment(dateRange.end))) {
      if (moment(dateRange.start).isAfter(moment(date))) {
        // set startDate with the selected month
        onDateRangeChange({
          start: moment(date),
          end: dateRange.end
        })
      } else {
        // set endDate with the selected month
        onDateRangeChange({
          start: dateRange.start,
          end: moment(date)
        })
      }
    } else {
      // set start and end dates with the selected month
      onDateRangeChange({
        start: moment(date),
        end: moment(date)
      })
    }
  }, [dateRange.start, dateRange.end, onDateRangeChange])

  return (
    <div className="text-center lg:col-start-8 lg:col-end-13 lg:row-start-1 xl:col-start-9">
      <div className="flex items-center text-gray-900">
        <button
          type="button"
          className="flex flex-none items-center justify-center border border-table-text dark:border-revtron-gray2 rounded p-1.5 text-gray-400 hover:text-gray-500"
          onClick={() => setMonth(moment(month).subtract(1, 'month'))}
        >
          <span className="sr-only">Previous month</span>
          <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
        </button>
        <div className="flex-auto text-base text-black dark:text-white">{month.format('MMMM')}</div>
        <button
          type="button"
          className="flex flex-none items-center justify-center border border-table-text dark:border-revtron-gray2 rounded p-1.5 text-gray-400 hover:text-gray-500"
          onClick={() => setMonth(moment(month).add(1, 'month'))}
        >
          <span className="sr-only">Next month</span>
          <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="mt-3 grid grid-cols-7 text-xs leading-6 text-gray-500">
        <div>Mo</div>
        <div>Tu</div>
        <div>We</div>
        <div>Th</div>
        <div>Fr</div>
        <div>Sa</div>
        <div>Su</div>
      </div>
      <div className="mt-2 grid grid-cols-7 text-sm gap-1">
        {days.map((day, index) => {
          const isToday = moment(day).isSame(moment().startOf('day'))
          const isCurrentMonth = moment(month).month() === moment(day).month()
          const isSelected = moment(day).isSameOrBefore(moment(dateRange.end)) && moment(day).isSameOrAfter(moment(dateRange.start))
          let isSelectedByHover = false
          if (moment(dateRange.start).isSame(moment(dateRange.end)) && hoverValue !== null) {
            if (moment(hoverValue).isBefore(dateRange.start)) {
              isSelectedByHover = moment(day).isBefore(moment(dateRange.end)) && moment(day).isAfter(moment(hoverValue))
            } else {
              isSelectedByHover = moment(day).isBefore(moment(hoverValue)) && moment(day).isAfter(moment(dateRange.start))
            }
          }
          return (
            <button
              key={index}
              type="button"
              className={`
                py-1.5 hover:bg-gray-100 dark:hover:bg-revtron-gray2 focus:z-10 rounded
                ${isCurrentMonth ? 'text-gray-900 dark:text-white' : 'text-gray-400'}
                ${isToday && 'bg-gray-200 dark:bg-gray-500'}
                ${isCurrentMonth && isSelected && 'bg-cyan-200 dark:bg-cyan-500'}
                ${isSelectedByHover && 'bg-cyan-50 dark:bg-cyan-800'}
              `}
              onClick={() => setStateHandler(day)}
              onMouseEnter={() => setHoverValue(day)}
              onMouseLeave={() => setHoverValue(null)}
            >
              <div className='mx-auto flex w-5 h-5 items-center justify-center rounded-full'>
                {moment(day).date()}
              </div>
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default memo(DailyPicker)
