import { FC, useEffect, useState } from 'react'
import * as d3 from 'd3'
import moment from 'moment'
import Spin from '../../atoms/spin'

import {
  useMonthlyEngagementNewQuery,
  useWeeklyEngagementNewQuery
} from '../../../graphql/__generated__/graphql'
import { numberWithCommas } from '../../../utils/functions'
import { useFeatureContext } from '../../../hooks/featureContext'

const typeColors: any = {
  'Rep Email Opens': '#703c8f',
  'Rep Email Replies': '#AB56CE',
  'Rep Answered Phone Calls': '#e9729a',
  'Rep Inbound Calls': '#f7b5d9',
  'Content Opens ': '#0eaabb',
  'Content Clicks ': '#c3f7fe',
  'Form Submissions': '#5aecd4'
}

const EngagementOverChart: FC<{ viewType: string }> = ({ viewType }) => {
  const { isHarry } = useFeatureContext()
  const [selectedType, selectType] = useState('')
  const { data: monthlyData, loading: monthlyLoading } = useMonthlyEngagementNewQuery({
    skip: viewType !== 'monthly'
  })
  const { data: weeklyData, loading: weeklyLoading } = useWeeklyEngagementNewQuery({
    skip: viewType !== 'weekly'
  })

  const months: any[] = []
  let jan = Date.parse(moment().startOf('year') as any)
  if (isHarry) {
    jan = Date.parse(moment().startOf('year').add(1, 'month') as any)
  }
  months.push(jan)
  for (let i = 1; i <= 11; i++) {
    months.push(Date.parse(moment(jan).add(i, 'month') as any))
  }

  const weeks: any[] = []
  let startYearDate = Date.parse(moment().startOf('year') as any)
  if (isHarry) {
    startYearDate = Date.parse(moment().startOf('year').month(1) as any)
  }
  const startWeekDate = Date.parse(moment().startOf('week').add(1, 'days') as any)
  let loopDate = startWeekDate
  while (loopDate > startYearDate) {
    weeks.push(loopDate)
    loopDate = Date.parse(moment(loopDate).subtract(1, 'weeks') as any)
  }

  const getBarData = () => {
    let engagemets: any[] = []
    const resultData: any[] = []
    if (viewType === 'monthly') {
      engagemets = monthlyData ? monthlyData.monthly_engagement_breakdown_overview_gql : []
    } else {
      engagemets = weeklyData ? weeklyData.weekly_engagement_breakdown_overview_gql : []
    }

    engagemets.forEach(effort => {
      resultData.push({
        x: Date.parse(moment(effort.date_trunc).format('MM/DD/YYYY')),
        y: effort.count,
        count: effort.count,
        type_category: effort.type_category,
        type_detail: effort.type_detail,
        meeting_key: effort.meeting_key
      })
    })
    if (viewType === 'weekly') {
      const availableWeeks = resultData.map(d => d.x)
      weeks.forEach(week => {
        if (availableWeeks.indexOf(week) === -1) {
          resultData.push({
            x: week,
            y: 0
          })
        }
      })
    }
    if (viewType === 'monthly') {
      const availableMonths = resultData.map(d => d.x)
      months.forEach(month => {
        if (availableMonths.indexOf(month) === -1) {
          resultData.push({
            x: month,
            y: 0
          })
        }
      })
    }
    resultData.sort((a, b) => a.x - b.x)

    return resultData
  }

  
  const chartData = getBarData()

  let typeDetails = [
    ...new Set(chartData.map((d) => d.type_detail)) as any,
  ]
  typeDetails = typeDetails.filter(el => el) // remove null or undefined

  const onSelectType = (type: string) => {
    selectType(selectedType === type ? '' : type)
  }

  const TypeDetails = () => {
    return (
      <div className='flex justify-end flex-wrap mt-6'>
        {typeDetails.map((type, index) => {
          let opacity = 1
          if (selectedType) {
            opacity = selectedType === type ? 1 : 0.3
          }
          return (
            <div key={index} className='flex items-center cursor-pointer ml-3 my-1' style={{ opacity }} onClick={() => onSelectType(type)}>
              <div className='w-2.5 h-2.5 rounded-full bg-black' style={{background: typeColors[type]}}></div>
              <div className='text-xxs ml-1'>{type}</div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className='engagement-over-container'>
      <div className="overflow-hidden rounded-lg bg-white dark:bg-header-dark dark:border-gray-500 px-4 py-5 shadow sm:p-6 mb-6">
        <div className='text-sm dark:text-theme-toggle-icon'>Engagement Over Time</div>
        {(monthlyLoading || weeklyLoading) ? (
          <div className='flex justify-center'>
            <Spin />
          </div>
        ) : (
          <>
            <TypeDetails />
            <Chart viewType={viewType} data={chartData} selectedType={selectedType} selectType={selectType} />
          </>
        )}
      </div>
    </div>
  )
}

const Chart: FC<{ data: any[]; viewType: string; selectedType: string; selectType: Function; }> = ({
  data,
  viewType,
  selectedType,
  selectType
}) => {
  const width = 995
  const height = 200
  const left = 50
  const right = 20
  const top = 20
  const bottom = 30
  const chartWidth = width - left - right
  const chartHeight = height - top - bottom

  useEffect(() => {
    draw()
  })

  const draw = () => {
    d3.select('.engagement-over-chart').select('svg').remove()
    d3.select('.engagement-over-chart').selectAll('.tooltip').remove()

    const svg = d3
      .select('.engagement-over-chart')
      .append('svg')
      .attr('class', 'max-h-64')
      .attr('viewBox', `0 0 ${chartWidth + left + right} ${chartHeight + top + bottom}`)
      .attr('width', '100%')
      .attr('preserveAspectRatio', 'xMidYMin')
      .on('click', function (e) {
        if (e.target.nodeName !== 'text') {
          // d3.selectAll('.engagement-type-detail')
          //   .attr('opacity', 1)
          //   .classed('active', false)
        }
      })
      .append('g')
      .attr('transform', `translate(${left},${top})`)

    const dataByDate = d3.group(data, (d) => d.x)

    const transformedData = []
      for (const [date, originalRows] of dataByDate as any) {
        const row: any = { date }
        for (const d of originalRows) {
          row[d.type_detail] = d.y
          row[`${d.type_detail} Detail`] = d
        }
        transformedData.push(row)
      }

    const xValue = (d: any) => d.x

    const valueDomain = [...new Set(data.map((d) => xValue(d))) as any]
    let typeDetails = [
      ...new Set(data.map((d) => d.type_detail)) as any,
    ]
    typeDetails = typeDetails.filter(el => el) // remove null or undefined
    const stackedData = d3.stack().keys(typeDetails)(
      transformedData
    )
    let maxValue = 10;
    if (data.length > 0) {
      maxValue = Math.max(...stackedData.map(stackedItem => {
        return Math.max(...stackedItem.map(item => {
          return isNaN(item[1]) ? 0 : item[1]
        }))
      }))
    }
    maxValue = maxValue <= 0 ? 10 : maxValue;

    const xScale = d3.scaleBand()
      .domain(valueDomain)
      .range([0, chartWidth])
      .padding(3/valueDomain.length)

    svg.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0,${chartHeight})`)
      .call(
        d3.axisBottom(xScale)
          .tickFormat((x: any) => moment(x).format(viewType === 'weekly' ? 'MM/DD' : 'MMM'))
      )

    svg.select('.x-axis').select('path').remove()
    svg.select('.x-axis')
        .selectAll('g')
        .select('line')
        .remove()
    svg.select('.x-axis')
        .selectAll('g')
        .select('text')
        .style('color', '#6B7280')
        .style('display', (d, index) => (viewType === 'monthly' || index % 3 === 0) ? 'block' : 'none')

    const yScale = d3.scaleLinear()
      .domain([maxValue, 0])
      .range([0, chartHeight])
      .nice()

    svg.append("g")
      .attr("class", "grid")
      .attr("class", "xaxis")
      .call(
        d3.axisLeft(yScale)
        .ticks(5)
        .tickSize(-chartWidth)
        .tickPadding(10)
      )
    svg.select('.xaxis').select('path').remove()
    svg.select('.xaxis')
      .selectAll('line')
      .style("stroke", "#555")
      .style("stroke-width", 0.5)
      .style("opacity", 0.5)
    svg.select('.xaxis')
      .selectAll('g')
      .select('text')
      .style('color', '#6B7280')
      .style('display', d => {
        return d === 0 ? 'none' : ''
      })

    const div = d3.select(".engagement-over-chart").append("div")
      .attr("class", "tooltip bg-chart-tooltip border border-revtron-cyan")
      .style("opacity", 0)

    svg.append('g')
      .selectAll('g')
      // Enter in the stack data = loop key per key = group per group
      .data(stackedData)
      .join('g')
      .attr('fill', (d: any): any => typeColors[d.key])
      .attr("class", (d: any): any => `engagement-type-detail ${d.key.toLowerCase().replaceAll(' ', '-')}`)
      .attr('opacity', (d) => {
        const currentTypeDetail = d.key
        let opacity = 1
        if (selectedType) {
          opacity = selectedType === currentTypeDetail ? 1 : 0.1
        }
        return opacity
      })
      .selectAll('rect')
      // enter a second time = loop subgroup per subgroup to add all rectangles
      .data((d) => d)
      .join('rect')
      .attr('x', (d, i): any => xScale(d.data.date as any))
      .attr('y', (d) => yScale(isNaN(d[1]) ? 0 : d[1]))
      .attr('height', (d) => isNaN(d[1]) ? 0 : yScale(d[0]) - yScale(d[1]))
      .attr('width', xScale.bandwidth())
      .on('mouseover', function (event, d) {
        const type = Object.keys(d.data)[Object.values(d.data).indexOf(d[1] - d[0])]
        const typeData: any = d.data[`${type} Detail`]
        d3.select(this).attr('opacity', 0.75)
        div.transition()
          .duration(200)
          .style("opacity", 1)
        div.html(`
            <div class="text-chart-tooltip-text">
              <p>Engagement category: ${typeData.type_category}</p>
              <p>Engagement detail: ${typeData.type_detail}</p>
              <p>Count: ${numberWithCommas(typeData.count)}</p>
            </div>
          `)
          .style("left", (event.offsetX - 100) + "px")
          .style("top", (event.offsetY - 60) + "px")
      })
      .on('mouseout', function (d) {
        d3.select(this).attr('opacity', 1)
        div.transition()
          .duration(500)
          .style("opacity", 0)
      })
      .on('click', function (e, d) {
        const selectedTypeDetail = Object.keys(d.data)[Object.values(d.data).indexOf(d[1] - d[0])];
        selectType(selectedType === selectedTypeDetail ? '' : selectedTypeDetail)
      })
  }

  return (
    <div className="engagement-over-chart relative" />
  )
}

export default EngagementOverChart;