import { FC } from 'react'
import { Switch } from '@headlessui/react'
import { SunIcon, MoonIcon } from '@heroicons/react/outline'

const ThemeSwitchBtn: FC<ThemeSwitchBtnProps> = ({
  enabled,
  setEnabled,
  theme,
}) => {

  return (
    <Switch.Group as="div" className="flex items-center">
      <Switch
        checked={enabled}
        onChange={() => setEnabled(theme === 'dark' ? 'light' : 'dark')}
        className={`${enabled ? 'bg-black' : 'bg-theme-toggle-white-bg'}
          relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-revtron-green transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2  focus:ring-revtron-green focus:ring-offset-2`}
      >
        <span
          aria-hidden="true"
          className={`${enabled ? 'translate-x-5' : 'translate-x-0' && enabled ? 'bg-gray-100' : 'bg-white'}
            pointer-events-none relative inline-block h-5 w-5 transform rounded-full shadow ring-0 transition duration-200 ease-in-out`}
        >
          {enabled ? (
            <MoonIcon className="w-5 h-5 text-revtron-green" aria-hidden="true" />
          ) : (
            <SunIcon className="w-5 h-5 text-theme-toggle-icon" aria-hidden="true" />
          )}
        </span>
      </Switch>
    </Switch.Group>
  )
}

interface ThemeSwitchBtnProps {
  enabled: boolean
  setEnabled: (val: string) => void
  theme?: string
}

export default ThemeSwitchBtn
