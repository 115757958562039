import {
  HomeIcon,
  ChatAlt2Icon,
  FilterIcon,
  CalendarIcon,
  CurrencyDollarIcon,
  CollectionIcon,
  ZoomInIcon
} from '@heroicons/react/outline'
import ICPIcon from '../components/common/Icons/ICPIcon'
import { NavigationType } from './Layout.type'

import Overview from '../containers/overview'
import Activity from '../containers/activity'
import ActivityLookBack from '../containers/activity/ActivityLookBack'
import Pipeline from '../containers/pipeline'
import Calendar from '../containers/calendar'
import Leaderboard from '../containers/leaderboard'
import LeaderboardV1 from '../containers/leaderboard_v1'
import SalesVelocity from '../containers/leaderboard_v1/SalesVelocity'
import Accounts from '../containers/accounts'
import ICP from '../containers/icp/icp-profile'
import Prospector from '../containers/prospector'
import AccountRecommendations from '../containers/icp/account-rec'
import ContactRecommendations from '../containers/prospector/contact-rec'

export const DefaultNavigations: NavigationType[] = [
  { name: 'Overview', href: '/overview', icon: HomeIcon, component: Overview },
  { 
    name: 'Activity', 
    href: '#',
    icon: ChatAlt2Icon,
    children: [
      { name: 'Weekly Targets', href: '/activity-weekly', key:'activity_weekly', component: Activity },
      { name: 'Insights', href: '/activity-insights', key:'activity_insights', component: ActivityLookBack },
    ],
    component: null
  },
  { name: 'Pipeline', href: '/pipeline', icon: FilterIcon, component: Pipeline },
  { name: 'Calendar', href: '/calendar', icon: CalendarIcon, component: Calendar },
  { name: 'Leaderboard', href: '/leaderboard', icon: CurrencyDollarIcon, component: Leaderboard },
  { name: 'Accounts', href: '/accounts', icon: CollectionIcon, component: Accounts },
  { name: 'ICP', href: '/icp', icon: ICPIcon, component: ICP },
]

export const ProductPlanNavigations: NavigationType[] = [
  { name: 'Overview', href: '/overview', icon: HomeIcon, component: Overview },
  { 
    name: 'Activity', 
    href: '#',
    icon: ChatAlt2Icon,
    children: [
      { name: 'Weekly Targets', href: '/activity-weekly', key:'activity_weekly', component: Activity },
      { name: 'Insights', href: '/activity-insights', key:'activity_insights', component: ActivityLookBack },
    ],
    component: null,
  },
  { name: 'Pipeline', href: '/pipeline', icon: FilterIcon, component: Pipeline },
  { name: 'Calendar', href: '/calendar', icon: CalendarIcon, component: Calendar },
  {
    name: 'Leaderboard',
    href: '#',
    icon: CurrencyDollarIcon,
    children: [
      { name: 'Bookings', href: '/bookings', key:'bookings', component: LeaderboardV1 },
      { name: 'Sales Velocity', href: '/sales-velocity', key:'sales_velocity', component: SalesVelocity },
    ],
    component: null
  },
  { name: 'Accounts', href: '/accounts', icon: CollectionIcon, component: Accounts },
  { name: 'ICP', href: '/icp', icon: ICPIcon, component: ICP },
]

export const HarriNavigations: NavigationType[] = [
  { name: 'Overview', href: '/overview', icon: HomeIcon, component: Overview },
  { 
    name: 'Activity', 
    href: '#',
    icon: ChatAlt2Icon,
    children: [
      { name: 'Weekly Targets', href: '/activity-weekly', key:'activity_weekly', component: Activity },
      { name: 'Insights', href: '/activity-insights', key:'activity_insights', component: ActivityLookBack },
    ],
    component: null
  },
  { name: 'Pipeline', href: '/pipeline', icon: FilterIcon, component: Pipeline },
  { name: 'Calendar', href: '/calendar', icon: CalendarIcon, component: Calendar },
  {
    name: 'Leaderboard',
    href: '#',
    icon: CurrencyDollarIcon,
    children: [
      { name: 'Bookings', href: '/bookings', key:'bookings', component: Leaderboard },
      { name: 'Sales Velocity', href: '/sales-velocity', key:'sales_velocity', component: SalesVelocity },
    ],
    component: null
  },
  { name: 'Accounts', href: '/accounts', icon: CollectionIcon, component: Accounts },
  { name: 'ICP', href: '/icp', icon: ICPIcon, component: ICP },
]

export const XylemeNavigations: NavigationType[] = [
  { name: 'Overview', href: '/overview', icon: HomeIcon, component: Overview },
  { 
    name: 'Activity', 
    href: '#',
    icon: ChatAlt2Icon,
    children: [
      { name: 'Weekly Targets', href: '/activity-weekly', key:'activity_weekly', component: Activity },
      { name: 'Insights', href: '/activity-insights', key:'activity_insights', component: ActivityLookBack },
    ],
    component: null
  },
  { name: 'Pipeline', href: '/pipeline', icon: FilterIcon, component: Pipeline },
  { name: 'Calendar', href: '/calendar', icon: CalendarIcon, component: Calendar },
  {
    name: 'Leaderboard',
    href: '#',
    icon: CurrencyDollarIcon,
    children: [
      { name: 'Bookings', href: '/bookings', key:'bookings', component: LeaderboardV1 },
      { name: 'Sales Velocity', href: '/sales-velocity', key:'sales_velocity', component: SalesVelocity },
    ],
    component: null,
  },
  { name: 'Accounts', href: '/accounts', icon: CollectionIcon, component: Accounts },
  {
    name: 'ICP',
    href: '#',
    icon: ICPIcon,
    children: [
      { name: 'ICP Profile', href: '/icp', key:'icp_profile', component: ICP },
      { name: 'Recommendations', href: '/account-recommendations', key:'account_rec', component: AccountRecommendations },
    ],
    component: null,
  },
  { name: 'Prospector', href: '/prospector', icon: ZoomInIcon, component: Prospector }
]

export const XimaNavigations: NavigationType[] = [
  { name: 'Overview', href: '/overview', icon: HomeIcon, component: Overview },
  { 
    name: 'Activity', 
    href: '#',
    icon: ChatAlt2Icon,
    children: [
      { name: 'Weekly Targets', href: '/activity-weekly', key:'activity_weekly', component: Activity },
      { name: 'Insights', href: '/activity-insights', key:'activity_insights', component: ActivityLookBack },
    ],
    component: null
  },
  { name: 'Pipeline', href: '/pipeline', icon: FilterIcon, component: Pipeline },
  { name: 'Calendar', href: '/calendar', icon: CalendarIcon, component: Calendar },
  {
    name: 'Leaderboard',
    href: '#',
    icon: CurrencyDollarIcon,
    children: [
      { name: 'Bookings', href: '/bookings', key:'bookings', component: LeaderboardV1 },
      { name: 'Sales Velocity', href: '/sales-velocity', key:'sales_velocity', component: SalesVelocity },
    ],
    component: null,
  },
  { name: 'Accounts', href: '/accounts', icon: CollectionIcon, component: Accounts },
  {
    name: 'ICP',
    href: '#',
    icon: ICPIcon,
    children: [
      { name: 'ICP Profile', href: '/icp', key:'icp_profile', component: ICP },
      { name: 'Recommendations', href: '/account-recommendations', key:'account_rec', component: AccountRecommendations },
    ],
    component: null,
  },
  { name: 'Prospector', href: '/prospector', icon: ZoomInIcon, component: Prospector }
]

export const HrsoftNavigations: NavigationType[] = [
  { name: 'Overview', href: '/overview', icon: HomeIcon, component: Overview },
  { 
    name: 'Activity', 
    href: '#',
    icon: ChatAlt2Icon,
    children: [
      { name: 'Weekly Targets', href: '/activity-weekly', key:'activity_weekly', component: Activity },
      { name: 'Insights', href: '/activity-insights', key:'activity_insights', component: ActivityLookBack },
    ],
    component: null,
  },
  { name: 'Pipeline', href: '/pipeline', icon: FilterIcon, component: Pipeline },
  { name: 'Calendar', href: '/calendar', icon: CalendarIcon, component: Calendar },
  {
    name: 'Leaderboard',
    href: '#',
    icon: CurrencyDollarIcon,
    children: [
      { name: 'Bookings', href: '/bookings', key:'bookings', component: LeaderboardV1 },
      { name: 'Sales Velocity', href: '/sales-velocity', key:'sales_velocity', component: SalesVelocity },
    ],
    component: null,
  },
  { name: 'Accounts', href: '/accounts', icon: CollectionIcon, component: Accounts },
  {
    name: 'ICP',
    href: '#',
    icon: ICPIcon,
    children: [
      { name: 'ICP Profile', href: '/icp', key:'icp_profile', component: ICP },
      { name: 'Recommendations', href: '/account-recommendations', key:'account_rec', component: AccountRecommendations },
    ],
    component: null,
  },
  { name: 'Prospector', href: '/prospector', icon: ZoomInIcon, component: Prospector }
]

export const NewfrontNavigations: NavigationType[] = [
  { name: 'Overview', href: '/overview', icon: HomeIcon, component: Overview },
  { 
    name: 'Activity', 
    href: '#',
    icon: ChatAlt2Icon,
    children: [
      { name: 'Weekly Targets', href: '/activity-weekly', key:'activity_weekly', component: Activity },
      { name: 'Insights', href: '/activity-insights', key:'activity_insights', component: ActivityLookBack },
    ],
    component: null,
  },
  { name: 'Pipeline', href: '/pipeline', icon: FilterIcon, component: Pipeline },
  { name: 'Calendar', href: '/calendar', icon: CalendarIcon, component: Calendar },
  {
    name: 'Leaderboard',
    href: '#',
    icon: CurrencyDollarIcon,
    children: [
      { name: 'Bookings', href: '/bookings', key:'bookings', component: LeaderboardV1 },
      { name: 'Sales Velocity', href: '/sales-velocity', key:'sales_velocity', component: SalesVelocity },
    ],
    component: null,
  },
  { name: 'Accounts', href: '/accounts', icon: CollectionIcon, component: Accounts },
  {
    name: 'ICP',
    href: '#',
    icon: ICPIcon,
    children: [
      { name: 'ICP Profile', href: '/icp', key:'icp_profile', component: ICP },
      { name: 'Recommendations', href: '/account-recommendations', key:'account_rec', component: AccountRecommendations },
    ],
    component: null,
  },
  { name: 'Prospector', href: '/prospector', icon: ZoomInIcon, component: Prospector }
]

export const GigProNavigations: NavigationType[] = [
  { name: 'Overview', href: '/overview', icon: HomeIcon, component: Overview },
  { 
    name: 'Activity', 
    href: '#',
    icon: ChatAlt2Icon,
    children: [
      { name: 'Weekly Targets', href: '/activity-weekly', key:'activity_weekly', component: Activity },
      { name: 'Insights', href: '/activity-insights', key:'activity_insights', component: ActivityLookBack },
    ],
    component: null,
  },
  { name: 'Pipeline', href: '/pipeline', icon: FilterIcon, component: Pipeline },
  { name: 'Calendar', href: '/calendar', icon: CalendarIcon, component: Calendar },
  { name: 'Leaderboard', href: '/leaderboard', icon: CurrencyDollarIcon, component: Leaderboard },
  { name: 'Accounts', href: '/accounts', icon: CollectionIcon, component: Accounts },
  {
    name: 'ICP',
    href: '#',
    icon: ICPIcon,
    children: [
      { name: 'ICP Profile', href: '/icp', key:'icp_profile', component: ICP },
      { name: 'Recommendations', href: '/account-recommendations', key:'account_rec', component: AccountRecommendations },
    ],
    component: null,
  },
  {
    name: 'Prospector',
    href: '#',
    icon: ZoomInIcon,
    children: [
      { name: 'Prospector', href: '/prospector', key:'prospector', component: Prospector },
      { name: 'Recommendations', href: '/contact-recommendations', key:'contact_rec', component: ContactRecommendations },
    ],
    component: null,
  }
]

export const NFSandboxNavigations: NavigationType[] = [
  {
    name: 'ICP',
    href: '#',
    icon: ICPIcon,
    children: [
      { name: 'ICP Profile', href: '/icp', key:'icp_profile', component: ICP },
      { name: 'Recommendations', href: '/account-recommendations', key:'account_rec', component: AccountRecommendations },
    ],
    component: null,
  },
  { name: 'Prospector', href: '/prospector', icon: ZoomInIcon, component: Prospector }
]