import { FC } from 'react'
import Spin from '../atoms/spin'

const ShadowCard: FC<ShadowCardProps> = ({ label, children, loading, className = '', header = null }) => {
  return (
    <div className={`flex flex-col ${loading ? 'items-center' : ''} py-2.5 ${className || ''}`}>
      <div className='w-full mb-3.5 flex justify-between items-center'>
        <p className='label text-lg text-revtron-gray3 dark:text-revtron-gray1'>{label}</p>
        {header}
      </div>
      {loading ? <Spin /> : children}
    </div>
  )
}

interface ShadowCardProps {
  label: string
  children: any
  loading?: boolean
  className?: string
  header?: any
}

export default ShadowCard
