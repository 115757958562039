import { FC } from 'react'
import { PlusCircleIcon } from '@heroicons/react/outline'
import FieldMappingItem from '../../components/organisms/settings/fieldMappingItem'
import { useFieldMappings } from './Settings.graphql'
import Spin from '../../components/atoms/spin'
import { BorderedButton as Button } from '../../components/common/buttons'
import { FieldMappingType } from './Settings.Utils'

const Typography: FC<{ text: string }> = ({ text }) => (
  <h1 className="text-xl font-normal text-gray-900 dark:text-neutral-300">{text}</h1>
)

const ContactMapping: FC = () => {
  const {contactMappingLoading: loading, isDirty, fields, mappings, updateMapping, addNewMapping, saveMappings } = useFieldMappings(FieldMappingType.CONTACT)

  return (
    <div className="crm-entrichment px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center mb-6">
        <div className="sm:flex-auto">
          <Typography text='Set Contact Fields Mappings' />
          <h3 className="text-gray-500 dark:text-neutral-500">Choose how your Contact data is enriched.</h3>
        </div>
      </div>
      <div className="mx-auto max-w-4xl">
        <div className="flex justify-end mb-4">
          <Button title='Add Mapping' classnames="w-40" onButtonClick={addNewMapping} Icon={PlusCircleIcon} />
        </div>
        <div className="bg-white dark:bg-header-dark relative rounded-lg shadow border dark:border-header-dark">
          <div className="p-4">
            <div className="flex flex-row  justify-between items-center mb-4">
              <div className="w-72"><Typography text='RevTron Fields' /></div>
              <div className="w-[25px]" />
              <div className="w-72"><Typography text='CRM Fields' /></div>
              <div className="w-10" />
              <div className="w-32" />
              <div className="w-[25px]" />
            </div>
            {loading && <div className="absolute top-0 left-0 right-0 bottom-0 w-full z-50 overflow-hidden bg-gray-500 opacity-75 flex flex-col items-center justify-center"><Spin /></div>}
            {mappings.length === 0
              ? <h3 className="text-gray-500 dark:text-neutral-500 text-center py-4">No Mappings</h3>
              : mappings.map((mapping, index) => (
                <FieldMappingItem
                  key={String(index)}
                  mapping={mapping}
                  updateMapping={updateMapping}
                  crmFields={fields.crmFields}
                  revtronFeilds={fields.revtronFields}
                />
              ))
            }
          </div>
          <div className="w-full border-t border-theme-toggle-white-bg dark:border-revtron-dark2"></div>
          <div className="flex justify-end py-4 pr-4">
            <Button title="Save" onButtonClick={saveMappings} disabled={!isDirty}  />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactMapping
