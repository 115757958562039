import { FC } from 'react'

import EngagementDetail from '../../components/organisms/activity/engagementDetail'
import TeamActivity from '../../components/organisms/activity/teamActivity'
import EffortDetail from '../../components/organisms/activity/effortDetail'
import OppDetail from '../../components/organisms/activity/oppDetail'
import MeetingDetail from '../../components/organisms/activity/meetingDetail'

import { useWeeklyLeaderboardQuery } from '../../graphql/__generated__/graphql'

const Activity: FC<ActivityProps> = ({ loading: activityLoading, client }) => {
  const { data, loading } = useWeeklyLeaderboardQuery()

  return (
    <div className='activity'>
      <TeamActivity />
      <EngagementDetail leadData={data} leadLoading={loading} />
      <EffortDetail data={data} loading={loading} />
      <OppDetail />
      <MeetingDetail />
    </div>
  );
}

interface ActivityProps {
  loading?: boolean
  client?: any
}

export default Activity;
