import { Route, Routes, Navigate } from 'react-router-dom'

import ApolloWrapper from '../components/templates/apolloWrapper'
import Layout from './layout'
import PrivateRoute from './privateRoute'
import SignIn from '../containers/signIn'
import SignUp from '../containers/signUp'
import ResetPassword from '../containers/resetPassword'
import ChangePassword from '../containers/changePassword'
import Settings from '../containers/settings'
import Profile from '../containers/profile'
import { SubDomains, DefaultDomainConfig } from '../utils/variable'

const suburl = window.location.host.split('.')[0]
const { navigations } = SubDomains[suburl] || DefaultDomainConfig
const routes: { path: string, component: any }[]  = []
navigations.forEach(nav => {
  if (nav.children) {
    nav.children.forEach(child => {
      routes.push({
        path: child.href,
        component: child.component
      })
    })
  } else {
    routes.push({
      path: nav.href,
      component: nav.component
    })
  }
})

const LayoutContainer = () => {
  return (
    <Routes>
      <Route path='/sign-in' element={<SignIn />} />
      <Route path='/sign-up' element={<SignUp />} />
      <Route path='/reset-password' element={<ResetPassword />} />
      <Route path='/settings/change-password' element={<ChangePassword />} />
      <Route
        path='/'
        element={
          <PrivateRoute>
            <Layout navigations={navigations} />
          </PrivateRoute>
        }
      >
        {routes.map(route => (
          <Route key={route.path} path={route.path} element={<ApolloWrapper element={route.component} />} />  
        ))}
        <Route path='settings' element={<ApolloWrapper element={Settings} />} />
        <Route path='profile' element={<ApolloWrapper element={Profile} />} />
        <Route path='/' element={<Navigate replace to={routes[0].path} />} />
      </Route>
    </Routes>
  )
}

export default LayoutContainer
