import { FC, Fragment, useState, useEffect, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon, KeyIcon } from '@heroicons/react/outline'
import { PencilIcon } from '@heroicons/react/solid'

import { useUpdateUserAvatarMutation, useRemoveUserMutation } from '../../../graphql/__generated__/graphql'
import { nhost, storage } from '../../../utils/nhost'
import { alert, copyResizedImage } from '../../../utils/functions'

interface initialStateProp {
  file: any,
  dataurl: string
}
const initialState: initialStateProp = {
  file: null,
  dataurl: ''
}

const EditUserModal: FC<EditUserModalProps> = ({ user, isOpen, closeModal }) => {
  const inputFile: any = useRef(null)
  const [updateUserAvatar] = useUpdateUserAvatarMutation()
  const [removeUser] = useRemoveUserMutation()
  const [state, setState] = useState(initialState)

  const onCloseModal = () => {
    closeModal()
    setState(initialState)
  }

  const onUserImgClick = () => {
    inputFile.current.click()
  }

  const onChangeFile = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    let imageFile = e.target.files[0]

    if (imageFile) {
      const reader = new FileReader()
      reader.onload = function (e) {
        const img: any = document.createElement("img")
        img.onload = function () {
          const file = copyResizedImage(img, imageFile)
          setState({...state, file})
        }
        img.src = e.target?.result
      }
      reader.readAsDataURL(imageFile)
    }
  }

  const onSaveUser = async () => {
    if (user) {
      try {
        if (state.file) {
          const { fileMetadata } = await storage.upload({file: state.file}) // upload a file to nhost storage
          const fileId = fileMetadata ? fileMetadata.id : ''
          if (fileId) {
            const publicUrl = await storage.getPublicUrl({ fileId }) // get a public url of the file that is uploaded in storage
            await updateUserAvatar({ // update user data with public url 
              variables: {
                id: user.id,
                avatarUrl: publicUrl
              }
            })
          }
        }
        alert('success', 'Successful in updating profile!')
        onCloseModal()
      } catch {
        alert('error', 'Failed in updating profile.')
      }
    }
  }

  const onResetPassword = async () => {
    nhost.auth.resetPassword({ email: user.email, options: {redirectTo: '/profile' }})
      .then((res: any) => {
        alert('success', 'Successful in sending an email for reset password!')
      }).catch((err: any) => {
        alert('error', 'Failed in sending an email for reset password.')
      })
  }

  const onRemoveUser = async () => {
    removeUser({
      variables: { id: user.id }
    }).then((res: any) => {
      alert('success', 'Successful in removing a user!')
      onCloseModal()
    }).catch((err: any) => {
      alert('error', 'Failed in removing a user.')
    })
  }

  let filePath = ''
  if (state.file) {
    filePath = URL.createObjectURL(state.file)
  }

  if (!user) {
    return <></>
  }

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onCloseModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black dark:bg-gray-700 !bg-opacity-30" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-sm transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all dark:bg-header-dark">
                <div className='px-4 pt-6'>
                  <XIcon className='w-4 cursor-pointer dark:text-gray-200 ml-auto' onClick={onCloseModal} />
                </div>
                <div className="px-4 py-6 border-b border-gray-300 dark:border-neutral-700">
                  <div className="w-24 h-24 bg-gray-100 dark:bg-neutral-700 rounded-full overflow-hidden relative p-1 mx-auto mb-6">
                    <img 
                      className="w-full h-full object-cover rounded-full"
                      src={filePath || user.avatarUrl}
                      alt=""
                    />
                    <div
                      className='w-full flex justify-center absolute bottom-0 left-0 right-0 cursor-pointer bg-gray-300/50 dark:bg-neutral-500/50'
                      onClick={onUserImgClick}
                    >
                      <PencilIcon className='w-5 text-black dark:text-white mb-2' />
                    </div>
                    <input ref={inputFile} hidden accept="image/*" multiple type="file" onChange={onChangeFile} />
                  </div>
                  <div className='text-lg text-black dark:text-white text-center'>{user.displayName}</div>
                  <div className='text-revtron-gray3 dark:text-white text-center'>{user.crm_users.title}</div>
                  <div className='text-revtron-dark4 text-center'>{user.defaultRole}</div>
                </div>

                <div className="px-4 py-6 border-b border-gray-300 dark:border-neutral-700">
                  <div className='flex items-center mb-2'>
                    <div className='w-20 text-revtron-dark4'>email: </div>
                    <div className='text-revtron-gray3 dark:text-white'>{user.email}</div>
                  </div>
                  <div className='flex items-center'>
                    <div className='w-20 text-revtron-dark4'>status: </div>
                    {user.crm_users.isactive ? (
                      <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                        Active
                      </span>
                    ) : (
                      <span className="inline-flex rounded-full bg-gray-100 px-2 text-xs font-semibold leading-5 text-gray-800">
                        Inactive
                      </span>
                    )}
                  </div>
                </div>

                <div className="px-4 py-6">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent bg-cyan-400 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-cyan-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={onSaveUser}
                    disabled={!state.file}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="w-full inline-flex justify-center items-center rounded-md border border-transparent bg-cyan-400 px-4 py-2 mt-3 text-sm font-medium text-gray-900 hover:bg-cyan-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={onResetPassword}
                  >
                    <KeyIcon className='w-4 text-black dark:text-white mr-2' />
                    Reset Password by Email
                  </button>
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 mt-3 text-sm font-medium text-red-500 hover:bg-red-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={onRemoveUser}
                  >
                    Remove User
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

interface EditUserModalProps {
  user: any
  isOpen: boolean
  closeModal: () => void
}

export default EditUserModal
