import { useEffect, useState, useCallback } from 'react'
import { isEqual } from 'lodash'

import { getIcpModuleDataForGigPro } from './ICP.gigPro.util'
import { ICPFilters } from '../../../@types/module'

import { useIcpModuleForGigProLazyQuery, order_by } from '../../../graphql/__generated__/gigpro_graphql'

const useGraphQL = () => {
  const [filters, setFilters] = useState<ICPFilters>({})
  const [loadIcpModuleData, { data, loading }] = useIcpModuleForGigProLazyQuery()

  const changeFilters = useCallback((_filters: ICPFilters) => {
    if (!isEqual(_filters, filters)) {
      setFilters(_filters)
    }
  }, [filters])

  useEffect(() => {
    const where = {
      "isdeleted": {
        "_eq": false
      }
    }

    const orderBy = {
      "to_yelp_icp": {
        "predct_proba": order_by.desc_nulls_last
      }
    }

    if (!data) {
      loadIcpModuleData({
        variables: { where, orderBy, limit: 1000 }
      })
    }
  }, [data, loadIcpModuleData])

  return {
    filters,
    setFilters: changeFilters,
    icpModuleData: getIcpModuleDataForGigPro(data, filters),
    isLoading: loading,
  }
}

export default useGraphQL
