import { FC, useRef, useEffect, useContext, useState } from 'react'
import { cloneDeep, omit } from 'lodash'
import * as d3 from 'd3'
import { ThemeContext } from '../../../layouts/themeContext'
import { numberWithCommas } from '../../../utils/functions'
import { GeoMapData, GeoMapDataUnit, ICPFilters } from '../../../@types/module'
import usStatesJson from './us-states.json'
import worldJson from './world-110m.json'
import countryCodes from './country-codes.json'

interface GeomapProps {
  data: GeoMapData
  filters: ICPFilters
  setFilters: Function
}

export const getBackgroundColor = (theme: string, values: number[], value: number) => {
  const color2 = 240
  const color1 = 50
  const index = values.indexOf(value) + 1
  const c = color1 + (color2 - color1) / values.length * index
  return `rgb(${c}, ${c}, ${c})`
}

const Geomap: FC<GeomapProps> = ({data, filters, setFilters}) => {
  const { theme } = useContext<any>(ThemeContext)
  const ref = useRef<HTMLDivElement>(null)
  const [mapType, setMapType] = useState('world')

  useEffect(() => {
    if (mapType === 'world') {
      drawWorldMap(data.world)
    } else {
      drawUSMap(data.us)
    }
  }, [data, filters, theme, mapType])

  const drawWorldMap = (chartData: GeoMapDataUnit[]) => {
    d3.select(ref.current).select('svg').remove()
    d3.select(ref.current).selectAll('.tooltip').remove()
    d3.select(ref.current).selectAll('.summary').remove()

    const countriesData: any = cloneDeep(worldJson)
    
    const width = 960
    const height = 450
    const path: any = d3.geoPath(d3.geoEquirectangular())
    let zoomed = false

    const svg = d3.select(ref.current)
                .append("svg")
                .attr('viewBox', `0 0 ${width} ${height}`)
                .attr('width', '100%')
                .attr('height', '100%')
    
    // Append Div for tooltip to SVG
    const tooltip = d3.select(ref.current)
                .append("div")
                .attr("class", "tooltip")
                .style("opacity", 0)

    const zoom: any = d3.zoom()
      .scaleExtent([1, 8])
      .on("zoom", (event: any, d: any) =>  {
        g.style("stroke-width", 1.5 / event.transform.k + "px")
        g.attr("transform", event.transform)
      })
    
    const g = svg.append( "g" )
    const companyValues = [...new Set(chartData.map((d) => d.companies || 0)) as any].sort((a, b) => a - b)

    // Loop through each country data value
    for (let i = 0; i < chartData.length; i++) {
      // Grab Country Name
      const dataCountry = chartData[i].location

      // Grab data value 
      const companies = chartData[i].companies
      const employees = chartData[i].employees
      const amount = chartData[i].amount

      // Find the corresponding state inside the GeoJSON
      for (let j = 0; j < countriesData.features.length; j++)  {
        const code = countryCodes.find(c => c["country-code"] === countriesData.features[j].id)
        const location = code ? code.name : 'unknown'
        countriesData.features[j].properties.name = location
        if (dataCountry === location) {
          // Copy the data value into the JSON
          countriesData.features[j].properties.companies = companies
          countriesData.features[j].properties.employees = employees
          countriesData.features[j].properties.amount = amount
          break
        }
      }
    }

    g.selectAll("path")
      .data(countriesData.features)
      .enter().append("path")
        .attr("class", "stroke-gray-500 dark:stroke-gray-400")
        .attr("stroke-width", 0.5)
        .attr("stroke-linejoin", "round")
        .attr("d", path)
        .attr("opacity", 0.7)
        .style("fill", function(d: any) {
          const value = d.properties.companies || 0
          return getBackgroundColor(theme, companyValues, value)
        })
        .on('mouseover', function (event, d: any) {
          d3.select(this).attr('opacity', 1)
          tooltip.transition()
            .duration(200)
            .style("opacity", 1)
          tooltip.html(`
            <div class="bg-white border text-sm p-2">
              <div>
                <p class="text-xxs text-gray-500">Country</p>
                <p>${d.properties.name}</p>
              </div>
              <div class="mt-1">
                <p class="text-xxs text-gray-500">Companies</p>
                <p>${d.properties.companies || 0}</p>
              </div>
              <div class="mt-1">
                <p class="text-xxs text-gray-500">Employees</p>
                <p>${numberWithCommas(d.properties.employees)}</p>
              </div>
              <div class="mt-1">
                <p class="text-xxs text-gray-500">Annual Revenue</p>
                <p>$${numberWithCommas(d.properties.amount)}</p>
              </div>
            </div>
          `)
          .style("left", (event.offsetX) + "px")
          .style("top", (event.offsetY-100) + "px")
        })
        .on('mouseout', function (e, d) {
          d3.select(this).attr('opacity', 0.7)
          tooltip.transition().duration(500).style("opacity", 0)
        })
        .on("click", (event: any, d: any) => {
          if (d.id === '840') { // if country is US
            setMapType('us')
            return
          }
          if (zoomed) {
            zoomed = false
            svg.transition()
              .duration(750)
              .call( zoom.transform, d3.zoomIdentity )
            return
          }
          zoomed = true
          const bounds = path.bounds(d),
              dx = bounds[1][0] - bounds[0][0],
              dy = bounds[1][1] - bounds[0][1],
              x = (bounds[0][0] + bounds[1][0]) / 2,
              y = (bounds[0][1] + bounds[1][1]) / 2,
              scale = Math.max(1, Math.min(8, 0.9 / Math.max(dx / width, dy / height))),
              translate = [width / 2 - scale * x, height / 2 - scale * y]
          svg.transition()
              .duration(750)
              .call( zoom.transform, d3.zoomIdentity.translate(translate[0],translate[1]).scale(scale) )
        })
  }

  const drawUSMap = (chartData: GeoMapDataUnit[]) => {
    d3.select(ref.current).select('svg').remove()
    d3.select(ref.current).selectAll('.tooltip').remove()
    d3.select(ref.current).selectAll('.summary').remove()

    const statesData: any = cloneDeep(usStatesJson)

    //Width and height of map
    const width = 960
    const height = 350

    // D3 Projection
    const projection = d3.geoAlbersUsa()
                    .translate([width/2, height/2])    // translate to center of screen
                    .scale(750)          // scale things down so see entire US
            
    // Define path generator
    const path: any = d3.geoPath()               // path generator that will convert GeoJSON to SVG paths
                .projection(projection)  // tell path generator to use albersUsa projection

    // Create SVG element and append map to the SVG
    const svg = d3.select(ref.current)
                .append("svg")
                .attr('viewBox', `0 0 ${width} ${height}`)
                .attr('width', '100%')
                .attr('height', '100%')
                .on('click', function (e, d: any) {
                  if (e.srcElement.tagName === 'svg') {
                    setFilters(omit(filters, 'state'))
                    setMapType('world')
                  }
                })

    // Append Div for tooltip to SVG
    const tooltip = d3.select(ref.current)
                .append("div")
                .attr("class", "tooltip")
                .style("opacity", 0)
    // Append Div for total summary
    const summary = d3.select(ref.current)
                .append("div")   
                .attr("class", "summary")

    const companyValues = [...new Set(chartData.map((d) => d.companies || 0)) as any].sort((a, b) => a - b)
    const isSelected = (d: any) => filters.state === d.properties.name

    // Loop through each state data value in the .csv file
    for (let i = 0; i < chartData.length; i++) {
      // Grab State Name
      const dataState = chartData[i].location

      // Grab data value 
      const companies = chartData[i].companies
      const employees = chartData[i].employees
      const amount = chartData[i].amount

      // Find the corresponding state inside the GeoJSON
      for (let j = 0; j < statesData.features.length; j++)  {
        const jsonState = statesData.features[j].properties.name
        if (dataState == jsonState) {
          // Copy the data value into the JSON
          statesData.features[j].properties.companies = companies
          statesData.features[j].properties.employees = employees
          statesData.features[j].properties.amount = amount
          break
        }
      }
    }
            
    // Bind the data to the SVG and create one path per GeoJSON feature
    svg.selectAll("path")
      .data(statesData.features)
      .enter()
      .append("path")
      .attr("d", path)
      .attr("class", "cursor-pointer")
      .style("stroke", "#ccc")
      .style("stroke-width", "0.5")
      .style("fill", function(d: any) {
        const value = d.properties.companies || 0
        return getBackgroundColor(theme, companyValues, value)
      })
      .attr('opacity', (d) => {
        let opacity = 1
        if (filters && filters.state) {
          opacity = isSelected(d) ? 1 : 0.3
        }
        return opacity
      })
      .on("mouseover", function(event, d: any) {
        d3.select(this).attr('opacity', 0.7)
        tooltip.transition()
          .duration(200)
          .style("opacity", 1)
        tooltip.html(`
          <div class="bg-white border text-sm p-2">
            <div>
              <p class="text-xxs text-gray-500">State</p>
              <p>${d.properties.name}</p>
            </div>
            <div class="mt-1">
              <p class="text-xxs text-gray-500">Companies</p>
              <p>${d.properties.companies || 0}</p>
            </div>
            <div class="mt-1">
              <p class="text-xxs text-gray-500">Employees</p>
              <p>${numberWithCommas(d.properties.employees)}</p>
            </div>
            <div class="mt-1">
              <p class="text-xxs text-gray-500">Annual Revenue</p>
              <p>$${numberWithCommas(d.properties.amount)}</p>
            </div>
          </div>
        `)
        .style("left", (event.offsetX) + "px")
        .style("top", (event.offsetY-100) + "px")
      })
      .on("mouseout", function(d) {
        d3.select(this).attr('opacity', (d) => {
          let opacity = 1
          if (filters && filters.state) {
            opacity = isSelected(d) ? 1 : 0.3
          }
          return opacity
        })
        tooltip.transition().duration(500).style("opacity", 0)
      })
      .on('click', function (e, d: any) {
        if (filters && setFilters) {
          if (isSelected(d)) {
            setFilters(omit(filters, 'state'))
          } else {
            setFilters({...filters, state: d.properties.name})
          }
        }
      })

    // Display total summary
    let totalCompanies = 0
    let totalEmployees = 0
    let totalAmount = 0
    statesData.features.forEach((d: any) => {
      totalCompanies += d.properties.companies || 0
      totalEmployees += d.properties.employees || 0
      totalAmount += d.properties.amount || 0
    })
    summary.html(`
      <div class="flex text-sm mt-6">
        <div class="mr-5">
          <p class="text-xxs text-gray-500">Companies</p>
          <p class="dark:text-gray-300">${numberWithCommas(totalCompanies)}</p>
        </div>
        <div class="mr-5">
          <p class="text-xxs text-gray-500">Employees</p>
          <p class="dark:text-gray-300">${numberWithCommas(totalEmployees)}</p>
        </div>
        <div class="mr-5">
          <p class="text-xxs text-gray-500">Annual Revenue</p>
          <p class="dark:text-gray-300">$${numberWithCommas(totalAmount)}</p>
        </div>
      </div>
    `)
  }

  return (
    <div className="rounded-lg bg-white dark:bg-header-dark dark:border-gray-500 p-3 shadow sm:p-4 mt-6">
      <div className='w-full h-full relative' ref={ref} />
    </div>
  )
}

export default Geomap
