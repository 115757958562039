import { FC } from 'react'
import HistogramChart from './histogramChart'
import { ICPChartData, ICPFilters } from '../../../@types/module'
import { useFeatureContext } from '../../../hooks/featureContext'
interface ChartsProps {
  data: ICPChartData
  filters: ICPFilters
  setFilters: Function
}

const Charts: FC<ChartsProps> = ({ data, filters, setFilters }) => {
  const { isHarry, isGigPro } = useFeatureContext()

  return (
    <div className="grid grid-cols-1 xl:grid-cols-3 grid-flow-row gap-6 mb-6">
      <div className="rounded-lg bg-white dark:bg-header-dark dark:border-gray-500 p-3 shadow sm:p-4">
        <div className='text-sm dark:text-white'>
          {isGigPro ? "Rating" : "Employee Counts"}
        </div>
        <HistogramChart
          data={data.numberOfEmployees}
          type="employeeCount"
          color="#4DD4FF"
          xFormat={data.maxEmployeeCountFormatter}
          xUnit={data.employeeCountUnit}
          filters={filters}
          setFilters={setFilters}
        />
        <div className='text-xs text-gray-600 dark:text-theme-toggle-icon'>
          { isGigPro ? "Ratings (bin)" : "Employee Count (bin)" }
        </div>
      </div>
      <div className="rounded-lg bg-white dark:bg-header-dark dark:border-gray-500 p-3 shadow sm:p-4">
        <div className='text-sm dark:text-white'>
          {isHarry? "Number of Locations" : isGigPro ? "Number of Reviews" : "Age of Business"}
        </div>
        <HistogramChart
          data={data.ageOfBusiness}
          type="ageOfBusiness"
          color="#B350CC"
          filters={filters}
          setFilters={setFilters}
        />
        <div className='text-xs text-gray-600 dark:text-theme-toggle-icon'>
          {isHarry ? "Number of Locations (bin)" : isGigPro ? "Number of reviews (bin)" : "Age of Business in yrs (bin)"}
        </div>
      </div>
      <div className="rounded-lg bg-white dark:bg-header-dark dark:border-gray-500 p-3 shadow sm:p-4">
        <div className='text-sm dark:text-white'>
          {isGigPro ? "Price" : "Revenue"}
        </div>
        <HistogramChart
          data={data.annualRevenue}
          type="annualRevenue"
          color="#58E1D9"
          xFormat={data.maxAnnualRevenueFormatter}
          xUnit={data.annualRevenueUnit}
          filters={filters}
          setFilters={setFilters}
        />
        <div className='text-xs text-gray-600 dark:text-theme-toggle-icon'>
          {isGigPro ? "Price (bin)" : "Revenue (bin)"}
        </div>
      </div>
    </div>
  )
}

export default Charts
