import React, { FC, useState, useMemo, useCallback } from 'react'
import AccountRow from './AccountRow'
import { useFeatureContext } from '../../../../hooks/featureContext'

import { AccountRecord } from '../../../../@types/module'
import Pagination from '../../../common/pagination'
interface AccountsTableProps {
  data: AccountRecord[]
}

const AccountsTable: FC<AccountsTableProps> = ({ data }) => {
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const { isHarry, isGigPro } = useFeatureContext()

  const paginatedData = useMemo(() => {
    return data.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
  }, [pageNumber, pageSize, data])

  const onPageChanged = useCallback((c: number, ps: number) => {
    if (c !== pageNumber || ps !== pageSize) {
      setPageNumber(c)
      setPageSize(ps)
    }
  }, [pageNumber, pageSize])

  return (
    <div className="icp-accounts mt-8 mb-8">
      <div className='text-xl font-semibold dark:text-white mb-6'>Business Details</div>
      <div className="rounded-lg shadow text-black dark:text-white bg-white dark:bg-revtron-dark2 flex items-center p-6 mb-3">
        <div className='mr-6 p-1'>#</div>
        <div className='flex-1 max-w-[280px] p-1'>Name</div>
        <div className='flex-1 p-1'>Domain</div>
        <div className='w-40 p-1'>City</div>
        <div className='w-32 p-1'>
          { isGigPro ? "Rating" : "Emp.Count" }
        </div>
        <div className='w-20 p-1'>
          { isHarry ? "Lctns" : isGigPro ? "Rvws" : "Age" }
        </div>
        <div className='w-48 p-1'>
          {isGigPro ? "Price" : "Revenue" } 
        </div>
        <div className='w-32 p-1'>ICP Score</div>
      </div>
      {paginatedData.length > 0 ? (
        paginatedData.map((detail: AccountRecord, index: number) => (
          <AccountRow detail={detail} index={index + 1} key={index.toString()}/>
        ))
      ) : (
        <div className='rounded-lg shadow text-black dark:text-white bg-revtron-gray1 dark:bg-neutral-800 p-6 text-center'>
          No data
        </div>
      )}
      <Pagination total={data.length} current={pageNumber} pageSize={pageSize} onChange={onPageChanged} />
    </div>
  )
}

export default AccountsTable
