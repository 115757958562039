import { FC, useState } from 'react'
import { UserAddIcon } from '@heroicons/react/solid'

import CustomTable from '../../molecules/table'
import Spin from '../../atoms/spin'
import InviteUserModal from './inviteUserModal'
import EditUserModal from './editUserModal'
import { BorderedButton as Button } from '../../../components/common/buttons'

import { useUserListQuery } from '../../../graphql/__generated__/graphql'

const UsersSection: FC<UsersSectionProps> = () => {
  const [isOpenInviteUserModal, setOpenInviteUserModal] = useState(false)
  const { data, loading } = useUserListQuery()
  const [selectedUser, setSelectedUser] = useState(null)
  const [editUserModalOpened, toggleEditUserModal] = useState(false)

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: (a: any, b: any) => a.crm_users.name && a.crm_users.name.localeCompare(b.crm_users.name),
      render: (_: any, row: any) => {
        return (
          <div className="flex items-center">
            <div className="h-10 w-10 flex-shrink-0">
              <img className="h-10 w-10 rounded-full object-cover" src={row.avatarUrl} alt="" />
            </div>
            <div className="ml-4">
              <div className="font-medium text-gray-900 dark:text-gray-200">{row.crm_users.name}</div>
              <div className="text-gray-500">{row.email}</div>
            </div>
          </div>
        )
      }
    },
    {
      title: 'Title',
      dataIndex: '',
      align: 'center',
      render: (_: any, row: any) => {
        return (
          <>
            <div className="text-gray-900 dark:text-gray-200">{row.crm_users.title}</div>
            {/* <div className="text-gray-500">{row.sf_users.department}</div> */}
          </>
        )
      }
    },
    {
      title: 'Status',
      dataIndex: '',
      align: 'center',
      render: (_: any, row: any) => {
        if (row.crm_users.isactive) {
          return (
            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
              Active
            </span>
          )
        } else {
          return (
            <span className="inline-flex rounded-full bg-gray-100 px-2 text-xs font-semibold leading-5 text-gray-800">
              Inactive
            </span>
          )
        }
      }
    },
    {
      title: 'Role',
      dataIndex: 'defaultRole',
      align: 'center',
      sorter: (a: any, b: any) => a.defaultRole && a.defaultRole.localeCompare(b.defaultRole),
    },
    {
      title: '',
      dataIndex: '',
      render: (_: any, row: any) => {
        return (
          <button
            className="inline-flex items-center justify-center rounded-md px-4 py-1.5 text-xs font-medium border border-neutral-400 dark:border-neutral-700 bg-white dark:bg-header-dark focus:outline-none hover:bg-gray-50 sm:w-auto"
            onClick={() => {
              setSelectedUser(row)
              toggleEditUserModal(true)
            }}
          >
            Edit
          </button>
        )
      }
    }
  ]

  return (
    <>
      <div className="sm:flex sm:items-center mb-6">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-neutral-300">Users</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Button
            title='Invite User'
            Icon={UserAddIcon}
            onButtonClick={() => setOpenInviteUserModal(true)}
          />
        </div>
      </div>
      {loading ? (
        <div className='w-full flex justify-center items-center my-4'>
          <Spin />
        </div>
      ) : (
        <CustomTable
          wrapperClassName='border overflow-hidden rounded-xl dark:border-header-dark'
          columns={columns}
          dataSource={data ? data.users : []}
        />
      )}
      <InviteUserModal isOpen={isOpenInviteUserModal} closeModal={() => setOpenInviteUserModal(false)} />
      <EditUserModal
        user={selectedUser}
        isOpen={editUserModalOpened}
        closeModal={() => toggleEditUserModal(false)}
      />
    </>
  )
}

interface UsersSectionProps {
}

export default UsersSection
