import { FC, useRef, useEffect, useContext } from 'react'
import * as d3 from 'd3'
import { omit } from 'lodash'
import { ThemeContext } from '../../../layouts/themeContext'
import { ICPFilters } from '../../../@types/module'

interface TreemapChartProps {
  shape: 'portrait' | 'landscape'
  data: any
  filterField: 'companyType' | 'subCategory'
  filters: ICPFilters
  setFilters: Function
  filterEnabled: Boolean // one of two treempas can be filterable
}

// export const getBackgroundColor = (theme: any, value: any, max: any, min: any) => {
//   if (theme === 'light') {
//     const colorValue = 210 + 45 / (max-min) * (value-min)
//     return `rgb(${colorValue}, ${colorValue}, ${colorValue})`
//   } else {
//     return `rgb(${200 * (value)/max}, ${200 * (value)/max}, ${200 * (value)/max})`
//   }
// }

export const getBackgroundColor = (theme: any, value: any, max: any, min: any) => {
  const color2 = theme === 'light' ? [150, 150, 150] : [50, 50, 50]
  const color1 = theme === 'light' ? [245, 245, 245] : [150, 150, 150]
  const weight = value / max
  var w1 = weight;
  var w2 = 1 - w1;
  var rgb = [Math.round(color1[0] * w1 + color2[0] * w2),
      Math.round(color1[1] * w1 + color2[1] * w2),
      Math.round(color1[2] * w1 + color2[2] * w2)];
  // return rgb;
  return 'rgb('+rgb.join()+')'
}

const TreemapChart: FC<TreemapChartProps> = ({ shape = 'landscape', data, filters, setFilters, filterField, filterEnabled }) => {
  const { theme } = useContext<any>(ThemeContext)
  const ref = useRef<HTMLDivElement>(null)
  const width = shape === 'landscape' ? 600 : 300
  const height = shape === 'landscape' ? 300 : 300
  const margin = {top: 10, right: 10, bottom: 10, left: 10}
  const viewWidth = width - margin.left - margin.right
  const viewHeight = height - margin.top - margin.bottom

  useEffect(() => {
    data.children.length > 0 && draw()
  }, [data, theme])

  const draw = () => {
    d3.select(ref.current).select('svg').remove()
    d3.select(ref.current).selectAll('.tooltip').remove()

    // append the svg object to the body of the page
    const svg = d3
    .select(ref.current)
    .append('svg')
    .attr('viewBox', `0 0 ${viewWidth} ${viewHeight}`)
    .attr('width', '100%')
    .attr('height', '100%')
    // .attr('preserveAspectRatio', 'xMaxYMax')

    const root = d3.hierarchy(data).sum((d: any) => d.value) // Here the size of each leave is given in the 'value' field in input data

    // Then d3.treemap computes the position of each element of the hierarchy
    d3.treemap()
      .size([viewWidth, viewHeight])
      .padding(2)
      (root)

    const tooltip = d3.select(ref.current).append("div").attr('class', 'tooltip')
    const max: any = d3.max(data.children, (d: any) => d.value)
    const min: any = d3.min(data.children, (d: any) => d.value)
    const isSelected = (d: any) => filters ? filters[filterField] === d.data.name : null

    // use this information to add rectangles:
    const node = svg
      .selectAll("rect")
      .data(root.leaves())
      .enter()
      .append("g")
      .on('mouseover', function (event, d) {
        d3.select(this).attr('opacity', 0.7)
        tooltip.transition()
          .duration(200)
          .style("opacity", 1)
        tooltip.html(`
          <div class="bg-white border p-2">
            <p>${d.data.name}</p>
            <p>${d.data.value}</p>
          </div>
        `)
        .style("left", (event.offsetX-40) + "px")
        .style("top", (event.offsetY) + "px")
      })
      .on('mouseout', function (e, d) {
        d3.select(this).attr('opacity', 1)
        tooltip.transition().duration(500).style("opacity", 0)
      })
      .on('click', function (e, d) {
        if (filters && setFilters) {
          if (isSelected(d)) {
            setFilters(omit(filters, filterField))
          } else {
            filterEnabled && setFilters({...filters, [filterField]: d.data.name})
          }
        }
      })

    node.append("rect")
      .attr('x', (d:any) => d.x0)
      .attr('y', (d:any) => d.y0)
      .attr('width', (d:any) => d.x1 - d.x0)
      .attr('height', (d:any) => d.y1 - d.y0)
      .attr('class', (d) => {
        let classes = 'cursor-pointer stroke-gray-600 hover:stroke-revtron-cyan '
        if (filters && filters[filterField]) {
          classes += isSelected(d) ? '!stroke-revtron-cyan' : ''
        }
        return classes
      })
      .style("stroke-width", 0.5)
      // .style("fill", (d: any) => getBackgroundColor(theme, d.data.value, max, min))
      .style("fill", (d: any) => getBackgroundColor(theme, d.data.value, max, min))
      .attr('opacity', (d) => {
        let opacity = 1
        if (filters && filters[filterField]) {
          opacity = isSelected(d) ? 1 : 0.3
        }
        return opacity
      })

    const uid = `O-${Math.random().toString(16).slice(2)}`;
    node.append("clipPath")
      .attr("id", (d, i) => `${uid}-clip-${i}`)
      .append("rect")
        .attr('x', (d:any) => d.x0)
        .attr('y', (d:any) => d.y0)
        .attr("width", (d: any) => d.x1 - d.x0 - 5)
        .attr("height", (d: any) => d.y1 - d.y0);

    const text = node.append("text")
      .attr("clip-path", (d, i) => `url(${new URL(`#${uid}-clip-${i}`, window.location.href)})`)
      .attr("x", (d: any) => d.x0+5)
      .attr("y", (d: any) => d.y0+10)
      .attr("font-size", "9px")
      .attr('class', 'fill-black dark:fill-gray-200')
    text.append("tspan")
      .attr('x', (d:any) => d.x0+5)
      .attr('dy', 0)
      .text((d: any) => d.data.name)
    text.append("tspan")
      .attr('x', (d:any) => d.x0+5)
      .attr('dy', 10)
      .text((d: any) => d.data.value)
  }

  return (
    <div className='w-full h-full relative' ref={ref} />
  )
}

export default TreemapChart
