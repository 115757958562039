import { FC } from 'react'

import Input from '../atoms/input'

const TextField: FC<TextFieldProps> = ({
  className,
  label,
  id = 'email',
  name = 'email',
  type = 'email',
  autoComplete = 'email',
  required,
  value = '',
  onChange,
  placeholder = 'email'
}) => {
  return (
    <div className={className}>
      <label
        htmlFor={id}
        className="block text-sm font-medium text-gray-700 dark:text-gray-400"
      >
        {label}
      </label>
      <div className="mt-1">
        <Input
          id={id}
          name={name}
          type={type}
          autoComplete={autoComplete}
          required={required}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

interface TextFieldProps {
  className?: string
  label: string
  id: string
  name: string
  type: 'email' | 'password' | 'text' | 'number'
  autoComplete?: 'email' | 'current-password'
  required?: boolean
  value: string
  onChange: any
  placeholder: string
}

export default TextField