export const teamEmptyState: any = {
  teamName: '',
  teamType: {
    name: '',
    value: ''
  },
  opportunities: '',
  emailsSent: '',
  calls: '',
  social: ''
}

export const Seniorities: string[] = [
  'owner',
  'partner',
  'cxo',
  'vp',
  'director',
  'manager',
  'senior',
  'entry',
  'training',
  'unpaid',
]

export const Roles: { role: string, subrole: string[] }[] = [
  {
    role: 'customer_service',
    subrole: [
      'customer_success',
      'support'
    ]
  },
  {
    role: 'design',
    subrole: [
      'graphic_design',
      'product_design',
      'web_design'
    ]
  },
  {
    role: 'education',
    subrole: [
      'education_administration',
      'professor',
      'researcher',
      'teacher',
    ]
  },
  {
    role: 'engineering',
    subrole: [
      'data',
      'devops',
      'electrical',
      'mechanical',
      'network',
      'information_technology',
      'project',
      'quality_assurance',
      'security',
      'software',
      'systems',
      'web',
    ]
  },
  {
    role: 'finance',
    subrole: [
      'accounting',
      'investment',
      'tax',
    ]
  },
  {
    role: 'health',
    subrole: [
      'dental',
      'fitness',
      'doctor',
      'nursing',
      'therapy',
      'wellness',
    ]
  },
  {
    role: 'human_resources',
    subrole: [
      'compensation',
      'employee_development',
      'recruiting',
    ]
  },
  {
    role: 'legal',
    subrole: [
      'lawyer',
      'paralegal',
      'judicial',
    ]
  },
  {
    role: 'marketing',
    subrole: [
      'brand_marketing',
      'content_marketing',
      'product_marketing',
    ]
  },
  {
    role: 'media',
    subrole: [
      'broadcasting',
      'editorial',
      'journalism',
      'video',
      'writing',
    ]
  },
  {
    role: 'operations',
    subrole: [
      'project_management',
      'office_management',
      'logistics',
      'product',
    ]
  },
  {
    role: 'public_relations',
    subrole: [
      'events',
      'media_relations',
    ]
  },
  {
    role: 'real_estate',
    subrole: [
      'property_management',
      'realtor',
    ]
  },
  {
    role: 'sales',
    subrole: [
      'accounts',
      'business_development',
      'pipeline',
    ]
  },
]