import { FC, memo, useState, useCallback } from 'react'

import ActivityLookBackSummary from '../../../components/organisms/activity/activityLookBack/summaryNumbers'
import PlayerCards from '../../../components/organisms/activity/activityLookBack/playerCards'
import DateRangePicker from '../../../components/common/date-range-picker'
import MeetingTable from '../../../components/organisms/activity/activityLookBack/meetingTable'
import OpportunityTable from '../../../components/organisms/activity/activityLookBack/opportunityTable'
import useGraphQL from './ActivityLookBack.graphql'

const ActivityLookBack: FC<ActivityProps> = () => {
  const {
    activityByUserData: [activityByUserData, totalActivityByUser, meetings, opportunities],
    isActivityByUserDataLoading,
    dateFilter,
    onDateFilterChange,
  } = useGraphQL()
  const [playerId, setPlayerId] = useState<string | undefined>(undefined)

  const onChangePlayerId = useCallback((id: string) => {
    if (id === playerId) {
      setPlayerId(undefined)
    } else {
      setPlayerId(id)
    }
  }, [playerId])

  return (
    <div className='activityLookBack'>
      <DateRangePicker dateFilter={dateFilter} applyDateFilter={onDateFilterChange} classNames='my-3 py-2 w-full ml-0 md:w-1/2 md:ml-auto' />
      <ActivityLookBackSummary {...totalActivityByUser} isLoading={isActivityByUserDataLoading} />
      <PlayerCards leadData={activityByUserData} leadLoading={isActivityByUserDataLoading} playerId={playerId} onChangePlayerId={onChangePlayerId} />
      <OpportunityTable opportunities={opportunities} ownerId={playerId} />
      <MeetingTable meetings={meetings} ownerId={playerId} />
    </div>
  )
}

interface ActivityProps {}

export default memo(ActivityLookBack)
