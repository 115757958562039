import { FC } from 'react'
import Spin from '../../atoms/spin'
import { SummaryTileContainer, SummaryTile } from '../../molecules/summary'
import { numberWithCommas, isInteger } from '../../../utils/functions'
import { useFeatureContext } from '../../../hooks/featureContext'

const Summary: FC<SummaryProps> = ({
  bookedData,
  bookedLoading,
  pipeData,
  pipeLoading,
  weightedData,
  weightedLoading,
}) => {
  const { isHarry } = useFeatureContext()

  if (bookedLoading || pipeLoading || weightedLoading) {
    return (
      <div className='w-full flex justify-center items-center my-4'>
        <Spin />
      </div>
    )
  }

  const bookedAggregate = bookedData ? bookedData.pipeline_opp_detail_ml_gql_aggregate.aggregate : null
  const pipeAggregate = pipeData ? pipeData.pipeline_opp_detail_ml_gql_aggregate.aggregate : null
  let weightedAggregate = weightedData ? weightedData.weighted_pipeline_ml_gql_aggregate.aggregate : null

  const calculateMLWeightedAndBooked = () => {
    if (bookedAggregate && weightedAggregate) {
      const sum = (bookedAggregate.sum.amount || 0) + (weightedAggregate.sum.ml_weighted_arr || 0)
      return numberWithCommas(isInteger(sum,0))
    }
    return 0
  }

  return (
    <div className='pipeline-summary-content my-4 flex flex-wrap -mx-2 text-center'>
      <SummaryTileContainer className='mx-2 my-1'>
        <SummaryTile
          label={`Booked ${isHarry ? 'MRR' : 'ARR'} $`}
          value={`${bookedAggregate ? numberWithCommas(bookedAggregate.sum.amount ? isInteger(bookedAggregate.sum.amount, 0) : 0) : 0}`}
        />
        <SummaryTile
          label="Booked Opp #"
          value={bookedAggregate ? bookedAggregate.count : 0}
        />
        <SummaryTile
          label="Booked Services $"
          value={`${bookedAggregate ? numberWithCommas(bookedAggregate.sum.services_amount ? isInteger(bookedAggregate.sum.services_amount, 0) : 0) : 0}`}
        />
      </SummaryTileContainer>
      <SummaryTileContainer className='mx-2 my-1'>
        <SummaryTile
          label={`Pipe ${isHarry ? 'MRR' : 'ARR'} $`}
          value={`${pipeAggregate ? numberWithCommas(pipeAggregate.sum.amount ? isInteger(pipeAggregate.sum.amount, 0) : 0) : 0}`}
        />
        <SummaryTile
          label="Pipe Opp #"
          value={pipeAggregate ? pipeAggregate.count : 0}
        />
        <SummaryTile
          label="Pipe Services $"
          value={`${pipeAggregate ? numberWithCommas(pipeAggregate.sum.services_amount ? isInteger(pipeAggregate.sum.services_amount, 0) : 0) : 0}`}
        />
      </SummaryTileContainer>
      <SummaryTileContainer className='mx-2 my-1'>
        <SummaryTile
          label={`Weighted ${isHarry ? 'MRR' : 'ARR'} $`}
          value={`${weightedAggregate ? numberWithCommas(weightedAggregate.sum.weighted_arr ? isInteger(weightedAggregate.sum.weighted_arr, 0) : 0) : 0}`}
        />
        <SummaryTile
          label={`ML Weighted ${isHarry ? 'MRR' : 'ARR'} $`}
          value={`${weightedAggregate ? numberWithCommas(weightedAggregate.sum.ml_weighted_arr ? isInteger(weightedAggregate.sum.ml_weighted_arr, 0) : 0) : 0}`}
        />
        <SummaryTile
          label='ML Wghtd + Booked'
          value={calculateMLWeightedAndBooked()}
        />
      </SummaryTileContainer>
    </div>
  )
}

interface SummaryProps {
  bookedData: any
  bookedLoading: boolean
  pipeData: any
  pipeLoading: boolean
  weightedData: any
  weightedLoading: boolean
  ximaEnabled?:boolean
}

export default Summary
