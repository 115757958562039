import { FC } from 'react'
import ContactTable from '../../../components/organisms/prospector/contact-table'
import useGraphQL from './ContactRec.graphql'

const ContactTableComponent: FC = () => {
  const {
    contactRecData: { contactDetails, totalRecordsCount },
    fields,
    filters,
    sendToCRM,
    isSendToCrmLoading,
    ...rest
  } = useGraphQL()

  return (
    <div className='contact-recommendations-content'>
      <ContactTable
        tableData={contactDetails}
        fields={fields}
        totalRowsCount={totalRecordsCount}
        headerActionLoading={isSendToCrmLoading}
        headerActionText='Send to CRM'
        tableHeaderAction={sendToCRM}
        {...rest}
        filters={filters}
      />
    </div>
  );
}

export default ContactTableComponent
