import { FC, useState, useEffect } from 'react'
import sortIcon from '../../assets/images/sort.svg'
import Pagination from '../common/pagination'

const RowItem: FC<RowItemProps> = ({ columns, rowIndex, expandable, row }) => {
  const [isExpandable, setExpandable] = useState<boolean>(false)

  return (
    <>
      <tr
        className={`${rowIndex % 2 !== 0 ? 'bg-revtron-gray1 dark:bg-revtron-dark2' : 'bg-white dark:bg-neutral-800'}`}
      >
        {expandable && expandable.rowExpandable(row) && (
          <td className='py-4 px-4'>
            <button
              className='dark:text-table-text border border-table-text w-4 h-4 flex justify-center items-center'
              onClick={() => setExpandable(!isExpandable)}
            >
              {isExpandable ? '-' : '+'}
            </button>
          </td>
        )}
        {columns.map((cell, cellIndex) => {
          return (
            <td key={cellIndex} className='py-4 px-4 dark:text-table-text'>
              <div className='whitespace-normal'>
                {cell.render ? cell.render(row[cell.dataIndex], row) : row[cell.dataIndex]}
              </div>
            </td>
          )
        })}
      </tr>
      {isExpandable && (
        <tr className={`${rowIndex % 2 !== 0 ? 'bg-gray-50 dark:bg-revtron-dark2' : 'bg-white dark:bg-neutral-800'}`}>
          <td className=''></td>
          <td colSpan={columns.length}>
            {expandable.expandedRowRender(row)}
          </td>
        </tr>
      )}
    </>
  )
}

interface RowItemProps {
  columns: any[]
  rowIndex: number
  expandable?: any
  row: any
} 

const CustomTable: FC<CustomTableProps> = ({ wrapperClassName='', className = '', columns, dataSource, expandable, pagination }) => {
  const [data, setData] = useState<any[]>(dataSource)
  const [sortDir, setSortDir] = useState<string>('asc')

  useEffect(() =>{
    setData(dataSource)
  }, [dataSource])

  const sortCurrentTable = (sort: any, dir: string) => {
    let copiedData = [...data]
    if (dir === 'asc') {
      copiedData.sort((a, b) => sort(a, b))
    } else {
      copiedData.sort((a, b) => sort(b, a))
    }
    setData(copiedData)
    if (dir === 'asc') {
      setSortDir('desc')
    } else {
      setSortDir('asc')
    }
  }

  return (
    <>
      <div className={`w-full h-full overflow-x-auto rounded-lg shadow ${wrapperClassName || ''}`}>
        <table className={`w-full h-full overflow-x-auto whitespace-nowrap text-sm text-left text-gray-500 dark:text-gray-400 ${className || ''}`}>
          <thead className='border-b dark:border-neutral-700 text-xs text-table-head uppercase bg-white dark:bg-neutral-800 dark:text-table-head'>
            <tr>
              {data && data.length > 0 && expandable && <th />}
              {columns.map((item, index) => {
                return (
                  <th key={index.toString()} scope='col' className='py-4 px-4'>
                    {item.sorter ? (
                      <button
                        onClick={() => sortCurrentTable(item.sorter, sortDir)}
                        className='w-full flex justify-between items-center uppercase font-bold text-xs text-table-head dark:text-table-head'
                      >
                        <span>{item.title}</span>
                        {item.sorter && <img src={sortIcon} alt='sort image' className='ml-1' />}
                      </button>
                    ) : (
                      <span>
                        {item.title}
                      </span>
                    )}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {data && data.length === 0 && (
              <tr>
                <td colSpan={columns.length}>
                  <div className='dark:text-table-text px-4 py-8 flex justify-center items-center bg-gray-50 dark:bg-neutral-800'>No data</div>
                </td>
              </tr>
            )}
            {data && data.length > 0 && data.map((row, rowIndex) => {
              return (
                <RowItem
                  key={rowIndex.toString()}
                  row={row}
                  rowIndex={rowIndex}
                  expandable={expandable}
                  columns={columns}
                />
              )
            })}
            <tr></tr>
          </tbody>
        </table>
      </div>
      {pagination && <Pagination {...pagination}  />}
    </>
  )
}

interface CustomTableProps {
  wrapperClassName?: string
  className?: string
  columns: any[]
  dataSource: any[]
  expandable?: any
  pagination?: any
}

export default CustomTable