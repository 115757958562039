import { FC, useMemo, useState, useCallback } from 'react'

import ShadowCard from '../../../templates/shadowCard'
import CustomTable from '../../../molecules/table'
import { convertUTCToLocal } from '../../../../utils/functions'
import { OpportunityType } from '../../../../containers/activity/ActivityLookBack/ActivityLookBack.util'
import { sortAlphabetically } from '../../../../utils/functions'

type OpportunityTableProps = {
  opportunities: OpportunityType[]
  ownerId?: string 
}

const OpportunityTable: FC<OpportunityTableProps> = ({ opportunities, ownerId }) => {
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const data = useMemo(() => {
    if (!!ownerId) {
      setPageNumber(1)
      return opportunities.filter(m => m.createdbyid === ownerId)
    }
    return opportunities
  }, [opportunities, ownerId])

  const paginatedData = useMemo(() => {
    return data.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
  }, [pageNumber, pageSize, data])

  const onPaginationChange = useCallback((pageNumber: number, pageSize: number) => {
    setPageNumber(pageNumber)
    setPageSize(pageSize)
  }, [])

  const columns = [
    {
      title: 'Opp Creator',
      dataIndex: 'creator',
      sorter: (a: OpportunityType, b: OpportunityType) => sortAlphabetically(a.creator, b.creator)
    },
    {
      title: 'Opp Name',
      dataIndex: 'name',
      sorter: (a: OpportunityType, b: OpportunityType) => sortAlphabetically(a.name, b.name)
    },
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: (a: OpportunityType, b: OpportunityType) => sortAlphabetically(a.type, b.type)
    },
    {
      title: 'Stage',
      dataIndex: 'stagename',
      sorter: (a: OpportunityType, b: OpportunityType) => sortAlphabetically(a.stagename, b.stagename)
    },
    {
      title: 'Lead Source',
      dataIndex: 'leadsource',
      sorter: (a: OpportunityType, b: OpportunityType) => sortAlphabetically(a.leadsource, b.leadsource)
    },
    {
      title: 'Created Date',
      dataIndex: 'createddate',
      sorter: (a: OpportunityType, b: OpportunityType) => (a.createddate < b.createddate) ? -1 : ((a.createddate > b.createddate) ? 1 : 0),
      render: (text: string) => {
        return (
          <p>{convertUTCToLocal(text)}</p>
        )
      }
    },
    {
      title: 'Closed Date',
      dataIndex: 'closedate',
      sorter: (a: OpportunityType, b: OpportunityType) => (a.closedate < b.closedate) ? -1 : ((a.closedate > b.closedate) ? 1 : 0),
      render: (text: string) => {
        return (
          <p>{convertUTCToLocal(text)}</p>
        )
      }
    },
  ]

  return (
    <ShadowCard label='Opportunites Created' className='mt-10'>
      <CustomTable
        columns={columns}
        dataSource={paginatedData}
        pagination={{
          total: data.length,
          current: pageNumber,
          pageSize,
          onChange: onPaginationChange
        }}
      />
    </ShadowCard>
  )
}

export default OpportunityTable
