import IntegrationList from "../../components/organisms/settings/integrationList"

const Integrations = () => {
  return (
    <div>
      <div className="mt-2 md:flex md:items-center md:justify-between">
        <div className="flex-1 min-w-0">
          <h2 className="text-2xl font-bold leading-7 text-revtron-black dark:text-neutral-300 sm:text-3xl sm:truncate">
            Data Connections
          </h2>
        </div>
      </div>
      <div className="py-12">
        <IntegrationList />
      </div>
    </div>
  )
}

export default Integrations
