import { FC, useEffect } from 'react'
import moment from 'moment'
import * as d3 from 'd3'

import Spin from '../../atoms/spin'

import { groupBy, sortCompareFuncByDate, getAlphaValue } from '../../../utils/functions'

const MAX_SCATTER_X_LABEL_COUNT = 15

const Scatter: FC<ScatterProps> = ({ all, data, ids, addIds, loading }) => {
  if (loading) {
    return (
      <div className='w-full flex justify-center items-center my-4'>
        <Spin />
      </div>
    )
  }

  const formattedData: any[] = []
  let sortedStages: any[] = []
  let filteredStages: any[] = []

  if (all && all.pipeline_opp_detail_ml_gql) {
    const stageByProb: any[] = []
    const groupedData = groupBy(all.pipeline_opp_detail_ml_gql, 'stage')
    Object.keys(groupedData).forEach(stageName => {
      const stageData = groupedData[stageName]
      const avgProb = Math.round(stageData.reduce((sum: any, cur: any) => sum + cur.probability, 0) / stageData.length)
      stageByProb.push({
        stage: stageName,
        probability: avgProb
      })
    })
    stageByProb.sort((a, b) => a.probability - b.probability)
    sortedStages = stageByProb.map(s => ({ stage: s.stage, color: s.probability === 100 ? `rgb(168, 85, 247, 0.7)` : `rgba(5, 217, 232, ${s.probability / 100})` }))
  }

  if (data && data.pipeline_opp_detail_ml_gql) {
    const gqlData = data.pipeline_opp_detail_ml_gql
    const groupedData = groupBy(gqlData, 'stage')
    filteredStages = sortedStages.filter(s => Object.keys(groupedData).indexOf(s.stage) !== -1)
    const sortedData = gqlData.slice().sort((a: any, b: any) => sortCompareFuncByDate(a, b, 'closedate'))
    sortedData.forEach((p: any) => {
      const selectedStage = filteredStages.find(s => s.stage === p.stage)
      formattedData.push({
        x: p.closedate,
        date: moment(p.closedate).format('MMM DD'),
        y: filteredStages.length - filteredStages.map(s => s.stage).indexOf(p.stage),
        amount: p.amount,
        stage: p.stage,
        opp_name: p.opportunity_name,
        opp_owner: p.opportunity_owner,
        opp_id: p.opportunity_id,
        color: selectedStage && selectedStage.color
      })
    })
  }

  return (
    <div className='pipeline-scatter-container flex-1 flex flex-col mt-4'>
      <div className='flex flex-wrap justify-end my-4'>
        {
          sortedStages.map((s, index) => (
            <div className='flex items-center mr-3' key={index}>
              <div className='w-2.5 h-2.5 rounded-full border border-cyan-200 mr-1' style={{ backgroundColor: s.color }} />
              <div className='text-xxs dark:text-white'>{s.stage}</div>
            </div>
          ))
        }
      </div>
      <Chart stageCount={filteredStages.length} data={formattedData} ids={ids} addIds={addIds} />
    </div>
  )
}

const Chart: FC<{ stageCount: number, data: any[], ids: any[], addIds: Function }> = ({ stageCount, data = [], ids, addIds }) => {
  const width = 995
  const height = 500
  const left = 60
  const right = 60
  const top = 40
  const bottom = 30
  const chartWidth = width - left - right
  const chartHeight = height - top - bottom

  data.forEach(d => {
    d.date = d.x
    d.x = Date.parse(moment(d.x).format('MM/DD/YYYY'))
  })

  useEffect(() => {
    draw()
  })

  const draw = () => {
    d3.select('.scatter-chart').select('svg').remove()
    d3.select('.scatter-chart').selectAll('.tooltip').remove()

    const svg = d3
      .select('.scatter-chart')
      .append('svg')
      .attr('viewBox', `0 0 ${chartWidth + left + right} ${chartHeight + top + bottom}`)
      .attr('width', '100%')
      .attr('height', '100%')
      .append('g')
      .attr('transform', `translate(${left},${top})`)

    // setup x 
    const xScale = d3
      .scaleTime()
      .domain(
        d3.extent(data, (d) => {
          return new Date(d.x)
        }) as [Date, Date]
      )
      .range([0, chartWidth])
      
    const valueDomain = [...new Set(data.map((d) => d.x)) as any]

    const xAxis = svg.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0, ${chartHeight})`)
      .call(
        d3
          .axisBottom(xScale)
          .tickFormat(x => moment(x.toString()).format('MMM DD'))
      )
    xAxis.selectAll('text')
      .style('color', '#6B7280')
      .style('font-size', '0.875rem')
    xAxis.selectAll('line')
      .style('color', '#6B7280')
      .style('font-size', '0.875rem')

    const yScale = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(data, (d) => {
          return d.y
        }) + 1 / stageCount,
      ] as number[])
      .range([chartHeight, 0])

    const yAxis = svg.append("g")
      .attr('class', 'y-axis')
      .call(d3.axisLeft(yScale).tickSize(-chartWidth))

    yAxis.selectAll('path')
      .remove()
    yAxis.selectAll('.tick')
      .select('text')
      .remove()
    yAxis.selectAll('.tick')
      .select('line')
      .style('color', '#6B7280')
      .style('opacity', (d) => data.map(el => el.y).indexOf(d) > -1 ? 1 : 0)

    const div = d3.select(".scatter-chart").append("div")
      .attr("class", "tooltip bg-chart-tooltip")
      .style("opacity", 0)

    const getCircleSize = (amount: any) => {
      const min = 10
      const max = 50
      let size = amount / 2000
      if (size > max) size = max
      else if (size < min) size = min
      return size
    }

    svg
      .selectAll("circle")
      .data(data)
      .enter()
      .append("circle")
      .attr("class", "dot")
      .attr("r", d => getCircleSize(d.amount))
      .attr("cx", d => xScale(d.x) || null)
      .attr("cy", d => yScale(d.y))
      .attr("data-xvalue", d => d.x)
      .attr("data-yvalue", d => d.y)
      .attr("yvalue", d => d.y)
      .attr("stroke", "#B854F5")
      .attr("stroke-width", 1.6)
      .style("fill", d => d.color)
      .style("cursor", "pointer")
      .on('mouseover', function (event, d) {
        d3.select(this).attr('opacity', 0.75)
        div.transition()
          .duration(200)
          .style("opacity", .9)
        div.html(`
            <div class="text-chart-tooltip-text text-left h-full">
              <p>Name: ${d.opp_name}</p>
              <p>Owner: ${d.opp_owner}</p>
              <p>Amount: ${d.amount}</p>
            </div>
          `)
          .style("left", (event.pageX - 100) + "px")
          .style("top", (event.pageY - 60) + "px")
      })
      .on('mouseout', function (d) {
        d3.select(this).attr('opacity', 1)
        div.transition()
          .duration(500)
          .style("opacity", 0)
      })
      .on('click', function (e, d) {
        addIds(d.opp_id)
      })

      if (ids && ids.length > 0) {
        svg.selectAll('circle')
          .style("opacity", (d: any) => ids.indexOf(d.opp_id) > -1 ? 1 : 0.3)
      }

      // add a dotted line for today in x-axis
      svg.append("line")
        .attr("class", "today")
        .attr("x1", xScale(new Date()))
        .attr("y1", 0)
        .attr("x2", xScale(new Date()))
        .attr("y2", chartHeight)
        .style("stroke-width", 2)
        .style("stroke-dasharray", "5 5")
        .style("stroke", "#6B7280")
        .style("fill", "none");
  }

  return (
    <div className="scatter-container flex-1">
      <div className="scatter-chart h-full" />
    </div>
  )
}

interface ScatterProps {
  loading: boolean
  all: any
  data: any
  ids: any
  addIds: any
}

export default Scatter