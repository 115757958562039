import { FC } from 'react'
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { numberWithCommas } from '../../../../utils/functions'
import { AccountRecord } from '../../../../@types/module'
import { useFeatureContext } from '../../../../hooks/featureContext'
import { websitelink } from '../../../../utils/functions'

interface AccountRowProps {
  detail: AccountRecord,
  index: number,
  isRowSelectable?: boolean,
  selectedRows?: number[],
  onRowSelect?: (id: any) => void,
}

const AccountRow: FC<AccountRowProps> = ({
  detail,
  index,
  isRowSelectable = false,
  selectedRows = [],
  onRowSelect = (id: number) => id
}) => {
  const { isHarry, isGigPro } = useFeatureContext()

  return (
    <Disclosure as="div" className={`rounded-lg shadow text-black dark:text-white mb-3 ${index % 2 === 1 ? 'bg-revtron-gray1 dark:bg-neutral-800' : 'bg-white dark:bg-revtron-dark2'}`}>
      {({ open }) => (
        <>
          <div className='flex items-center p-6 cursor-pointer'>
            <div className='mr-6 p-1'>
              {isRowSelectable ? (
                <input
                  id="check_box_handler"
                  aria-describedby="row-selectable"
                  name="row"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-revtron-cyan1 focus:ring-revtron-cyan1"
                  checked={selectedRows.findIndex(id => detail.uuid === id) > -1}
                  onChange={() => onRowSelect(detail.uuid)}
                />
              ) : index}
            </div>
            <Disclosure.Button as="div" className="flex items-center grow">
              <div className='flex-1 max-w-[280px] p-1'>{detail.name}</div>
              <div className='flex-1 break-all p-1'>
                {detail.domain && (
                  <a href={websitelink(detail.domain)} className='no-underline hover:underline' target='_blank' rel="noreferrer">
                  {websitelink(detail.domain)}
                  </a>
                )}
              </div>
              <div className='w-40 p-1'>{detail.city}</div>
              <div className='w-32 p-1'>{numberWithCommas(detail.empCount)}</div>
              <div className='w-20 p-1'>{new Date().getFullYear() - Number(detail.foundingYear)}</div>
              <div className='w-48 p-1'>${numberWithCommas(detail.annualRevenue)}</div>
              <div className='w-32 flex items-center justify-between p-1'>
                <span className="inline-flex items-center rounded-full bg-revtron-cyan px-3 py-1 text-sm font-medium text-gray-800">
                  {(detail.predictProba * 100).toFixed(0)}%
                </span>
                <ChevronDownIcon className={`h-5 w-5 ${open ? 'rotate-180 transform' : ''}`} />
              </div>
            </Disclosure.Button>
          </div>
          <Disclosure.Panel className="flex flex-wrap">
            <div className='w-1/3 border-t border-r border-gray-200 dark:border-gray-600 p-6'>
              <div className='font-medium text-gray-600 dark:text-theme-toggle-icon'> { isHarry ? "Type" : isGigPro ? "Vertical" : "Funding Type" }</div>
              <div className='capitalize'>{detail.companyType}</div>
            </div>
            <div className='w-1/3 border-t border-r border-gray-200 dark:border-gray-600 p-6'>
              <div className='font-medium text-gray-600 dark:text-theme-toggle-icon'>State</div>
              <div className=''>{detail.state}</div>
            </div>
            <div className='w-1/3 border-t border-gray-200 dark:border-gray-600 p-6'>
              <div className='font-medium text-gray-600 dark:text-theme-toggle-icon'>Country</div>
              <div className=''>{detail.country}</div>
            </div>
            <div className='w-1/3 border-t border-r border-gray-200 dark:border-gray-600 p-6'>
              <div className='font-medium text-gray-600 dark:text-theme-toggle-icon'> { isHarry ? "Locations" : isGigPro ? "Review Count" : "Founding Year" } </div>
              <div className=''>{detail.foundingYear}</div>
            </div>
            <div className='w-1/3 border-t border-r border-gray-200 dark:border-gray-600 p-6'>
              <div className='font-medium text-gray-600 dark:text-theme-toggle-icon'>
                { isHarry ? "Concept Type" : "Industry" }
              </div>
              <div className=''>{detail.industry}</div>
            </div>
            <div className='w-1/3 border-t border-gray-200 dark:border-gray-600 p-6'>
              <div className='font-medium text-gray-600 dark:text-theme-toggle-icon'>
                {isHarry ? "Franchise Brands" : "Tags"} 
              </div>
              <div className=''>{detail.specialities}</div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}


export default AccountRow
