import { FC } from 'react'
import AccountTable from '../../../components/organisms/account-table'
import { CompanyTabProps } from '../../../@types/module'

const CompanyTab: FC<CompanyTabProps> = ({ companyTableData, totalRowsCount, fields, filters, isFindPeopleLoading, findPeople, ...rest }) => {
  return (
    <div className='prospector-company'>
      <AccountTable
        tableData={companyTableData}
        fields={fields}
        totalRowsCount={totalRowsCount}
        headerActionLoading={isFindPeopleLoading}
        headerActionText='Find People'
        tableHeaderAction={findPeople}
        filters={filters}
        isAccountRec={false}
        {...rest}
      />
    </div>
  )
}

export default CompanyTab