import { FC } from 'react'
import { PeopleTable } from '../../../components/organisms/prospector'
import { PeopleTableProps, PeopleTableFilter } from '../../../@types/module'

type PassProps = PeopleTableProps & PeopleTableFilter

const PeopleTab: FC<PassProps> = (props) => {
  return (
    <div className='prospector-people'>
      <PeopleTable {...props} />
    </div>
  )
}

export default PeopleTab