import React, { useContext, useEffect, createContext } from 'react'
import { useUserData, useAuthenticated } from '@nhost/react'
import { useGrowthBook } from '@growthbook/growthbook-react'

import { useInDevelopmentFlag, InDevelopmentFlagType } from '../featureFlags/pipelineFilter'
import { FLAG_NAMES, DOMAIN_FLAGS } from '../../utils/constants'
import { SubDomains } from '../../utils/variable'

const isSubDomainEnabled = (subname: string) => {
  const isConfigExisting = SubDomains[subname]
  if (!isConfigExisting) {
    return false
  }

  return window.location.host.includes(subname)
}

export type FeatureContextType = {
  isHarry: boolean
  isXima: boolean
  isGigPro: boolean
  isHrSoft: boolean
  isProductPlan: boolean
  isXyleme: boolean
  isNewfront: boolean
  developmentFlags: InDevelopmentFlagType | null
  isNewfrontSandbox: boolean
}

export const FeatureContext = createContext<FeatureContextType>({
  isHarry: false,
  isXima: false,
  isGigPro: false,
  isHrSoft: false,
  isProductPlan: false,
  isXyleme: false,
  isNewfront: false,
  developmentFlags: null,
  isNewfrontSandbox: false
})
export const useFeatureContext = () => useContext(FeatureContext)

const AppWithContext = ({ children }: { children: React.ReactNode }) => {
  const growthbook = useGrowthBook()
  const user = useUserData()
  const authenticated = useAuthenticated()
  const { value: developmentFlags } = useInDevelopmentFlag(FLAG_NAMES.IN_DEVELOPMENT_FLAG)
  const harryEnabled = isSubDomainEnabled(DOMAIN_FLAGS.HARRI)
  const ximaEnabled = isSubDomainEnabled(DOMAIN_FLAGS.XIMA)
  const gigProEnabled = isSubDomainEnabled(DOMAIN_FLAGS.GIGPRO)
  const hrSoftEnabled = isSubDomainEnabled(DOMAIN_FLAGS.HRSOFT)
  const productplanEnabled = isSubDomainEnabled(DOMAIN_FLAGS.PRODUCTPLAN)
  const xylemeEnabled = isSubDomainEnabled(DOMAIN_FLAGS.XYLEME)
  const newfrontEnabled = isSubDomainEnabled(DOMAIN_FLAGS.NEWFRONT)
  const newfrontSandboxEnabled = isSubDomainEnabled(DOMAIN_FLAGS.NEWFRONT_SANDBOX)

  useEffect(() => {
    if (!user || !growthbook) return
    growthbook.setAttributes({
      id: user.id,
      email: user.email,
      url: window.location.hostname,
      userAgent: navigator.userAgent,
      loggedIn: authenticated,
      // admin: user.isAdmin,
      // age: user.age,
    })
  }, [user, authenticated, growthbook])

  return (
    <FeatureContext.Provider value={{
      isHarry: harryEnabled,
      isXima: ximaEnabled,
      isGigPro: gigProEnabled,
      isHrSoft: hrSoftEnabled,
      isProductPlan: productplanEnabled,
      isXyleme: xylemeEnabled,
      isNewfront: newfrontEnabled,
      developmentFlags,
      isNewfrontSandbox: newfrontSandboxEnabled
    }}>
      {children}
    </FeatureContext.Provider>
  )
}

export default AppWithContext