import { FC, useEffect, useRef, useLayoutEffect, useState } from 'react'
import * as d3 from 'd3'

const ProgressBar: FC<ProgressBarProps> = ({ percent, strokeColor }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [size, setSize] = useState<any>(0)

  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize)
    return () => window.removeEventListener('resize', updateSize)
  }, [])

  useEffect(() => {
    draw()
  })

  const updateSize = () => {
    setSize(d3.select(ref.current).style('width').slice(0, -2))
  }

  const draw = () => {
    const width = d3.select(ref.current).style('width').slice(0, -2) || size || 1000
    const height = 8
    const barHeight = 8

    d3.select(ref.current).select('svg').remove()

    const svg = d3.select(ref.current)
      .append('svg')
      .attr('viewBox', `0 0 ${width} ${height}`)
      .attr('height', '100%')
      .attr('width', '100%')

    const xScale = d3.scaleLinear()
      .domain([0, 100])
      .range([0, (width - 66)])

    svg
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', width - 56)
      .attr('height', barHeight)
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('class', 'fill-revtron-white dark:fill-revtron-dark3')

    const progress = svg
      .append('rect')
      .attr('fill', strokeColor)
      .attr('x', 0)
      .attr('y', 0)
      .attr('rx', 4)
      .attr('ry', 4)
      .attr('width', 0)
      .attr('height', barHeight)

    progress.transition()
      .duration(2000)
      .attr('width', xScale(percent / 100 > 1 ? 100 : percent))

    svg.append('text')
      .attr('class', 'fill-theme-toggle-icon')
      .attr('x', width)
      .attr('y', 8)
      .attr('font-size', 12)
      .attr('text-anchor', 'end')
      .text(`${percent}%`)
  }

  return (
    <div className='flex items-center'>
      <div ref={ref} className='progress-bar w-full mt-2.5' />
    </div>
  )
}

interface ProgressBarProps {
  percent: any,
  strokeColor: any
}

export default ProgressBar
