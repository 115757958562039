import { FC} from 'react'

import CommonTabs from '../../components/common/tabs'
import { ProspectorTabName, ProspectorTabs } from './Prospector.default.utils'
import CompanyTab from './CompanyTab'
import PeopleTab from './PeopleTab'
import { useProspectorDefaultModule } from './Prospector.default.graphql'
const Prospector: FC = () => {
  const {
    tab,
    changeTab,
    companyTableData,
    companyTableTotalRowsCount,
    loading,
    findPeople,
    pageNumber,
    pageSize,
    companyFields,
    filters,
    peopleFields,
    peopleFilters,
    addPeopleFilter,
    removePeopleFilter,
    clearAllPeopleFilters,
    isFindPeopleLoading,
    loadMorePeople,
    isLoadMoreEnabled,
    peopleData,
    isSubmitting,
    submitPeopleData,
    ...others
  } = useProspectorDefaultModule()

  return (
    <div className='prospector'>
      <CommonTabs tabs={ProspectorTabs} onChangeTab={changeTab} currentTab={tab} />
      {tab === ProspectorTabName.COMPANY && (
        <CompanyTab
          companyTableData={companyTableData}
          totalRowsCount={companyTableTotalRowsCount}
          fields={companyFields}
          isFindPeopleLoading={isFindPeopleLoading}
          findPeople={findPeople}
          filters={filters}
          pageNumber={pageNumber}
          pageSize={pageSize}
          loading={loading}
          {...others}
        />
      )}
      {tab === ProspectorTabName.PEOPLE && (
        <PeopleTab
          peopleData={peopleData}
          loading={isFindPeopleLoading}
          loadMorePeople={loadMorePeople}
          isLoadMoreEnabled={isLoadMoreEnabled}
          isSubmitting={isSubmitting}
          submitPeopleData={submitPeopleData}
          filters={peopleFilters}
          fields={peopleFields}
          addFilter={addPeopleFilter}
          removeFilter={removePeopleFilter}
          clearAllFilters={clearAllPeopleFilters}
        />
      )}
    </div>
  )
}

export default Prospector
