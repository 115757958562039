import { useEffect, useRef } from 'react'
import { Disclosure } from '@headlessui/react'
import { FC, Fragment } from 'react'
import { CogIcon } from '@heroicons/react/outline'
import { NavLink } from 'react-router-dom'
import { LayoutProps, NavItem } from '../Layout.type'

import revtronLogo from '../../assets/images/revtron_logo.svg'
import { classNames } from '../../utils/functions'

interface SidebarProps extends LayoutProps {
  sidebarOpen: boolean
  handleSidebar: (v: boolean) => void
}

const Sidebar: FC<SidebarProps> = ({
  sidebarOpen,
  handleSidebar,
  navigations
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (sidebarOpen && ref.current && ref.current.contains(e.target)) {
        handleSidebar(false)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [handleSidebar, sidebarOpen])

  const MenuItem = ({ item, isChild = false }: { item: NavItem, isChild?: boolean }) => (
    <NavLink
      to={item.href}
      className={({ isActive }) => {
        return classNames(
          isActive
            ? "bg-sidebar-active dark:bg-revtron-dark3 text-white"
            : "hover:bg-sidebar-active dark:hover:bg-revtron-dark3 text-table-text",
          "group flex items-center px-2 py-2 text-sm font-medium rounded-md hover:text-white",
          isChild ? 'pl-10' : ''
        )
      }}
      onClick={() => handleSidebar(false)}
    >
      {({ isActive }) => {
        return  item.icon ?
        (
          <>
            <item.icon
              className={`flex-shrink-0 w-6 h-6 mr-3 ${isActive ? 'stroke-revtron-cyan' : 'stroke-revtron-gray2'}`}
              aria-hidden="true"
            />
            {item.name}
          </>
        ): item.name
      }}
    </NavLink>
  )

  return (
    <Fragment>
      <div
        className={`transition-all duration-100 md:transition-none flex flex-col fixed inset-y-0 z-50 md:w-64 ${sidebarOpen ? 'w-64' : 'w-0'}`}
      >
        {sidebarOpen && <div className='fixed w-full h-full bg-gray-600 bg-opacity-75' ref={ref} />}
        <div className="flex flex-col flex-grow pt-5 overflow-y-auto bg-revtron-black dark:bg-header-dark z-10">
          <div className="flex items-center flex-shrink-0 px-4">
            <img 
              className="w-36 h-auto"
              src={revtronLogo}
              alt="RevTron"
            />
          </div>
          <div className="flex flex-col flex-1 px-2 pb-4 mt-7">
            <nav className="flex-1 space-y-2">
              {navigations.map((item: any) => 
              !item.children ? (
                <MenuItem key={item.name} item={item} />
              ) : (
                <Disclosure as="div" key={item.name} className="space-y-1">
                  {({ open }) => (
                    <>
                      <Disclosure.Button
                        className={'hover:bg-sidebar-active dark:hover:bg-revtron-dark3 text-table-text group w-full flex items-center pl-2 pr-1 py-2 text-left text-sm font-medium rounded-md hover:text-white'}
                      >
                        <item.icon
                          className={`flex-shrink-0 w-6 h-6 mr-3 ${open ? 'stroke-revtron-cyan' : 'stroke-revtron-gray2'}`}
                          aria-hidden="true"
                        />
                        <span className="flex-1">{item.name}</span>
                        <svg
                          className={classNames(
                            open ? 'text-table-text rotate-90' : 'text-white',
                            'ml-3 h-5 w-5 flex-shrink-0 transform transition-colors duration-150 ease-in-out hover:text-white'
                          )}
                          viewBox="0 0 20 20"
                          aria-hidden="true"
                        >
                          <path d="M6 6L14 10L6 14V6Z" fill="currentColor" />
                        </svg>
                      </Disclosure.Button>
                      <Disclosure.Panel className="space-y-1">
                        {item.children.map((subItem: { name: string, href: string, key: string }) => (
                          <MenuItem key={subItem.key} item={subItem} isChild />
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </nav>
            <NavLink
              key="settings"
              to="/settings"
              className={({ isActive }) => {
                return classNames(
                  isActive
                    ? "bg-sidebar-active dark:bg-revtron-dark3 text-white"
                    : "hover:bg-sidebar-active dark:hover:bg-revtron-dark3 text-table-text",
                  "group flex items-center px-2 py-2 text-sm font-medium rounded-md hover:text-white"
                )
              }}
              onClick={() => handleSidebar(false)}
            >
              {({ isActive }) => {
                return (
                  <>
                    <CogIcon
                      className={`flex-shrink-0 w-6 h-6 mr-3 ${isActive ? 'stroke-revtron-cyan' : 'stroke-revtron-gray2'}`}
                      aria-hidden="true"
                    />
                    Settings
                  </>
                )
              }}
            </NavLink>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Sidebar
