import PDLJS, { PersonSearchParams, PersonSearchResponse, BulkPersonRetrieveParams, BulkPersonRetrieveResponse } from 'peopledatalabs'
import { PDLQuery } from '../@types/module'

const PDL_API_KEY = process.env.REACT_APP_PDL_API_KEY as string

const PDLJSClient = new PDLJS({ apiKey: PDL_API_KEY })

export const findPeopleService = async (query: PDLQuery): Promise<PersonSearchResponse> => {
  const { scroll_token, job_company_website, location_locality, location_name, mapping, ...other } = query
  const esQuery: any = {
    query: {
      bool: {
        must: [
          {
            exists: {field: "work_email"}
          },
        ]
      }
    }
  }

  // Static filters - job_company_website
  if (job_company_website.length > 0) {
    if (job_company_website.length === 1) {
      esQuery.query.bool.must.push({ term: { job_company_website: job_company_website.join() } })
    } else {
      esQuery.query.bool.must.push({ terms: { job_company_website } })
    }
  }

  // Static filters - location_locality
  if (location_locality.length > 0) {
    if (location_locality.length === 1) {
      esQuery.query.bool.must.push({ term: { location_locality: location_locality.join() } })
    } else {
      esQuery.query.bool.must.push({ terms: { location_locality } })
    }
  }

  // Static filters - location_name
  if (location_name.length > 0) {
    if (location_name.length === 1) {
      esQuery.query.bool.must.push({ term: { location_name: location_name.join() } })
    } else {
      esQuery.query.bool.must.push({ terms: { location_name } })
    }
  }

  // Dynamic filters
  Object.keys(other).forEach((key) => {
    const value = other[key]
    esQuery.query.bool.must.push({ terms: { [key]: value } })
  })

  const params: PersonSearchParams = {
    searchQuery: esQuery, 
    size: 10,
    pretty: true,
    titlecase: true,
    scroll_token,
  }

  return await PDLJSClient.person.search.elastic(params).catch(error => {
    throw error
  })
}

// bulk retreive data from PDL
export const bulkRetrievePeople = async (ids: string[]): Promise<BulkPersonRetrieveResponse> => {
  const params: BulkPersonRetrieveParams = {
    requests: ids.map(id => ({ id }))
  }

  return await PDLJSClient.person.bulk.retrieve(params).catch(error => {
    throw error
  })
}
