import { Fragment, FC } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { classNames } from '../../../utils/functions'
import { DropdownOption } from '../../../@types/common/Dropdown'

type DropdownProps = {
  value: string
  options: DropdownOption[]
  onSelect: (v: string) => void
  classnames?: string
}

const Dropdown: FC<DropdownProps> = ({ value, options, onSelect, classnames = 'w-24' }) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className={`inline-flex justify-between gap-x-1.5 rounded-md bg-white dark:bg-revtron-dark1 px-3 py-2 text-sm font-normal text-gray-900 dark:text-revtron-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-revtron-dark2 hover:bg-gray-50 ${classnames}`}>
          {options.find(o => o.value === value)?.label || 'Select an option'}
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={`absolute right-0 z-10 mt-1 ${classnames} origin-top-right divide-y divide-gray-100 rounded-md bg-white dark:bg-revtron-dark2 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}>
          <div className="py-1 max-h-[300px] overflow-y-auto">
            {options.map((option, index) => (
              <Menu.Item key={`${option.value}_${index}`}>
              {({ active }) => (
                <span
                  className={classNames(
                    active ? 'bg-gray-100 dark:bg-revtron-dark3 text-gray-900 dark:text-revtron-white' : 'text-gray-700 dark:text-revtron-white',
                    'block px-4 py-2 text-sm cursor-pointer'
                  )}
                  onClick={() => onSelect(option.value)}
                >
                  {option.label}
                </span>
              )}
            </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default Dropdown