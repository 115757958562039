import { FC, Fragment, memo } from 'react'
import { ContactTableProps, ContactRecord } from '../../../../@types/module'
import TableRecord from './TableRecord';
import Spin from '../../../atoms/spin'

type TableBodyProps = Pick<ContactTableProps, 'loading' | 'tableData'> & {
  selectable: string
  selectedRows: any[]
  onCheckboxChange: () => void
  onRowSelect: (id: any) => void
}

const TableBody: FC<TableBodyProps> = ({
  loading,
  tableData,
  selectedRows,
  selectable,
  onCheckboxChange,
  onRowSelect,
}) => {
  return (
    <div className="icp-accounts mt-8 mb-8">
      <div className="rounded-lg shadow text-black dark:text-white bg-white dark:bg-revtron-dark2 flex items-center p-6 mb-3">
        <div className='mr-6 p-1'>
          <input
            id="check_box_handler"
            aria-describedby="row-selectable"
            name="row"
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-revtron-cyan1 focus:ring-revtron-cyan1"
            checked={selectable === 'checked'}
            onChange={onCheckboxChange}
          />
        </div>
        <div className='flex-1 p-1'>Name</div>
        <div className='flex-1 p-1'>Title</div>
        <div className='flex-1 p-1'>Company</div>
        <div className='w-52 p-1'>Contacts</div>
      </div>
      {loading && (
        <div className='flex justify-center bg-white dark:bg-revtron-dark2 shadow rounded-lg p-7 mb-6'>
          <Spin />
        </div>
      )}
      {!loading && tableData.length === 0 && (
        <div className='flex justify-center bg-white dark:bg-revtron-dark2 shadow rounded-lg p-7 mb-6 text-gray-500 dark:text-gray-500'>
          No records
        </div>
      )}
      {tableData.length > 0 && tableData.map((detail: ContactRecord, index: number) => (
        <Fragment key={index.toString()}>
          <TableRecord
            detail={detail}
            index={index + 1}
            selectedRows={selectedRows}
            onRowSelect={onRowSelect}
          />
        </Fragment>
      ))}
    </div>
  )
}

export default memo(TableBody)
