import { FC } from 'react'
import { SummaryTileContainer, SummaryTile } from '../../molecules/summary'
import { numberWithCommas, isInteger } from '../../../utils/functions'
import { ICPSummary } from '../../../@types/module'
import { useFeatureContext } from '../../../hooks/featureContext'

const Summary: FC<{ data: ICPSummary }> = ({ data }) => {
  const { isHarry, isGigPro } = useFeatureContext()

  return (
    <div className="w-full">
      <SummaryTileContainer className='mb-6'>
        <SummaryTile
          label="# of Businesses"
          value={numberWithCommas(isInteger(data.businessCount, 0))}
        />
        <SummaryTile
          label="Median ICP Score"
          value={`${Math.round(data.averageICPScore)}%`}
        />
        <SummaryTile
          label={isGigPro ? "Median Rating (1-5)" : "Median Employee Count"}
          value={isGigPro ? numberWithCommas(data.averageEmployeeCount.toFixed(0)) : (data.averageEmployeeCount) }
        />
        <SummaryTile
          label={isHarry ? "Median # of Locations" : isGigPro ? "Median # of Reviews" : "Median Age of Business"}
          value={data.averageBusinessAge.toFixed(0)}
        />
        <SummaryTile
          label={isGigPro ? "Median Price (1-4)" : "Median Annual Revenue"}
          value={isGigPro ? data.averageAnnualRevenue : `$${numberWithCommas(data.averageAnnualRevenue.toFixed(0))}`}
        />
      </SummaryTileContainer>
    </div>
  )
}

export default Summary
