import { FC } from 'react'
import moment from 'moment'

import Spin from '../../atoms/spin'
import MultipleSelect from '../../molecules/multipleSelect'
import DateRangePicker from '../../common/date-range-picker'
import { DateRangeType } from '../../../@types/common/DatePicker'

const Categories: FC<CategoriesProps> = ({ filters, setFilters, data, loading, ximaEnabled = false, harriEnabled = false }) => {
  if (loading) {
    return (
      <div className='w-full flex justify-center items-center my-4'>
        <Spin />
      </div>
    )
  }

  const stages: any[] = []
  const types: any[] = []
  const forecasts: any[] = []
  const owners: any[] = []
  const products: any[] = []
  const regions: any[] = []
  if (data && data.pipeline_opp_detail_ml_gql) {
    data.pipeline_opp_detail_ml_gql.forEach((opp: any) => {
      if (opp.stage !== null && stages.indexOf(opp.stage) === -1) {
        stages.push(opp.stage)
      }
      if (opp.type !== null && types.indexOf(opp.type) === -1) {
        types.push(opp.type)
      }
      if (opp.forecast_category !== null && forecasts.indexOf(opp.forecast_category) === -1) {
        forecasts.push(opp.forecast_category)
      }
      if (opp.opportunity_owner !== null && owners.indexOf(opp.opportunity_owner) === -1) {
        owners.push(opp.opportunity_owner)
      }
      if (ximaEnabled && opp.custom_filter_1 && products.indexOf(opp.custom_filter_1) === -1) {
        products.push(opp.custom_filter_1)
      }
      if (harriEnabled && opp.custom_filter_1 && regions.indexOf(opp.custom_filter_1) === -1) {
        regions.push(opp.custom_filter_1)
      }
    })
  }

  return (
    <div className='pipeline-categories-content flex items-center flex-wrap'>
      <MultipleSelect
        className='w-1/2 xl:w-auto mr-2'
        value={filters.stage}
        onChange={(value) => setFilters({ ...filters, stage: value })}
        options={stages.map(value => ({ value, label: value }))}
        placeholder='Stage Name'
      />
      <MultipleSelect
        className='w-1/2 xl:w-auto mr-2'
        value={filters.type}
        onChange={(value) => setFilters({ ...filters, type: value })}
        options={types.map(value => ({ value, label: value }))}
        placeholder='Revenue Category'
      />
      <MultipleSelect
        className='w-1/2 xl:w-auto mr-2'
        value={filters.forecast}
        onChange={(value) => setFilters({ ...filters, forecast: value })}
        options={forecasts.map(value => ({ value, label: value }))}
        placeholder='Forecast Category'
      />
      <MultipleSelect
        className='w-1/2 xl:w-auto mr-2'
        value={filters.owner}
        onChange={(value) => setFilters({ ...filters, owner: value })}
        options={owners.map(value => ({ value, label: value }))}
        placeholder='Opportunity Owner'
      />
      {ximaEnabled && (
        <MultipleSelect
          className='w-1/2 xl:w-auto mr-2 min-w-40'
          value={filters.custom_filter_1}
          onChange={(value) => setFilters({ ...filters, custom_filter_1: value })}
          options={products.map(value => ({ value, label: value }))}
          placeholder='Product'
        />
      )}
      {harriEnabled && (
        <MultipleSelect
          className='w-1/2 xl:w-auto mr-2 min-w-40'
          value={filters.custom_filter_1}
          onChange={(value) => setFilters({ ...filters, custom_filter_1: value })}
          options={regions.map(value => ({ value, label: value }))}
          placeholder='Region'
        />
      )}
      <DateRangePicker
        classNames='flex-1 flex justify-end py-2 pr-2'
        dateFilter={{ start: filters.date[0], end: filters.date[1] }}
        applyDateFilter={(dateRange: DateRangeType) => {
          setFilters({ ...filters, date: [moment(dateRange.start, 'YYYY/MM/DD'), moment(dateRange.end, 'YYYY/MM/DD')] })
        }}
      />
    </div>
  )
}

interface CategoriesProps {
  filters: any
  setFilters: (options: any) => void
  data: any
  loading: boolean
  ximaEnabled?: boolean
  harriEnabled?: boolean
}

export default Categories
