import { FC, useState, useCallback, useMemo, useEffect } from 'react'
import TableHeader from './TableHeader'
import TableBody from './TableBody'
import { PeopleTableProps, PeopleTableFilter } from '../../../../@types/module'
import { SelectableOptionsEnum } from '../../../../@types/common/Dropdown'

type PassProps = PeopleTableProps & PeopleTableFilter

const PeopleTable: FC<PassProps> = ({
  peopleData: sourceData,
  submitPeopleData,
  fields,
  filters,
  addFilter,
  removeFilter,
  clearAllFilters,
  ...other
}) => {
  const [pageSize, setPageSize] = useState<number>(10)
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [selectable, setSelectable] = useState<SelectableOptionsEnum>(SelectableOptionsEnum.UNCHECKED)

  const [nameFilter, setNameFilter] = useState<string>('')

  useEffect(() => {
    if (!other.isSubmitting) {
      setSelectedRows([])
    }
  }, [other.isSubmitting])

  const changeNameFilter = useCallback((filter: string) => {
    setNameFilter(filter)
  }, [])

  const changePagination = useCallback((pn: number, pz: number) => {
    if (pn !== pageNumber) setPageNumber(pn)
    if (pz !== pageSize) setPageSize(pz)
  }, [pageSize, pageNumber])

  const peopleData = useMemo(() => {
    return sourceData.filter(s => !nameFilter || s.fullName.toLowerCase().includes(nameFilter.toLowerCase()))
  }, [nameFilter, sourceData])

  const uuids = useMemo(() => {
    return peopleData.filter(d => !d.isInCRM).map(d => d.id).sort((a, b) => a > b ? -1 : 1)
  }, [peopleData])

  useEffect(() => {
    const checkbox = document.getElementById('ppl_check_box_handler') as any

    if (selectedRows.length === 0) {
      checkbox.indeterminate = false
      setSelectable(SelectableOptionsEnum.UNCHECKED)
    } else {
      const _selectedRows = selectedRows.sort((a, b) => a > b ? -1 : 1)
      // Compare 2 arrays
      if (JSON.stringify(uuids) === JSON.stringify(_selectedRows)) {
        checkbox.indeterminate = false
        setSelectable(SelectableOptionsEnum.CHECKED)
      } else {
        checkbox.indeterminate = true
      }
    }
  }, [uuids, selectedRows])

  const onCheckboxChange = useCallback(() => {
    if (selectable === SelectableOptionsEnum.UNCHECKED) {
      setSelectedRows(uuids)
      setSelectable(SelectableOptionsEnum.CHECKED)
    } else {
      setSelectedRows([])
      setSelectable(SelectableOptionsEnum.UNCHECKED)
    }
  }, [uuids, selectable])

  const onRowSelect = useCallback((id: any) => {
    const _selectedRows = [...selectedRows]
    const index = selectedRows.findIndex(i => i === id)
    if (index < 0) {
      _selectedRows.push(id)
    } else {
      _selectedRows.splice(index, 1)
    }
    setSelectedRows(_selectedRows)
  }, [selectedRows])

  const onSubmit = useCallback(() => {
    if (selectedRows.length > 0) {
      submitPeopleData(selectedRows)
    }
  }, [selectedRows, submitPeopleData])

  return (
    <>
      <TableHeader
        fields={fields}
        filters={filters}
        addFilter={addFilter}
        removeFilter={removeFilter}
        clearAllFilters={clearAllFilters}
        pageSize={pageSize}
        pageNumber={pageNumber}
        nameFilter={nameFilter}
        changeFilter={changeNameFilter}
        changePagination={changePagination}
        submitDisabled={selectedRows.length === 0}
        isSubmitting={other.isSubmitting}
        onClickSubmit={onSubmit}
      />
      <TableBody
        peopleData={peopleData}
        selectable={selectable}
        onCheckboxChange={onCheckboxChange}
        onRowSelect={onRowSelect}
        selectedRows={selectedRows}
        {...other}
      />
    </>
  )
}

export default PeopleTable
