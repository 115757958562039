import React, { FC } from 'react'

const Input: FC<InputProps> = ({
  id = 'email',
  name = 'email',
  type = 'email',
  autoComplete = 'email',
  required,
  value = '',
  onChange,
  placeholder = 'email'
}) => {
  return (
    <input
      id={id}
      name={name}
      placeholder={placeholder}
      type={type}
      autoComplete={autoComplete}
      required={required}
      className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 dark:border-neutral-700 dark:bg-revtron-dark1 dark:text-gray-200 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-revtron-cyan focus:border-revtron-cyan sm:text-sm"
      tabIndex={2}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  )
}

interface InputProps {
  id: string
  name: string
  type: 'email' | 'password' | 'text' | 'number'
  autoComplete: 'email' | 'current-password'
  required?: boolean
  value: string
  onChange: any
  placeholder: string
}

export default Input
