import { FC } from 'react'
import moment from 'moment'

import ActivityDrill from './activityDrill'
import Spin from '../../atoms/spin'
import CustomTable from '../../molecules/table'

import {
  sortCompareFuncByDate,
  numberWithCommas,
  isInteger,
  findColorInGradient
} from '../../../utils/functions'
import { useFeatureContext } from '../../../hooks/featureContext'

const EngagementTable: FC<EngagementTableProps> = ({ data, ids, loading }) => {
  const { isHarry } = useFeatureContext()

  if (loading) {
    return (
      <div className='w-full flex justify-center items-center my-4'>
        <Spin />
      </div>
    )
  }

  let min = 0
  let max = 0

  if (data && data.pipeline_opp_detail_ml_gql) {
    min = data && data.pipeline_opp_detail_ml_gql.reduce((res: any, cur: any) => res > cur.coalesce ? cur.coalesce : res, 0)
    max = data && data.pipeline_opp_detail_ml_gql.reduce((res: any, cur: any) => res < cur.coalesce ? cur.coalesce : res, 0)  
  }

  const columns = [
    {
      title: 'Opportunity Name',
      dataIndex: 'opportunity_name',
      render: (opportunityName: any, row: any) => (
        <div>
          <a target='_blank' rel='noopener noreferrer' href={row.opportunity_link} className='text-gray-900 dark:text-gray-300'>
            {opportunityName}
          </a>
          <p className='text-gray-500 dark:text-gray-400'>{row.type}</p>
        </div>
      ),
      sorter: (a: any, b: any) => a.opportunity_name.localeCompare(b.opportunity_name)
    },
    {
      title: 'Opportunity Owner',
      dataIndex: 'opportunity_owner',
      width: '15%',
      sorter: (a: any, b: any) => a.opportunity_owner.localeCompare(b.opportunity_owner)
    },
    {
      title: 'Stage name',
      dataIndex: 'stage',
      width: '15%',
      sorter: (a: any, b: any) => a.stage.localeCompare(b.stage)
    },
    {
      title: 'Forecast Category',
      dataIndex: 'forecast_category',
      width: '150px',
      sorter: (a: any, b: any) => a.forecast_category.localeCompare(b.forecast_category)
    },
    // {
    //   title: 'Account Name',
    //   dataIndex: 'account_name',
    //   width: '20%',
    //   sorter: (a, b) => a.account_name.localeCompare(b.account_name)
    // },
    {
      title: 'Age',
      dataIndex: 'opportunity_age',
      width: '8%',
      sorter: (a: any, b: any) => a.opportunity_age - b.opportunity_age
    },
    {
      title: 'Closedate',
      dataIndex: 'closedate',
      render: (date: any) => moment(date).format('MM/DD/YYYY'),
      width: '8%',
      sorter: (a: any, b: any) => sortCompareFuncByDate(a, b, 'closedate')
    },
    // {
    //   title: 'ARR',
    //   dataIndex: 'amount',
    //   render: (amount: any, row: any) => (
    //     <div style={{ backgroundColor: `rgba(0, 94, 102, ${getAlphaValue(amount)})` }}>${numberWithCommas(isInteger(amount))}</div>
    //   ),
    //   width: '5%',
    //   sorter: (a: any, b: any) => a.amount - b.amount
    // },
    {
      title: isHarry ? 'MRR' : 'ARR',
      dataIndex: 'amount',
      render: (amount: any, row: any) => (
        <div>${numberWithCommas(isInteger(amount))}</div>
      ),
      width: '5%',
      sorter: (a: any, b: any) => a.amount - b.amount
    },
    {
      title: 'ML Probability',
      dataIndex: 'coalesce',
      render: (coalesce: any, row: any) => {
        const styles: any = {}
        const avgCoalesce = (min + max) / 2
        if (avgCoalesce !== 0) {
          // const bgColor = findColorInGradient(coalesce, min, max)
          // const bgColor = row.probability === 100 ? `rgb(168, 85, 247)` : `rgba(5, 217, 232, ${row.probability / 100})`
          const bgColor = row.probability === 100 ? `rgb(168, 85, 247)` : `rgba(5, 217, 232, ${row.coalesce})`
          // const bgColor =`rgba(5, 217, 232, ${row.coalesce})`
          const textColor = row.probability === 100 ? `white` : `black`
          // const textColor = coalesce > avgCoalesce ? `rgba(255, 255, 255, ${coalesce})` : `rgba(0, 0, 0, ${1 - coalesce})`
          styles.backgroundColor = bgColor
          styles.color = textColor
        }
        return (
          <div style={styles} className='rounded-full dark:text-table-text font-semibold leading-5 text-center'>
            {Math.round(isInteger(coalesce * 100) as any)}%
          </div>
        )
      },
      width: '120px',
      sorter: (a: any, b: any) => a.coalesce - b.coalesce
    },
    {
      title: '',
      dataIndex: 'oppotunity_link',
      render: (_: any, row: any) => {
        return (
          <a href={row.opportunity_link} target='_blank' rel="noreferrer">
            <div className='flex items-center justify-center w-8 h-8 rounded-full bg-revtron-teal'>
              <svg width="24" height="17" viewBox="0 0 24 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9.98936 1.90477C10.7626 1.09908 11.8391 0.599407 13.0297 0.599407C14.6124 0.599407 15.9933 1.48194 16.7286 2.79208C17.3676 2.50656 18.0749 2.34775 18.8192 2.34775C21.6737 2.34775 23.988 4.68216 23.988 7.56167C23.988 10.4415 21.6737 12.7759 18.8192 12.7759C18.4708 12.7759 18.1303 12.7411 17.801 12.6745C17.1535 13.8296 15.9195 14.61 14.5031 14.61C13.9102 14.61 13.3494 14.473 12.8501 14.2295C12.1936 15.7736 10.6642 16.8563 8.88174 16.8563C7.02549 16.8563 5.44348 15.6818 4.83623 14.0345C4.57086 14.0909 4.29591 14.1202 4.01379 14.1202C1.80371 14.1202 0.0119934 12.3101 0.0119934 10.0768C0.0119934 8.58013 0.816999 7.27341 2.01307 6.57428C1.76682 6.00767 1.62986 5.38232 1.62986 4.72485C1.62986 2.15648 3.71496 0.0744629 6.28674 0.0744629C7.79668 0.0744629 9.13858 0.792377 9.98936 1.90477Z" fill="#01012B"/>
              </svg>
            </div>
          </a>
        )
      },
      width: '5%',
    }
  ]

  const scatterData = (data && data.pipeline_opp_detail_ml_gql) || []
  const selectedOpps = scatterData.filter((opp: any) => ids.indexOf(opp.opportunity_id) > -1)
  const dataSource = selectedOpps.length > 0 ? selectedOpps : scatterData

  return (
    <div className='mt-5'>
      <CustomTable
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        expandable={{
          expandedRowRender: (record: any) => {
            return <ActivityDrill accountId={record.account_id} />
          },
          rowExpandable: (record: any) => true
        }}
      />
    </div>
  )
}

interface EngagementTableProps {
  data: any
  ids: any
  loading: boolean
}

export default EngagementTable