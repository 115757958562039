import { FC, useCallback, useEffect, useMemo, useState} from 'react'
import TableHeader from './TableHeader';
import TableBody from './TableBody';
import { AccountTableProps } from '../../../@types/common/AccountTable';
import Pagination from '../../common/pagination'
import { SelectableOptionsEnum } from '../../../@types/common/Dropdown'

const AccountTable: FC<AccountTableProps> = ({
  tableData: sourceData,
  totalRowsCount,
  tableHeaderAction,
  loading = false,
  isAccountRec = true,
  ...rest
}) => {
  const [selectable, setSelectable] = useState<SelectableOptionsEnum>(SelectableOptionsEnum.UNCHECKED)
  const [selectedRows, setSelectedRows] = useState<any[]>([])
  const [nameFilter, setNameFilter] = useState<string>('')

  const tableData = useMemo(() => {
    return sourceData.filter(d => !nameFilter || d.name.toLowerCase().includes(nameFilter.toLowerCase()))
  }, [sourceData, nameFilter])

  const uuids = useMemo(() => {
    return tableData.map(d => d.uuid).sort((a, b) => a > b ? -1 : 1)
  }, [tableData])

  // Clear selected rows
  useEffect(() => {
    if (!rest.headerActionLoading) {
      setSelectedRows([])
      const checkbox = document.getElementById('check_box_handler') as any
      checkbox.indeterminate = false
    }
  }, [rest.headerActionLoading])
  
  useEffect(() => {
    const checkbox = document.getElementById('check_box_handler') as any

    if (selectedRows.length === 0) {
      checkbox.indeterminate = false
      setSelectable(SelectableOptionsEnum.UNCHECKED)
    } else {
      const _selectedRows = selectedRows.sort((a, b) => a > b ? -1 : 1)
      // Compare 2 arrays
      if (JSON.stringify(uuids) === JSON.stringify(_selectedRows)) {
        checkbox.indeterminate = false
        setSelectable(SelectableOptionsEnum.CHECKED)
      } else {
        checkbox.indeterminate = true
      }
    }
  }, [uuids, selectedRows])

  const changeNameFilter = useCallback((filter: string) => {
    setNameFilter(filter)
  }, [])

  const onCheckboxChange = useCallback(() => {
    if (selectable === SelectableOptionsEnum.UNCHECKED) {
      setSelectedRows(uuids)
      setSelectable(SelectableOptionsEnum.CHECKED)
    } else {
      setSelectedRows([])
      setSelectable(SelectableOptionsEnum.UNCHECKED)
    }
  }, [uuids, selectable])

  const onRowSelect = useCallback((id: any) => {
    const _selectedRows = [...selectedRows]
    const index = selectedRows.findIndex(i => i === id)
    if (index < 0) {
      _selectedRows.push(id)
    } else {
      _selectedRows.splice(index, 1)
    }
    setSelectedRows(_selectedRows)
  }, [selectedRows])

  const onTableHeaderActionClick = useCallback(() => {
    if (selectedRows.length > 0) {
      tableHeaderAction(selectedRows)
    }
  }, [selectedRows, tableHeaderAction])

  return (
    <>
      <TableHeader
        onTableHeaderActionClick={onTableHeaderActionClick}
        headerActionDisabled={selectedRows.length === 0}
        changeNameFilter={changeNameFilter}
        {...rest}
      />
      <TableBody
        tableData={tableData}
        loading={loading}
        selectable={selectable}
        selectedRows={selectedRows}
        onCheckboxChange={onCheckboxChange}
        onRowSelect={onRowSelect}
        isAccountRec={isAccountRec}
      />
      <Pagination
        total={totalRowsCount}
        current={rest.pageNumber}
        pageSize={rest.pageSize}
        onChange={rest.changePagination} showInfo
      />
    </>
  )
}

export default AccountTable
