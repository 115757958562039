import { FC, Fragment, useState, useEffect, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { PencilIcon } from '@heroicons/react/solid'
import TextField from '../../molecules/textField'

import { useChangeEmail, useFileUpload } from '@nhost/react'
import { useUpdateUserMutation, useUpdateUserAvatarMutation } from '../../../graphql/__generated__/graphql'
import { storage } from '../../../utils/nhost'
import { alert, copyResizedImage } from '../../../utils/functions'

interface initialStateProp {
  displayName: string,
  email: string,
  file: any
}
const initialState: initialStateProp = {
  displayName: '',
  email: '',
  file: null
}

const EditUserModal: FC<EditUserModalProps> = ({ user, isOpen, closeModal }) => {
  const inputFile: any = useRef(null)
  const [updateUser] = useUpdateUserMutation()
  const [updateUserAvatar] = useUpdateUserAvatarMutation();
  const { changeEmail } = useChangeEmail()
  const { upload } = useFileUpload()
  const [state, setState] = useState(initialState)

  useEffect(() => {
    if (user) {
      setState({
        ...state,
        displayName: user.displayName,
        email: user.email
      })
    }
  }, [user, isOpen])

  const onCloseModal = () => {
    setState(initialState)
    closeModal()
  }

  const onUserImgClick = () => {
    inputFile.current.click()
  }

  const onChangeFile = (e: any) => {
    e.stopPropagation()
    e.preventDefault()
    let imageFile = e.target.files[0]

    if (imageFile) {
      const reader = new FileReader()
      reader.onload = function (e) {
        const img: any = document.createElement("img")
        img.onload = function () {
          const file = copyResizedImage(img, imageFile)
          setState({...state, file})
        }
        img.src = e.target?.result
      }
      reader.readAsDataURL(imageFile)
    }
  }

  const onSaveUser = async () => {
    if (user) {
      try {
        if (user.displayName !== state.displayName) {
          await updateUser({
            variables: {
              id: user.id,
              displayName: state.displayName
            }
          }) 
        }
        if (user.email !== state.email) {
          const result = await changeEmail(state.email)
          if (result.needsEmailVerification) {
            alert('info', `An email has been sent to ${state.email}. Please check your inbox and follow the link to confirm the email change.`)
          }
        }
        if (state.file) {
          const { id: fileId } = await upload({file: state.file}) // upload a file to nhost storage
          if (fileId) {
            const publicUrl = await storage.getPublicUrl({ fileId }) // get a public url of the file that is uploaded in storage
            await updateUserAvatar({ // update user data with public url 
              variables: {
                id: user.id,
                avatarUrl: publicUrl
              }
            })
          }
        }
        onCloseModal()
        alert('success', 'Successful in updating profile!')
      } catch {
        alert('error', 'Failed in updating profile.')
      }
    }
  }

  let filePath = ''
  if (state.file) {
    filePath = URL.createObjectURL(state.file)
  }

  const isChangedField = user.displayName !== state.displayName || state.file

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onCloseModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black dark:bg-gray-700 !bg-opacity-30" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all dark:bg-header-dark">
                <div className='px-4 py-6 border-b border-gray-300 dark:border-neutral-700'>
                  <div className='flex justify-between items-center mb-2'>
                    <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-200">Edit Profile</h3>
                    <XIcon className='w-4 cursor-pointer dark:text-gray-200' onClick={onCloseModal} />
                  </div>
                </div>
                <div className="px-4 py-6 border-b border-gray-300 dark:border-neutral-700">
                  <div className="w-24 h-24 bg-gray-100 dark:bg-neutral-700 rounded-full overflow-hidden relative p-1 mx-auto mb-6">
                    <img 
                      className="w-full h-full object-cover rounded-full"
                      src={filePath || user.avatarUrl}
                      alt=""
                    />
                    <div
                      className='w-full flex justify-center absolute bottom-0 left-0 right-0 cursor-pointer bg-gray-300/50 dark:bg-neutral-500/50'
                      onClick={onUserImgClick}
                    >
                      <PencilIcon className='w-5 text-black dark:text-white mb-2' />
                    </div>
                    <input ref={inputFile} hidden accept="image/*" multiple type="file" onChange={onChangeFile} />
                  </div>
                  <TextField
                    className='mb-6'
                    label="Display Name"
                    placeholder="Display Name"
                    id="display-name"
                    name="display-name"
                    type="text"
                    value={state.displayName}
                    onChange={(value: any) => setState({...state, displayName: value})}
                  />
                  {/* <TextField
                    className='mb-6'
                    label="Email"
                    placeholder="Email"
                    id="user-email"
                    name="user-email"
                    type="text"
                    value={state.email}
                    onChange={(value: any) => setState({...state, email: value})}
                  /> */}
                </div>

                <div className="px-4 py-6">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent bg-cyan-400 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-cyan-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={onSaveUser}
                    disabled={!isChangedField}
                  >
                    Save
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

interface EditUserModalProps {
  user: any
  isOpen: boolean
  closeModal: () => void
}

export default EditUserModal
