import { FC } from 'react'

import ShadowCard from '../../templates/shadowCard'
import CustomTable from '../../molecules/table'

import { useWeeklyLeaderboardMeetingDetailQuery } from '../../../graphql/__generated__/graphql'
import { convertUTCToLocal } from '../../../utils/functions'

const MeetingDetail: FC = () => {
  const { data } = useWeeklyLeaderboardMeetingDetailQuery()

  const columns = [
    {
      title: 'Name (Account)',
      dataIndex: 'account_name',
      sorter: (a: any, b: any) => a.account_name.toUpperCase() > b.account_name.toUpperCase() ? 1 : -1,
      render: (text: string) => {
        return (
          <p className='account-name'>{text}</p>
        )
      }
    },
    {
      title: 'Contact Name',
      dataIndex: 'contact',
      sorter: (a: any, b: any) => a.contact.toUpperCase() > b.contact.toUpperCase() ? 1 : -1
    },
    {
      title: 'Contact Title',
      dataIndex: 'title',
      sorter: (a: any, b: any) => a.title.toUpperCase() > b.title.toUpperCase() ? 1 : -1
    },
    {
      title: 'Meeting Subject',
      dataIndex: 'subject',
      sorter: (a: any, b: any) => a.subject.toUpperCase() > b.subject.toUpperCase() ? 1 : -1
    },
    {
      title: 'Created Date (Meeting)',
      dataIndex: 'createddate',
      sorter: (a: any, b: any) => (a.createddate < b.createddate) ? -1 : ((a.createddate > b.createddate) ? 1 : 0),
      render: (text: string) => {
        return (
          <p>{convertUTCToLocal(text)}</p>
        )
      }
    },
    {
      title: 'Activity Date',
      dataIndex: 'activitydate',
      sorter: (a: any, b: any) => (a.activitydate < b.activitydate) ? -1 : ((a.activitydate > b.activitydate) ? 1 : 0),
      render: (text: string) => {
        return (
          <p>{convertUTCToLocal(text)}</p>
        )
      }
    },
    {
      title: 'Meeting Owner',
      dataIndex: 'meeting_owner',
      sorter: (a: any, b: any) => a.meeting_owner.toUpperCase() > b.meeting_owner.toUpperCase() ? 1 : -1
    }
  ]

  return (
    <ShadowCard label='Meeting Detail' className='mt-5'>
      <CustomTable
        columns={columns}
        dataSource={data ? data.first_meetings_leads_accounts_gql : []}
      />
    </ShadowCard>
  )
}

export default MeetingDetail
