import { FC } from 'react'
import { useSalesVelocityDataByOwnerGraphQL } from './Leaderboard.graphql'
import { Summary, Leaders, PlayerCards, WinsTable } from '../../components/organisms/leaderboard_v1'

const SalesVelocity: FC = () => {
  const {
    isLoading,
    salesVelocityData: {
      summaryData,
      boardData,
      playerCardData,
      winsTableData,
    },
    oppId,
    onSelectOpp,
  } = useSalesVelocityDataByOwnerGraphQL()

  return (
    <div className='leaderboard dark:text-white'>
      <Summary data={summaryData} isLoading={isLoading} salesVelocityPage />
      <Leaders data={boardData} isLoading={isLoading} salesVelocityPage />
      <PlayerCards data={playerCardData} isLoading={isLoading} oppId={oppId} onSelectOpp={onSelectOpp} />
      <WinsTable data={winsTableData} ownerId={oppId} />
    </div>
  )
}

export default SalesVelocity
