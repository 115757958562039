/* eslint-disable react-hooks/rules-of-hooks */
import { FC, useMemo } from 'react'
import moment, { Moment } from 'moment'

import Spin from '../../atoms/spin'
import VerticalBarChart from './verticalBarChart'
import { MonthlyRevenueData } from '../../../containers/leaderboard_v1/Leaderboard.util'

import { useFeatureContext } from '../../../hooks/featureContext'
import { VerticalBarChartData } from '../../../@types/Chart'


type MonthRevenueProps = {
  data: MonthlyRevenueData[]
  isLoading: boolean
}

const MonthRevenue: FC<MonthRevenueProps> = ({ data, isLoading }) => {
  const { isHarry } = useFeatureContext()

  if (isLoading) {
    return (
      <div className='flex-1 flex justify-center bg-white dark:bg-header-dark shadow p-4 ml-6'>
        <Spin />
      </div>
    )
  }

  const chartData: VerticalBarChartData[] = useMemo(() => {
    if (isHarry) {
      return data.map((d: MonthlyRevenueData) => ({
        x: Date.parse(moment(d.date).format('MM/DD/YYYY')),
        y: d.amount / 1000,
        date: moment(d.date).format('MMM')
      }))
    }

    const cd: VerticalBarChartData[] = []
    const months: Moment[] = []
    const jan = moment().startOf('year')
    months.push(jan)
    for (let i = 1; i <= 11; i++) {
      months.push(moment(jan).set('month', i))
    }

    months.forEach(month => {
      let selectedMonth = moment(month)
      const matched = data.find((e: MonthlyRevenueData) => moment(e.date).isSame(selectedMonth))
      if (matched) {
        cd.push({
          x: Date.parse(moment(month).format('MM/DD/YYYY')),
          y: matched.amount / 1000,
          date: moment(month).format('MMM')
        })
      } else {
        cd.push({
          x: Date.parse(moment(month).format('MM/DD/YYYY')),
          y: 0,
          date: moment(month).format('MMM')
        })
      }
    })

    return cd
  }, [data, isHarry])

  return (
    <div className='flex-1 bg-white dark:bg-header-dark p-4 shadow ml-6'>
      <div className='text-sm dark:text-theme-toggle-icon'>Monthly Revenues</div>
      <VerticalBarChart data={chartData} />
    </div>
  )
}

export default MonthRevenue
