import { useState, useCallback, FC } from 'react'
import { Outlet } from 'react-router-dom'

import Sidebar from './sidebar'
import Header from './header'
import { LayoutProps } from './Layout.type'

const Layout: FC<LayoutProps> = ({ navigations }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const handleSidebar = useCallback((value: boolean): void => {
    setSidebarOpen(value)
  }, [setSidebarOpen])

  return (
    <div>
      <Sidebar
        sidebarOpen={sidebarOpen}
        handleSidebar={handleSidebar}
        navigations={navigations}
      />
      <div className='h-screen flex flex-col flex-1 md:pl-64'>
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={handleSidebar} />
        <main className="min-h-main-pannel flex-1 bg-gray-50 dark:bg-revtron-dark1 overflow-auto">
          <div className="h-full px-14 py-6 overflow-auto">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  )
}

export default Layout