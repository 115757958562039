import { FC, useEffect } from 'react'
import moment from 'moment'
import * as d3 from 'd3'
import Spin from '../../atoms/spin'

import { useMonthlyRevenueQuery } from '../../../graphql/__generated__/graphql'
import { useMonthlyBookingsQuery } from '../../../graphql/__generated__/graphql'
import { useFeatureContext } from '../../../hooks/featureContext'

import { commarize } from '../../../utils/functions'

const BookingsOverChart: FC<{viewType: string}> = ({viewType}) => {
  const { isHarry } = useFeatureContext()
  const { loading: revenueLoading, data: revenueData } = useMonthlyRevenueQuery()
  const { loading: revenueMonthlyLoading, data: revenueMonthlyData } = useMonthlyBookingsQuery()

  const getLineChartData = () => {
    const chartData: any[] = []
    let maxRevenue: any = 0
    const startDate = new Date(new Date().getFullYear(), 0, 1)
    const currentDate = new Date()
    if (revenueData) {
      const revenues: any[] = revenueData.running_revenue_gql
      if (revenues.length === 0) {
        chartData.push({
          x: startDate.toISOString(),
          y: 0
        })
        chartData.push({
          x: currentDate.toISOString(),
          y: 0
        })
      } else if (revenues.length === 1) {
        const oneDate = revenues[0].day as any
        if (Date.parse(startDate as any) !== Date.parse(oneDate as any) && Date.parse(currentDate as any) !== Date.parse(oneDate as any)) {
          chartData.push({
            x: startDate.toISOString(),
            y: 0
          })
          chartData.push({
            x: revenues[0].day,
            y: revenues[0].revenue
          })
          chartData.push({
            x: currentDate.toISOString(),
            y: revenues[0].revenue
          })
        } else if (Date.parse(startDate as any) === Date.parse(oneDate as any)) {
          chartData.push({
            x: revenues[0].day,
            y: revenues[0].revenue
          })
          chartData.push({
            x: currentDate.toISOString(),
            y: revenues[0].revenue
          })
        } else if (Date.parse(currentDate as any) === Date.parse(oneDate as any)) {
          chartData.push({
            x: startDate.toISOString(),
            y: 0
          })
          chartData.push({
            x: revenues[0].day,
            y: revenues[0].revenue
          })
        }
        maxRevenue = revenues[0].revenue
      } else {
        revenues.forEach((r) => {
          chartData.push({
            x: r.day,
            y: r.revenue
          })
          maxRevenue = maxRevenue < r.revenue ? r.revenue : maxRevenue
        })
      }
    }
    return chartData
  }

  const getBarChartData = () => {
    const chartData: any[] = []
    const months = []
    let jan = moment().startOf('year')
    if (isHarry) {
      jan = moment().startOf('year').add(1, 'month')
    }
    months.push(jan)
    for (let i = 1; i <= 11; i++) {
      months.push(Date.parse(moment(jan).add(i, 'month') as any))
    }
  
    if (revenueMonthlyData && revenueMonthlyData.monthly_bookings_gql) {
      const monthRevenues = revenueMonthlyData.monthly_bookings_gql
      months.forEach(month => {
        const matched = monthRevenues.filter(e => moment(e.date_trunc).isSame(month))
        if (matched.length > 0) {
          chartData.push({
            x: Date.parse(moment(matched[0].date_trunc).format('MM/DD/YYYY')),
            y: matched[0].sum / 1000,
            date: moment(matched[0].date_trunc).format('MMM')
          })
        } else {
          chartData.push({
            x: Date.parse(moment(month).format('MM/DD/YYYY')),
            y: 0,
            date: moment(month).format('MMM')
          })
        }
      })
    }
    return chartData
  }

  return (
    <div className="overflow-hidden rounded-lg bg-white dark:bg-header-dark dark:border-gray-500 px-4 py-5 shadow sm:p-6 mb-6">
      <div className='text-sm dark:text-theme-toggle-icon mb-2'>Running Sum of Bookings</div>
      {(revenueLoading || revenueMonthlyLoading) ? (
        <div className='flex justify-center'>
          <Spin />
        </div>
      ) : (
        <Chart
          viewType={viewType}
          lineChartData={getLineChartData()}
          barChartData={getBarChartData()}
        />
      )}
    </div>
  )
}

const Chart: FC<{ viewType: string, lineChartData: any[], barChartData: any[] }> = ({ viewType, lineChartData, barChartData }) => {
  const width = 995
  const height = 200
  const left = 60
  const right = 20
  const top = 20
  const bottom = 30
  const chartWidth = width - left - right
  const chartHeight = height - top - bottom

  useEffect(() => {
    if (viewType === 'weekly') {
      drawLineChart(lineChartData)
    } else {
      drawBarChart(barChartData)
    }
  }, [viewType, lineChartData, barChartData])

  const drawLineChart = (data: any[]) => {
    d3.select('.bookings-over-chart').select('svg').remove()

    const svg = d3
      .select('.bookings-over-chart')
      .append('svg')
      .attr('class', 'max-h-64')
      .attr('viewBox', `0 0 ${chartWidth + left + right} ${chartHeight + top + bottom}`)
      .attr('width', '100%')
      .attr('preserveAspectRatio', 'xMidYMin')
      .append('g')
      .attr('transform', `translate(${left},${top})`)

    const xScale = d3
      .scaleTime()
      .domain(
        d3.extent(data, (d) => {
          return new Date(d.x)
        }) as [Date, Date]
      )
      .range([0, chartWidth])

    svg.append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0, ${chartHeight})`)
      .call(
        d3
          .axisBottom(xScale)
          .tickFormat(x => moment(x.toString()).format('MM/DD'))
        )

    svg.select('.x-axis').select('path').remove()
    svg.select('.x-axis')
      .selectAll('g')
      .select('line')
      .remove()
      .style("stroke-dasharray", "5 5")
      .style("stroke-width", 0.5)
      .style("opacity", 0.5)
    
    svg.select('.x-axis')
      .selectAll('g')
      .select('text')
      .style('color', '#6B7280')


    const yScale = d3
      .scaleLinear()
      .domain([
        0,
        d3.max(data, (d) => {
          return d.y
        }) || 10000,
      ] as number[])
      .range([chartHeight, 0])
      .nice()

    svg.append("g")
      .attr("class", "grid")
      .attr("class","xaxis")
      .attr("class", "y-axis")
      .call(
        d3.axisLeft(yScale)
        .ticks(5)
        .tickFormat((d, i) => {
          return commarize(d)
        })
        .tickSize(-chartWidth)
        .tickPadding(10)
      )
    svg.select('.xaxis').select('path').remove()
    svg.select('xaxis')

    // remove y-axis and added y-axis grid
    svg.select('.y-axis').select('path').remove()
    svg.select('.y-axis')
      .selectAll('line')
      .style("stroke", "#555")
      .style("stroke-width", 0.5)
      .style("opacity", 0.5)
      // .remove()

    // set styles for y-axis tick label
    svg.select('.y-axis')
      .selectAll('g')
      .select('text')
      .style('color', '#6B7280')
      .style('font-size', '0.65rem')
      .style('display', d => {
        return d === 0 ? 'none' : ''
      })

    svg.append('linearGradient')
      .attr("id", "area-gradient")
      .attr("gradientUnits", "userSpaceOnUse")
      .attr("x2", 0).attr("y2", yScale(0))
      .attr("x1", 0).attr("y1", yScale(d3.max(data, (d) => d.y)))
      .selectAll("stop")						
      .data([
        {offset: "0%", color: "#4FD1C5", opacity: 0.54},
        {offset: "50%", color: "#4FD1C5", opacity: 0.1},
      ])
      .enter().append("stop")			
      .attr("offset", function(d) { return d.offset })
      .attr("stop-color", function(d) { return d.color })
      .attr("stop-opacity", function(d) { return d.opacity })

    svg
      .append('path')
      .datum(data)
      .attr("class", "area")
      .style('fill', 'url(#area-gradient)')
      .attr(
        'd',
        // @ts-ignore
        d3
          .area()
          // .curve(d3.curveBasis)
          .x((d) => {
            return xScale(new Date((d as any).x))
          })
          .y0(yScale(0))
          .y1((d) => {
            return yScale((d as any).y)
          })
      )
    svg.append('path')
      .datum(data)
      .style('fill', 'transparent')
      .attr('stroke', '#85EBD9')
      .attr('stroke-width', 3)
      .attr(
        'd',
        // @ts-ignore
        d3
          .line()
          // .curve(d3.curveBasis)
          .x((d) => {
            return xScale(new Date((d as any).x))
          })
          .y((d) => {
            return yScale((d as any).y)
          })
      )
  }

  const drawBarChart = (data: any[]) => {
    d3.select('.bookings-over-chart').select('svg').remove()

    const svg = d3
      .select('.bookings-over-chart')
      .append('svg')
      .attr('class', 'max-h-64')
      .attr('viewBox', `0 0 ${width} ${height}`)
      .attr('width', '100%')
      .attr('preserveAspectRatio', 'xMidYMin')
      .append('g')
      .attr('transform', `translate(${left},${top})`)

    const defs = svg.append('defs')
    
    const bgGradient = defs
      .append('linearGradient')
      .attr('id', 'bg-gradient')
      .attr('gradientTransform', 'rotate(90)')
    bgGradient
      .append('stop')
      .attr('stop-color', '#4fd1c58a')
      .attr('offset', '0%')
    bgGradient
      .append('stop')
      .attr('stop-color', '#4fd1c51a')
      .attr('offset', '100%')

    const getX = (d: any) => d.x
    const getY = (d: any) => d.y
  
    const x = d3.scaleBand()
      .rangeRound([0, chartWidth])
      .domain(data.map(getX))
      .padding(0.1)
  
    const y = d3.scaleLinear()
      .range([chartHeight, 0])
      .domain([
        d3.min(data, getY),
        d3.max(data, getY) === 0 ? 100 : d3.max(data, getY)
      ])
      .nice()
  
    const xAxis = d3.axisBottom(x)
    const yAxis = d3.axisLeft(y).ticks(5)

    xAxis
      .tickFormat(x => moment(x).format('MMM'))
      .tickSize(0)

    yAxis
      .ticks(5)
      .tickFormat(y => '$' + y + 'k')
      .tickPadding(10)

    yAxis.tickSize(-chartWidth)
  
    const ax = svg
      .append('g')
      .attr('class', 'x-axis')
      .attr('transform', `translate(0, ${chartHeight})`)
      .call(xAxis)
  
    // remove x-axis
    ax.select('path').remove()
    ax.selectAll('text')
      .style("alignment-baseline", "middle")
      .style('color', '#6B7280')
  
    const bx = svg
      .append('g')
      .attr('class', 'y-axis')
      .call(yAxis)
  
    // remove y-axis
    bx.select('path').remove()
    // custom y-axis grid
    bx.selectAll('line')
      .style("stroke-width", 0.5)
      .style("stroke", "#555")
      .style("opacity", 0.5)
    bx.selectAll('text')
      .style('color', '#6B7280')
  
    // Bars
    const bar = svg
      .selectAll("bar")
      .data(data)
      .enter()
      .append("g")
      .attr("class", "bar")

    // Add rect to bar
    bar.append("rect")
      .style("fill", "url(#bg-gradient)")
      .attr("stroke", "#05D9E8")
      .attr("stroke-width", "1px")
      .attr("x", d => (x(getX(d)) || 0) + 8)
      .attr("y", d => y(getY(d)))
      .attr("width", x.bandwidth()-16)
      .attr("height", d => chartHeight - y(getY(d)))

    // Add text label to bar
    bar.append("text")
      .attr("class", "label fill-black dark:fill-white")
      .attr("x", d => (x(d.x) || 0) + 30)
      .attr("y", d => y(d.y) - 10)
      .style("text-anchor", "middle")
      .style('font-size', '0.6rem')
      .text(d => `$${Math.round(d.y)}k`)
  }

  return (
    <div className="bookings-over-chart" />
  )
}

export default BookingsOverChart;
