import { FC } from 'react'
import UserInfo from '../../components/organisms/profile/userInfo'
import ResetPassword from '../../components/organisms/profile/resetPassword'
import InviteMember from '../../components/organisms/profile/inviteMember'

import ProfileGraphic from '../../assets/images/profile-graphic.svg'

const Profile: FC<ProfileProps> = () => {

  return (
    <div className='profile h-full flex items-start relative'>
      <UserInfo />
      <ResetPassword />
      <InviteMember />
      <img className="absolute -bottom-6 right-2/4" src={ProfileGraphic} alt="" />
    </div>
  );
}

interface ProfileProps {
  loading?: boolean,
}

export default Profile
