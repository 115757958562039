import { useState, FC } from 'react'
import { useSignUpEmailPassword } from '@nhost/react'
import { Link, Navigate } from 'react-router-dom'

import AuthWrapper from '../../components/templates/authWrapper'
import TextInput from '../../components/molecules/textInput'

const SignUp: FC<SignUpProps> = () => {
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const { signUpEmailPassword, isLoading, isSuccess, needsEmailVerification, isError, error } = useSignUpEmailPassword()

  const handleOnSubmit = (e: any) => {
    e.preventDefault()

    signUpEmailPassword(email, password, {
      displayName: `${firstName} ${lastName}`.trim(),
      metadata: {
        firstName,
        lastName
      }
    })
  }

  if (isSuccess) {
    return <Navigate to="/" replace={true} />
  }

  const disableForm = isLoading || needsEmailVerification

  return (
    <AuthWrapper>
      <div className='flex justify-center items-center'>
        <div className="w-full max-w-lg">
          <p className='text-center my-36 text-revtron-dark4 text-sm'>Register your account</p>
          <div className="sm:rounded-xl sm:shadow-md sm:border  border-theme-toggle-white-bg dark:border-revtron-dark3 bg-auth-background dark:bg-auth-background-dark backdrop-blur px-4 sm:px-8 py-12 flex flex-col items-center">
            {needsEmailVerification ? (
              <p className="mt-12 text-center">
                Please check your mailbox and follow the verification link to verify
                your email.
              </p>
            ) : (
              <form onSubmit={handleOnSubmit} className="w-full">
                <div className="mt-6 flex flex-col items-center space-y-6">
                  <div className="w-full flex gap-6">
                    <TextInput
                      placeholder="First name"
                      value={firstName}
                      onChange={e => setFirstName(e.target.value)}
                      required
                      disabled={disableForm}
                    />
                    <TextInput
                      placeholder="Last name"
                      value={lastName}
                      onChange={e => setLastName(e.target.value)}
                      required
                      disabled={disableForm}
                    />
                  </div>
                  <TextInput
                    type="email"
                    placeholder="Email address"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    required
                    disabled={disableForm}
                  />
                  <TextInput
                    type="password"
                    placeholder="Create password"
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    required
                    disabled={disableForm}
                  />
                </div>

                <button
                  type="submit"
                  disabled={disableForm}
                  className="mt-6 w-full bg-auth-btn-gradient font-medium inline-flex justify-center items-center rounded-md p-3 text-header-dark bg-blue-600 hover:bg-blue-500 focus:outline-none focus:ring-4 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed  disabled:hover:bg-blue-600 disabled:hover:border-bg-600 transition-colors"
                >
                  {isLoading ? 'Loading...' : 'Create account'}
                </button>
                {isError ? (
                  <p className="mt-4 text-red-500 text-center">{error?.message}</p>
                ) : null}
                <p className="sm:mt-8 text-gray-500 text-center">
                  Already have an account?{' '}
                  <Link
                    to="/sign-in"
                    className="text-blue-600 hover:text-blue-500 hover:underline hover:underline-offset-1 transition"
                  >
                    Sign in
                  </Link>
                </p>
              </form>
            )}
          </div>
        </div>
      </div>
    </AuthWrapper>
  )
}

interface SignUpProps {}

export default SignUp
