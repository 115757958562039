import Spin from '../../atoms/spin'
import LineChart from './lineChart'

import { useRunningRevenueQuery } from '../../../graphql/__generated__/graphql'
import { isInteger, numberWithCommas } from '../../../utils/functions'
import moment from 'moment'

const RevenueSum = () => {
  const {data, loading} = useRunningRevenueQuery()

  if (loading) {
    return (
      <div className='flex-1 flex justify-center bg-white dark:bg-header-dark shadow p-4'>
        <Spin />
      </div>
    )
  }

  const chartData: any[] = []
  let maxRevenue: any = 0
  const startDate = new Date(new Date().getFullYear(), 0, 1)
  const currentDate = new Date()

  if (data && data.running_revenue_gql) {
    const revenues = data.running_revenue_gql
    if (revenues.length === 0) {
      chartData.push({
        x: Date.parse(startDate.toISOString()),
        date: moment(startDate.toISOString()).format('MM/DD'),
        y: 0
      })
      chartData.push({
        x: Date.parse(currentDate.toISOString()),
        date: moment(currentDate.toISOString()).format('MM/DD'),
        y: 0
      })
    } else if (revenues.length === 1) {
      const oneDate = new Date(revenues[0].day)
      if (startDate.getTime() !== oneDate.getTime() && currentDate.getTime() !== oneDate.getTime()) {
        chartData.push({
          x: Date.parse(startDate.toISOString()),
          date: moment(startDate.toISOString()).format('MM/DD'),
          y: 0
        })
        chartData.push({
          x: Date.parse(revenues[0].day),
          date: moment(revenues[0].day).format('MM/DD'),
          y: revenues[0].revenue
        })
        chartData.push({
          x: Date.parse(currentDate.toISOString()),
          date: moment(currentDate.toISOString()).format('MM/DD'),
          y: revenues[0].revenue
        })
      } else if (startDate.getTime() === oneDate.getTime()) {
        chartData.push({
          x: Date.parse(revenues[0].day),
          date: moment(revenues[0].day).format('MM/DD'),
          y: revenues[0].revenue
        })
        chartData.push({
          x: Date.parse(currentDate.toISOString()),
          date: moment(currentDate.toISOString()).format('MM/DD'),
          y: revenues[0].revenue
        })
      } else if (currentDate.getTime() === oneDate.getTime()) {
        chartData.push({
          x: Date.parse(startDate.toISOString()),
          date: moment(startDate.toISOString()).format('MM/DD'),
          y: 0
        })
        chartData.push({
          x: Date.parse(revenues[0].day),
          date: moment(revenues[0].day).format('MM/DD'),
          y: revenues[0].revenue
        })
      }
      maxRevenue = revenues[0].revenue
    } else {
      revenues.forEach((r) => {
        chartData.push({
          x: Date.parse(r.day),
          date: moment(r.day).format('MM/DD'),
          y: r.revenue
        })
        maxRevenue = maxRevenue < r.revenue ? r.revenue : maxRevenue
      })
    }
  }

  return (
    <div className='flex-1 bg-white dark:bg-header-dark p-4 shadow'>
      <div className='text-sm dark:text-theme-toggle-icon'>Running Sum of Revenues</div>
      <div className='text-lg font-semibold'>${numberWithCommas(isInteger(maxRevenue))}</div>
      <LineChart data={chartData} />
    </div>
  )
}

export default RevenueSum