import { FC, useState } from 'react'
import { useUserData } from '@nhost/react'
import { CogIcon } from '@heroicons/react/solid'
import EditUserModal from './editUserModal'

const UserInfo: FC = () => {
  const user = useUserData()
  const [editUserModalOpened, toggleEditUserModal] = useState(false)

  return (
    <div className="flex-1 rounded-lg bg-white dark:bg-header-dark dark:border-gray-500 shadow self-baseline z-10 px-4 py-6 mr-6">
      <img className="w-20 h-20 bg-gray-100 dark:bg-neutral-700 rounded-full object-cover mb-6" src={user?.avatarUrl} alt="" />
      <div className="text-2xl text-black dark:text-white mb-1">{user?.displayName}</div>
      <div className="text-sm text-gray-400 mb-6">{user?.email}</div>
      <button 
        className="inline-flex items-center justify-center rounded-md px-4 py-2 text-sm text-gray-500 hover:text-revtron-cyan font-medium border border-neutral-400 dark:border-neutral-700 bg-white dark:bg-header-dark focus:outline-none hover:bg-gray-100 hover:dark:bg-revtron-dark3 sm:w-auto"
        onClick={() => toggleEditUserModal(true)}
      >
        <CogIcon className="w-4 h-4 mr-3" />
        <div className="text-gray-500 dark:text-gray-300">Edit Profile</div>
      </button>

      <EditUserModal
        user={user}
        isOpen={editUserModalOpened}
        closeModal={() => toggleEditUserModal(false)}
      />
    </div>
  )
}

export default UserInfo
