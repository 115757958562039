import { FC } from 'react'

export const SummaryTileContainer: FC<{className?: string, children: any}> = ({className = '', children}) => (
  <dl className={`flex-1 flex bg-white dark:bg-revtron-dark2 shadow rounded-lg divide-x dark:divide-gray-500 ${className}`}>
    {children}
  </dl>
)

export const SummaryTile: FC<{className?: string, label: string, value: any}> = ({className, label, value}) => (
  <div className='md:min-w-40 flex-1 px-4 py-5'>
    <div className='s-label text-base font-normal text-gray-900 dark:text-theme-toggle-icon one-line-ellipsis'>{label}</div>
    <div className='s-value text-xl font-semibold dark:text-white'>{value}</div>
  </div>
)
