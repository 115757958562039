import { FC } from 'react'
import Spin from '../../atoms/spin'
import { classNames, numberWithCommas, convertToMillions } from '../../../utils/functions'
import { useTotalBookingsYoyQuery, useBookingsYoyQuery } from '../../../graphql/__generated__/graphql'
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid'

const PercentBadge: FC<{ percent: any }> = ({ percent }) => (
  <div
    className={classNames(
      percent > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
      'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
    )}
  >
    {percent > 0 ? (
      <ArrowSmUpIcon
        className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
        aria-hidden="true"
      />
    ) : (
      <ArrowSmDownIcon
        className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
        aria-hidden="true"
      />
    )}
    <span className="sr-only">{percent > 0 ? 'Increased' : 'Decreased'} by</span>
    {percent.toFixed(0)}%
  </div>
)

const TotalBookingsSummary: FC = () => {
  const { data: bookData, loading: bLoading } = useBookingsYoyQuery()
  const { data: totalData, loading: tloading } = useTotalBookingsYoyQuery()

  const bookAggregateData = bookData ? bookData.revtron_reporting_bookings_yoy_gql : null;
  const totalAggregateData = totalData ? totalData.revtron_reporting_total_bookings_yoy_gql : null;
  const summaryData = []
  if (totalAggregateData) {
    summaryData.push(...totalAggregateData)
  }
  if (bookAggregateData) {
    summaryData.push(...bookAggregateData)
  }

  if (bLoading || tloading) {
    return (
      <div className="flex justify-center bg-white dark:bg-header-dark shadow p-4 mb-6">
        <Spin />
      </div>
    )
  }

  if (summaryData.length === 0) {
    return null
  }

  const summaryCounts = summaryData.length

  return (
    <div>
      <dl className="flex flex-wrap rounded-lg overflow-hidden mb-4">
        {summaryData.map((item: any, index: number) => (
          <div
            key={item.type}
            className={`shadow bg-white dark:bg-header-dark dark:border-gray-500 font-mono px-4 py-5 sm:px-6 sm:py-3 sm:p-6 ${index === 0 && 'rounded-tl-lg rounded-bl-lg'} ${index === summaryCounts - 1   ? 'rounded-tr-lg rounded-br-lg' : 'border-r'}`}
            style={{ width: 100/summaryCounts + '%' }}
          >
            <div className='flex justify-between'>
              <dt className="truncate text-base font-medium dark:text-theme-toggle-icon capitalize">{item.type}</dt>
              {summaryCounts > 4 && <PercentBadge percent={item.percent_change} />}
            </div>
            <div className='flex items-end'>
              <div className='text-xl font-semibold tracking-tight dark:text-theme-toggle-icon'>
                ${numberWithCommas(convertToMillions(item.current_period_revenue))}
              </div>
              <div className='text-sm text-table-cell dark:text-theme-toggle-icon ml-2 mr-3'>
                from ${numberWithCommas(convertToMillions(item.previous_period_revenue))}
              </div>
              {summaryCounts <= 4 && <PercentBadge percent={item.percent_change} />}
            </div>
          </div>
        ))}
      </dl>
    </div>
  )
}

export default TotalBookingsSummary
