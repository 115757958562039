import { FC, Fragment, useState, useCallback, useEffect, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ChevronRightIcon, SearchIcon, UsersIcon } from '@heroicons/react/outline'
import { classNames, getPeopleFieldOptions } from '../../../../utils/functions'
import { ColoredButton } from '../../../common/buttons'
import { CustomPDLQuery, PeopleFilterField, PeopleFilter } from '../../../../@types/module'
import MultipleSelector from '../../../common/multi-select'

type CommandPaletteProps = {
  open: boolean
  fields: PeopleFilterField[]
  filters: CustomPDLQuery[]
  handlePaletteOpen: (s: boolean) => void
  addFilter: (f: PeopleFilter) => void
}

const PaletteOption: FC<{
  filters: CustomPDLQuery[],
  option: PeopleFilterField,
  addFilter: (f: PeopleFilter) => void
}> = ({ filters, option, addFilter }) => {
  const [query, setQuery] = useState('')
  const [min, setMin] = useState(0)
  const [max, setMax] = useState(0)
  const [multi, setMulti] = useState<string[]>([])
  const [roles, setRoles] = useState<string[]>([])

  useEffect(() => {
    const appliedFilter = filters.find(f => f.fieldName === option.fieldName)

    setQuery(appliedFilter?.query || '')
    setMin(appliedFilter?.min || 0)
    setMax(appliedFilter?.max || 0)
    setMulti(appliedFilter?.multi || [])

    if (option.label === 'Sub Role') {
      const selectedRoles = filters.find(f => f.label === 'Role')?.multi || []
      setRoles(selectedRoles)
    }
  }, [option, filters])

  const onAddFilterClick = useCallback(() => {
    addFilter({
      fieldName: option.fieldName,
      label: option.label,
      query,
      type: option.type,
      min: min > 0 ? min : undefined,
      max: max > 0 ? max : undefined,
      multi: multi
    })
    setQuery('')
  }, [addFilter, option.fieldName, option.label, option.type, query, min, max, multi])

  return (
    <div className="hidden h-96 w-1/2 flex-none flex-col divide-y divide-gray-100 dark:divide-revtron-dark4 overflow-y-auto sm:flex">
      <div className="flex-auto p-6 text-center">
        <label htmlFor={option.fieldName} className="block text-sm font-medium leading-6 text-gray-900 dark:text-revtron-white text-left">
          {option.label}
        </label>
        <div className="mt-2">
          {option.type === 'text' && (
            <input
              value={query}
              name={option.fieldName}
              id={option.fieldName}
              className="block w-full rounded-md border-0 py-1.5 pl-1.5 text-gray-900 dark:text-gray-300 bg-transparent ring-1 ring-inset ring-sidebar-border dark:ring-revtron-dark3 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-revtron-dark3 dark:focus:ring-gray-500 sm:text-sm sm:leading-6"
              placeholder={`Enter ${option.label}`}
              onChange={e => setQuery(e.target.value)}
            />
          )}
          {option.type === 'number' && (
            <div className="inline-flex items-center">
              <input
                value={min ? String(min) : ''}
                name={option.fieldName}
                id={option.fieldName}
                className="block w-full rounded-md border-0 py-1.5 pl-1.5 text-gray-900 dark:text-gray-300 bg-transparent ring-1 ring-inset ring-sidebar-border dark:ring-revtron-dark3 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-revtron-dark3 dark:focus:ring-gray-500 sm:text-sm sm:leading-6"
                placeholder="Min value"
                onChange={e => setMin(Number(e.target.value))}
                type='number'
              />
              <div className="mx-1 text-black dark:text-white">-</div>
              <input
                value={max ? String(max) : ''}
                name={option.fieldName}
                id={option.fieldName}
                className="block w-full rounded-md border-0 py-1.5 pl-1.5 text-gray-900 dark:text-gray-300 bg-transparent ring-1 ring-inset ring-sidebar-border dark:ring-revtron-dark3 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-revtron-dark3 dark:focus:ring-gray-500 sm:text-sm sm:leading-6"
                placeholder="Max value"
                onChange={e => setMax(Number(e.target.value))}
                type='number'
              />
            </div>
          )}
          {option.type === 'multi' && (
            <>
              <MultipleSelector
                options={getPeopleFieldOptions(option.label, roles)}
                currents={multi}
                setOption={setMulti}
                placeholder={`Select a ${option.label}`}
              />
            </>
          )}
        </div>
      </div>
      <div className="flex flex-none flex-col justify-end p-6">
        <ColoredButton
          title='Add Filter'
          disabled={!query && min === 0 && max === 0 && multi.length === 0}
          variant="primary"
          onButtonClick={onAddFilterClick}
          onKeyHandler={(event: React.KeyboardEvent<HTMLButtonElement>) => {
            if (event.key === 'Enter') {
              onAddFilterClick()
            }
          }}
        />
      </div>
    </div>
  )
}

const CommandPalette: FC<CommandPaletteProps> = (props) => {
  const boxRef = useRef<HTMLDivElement>(null)
  const [query, setQuery] = useState<string>('')
  const [activeOption, setActiveOption] = useState<any>(null)

  const filtered: PeopleFilterField[] =
    query === ''
    ? props.fields
    : props.fields.filter((field) => {
        return field.label.toLowerCase().includes(query.toLowerCase())
      })
  
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    let index = null
    if (document.activeElement !== boxRef.current) {
      return
    }

    if (activeOption) {
      index = filtered.findIndex((e) => e.label === activeOption.label)
    }
    if (event.key === 'ArrowDown') {
      if (index !== null) {
        setActiveOption(filtered[index+1])
      } else {
        setActiveOption(filtered[0])
      }
    } else if (event.key === 'ArrowUp') {
      if (index !== null) {
        setActiveOption(filtered[index-1])
      } else {
        setActiveOption(filtered[filtered.length - 1])
      }
    }
  }
  
  return (
    <Transition.Root show={props.open} as={Fragment} afterLeave={() => setQuery('')} appear>
      <Dialog as="div" className="relative z-10" onClose={() => props.handlePaletteOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-32">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-3xl transform divide-y divide-gray-100 dark:divide-revtron-dark4 overflow-hidden rounded-xl bg-white dark:bg-revtron-dark2 shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
              <div className='outline-none'>
                <div className="relative">
                  <SearchIcon
                    className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-900 dark:text-gray-50 placeholder:text-gray-400 outline-none focus:ring-0 sm:text-sm"
                    placeholder="Search..."
                    onChange={(event) => setQuery(event.target.value)}
                  />
                </div>

                {(query === '' || filtered.length > 0) && (
                  <div className="flex divide-x divide-gray-100 dark:divide-revtron-dark4">
                    <div
                      className={classNames(
                        'max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4',
                        activeOption && 'sm:h-96'
                      )}
                    >
                      {query === '' && (
                        <h2 className="mt-2 mb-4 text-xs font-semibold text-gray-500 dark:text-gray-50">Filters</h2>
                      )}
                      <div className="-mx-2 text-sm text-gray-700" ref={boxRef} onKeyDown={handleKeyDown} tabIndex={0}>
                        {filtered.map((field) => {
                          const active = activeOption && activeOption.label === field.label;
                          return (
                            <div
                              key={field.fieldName}
                              className={
                                classNames(
                                  'flex cursor-default select-none items-center rounded-md p-2 text-gray-900 dark:text-revtron-white cursor-pointer',
                                  active && 'bg-gray-100 dark:bg-revtron-dark4'
                                )
                              }
                              onMouseEnter={() => setActiveOption(field)}
                            >
                                  <span className="ml-3 flex-auto truncate">{field.label}</span>
                                  {active && (
                                    <ChevronRightIcon
                                      className="ml-3 h-5 w-5 flex-none text-gray-400"
                                      aria-hidden="true"
                                    />
                                  )}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                    {activeOption && <PaletteOption option={activeOption} addFilter={props.addFilter} filters={props.filters} />}
                  </div>
                )}

                {query !== '' && filtered.length === 0 && (
                  <div className="py-14 px-6 text-center text-sm sm:px-14">
                    <UsersIcon className="mx-auto h-6 w-6 text-gray-400" aria-hidden="true" />
                    <p className="mt-4 font-semibold text-gray-900 dark:text-revtron-white">No field found</p>
                    <p className="mt-2 text-gray-500 dark:text-gray-300">
                      We couldn’t find anything with that term. Please try again.
                    </p>
                  </div>
                )}
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default CommandPalette
