import { FC, useState } from 'react'

const Dropdown: FC<DropdownProps> = ({ list, addItem }) => {  
  return (
    <div id="dropdown" className="absolute shadow top-100 bg-white dark:bg-revtron-dark2 z-40 w-full left-0 right-0 rounded max-h-72 overflow-y-auto">
      {list && list.length > 0 ? (
        <div className="flex flex-col w-full">
          {list.map((item, key) => {
            return (
              <div
                key={key} 
                className="cursor-pointer w-full rounded-t hover:bg-select-background dark:hover:bg-header-dark" 
                onClick={()=> addItem(item)}
              >
                <div className="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-theme-toggle-icon">
                  <div className="w-full items-center flex">
                    <div className="mx-2 leading-6 dark:text-white">
                      {item.label}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      ) : (
        <div className='w-full flex justify-center items-center py-2 dark:text-white'>
          No options
        </div>
      )}
    </div>
  )
}

interface DropdownProps {
  list: any[]
  addItem: any
}

const MultipleSelect: FC<MultipleSelectProps> = ({
  className,
  value,
  onChange,
  options,
  placeholder,
}) => {
  // state showing if dropdown is open or closed
  const [dropdown, setDropdown] = useState(false)


  const toogleDropdown = () => {
    setDropdown(!dropdown)
  }
  // adds new item to multiselect 
  const addTag = (item: any) => {
    if (value) {
      onChange(value.concat(item))
    } else {
      onChange([].concat(item))
    }
    setDropdown(false)
  }
  // removes item from multiselect
  const removeTag = (item: any) => {
    const filtered = value.filter((e) => e.value !== item.value)
    onChange(filtered)
  }

  return (
    <div className={className}>
      <div className="autcomplete">
        <div className="w-full flex flex-col items-center mx-auto">
          <div className="w-full relative">
            <div className="flex flex-col items-center">
              <div className="w-full">
                <div className="my-1 flex border border-table-text dark:border-revtron-dark3 bg-white dark:bg-revtron-dark1 rounded-md">
                  <div className={`flex flex-auto flex-wrap ${value && value.length > 0 ? 'p-1' : 'p-2'}`}>
                    {
                      value && value.length > 0 && value.map((tag, index) => {
                        const selectedOption = options && options.length > 0 && options.find(option => option.value === tag.value)

                        return (
                          <div key={index} className="flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded text-select-text dark:text-white bg-select-background dark:bg-revtron-dark3">
                            <div className="text-xs font-normal leading-none max-w-full flex-initial">
                              {selectedOption && selectedOption.label}
                            </div>
                            <div className="flex flex-auto flex-row-reverse">
                              <div onClick={() => removeTag(tag)}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" 
                                  className="feather feather-x cursor-pointer hover:text-select-text dark:hover:text-white rounded-full w-3 h-3 ml-2">
                                  <line x1="18" y1="6" x2="6" y2="18"></line>
                                  <line x1="6" y1="6" x2="18" y2="18"></line>
                                </svg>
                              </div>
                            </div>
                        </div>
                        )
                      })
                    }
                    <div className="flex-1">
                      <div
                        onClick={toogleDropdown}
                        placeholder={value && value.length > 0 ? '' : placeholder}
                        className="bg-transparent appearance-none outline-none h-full w-full text-theme-toggle-icon"
                      >
                        {value && value.length > 0 ? '' : placeholder}
                      </div>
                    </div>
                  </div>
                  <div className="text-gray-300 w-8 py-1 pl-2 pr-1 border-l flex items-center border-table-text dark:border-revtron-dark3" onClick={toogleDropdown}>
                    <button className="cursor-pointer w-6 h-6 text-gray-600 outline-none focus:outline-none">
                      {dropdown ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-up w-4 h-4">
                          <polyline points="18 15 12 9 6 15"></polyline>
                        </svg>
                      ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-up w-4 h-4">
                          <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {dropdown  ? <Dropdown list={options} addItem={addTag}></Dropdown>: null}
          </div>
        </div>
      </div>
    </div>
  )
}

interface MultipleSelectProps {
  className?: string
  value: any[]
  onChange: (val: any[]) => void
  options: any[]
  placeholder?: string
}

export default MultipleSelect
