import { useCallback, useEffect, useMemo, useState } from 'react'
import _ from 'lodash'

import { FieldMapping, FieldMappingType, ACTION_OPTIONS } from './Settings.Utils'
import {
  useCrmAccountFieldsLazyQuery,
  useCrmContactFieldsLazyQuery,
  useCrmAccountMappingsLazyQuery,
  useCrmContactMappingsLazyQuery,
  useUpdateAccountMappingsMutation,
  useUpdateContactMappingsMutation
} from '../../graphql/__generated__/graphql'
import { getCrmAccountFields, getCrmContactFields } from './Settings.Utils'
import { isArrayEqual } from '../../utils/functions'
import { DEFAULT_FIELD_MAPPINGS, DEFAULT_FIELD_MAPPINGS_GIGPRO } from '../../utils/constants'
import { alert } from '../../utils/functions'
import { useFeatureContext } from '../../hooks/featureContext'

export const useFieldMappings = (mappingType: FieldMappingType) => {
  const [loadCrmAccountFields, { data: crmAccountFieldsData, loading: crmAccountFieldsLoading }] = useCrmAccountFieldsLazyQuery()
  const [loadCrmContactFields, { data: crmContactFieldsData, loading: crmContactFieldsLoading }] = useCrmContactFieldsLazyQuery()
  const [loadAccountMappings, { data: accountMappingsData, loading: accountMappingLoading }] = useCrmAccountMappingsLazyQuery()
  const [loadContactMappings, { data: contactMappingsData, loading: contactMappingLoading }] = useCrmContactMappingsLazyQuery()
  const [saveAccountMapping, { loading: saveAccountMappingLoading }] = useUpdateAccountMappingsMutation()
  const [saveContactMapping, { loading: saveContactMappingLoading }] = useUpdateContactMappingsMutation()
  const [mappings, setMappings] = useState<FieldMapping[]>([])
  const [mappingsBackup, setMappingsBackup] = useState<FieldMapping[]>([])
  const [mappingIdsTobeRemoved, setMappingIdsTobeRemoved] = useState<number[]>([])
  const { isGigPro } = useFeatureContext()

  useEffect(() => {
    if (mappingType === FieldMappingType.ACCOUNT) {
      loadCrmAccountFields()
      loadAccountMappings()
    } else {
      loadCrmContactFields()
      loadContactMappings()
    }
  }, [loadCrmAccountFields, loadCrmContactFields, loadAccountMappings, mappingType, loadContactMappings])

  useEffect(() => {
    if (accountMappingsData && mappingType === FieldMappingType.ACCOUNT) {
      const fieldsMappings = accountMappingsData.crm_account_mappings.map(c => ({
        id: c.id as number,
        crm_field: c.crm_field,
        revtron_field: c.revtron_field,
        is_active: c.is_active,
        method: c.method,
        isNew: false,
      }))

      if (fieldsMappings.length > 0) {
        setMappings(fieldsMappings)
        setMappingsBackup(JSON.parse(JSON.stringify(fieldsMappings)))
      } else {
        let defaultMappings = DEFAULT_FIELD_MAPPINGS
        if (isGigPro) {
          defaultMappings = DEFAULT_FIELD_MAPPINGS_GIGPRO
        }

        const _defaultMappings = defaultMappings.map(m => _.omit(m, ['id', 'isNew']))

        // Save Default Field Mappings
        saveAccountMapping({
          variables: {
            mappings: _defaultMappings,
            removeIds: []
          }
        }).then(response => {
          const accountMappings = response.data?.insert_crm_account_mappings
          if (accountMappings) {
            const _mappings = accountMappings.returning.map(m => ({
              id: m.id as number,
              revtron_field: m.revtron_field,
              crm_field: m.crm_field,
              is_active: m.is_active,
              method: m.method,
              isNew: false,
            }))
            setMappings(_mappings)
            setMappingsBackup(JSON.parse(JSON.stringify(_mappings)))
          }
        }).catch(error => {
          alert('error', error?.message || 'Something went wrong' )
        })
      }
    }

    if (contactMappingsData && mappingType === FieldMappingType.CONTACT) {
      const fieldsMappings = contactMappingsData.crm_contact_mappings.map(c => ({
        id: c.id as number,
        crm_field: c.crm_field,
        revtron_field: c.revtron_field,
        is_active: c.is_active,
        method: c.method,
        isNew: false,
      }))

      if (fieldsMappings.length > 0) {
        setMappings(fieldsMappings)
        setMappingsBackup(JSON.parse(JSON.stringify(fieldsMappings)))
      }
    }
  }, [mappingType, accountMappingsData, contactMappingsData, isGigPro, saveAccountMapping])

  // Check if mappings are changed
  const isDirty = useMemo(() => {
    return !isArrayEqual(mappings, mappingsBackup)
  }, [mappings, mappingsBackup])

  const updateMapping = useCallback((m: FieldMapping, action: 'UPDATE' | 'REMOVE') => {
    const _mappings = [...mappings]
    const index = _mappings.findIndex(mc => mc.id === m.id)
    if (index > -1) {
      if (action === 'UPDATE') {
        _mappings[index] = m
      } else {
        if (!m.isNew) {
          const _mappingIdsTobeRemoved = [...mappingIdsTobeRemoved]
          _mappingIdsTobeRemoved.push(m.id)
          setMappingIdsTobeRemoved(_mappingIdsTobeRemoved)
        }
        _mappings.splice(index, 1)
      }
      setMappings(_mappings)
    }
  }, [mappingIdsTobeRemoved, mappings])

  const addNewMapping = useCallback(() => {
    const _mappings = [...mappings]
    _mappings.push({
      id: Math.floor(Math.random() * 10000),
      crm_field: '',
      revtron_field: '',
      method: ACTION_OPTIONS[0].value,
      is_active: false,
      isNew: true
    })
    setMappings(_mappings)
  }, [mappings])

  const saveMappings = useCallback(() => {
    if (isDirty) {
      const mappingsForm = mappings.map((m: Partial<FieldMapping>) => {
        if (m.isNew) {
          delete m.id
        }
        delete m.isNew
        return m
      }).filter((m) => !!m.crm_field && !!m.revtron_field)

      // Invoke mutation
      if (mappingType === FieldMappingType.ACCOUNT) {
        saveAccountMapping({
          variables: {
            mappings: mappingsForm,
            removeIds: mappingIdsTobeRemoved
          }
        })
        .then(response => {
          const accountMappings = response.data?.insert_crm_account_mappings
          if (accountMappings) {
            const _updatedMappings = accountMappings.returning.map(m => ({
              id: m.id as number,
              revtron_field: m.revtron_field,
              crm_field: m.crm_field,
              is_active: m.is_active,
              method: m.method,
              isNew: false,
            }))
            
            setMappingIdsTobeRemoved([])
            setMappings(_updatedMappings)
            setMappingsBackup(JSON.parse(JSON.stringify(_updatedMappings)))
          }
        })
        .catch(error => {
          alert('error', error?.message || 'Something went wrong' )
        })
      }

      if (mappingType === FieldMappingType.CONTACT) {
        saveContactMapping({
          variables: {
            mappings: mappingsForm,
            removeIds: mappingIdsTobeRemoved
          }
        })
        .then(response => {
          const contactMappings = response.data?.insert_crm_contact_mappings
          if (contactMappings) {
            const _updatedMappings = contactMappings.returning.map(m => ({
              id: m.id as number,
              revtron_field: m.revtron_field,
              crm_field: m.crm_field,
              is_active: m.is_active,
              method: m.method,
              isNew: false,
            }))
            
            setMappingIdsTobeRemoved([])
            setMappings(_updatedMappings)
            setMappingsBackup(JSON.parse(JSON.stringify(_updatedMappings)))
          }
        })
        .catch(error => {
          alert('error', error?.message || 'Something went wrong' )
        })
      }
    }
  }, [isDirty, mappings, mappingType, saveAccountMapping, mappingIdsTobeRemoved, saveContactMapping])

  const fields = useMemo(() => {
    if (mappingType === FieldMappingType.ACCOUNT) {
      return getCrmAccountFields(crmAccountFieldsData)
    } else {
      return getCrmContactFields(crmContactFieldsData)
    }
  }, [crmAccountFieldsData, crmContactFieldsData, mappingType])

  return {
    accountMappingLoading: crmAccountFieldsLoading || accountMappingLoading || saveAccountMappingLoading,
    contactMappingLoading: crmContactFieldsLoading || contactMappingLoading || saveContactMappingLoading,
    fields,
    mappings,
    updateMapping,
    addNewMapping,
    saveMappings,
    isDirty
  }
}
