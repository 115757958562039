import { FC } from 'react'
import Progress from '../../molecules/progressBar'

import { useIndividualProgressQuery } from '../../../graphql/__generated__/graphql'
import { intoRGB, calculateDefaultAvtarBg } from '../../../utils/functions'
import { getAvatarUrl, getUserAvatarByName } from '../../../utils/functions'

type percentageType =
  'opportunity_create_count' |
  'emails_sent' |
  'calls_out' |
  'social'

const EngagementDetail: FC<EngagementDetailProps> = ({ leadData, leadLoading }) => {
  const { data } = useIndividualProgressQuery()

  const getPercentage = (ownerId: string, type: percentageType) => {
    if (data?.actuals_vs_targets_activity) {
      const selectedItem = data.actuals_vs_targets_activity.find((item) => item.ownerid === ownerId)
      if (selectedItem) {
        if (type === 'opportunity_create_count') {
          return Math.round((selectedItem[type] / selectedItem.opportunities) * 100)
        } else if (type === 'emails_sent') {
          return Math.round((selectedItem[type] / selectedItem.emails) * 100)
        } else if (type === 'calls_out') {
          return Math.round((selectedItem[type] / selectedItem.calls) * 100)
        } else if (type === 'social') {
          return Math.round((selectedItem[type] / selectedItem.social_target) * 100)
        }
      }
    }
  }

  return (
    <div className='grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5'>
      {
        leadData?.weekly_activity_vs_targets_gql?.length > 0 &&
        leadData.weekly_activity_vs_targets_gql.map((item: any, index: number) => {
          const photoUrl = getAvatarUrl(item.photo_url, item.name)
          return (
            <div key={index.toString()} className={`col-span-1 flex flex-col divide-y divide-gray-100 dark:divide-neutral-500 rounded-lg bg-white dark:bg-neutral-800 shadow border border-none `}>
              <div className='relative flex item-center space-x-3 rounded-lg p-4'>
                <div className='flex-shrink-0'>
                  {
                    !!item.photo_url
                      ? (
                        <div className='w-10 h-10 rounded-full relative z-10 before:absolute before:top-1 before:right-1 before:w-full before:h-full before:rounded-full before:bg-revtron-teal'>
                          <img src={photoUrl} alt='' className='w-full rounded-full absolute z-10 inset-0 object-cover border border-collapse border-revtron-teal' />
                        </div>
                        )
                      : (
                        <div
                          className='w-10 h-10 rounded-full flex justify-center items-center'
                          style={{
                            backgroundColor: `#${intoRGB(calculateDefaultAvtarBg(item.name))}`
                          }}
                        >
                          <span>{getUserAvatarByName(item.name)}</span>
                        </div>
                        )
                  }
                </div>
                <div className='min-w-0 flex-1'>
                  <span className='absolute inset-0' aria-hidden='true' />
                  <p className='truncate font-semibold text-revtron-dark3 dark:text-revtron-white'>{item.name}</p>
                </div>
              </div>
              <div className='bottom-part w-full border-b border-theme-toggle-white-bg dark:border-revtron-dark3 px-4 pb-4'>
                <div className='right-item'>
                  <label className='pb-2 text-revtron-dark4 dark:text-revtron-white text-xs'>Opportunities Created</label>
                  <div className='bar-part'>
                    <Progress
                      percent={getPercentage(item.ownerid, 'opportunity_create_count') || 0}
                      strokeColor='#00BF9A'
                    />
                  </div>
                </div>
                <div className='right-item mt-4'>
                  <label className='pb-2 text-revtron-dark4 dark:text-revtron-white text-xs'>Emails Sent</label>
                  <div className='bar-part'>
                    <Progress
                      percent={getPercentage(item.ownerid, 'emails_sent') || 0}
                      strokeColor='#BA54F5'
                    />
                  </div>
                </div>
                <div className='right-item mt-4'>
                  <label className='pb-2 text-revtron-dark4 dark:text-revtron-white text-xs'>Calls Made</label>
                  <div className='bar-part'>
                    <Progress
                      percent={getPercentage(item.ownerid, 'calls_out') || 0}
                      strokeColor='#BA54F5'
                    />
                  </div>
                </div>
                <div className='right-item mt-4'>
                  <label className='pb-2 text-revtron-dark4 dark:text-revtron-white text-xs'>Social</label>
                  <div className='bar-part'>
                    <Progress
                      percent={getPercentage(item.ownerid, 'social') || 0}
                      strokeColor='#E14ECA'
                    />
                  </div>
                </div>
              </div>
              <p className='py-4 dark:text-table-text text-revtron-dark4 text-center'>
                {`Engagement Ratio of ${(item.engagement_ratio * 100).toFixed(0)}%`}
              </p>
            </div>
          )
        })
      }
    </div>
  )
}

interface EngagementDetailProps {
  leadData: any
  leadLoading: boolean
}

export default EngagementDetail
