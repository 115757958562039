import { UsersIcon, OfficeBuildingIcon } from '@heroicons/react/outline'
import _ from 'lodash'
import { Tabs } from '../../@types/common/Tab'
import {
  CrmContactMappingsQuery,
  LoggedinUserQuery,
  pdl_people_data_insert_input,
  ProspectorModuleQuery
} from '../../graphql/__generated__/graphql'
import { ProspectorModuleForGigProQuery } from '../../graphql/__generated__/gigpro_graphql'
import { AccountRecord, PDLPerson, CrmEventRecord, PDLQuery, IPersonResponse } from '../../@types/module'
import { YelpICP, PeopleFilterField } from '../../@types/module'
import { generateRandomId } from '../../utils/functions'
import { PersonResponse, BulkPersonRetrieveResponse } from 'peopledatalabs'

// TODO: Please add more hardcoded fields here
export const PeopleTableCommandPaletteFields: PeopleFilterField[] = [
  {
    label: 'Seniority',
    fieldName: 'job_title_levels',
    type: 'multi',
  },
  {
    label: 'Role',
    fieldName: 'job_title_role',
    type: 'multi',
  },
  {
    label: 'Sub Role',
    fieldName: 'job_title_sub_role',
    type: 'multi',
  },
  {
    label: 'Title',
    fieldName: 'job_title',
    type: 'text'
  },
]

export enum ProspectorTabName {
  COMPANY = 'Company',
  PEOPLE = 'People',
}

export const ProspectorTabs: Tabs[] = [
  { name: ProspectorTabName.COMPANY, icon: OfficeBuildingIcon },
  { name: ProspectorTabName.PEOPLE, icon: UsersIcon },
]

export const getProspectorModuleData = (data: ProspectorModuleQuery) => {
  const companyTableData: AccountRecord[] = data.prospectors.map(e => {
    return {
      crmId: e.Id,
      uuid: e.Id,
      annualRevenue: e.AnnualRevenue as number,
      name: e.Name as string,
      domain: e.Website as string,
      city: e.BillingCity as string,
      empCount: e.NumberOfEmployees as number,
      predictProba: _.get(e, 'account_module_static.predict_proba', 0),
      companyType: e.Type as string,
      state: e.BillingState as string,
      country: e.BillingCountry as string,
      foundingYear: 0,
      industry: e.Industry as string,
      specialities: e.Description as string,
    }
  })

  return { companyTableData, totalRowsCount: data.totalRecords.aggregate?.count || 0 }
}

export const getProspectorModuleGigproData = (data: ProspectorModuleForGigProQuery) => {
  const companyTableData: AccountRecord[] = data.Account.map(e => {
    const custom_account = _.get(e, 'to_yelp_icp') as YelpICP
    return {
      crmId: e.Id || '',
      uuid: e.Id,
      // annualRevenue: custom_account?.price || 0,
      annualRevenue: e.AnnualRevenue || 0,
      name: e.Name as string,
      domain: e.Website as string,
      city: e.BillingCity as string,
      empCount: e.NumberOfEmployees as number,
      // empCount: custom_account?.rating || 0,
      predictProba: _.get(e, 'account_module_static.predict_proba', 0),
      // predictProba: _.get(e, 'to_yelp_icp.predct_proba', 0),
      companyType: e.Vertical as string,
      // companyType: custom_account.vertical as string,
      state: e.BillingState as string,
      country: e.BillingCountry
        ? e.BillingCountry.toLowerCase() === 'us'
          ? 'United States'
          : e.BillingCountry as string
        : '',
      foundingYear: custom_account?.review_count || 0,
      industry: custom_account?.first_yelp_category || '',
      specialities: custom_account?.yelp_categories || '',
    }
  })

  return { companyTableData, totalRowsCount: data.totalRecords.aggregate?.count || 0 }
}

export const getPersonData = (data: IPersonResponse[]): PDLPerson[] => {
  return data.map((d: IPersonResponse) => ({
    id: d.id || generateRandomId(7),
    fullName: d.full_name || '',
    linkedin: d.linkedin_id || '',
    profession: d.job_title || '',
    company: d.job_company_name || '',
    isLinkedin: !!d.linkedin_id,
    isFacebook: !!d.facebook_id,
    isTwitter: !!d.twitter_username,
    isEmail: !!d.work_email,
    isPhone: !!d.mobile_phone,
    crmAccountId: d.crmAccountId
  }))
}

export const getCrmAccountId = (query: PDLQuery | null, item: PersonResponse): string => {
  if (!query) {
    return ''
  }
  const index = query.mapping.findIndex(n => {
    if (!item.job_company_website || !item.location_name) {
      return false
    }

    const key = n.isGigpro
      // eslint-disable-next-line no-useless-escape
      ? `${item.job_company_website}_${item.location_name.replace(/\s/g, '').replace(/\,/g, '').toLowerCase()}`
      : item.job_company_website
    return n.uniqueKey.toLowerCase().includes(key.toLowerCase())
  })

  if (index >= 0) {
    return query.mapping[index].crmAccountId
  }

  return ''
}

export const getCrmEventData = (
  data: BulkPersonRetrieveResponse,
  pdlQuery: PDLQuery | null,
  mappings?: CrmContactMappingsQuery,
  loggedinUser?: LoggedinUserQuery,
) => {
  const eventsData: CrmEventRecord[] = []
  const fieldMappings = mappings?.crm_contact_mappings
  const crmUser = loggedinUser?.user

  if (!fieldMappings || fieldMappings.length === 0 || !crmUser) {
    return { eventsData: [], pplData: [] }
  }

  const pplData = [] as pdl_people_data_insert_input[]
  data.items.forEach(i => {
    const crm_account_id = getCrmAccountId(pdlQuery, i.data);
    if (!!crm_account_id) {
      const firstName = _.startCase(i.data.first_name ?? '')
      const lastName = _.startCase(i.data.last_name ?? '') 
      const jobTitle = _.startCase(i.data.job_title ?? '')
      const updateData = {
        ...i.data,
        first_name: firstName,
        last_name: lastName,
        job_title: jobTitle,
        crm_account_id,
        revtron_user_id: crmUser.revtron_user_id,
        source: 'revtron'
      }
      pplData.push(updateData as pdl_people_data_insert_input)
    }
  })

  pplData.forEach(d => {
    type keyType = keyof typeof d
    const records: {[key: string]: any} = {}

    Object.keys(d).forEach((key) => {
      const index = fieldMappings.findIndex(m => m.revtron_field === key)
      if (index >= 0) {
        const crm_field = fieldMappings[index].crm_field
        records[crm_field] = d[key as keyType]
      }
    })
    records['AccountId'] = d.crm_account_id

    eventsData.push({
      revtron_user_id: d.revtron_user_id,
      revtron_company_id: null,
      crm_user_id: crmUser.crm_users?.id || null,
      records: [records],
      sobject: 'Contact'
    })
  })

  return { eventsData, pplData }
}
