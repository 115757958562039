import { FC, useState } from 'react'
import moment from 'moment'
import _ from 'lodash'

import {
  useMonthlyEngagementNewQuery,
  useWeeklyEngagementNewQuery,
} from '../../../graphql/__generated__/graphql'

import Spin from '../../atoms/spin'

import { numberWithCommas } from '../../../utils/functions'

const TotalEngagementSummary: FC = () => {
  const [viewType, setViewType] = useState({
    name: 'Weekly',
    value: 'weekly'
  })
  const { data: monthlyData, loading: monthlyLoading } = useMonthlyEngagementNewQuery({
    skip: viewType.value !== 'monthly'
  })
  const { data: weeklyData, loading: weeklyLoading } = useWeeklyEngagementNewQuery({
    skip: viewType.value !== 'weekly'
  })

  const months: any[] = []
  const jan = Date.parse(moment().startOf('year') as any)
  months.push(jan)
  for (let i = 1; i <= 11; i++) {
    months.push(Date.parse(moment(jan).set('month', i) as any))
  }

  const getSumEngagement = () => {
    let gqlData: any[] = []
    if (viewType.value === 'weekly') {
      gqlData = weeklyData ? weeklyData.weekly_engagement_breakdown_overview_gql : []
    } else {
      gqlData = monthlyData ? monthlyData.monthly_engagement_breakdown_overview_gql : []
    }
    return gqlData.reduce((acc: number, cur: any) => acc + cur.count, 0)
  }

  let summaryData: any[] = []
  let dataArr: any[] = []
  if (viewType.value === 'weekly') {
    dataArr = weeklyData ? weeklyData.weekly_engagement_breakdown_overview_gql : []
  } else {
    dataArr = monthlyData ? monthlyData.monthly_engagement_breakdown_overview_gql : []
  }

  if (dataArr.length > 0) {
    const gropedData = _.groupBy(dataArr, 'type_detail')
    summaryData = Object.keys(gropedData).map((item, index) => {
      return {
        title: item,
        value: gropedData[item].reduce((acc: number, cur: any) => acc + cur.count, 0)
      }
    })
  }

  if (weeklyLoading || monthlyLoading) {
    return (
      <div className="flex justify-center bg-white dark:bg-header-dark shadow p-4 mb-6">
        <Spin />
      </div>
    )
  }


  return (
    <>
      <div>
        <dl className="flex flex-wrap rounded-lg overflow-hidden shadow mb-4">
            <div
              className="shadow flex flex-col justify-between bg-white dark:bg-header-dark dark:border-gray-500 font-mono px-4 py-5 sm:px-6 sm:py-3 border-r"
              style={{ width: 100 / (summaryData.length + 1) + '%' }}
            >
              <dt className="truncate text-base font-medium dark:text-theme-toggle-icon">Total Engagement</dt>
              <dd className="mt-1 text-xl font-semibold tracking-tight dark:text-theme-toggle-icon"> {numberWithCommas(getSumEngagement())} </dd>
            </div>
            {summaryData?.length > 0 && summaryData.sort((a, b) => b.value - a.value).map((item, index) => {
              return (
                <div
                  key={index.toString()}
                  className={`shadow flex flex-col justify-between bg-white dark:bg-header-dark dark:border-gray-500 font-mono px-4 py-5 sm:px-6 sm:py-3 ${index === summaryData.length - 1  ? 'rounded-tr-lg rounded-br-lg' : 'border-r'}`}
                  style={{ width: 100 / (summaryData.length + 1) + '%' }}
                >
                  {/* <dt className="truncate text-base font-medium dark:text-theme-toggle-icon">{item.title}</dt> */}
                  <dt className="text-base font-medium dark:text-theme-toggle-icon">{item.title}</dt>
                  <dd className="mt-1 text-xl font-semibold tracking-tight dark:text-theme-toggle-icon"> {numberWithCommas(item.value)} </dd>
                </div>
              )
            })}
        </dl>
      </div>
    </>
  )
}


export default TotalEngagementSummary;
