import _ from 'lodash'
import { ActivityByUserQuery } from '../../../graphql/__generated__/graphql'

export type ActivityByUser = {
  id: string
  team_type: string
  team_name: string
  avatarUrl: string
  name: string
  meetingsCreated: number
  opportunitiesCreated: number
  accountsTouched: number
  contactsTouched: number
  avgContactsPerAccount: number
}

export type TotalActivityByUser = {
  totalmeetingsCreated: number
  totalopportunitiesCreated: number
  totalaccountsTouched: number
  totalcontactsTouched: number
  totalavgContactsPerAccount: number
}

export type MeetingType = {
  id: string
  ownerid: string
  creator: string
  subject: string
  createddate: string
  activitydatetime: string
  enddatetime: string
}

export type OpportunityType = {
  id: string
  ownerid: string
  createdbyid: string
  creator: string
  name: string
  stagename: string
  type: string
  amount: string
  leadsource: string
  createddate: string
  closedate: string
}

const initialValue: { users: ActivityByUser[], totals: TotalActivityByUser, meetings: MeetingType[], opportunities: OpportunityType[] } = {
  users: [], 
  totals: {
    totalmeetingsCreated: 0,
    totalopportunitiesCreated: 0,
    totalaccountsTouched: 0,
    totalcontactsTouched: 0,
    totalavgContactsPerAccount: 0,
  },
  meetings: [],
  opportunities: [],
}

export const getActivityData = (data: ActivityByUserQuery | undefined): [ActivityByUser[], TotalActivityByUser, MeetingType[], OpportunityType[]] => {
  const { users, totals, meetings, opportunities } = _.chain(data?.teams)
    .map('to_members')
    .flatten()
    .map('to_users')
    .reduce(
      (acc, v) => {
        const accountsTouched = _.get(v, 'crm_users.to_effort_engagement_aggregate.accountsTouched.count', 0) as number
        const contactsTouched = _.get(v, 'crm_users.to_effort_engagement_aggregate.contactsTouched.count', 0) as number
        const name = _.get(v, 'crm_users.name', '') as string
        const meetingsCreatedArr = (_.get(v, 'crm_users.meeting_creation', []) as MeetingType[]).map(i => ({ ...i, creator: name }))
        const opportunitiesCreatedArr = (_.get(v, 'crm_users.opp_creation', []) as OpportunityType[]).map(i => ({ ...i, creator: name }))
        const user = {
          id: _.get(v, 'crm_users.id', '') as string,
          avatarUrl: _.get(v, 'avatarUrl', ''),
          team_type: _.get(v, 'crm_users.revtron_users.revtron_team.to_teams.team_type', '') as string,
          team_name: _.get(v, 'crm_users.revtron_users.revtron_team.to_teams.team_name', '') as string,
          name,
          meetingsCreated: meetingsCreatedArr.length,
          opportunitiesCreated: opportunitiesCreatedArr.length,
          accountsTouched,
          contactsTouched,
          avgContactsPerAccount: accountsTouched > 0 ? contactsTouched / accountsTouched : 0,
        };

        return {
          users: [...acc.users, user],
          meetings: [...acc.meetings, ...meetingsCreatedArr],
          opportunities: [...acc.opportunities, ...opportunitiesCreatedArr],
          totals: {
            totalmeetingsCreated: acc.totals.totalmeetingsCreated + user.meetingsCreated,
            totalopportunitiesCreated: acc.totals.totalopportunitiesCreated + user.opportunitiesCreated,
            totalaccountsTouched: acc.totals.totalaccountsTouched + user.accountsTouched,
            totalcontactsTouched: acc.totals.totalcontactsTouched + user.contactsTouched,
            totalavgContactsPerAccount: acc.totals.totalavgContactsPerAccount + user.avgContactsPerAccount,
          },
        };
      },
      initialValue
    )
    .value();

  const activityByUserData = _.orderBy(users, ['opportunitiesCreated'], ['desc']);
  const totalActivityByUserData = {
    totalmeetingsCreated: totals.totalmeetingsCreated,
    totalopportunitiesCreated: totals.totalopportunitiesCreated,
    totalaccountsTouched: totals.totalaccountsTouched,
    totalcontactsTouched: totals.totalcontactsTouched,
    totalavgContactsPerAccount: totals.totalavgContactsPerAccount / users.length,
  };

  return [
    activityByUserData,
    totalActivityByUserData,
    meetings.sort((a: MeetingType, b: MeetingType) => b.createddate > a.createddate ? 1 : -1),
    opportunities.sort((a: OpportunityType, b: OpportunityType) => b.createddate > a.createddate ? 1 : -1)
  ];
};
