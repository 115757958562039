import { FC, memo, useMemo } from 'react'

import Spin from '../../atoms/spin'
import { intoRGB, calculateDefaultAvtarBg } from '../../../utils/functions'
import { getAvatarUrl, getUserAvatarByName } from '../../../utils/functions'
import { numberWithCommas } from '../../../utils/functions'
import { PlayerCardData } from '../../../containers/leaderboard_v1/Leaderboard.util'

interface PlayerCardsProps {
  data: PlayerCardData[]
  isLoading: boolean
  oppId: string
  onSelectOpp: (id: string) => void
}

const Card: FC<{item: PlayerCardData, onCardClick: (id: string) => void, selectedId: string}> = ({ item, onCardClick, selectedId }) => {
  const photoUrl = useMemo(() => {
    return getAvatarUrl(item.photoUrl, item.name)
  }, [item.photoUrl, item.name])

  return (
    <div
      className={`col-span-1 flex flex-col divide-y divide-gray-100 dark:divide-neutral-500 rounded-lg bg-white dark:bg-neutral-800 shadow cursor-pointer border border-revtron-cyan ${item.uuid === selectedId ? 'border-solid' : 'border-none'} hover:border-solid`}
      onClick={() => onCardClick(item.uuid)}
    >
    <div className='relative flex item-center space-x-3 rounded-lg p-4'>
      <div className='flex-shrink-0'>
      {
        !!photoUrl
          ? (
            <div className='w-10 h-10 rounded-full relative z-10 before:absolute before:top-1 before:right-1 before:w-full before:h-full before:rounded-full before:bg-revtron-teal'>
              <img src={photoUrl} alt='' className='w-full rounded-full absolute z-10 inset-0 object-cover border border-collapse border-revtron-teal' />
            </div>
            )
          : (
            <div
              className='w-10 h-10 rounded-full flex justify-center items-center'
              style={{
                backgroundColor: `#${intoRGB(calculateDefaultAvtarBg(item.name))}`
              }}
            >
              <span>{getUserAvatarByName(item.name)}</span>
            </div>
            )
      }
      </div>
      <div className='min-w-0 flex-1'>
          <span className='absolute inset-0' aria-hidden='true' />
          <p className='truncate font-semibold text-revtron-dark3 dark:text-revtron-white'>{item.name}</p>
          <p className='truncate text-sm text-revtron-dark2 dark:text-revtron-white'>{item.teamName}</p>
      </div>
    </div>
      <div className='overflow-hidden rounded-sm px-2'>
        <div className='flex items-center justify-between p-4'>
          <p className='truncate text-revtron-dark2 dark:text-revtron-white text-xs'>QTR $ Velocity</p>
          <p className='text-revtron-dark2 dark:text-revtron-white font-semibold text-base'>{`$${numberWithCommas(item.Quarterly_Sales_Velocity.toFixed(0))}`}</p>
        </div>
        <div className='flex items-center justify-between p-4'>
          <p className="truncate text-revtron-dark2 dark:text-revtron-white text-xs">Win Rate</p>
          <p className="text-revtron-dark2 dark:text-revtron-white font-semibold text-base">{`${numberWithCommas(item.Win_Rate.toFixed(0))}%`}</p>
        </div>
        <div className='flex items-center justify-between p-4'>
          <p className="truncate text-revtron-dark2 dark:text-revtron-white text-xs">Opps in 90 Day Pipeline</p>
          <p className="text-revtron-dark2 dark:text-revtron-white font-semibold text-base">{`${numberWithCommas(item.Ninety_Day_Pipeline.toFixed(0))}`}</p>
        </div>
        <div className='flex items-center justify-between p-4'>
          <p className="truncate text-revtron-dark2 dark:text-revtron-white text-xs">Avg Deal Size</p>
          <p className="text-revtron-dark2 dark:text-revtron-white font-semibold text-base">{`$${numberWithCommas(item.Avg_Deal_Size.toFixed(0))}`}</p>
        </div>
        <div className='flex items-center justify-between p-4'>
          <p className="truncate text-revtron-dark2 dark:text-revtron-white text-xs">Avg Sales Cycle</p>
          <p className="text-revtron-dark2 dark:text-revtron-white font-semibold text-base">{`${numberWithCommas(item.Avg_Sales_Cycle.toFixed(0))}`}</p>
        </div>
      </div>
    </div>
  )
}

const PlayerCards: FC<PlayerCardsProps> = ({ data, oppId, onSelectOpp, isLoading }) => {
  if (isLoading) {
    return (
      <div className='flex justify-center bg-white dark:bg-header-dark shadow p-4 mb-6'>
        <Spin />
      </div>
    )
  }

  return (
    <div className='grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5 mt-6'>
      {
        data.map((item: PlayerCardData, index: number) => {
          return (
            <Card key={index.toString()} item={item} selectedId={oppId} onCardClick={onSelectOpp} />
          )
        })
      }
    </div>
  )
}

export default memo(PlayerCards)
