import { useEffect, useState, useCallback, useMemo } from 'react'
import _ from 'lodash'
import { useUserId } from '@nhost/react'
import { AccountFilter, AccountFilterField } from '../../../@types/common/AccountTable'
import {
  useCrmAccountMappingsLazyQuery,
  useLoggedinUserLazyQuery,
  useCrmAccountFieldsLazyQuery,
  CrmAccountFieldsQuery
} from '../../../graphql/__generated__/graphql'


const getRevtronFields = (data: CrmAccountFieldsQuery): { revTronFields: AccountFilterField[], crmFields: AccountFilterField[] } => {
  const revTronFields: AccountFilterField[] = _.chain(data?.revtronCompanies?.fields)
    .filter(field => field.type.kind === 'SCALAR')
    .map(field => {
      let label = field.name
      if (field.name === 'employee_counts') {
        label = 'number of employees'
      }
      if (field.name === 'sub_industry') {
        label = 'sub industry'
      }
      if (field.name === 'founded_year') {
        label = 'founded year'
      }
      if (field.name === 'zip_code') {
        label = 'zip code'
      }
      return {
        label,
        fieldName: field.name,
        query: '',
        type: field.type.name === 'String' ? 'text' : 'number' as 'text' | 'number'
      }
    })
    .value()
  const crmFields = _.chain(data?.account?.fields)
    .filter(field => field.type.kind === 'SCALAR')
    .map(field => {
      let label = field.name
      return {
        label,
        fieldName: field.name,
        query: '',
        type: field.type.name === 'String' ? 'text' : 'number' as 'text' | 'number'
      }
    })
    .value()

  return { revTronFields, crmFields }
}

export const useAccountTable = (isAccountRec: boolean = false) => {
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [filters, setFilters] = useState<AccountFilter[]>([])

  const [loadCrmFieldsData, { data: fieldsData }] = useCrmAccountFieldsLazyQuery()
  const [loadAccountMappings, { data: accountMappingsData }] = useCrmAccountMappingsLazyQuery()
  const [getLoggedinUser, { data: loggedinUser }] = useLoggedinUserLazyQuery()
  const userId = useUserId()

  useEffect(() => {
    loadCrmFieldsData()

    if (isAccountRec) {
      loadAccountMappings()
    }
  }, [isAccountRec, loadCrmFieldsData, loadAccountMappings])

  useEffect(() => {
    if (userId && isAccountRec) {
      getLoggedinUser({ variables: { userId } })
    }
  }, [isAccountRec, userId, getLoggedinUser])

  const changePagination = useCallback((pn: number, ps: number) => {
    if (pn !== pageNumber || ps !== pageSize) {
      setPageNumber(pn)
      setPageSize(ps)
    }
  }, [pageNumber, pageSize])

  const addFilter = useCallback((filter: AccountFilter) => {
    const _filters = [...filters]
    const index = _filters.findIndex(f => f.fieldName === filter.fieldName)
    if (index < 0) {
      _filters.push(filter)
      setFilters(_filters)
    }
  }, [filters])

  const removeFilter = useCallback((filter: AccountFilter) => {
    const _filters = [...filters]
    const index = _filters.findIndex(f => f.fieldName === filter.fieldName)
    if (index >= 0) {
      _filters.splice(index, 1)
      setFilters(_filters)
    }
  }, [filters])

  const clearAllFilters = useCallback(() => {
    setFilters([])
  }, [])

  const fields = useMemo(() => {
    if (fieldsData) {
      return getRevtronFields(fieldsData) 
    }
    return { revTronFields: [], crmFields: [] }
  }, [fieldsData])

  return {
    ...fields,
    changePagination,
    pageNumber,
    pageSize,
    filters,
    addFilter,
    removeFilter,
    clearAllFilters,
    accountMappingsData,
    loggedinUser,
    revtronUserId: userId,
  }
}
