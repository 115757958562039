import { FC, Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

const CustomSelect: FC<CustomSelectProps> = ({
  className,
  label,
  placeholder,
  list,
  selected,
  onSelect,
}) => {
  return (
    <div className={className}>
      <Listbox value={selected} onChange={onSelect}>
        <Listbox.Label className="block text-sm font-medium text-gray-700: dark:text-gray-400">{label}</Listbox.Label>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full cursor-default bg-white border border-gray-300 dark:border-neutral-700 rounded-md shadow-sm appearance-none dark:bg-revtron-dark1 py-2 pl-3 pr-10 text-left focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            {/* <span className="block truncate">{selected.name}</span> */}
            {selected.name ? (
              <span className="block truncate dark:text-gray-200">{selected.name}</span>
            ) : (
              <span className="block truncate text-gray-400">{placeholder}</span>
            )}
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <SelectorIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white dark:bg-revtron-dark1 py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {list.map((item, itemIdx) => (
                <Listbox.Option
                  key={itemIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? 'bg-gray-100 dark:bg-header-dark dark:text-gray-400' : 'text-gray-900 dark:text-gray-400'
                    }`
                  }
                  value={item}
                >
                  <span
                    className={`block truncate ${
                      selected.value === item.value ? 'font-medium' : 'font-normal'
                    }`}
                  >
                    {item.name}
                  </span>
                  {selected.value === item.value ? (
                    <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                    </span>
                  ) : null}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}

interface CustomSelectProps {
  className?: string
  label?: string
  placeholder?: string
  list: Array<any>
  selected: { name: string, value: string }
  onSelect: (e: { name: string, value: string}) => void
}

export default CustomSelect
