import { FC } from 'react'
import Spin from '../../atoms/spin'
import { useTofTotalNumbersYoyQuery, useTofNumbersYoyQuery } from '../../../graphql/__generated__/graphql'
import { classNames, numberWithCommas } from '../../../utils/functions'
import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid'

const TotalOpportunitySummary: FC = () => {
  const { data: tofnumbersData, loading: tofnumbersDataLoading } = useTofNumbersYoyQuery()
  const { data: toftotalnumbersData, loading: toftotalnumbersDataLoading } = useTofTotalNumbersYoyQuery()

  const tofnData = tofnumbersData ? tofnumbersData.revtron_reporting_tof_numbers_yoy_gql : null
  const toftotalnData = toftotalnumbersData ? toftotalnumbersData.revtron_reporting_total_tof_numbers_yoy_gql : null
  const summaryData = []
  if (toftotalnData) {
    summaryData.push(...toftotalnData)
  }
  if (tofnData) {
    summaryData.push(...tofnData)
  }

  if (tofnumbersDataLoading || toftotalnumbersDataLoading) {
    return (
      <div className="flex justify-center bg-white dark:bg-header-dark shadow p-4 mb-6">
        <Spin />
      </div>
    )
  }

  return (
    <div>
      {summaryData.length > 0 && (
        <dl className="flex flex-wrap rounded-lg overflow-hidden shadow mb-4">
          {summaryData.map((item: any, index: number) => (
            <div
              key={item.type}
              className={`shadow bg-white dark:bg-header-dark dark:border-gray-500 font-mono px-4 py-5 sm:px-6 sm:py-3 ${index === 0 && 'rounded-tl-lg rounded-bl-lg'} ${index === summaryData.length - 1  ? 'rounded-tr-lg rounded-br-lg' : 'border-r'}`}
              style={{ width: 100/summaryData.length + '%' }}
            >
              <dt className="truncate text-base font-medium dark:text-theme-toggle-icon">{item.type}</dt>
              <dd className='flex items-end'>
                <div className='mt-1 text-xl font-semibold tracking-tight dark:text-theme-toggle-icon'>{numberWithCommas(item.current_period_opps)}</div>
                <div className='text-sm text-table-cell dark:text-theme-toggle-icon ml-2 mr-3'>from {numberWithCommas(item.previous_period_count)}</div>
                {item.percent_change && (
                  <div
                    className={classNames(
                      item.percent_change > 0 ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800',
                      'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0'
                    )}
                  >
                    {item.percent_change > 0 ? (
                      <ArrowSmUpIcon
                        className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-green-500"
                        aria-hidden="true"
                      />
                    ) : (
                      <ArrowSmDownIcon
                        className="-ml-1 mr-0.5 flex-shrink-0 self-center h-5 w-5 text-red-500"
                        aria-hidden="true"
                      />
                    )}
                    <span className="sr-only">{item.percent_change > 0 ? 'Increased' : 'Decreased'} by</span>
                    {item.percent_change.toFixed(0)}%
                  </div>
                )}
              </dd>
            </div>
          ))}
        </dl>
      )}
    </div>
  )
}

export default TotalOpportunitySummary
