import { FC, useState } from 'react'
import { BadgeCheckIcon } from '@heroicons/react/outline'
import TextInput from '../../molecules/textInput'
import { useChangePassword } from '@nhost/react'

import { alert } from '../../../utils/functions'

const initialPasswordState = {
  currentPassword: '',
  newPassword: '',
  repeatPassword: ''
}

const ResetPassword: FC = () => {
  const { changePassword } = useChangePassword()
  const [state, setState] = useState(initialPasswordState)

  const onSave = async () => {
    if (!state.newPassword || state.newPassword !== state.repeatPassword) {
      return
    }
    const result = await changePassword(state.newPassword)
    if (result.isSuccess) {
      alert('success', 'Successful in updating password!')
      setState(initialPasswordState)
    }
    if (result.error) {
      alert('error', 'Failed in updating password.')
      setState(initialPasswordState)
    }
  }

  return (
    <div className="flex-1 rounded-lg bg-white dark:bg-header-dark dark:border-gray-500 shadow z-10 mr-6">
      <div className='px-4 py-6 border-b border-gray-300 dark:border-neutral-700'>
        <div className='flex justify-between items-center mb-2'>
          <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-200">Reset Password</h3>
        </div>
        <div className="text-sm font-medium leading-6 text-gray-500 dark:text-gray-400">
          Enter the old password you had, and then come up with a new one.
        </div>
      </div>
      <div className="px-4 py-6 dark:border-neutral-700">
        {/* <div className="mb-4">
          <TextInput
            label="Current Password"
            labelClassName="text-sm font-medium leading-6 text-gray-500 dark:text-gray-400"
            placeholder="Old password"
            type="password"
            value={state.currentPassword}
            onChange={e => setState({...state, currentPassword: e.target.value})}
            required
          />
        </div> */}
        <div className="mb-4">
          <TextInput
            label="New Password"
            labelClassName="text-sm font-medium leading-6 text-gray-500 dark:text-gray-400"
            placeholder="New password"
            type="password"
            value={state.newPassword}
            onChange={e => setState({...state, newPassword: e.target.value})}
            required
          />
        </div>
        <div className="mb-6">
          <TextInput
            label="Repeat Password"
            labelClassName="text-sm font-medium leading-6 text-gray-500 dark:text-gray-400"
            placeholder="Repeat new password"
            type="password"
            value={state.repeatPassword}
            onChange={e => setState({...state, repeatPassword: e.target.value})}
            required
          />
        </div>
        <button
          type="button"
          className="w-full inline-flex justify-center items-center rounded-md border border-transparent bg-cyan-400 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-cyan-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
          onClick={onSave}
        >
          <BadgeCheckIcon
            className="mr-1 h-4 w-4 text-gray-900"
            aria-hidden="true"
          />
          Save New Password
        </button>
      </div>
    </div>
  )
}

export default ResetPassword
