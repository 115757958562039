import _ from 'lodash'
import { IcpModuleQuery } from '../../../graphql/__generated__/graphql'
import { calcMedian } from '../../../utils/functions'
import { ICPAccount, ICPChartData, ICPFilters, ICPSummary, ICPTreemapChartData, AccountRecord, GeoMapData, RevtronCompany, TreemapChart, } from '../../../@types/module'
import { getFormatter } from './ICP.default.util'

export const getIcpModuleData = (data?: IcpModuleQuery, filters?: ICPFilters) => {
  const allData: ICPAccount[] = _.chain(data?.Account).map(a => ({
    Id: a.Id,
    Name: a.Name || '',
    Website: a.Website || '',
    AnnualRevenue: a.AnnualRevenue ? Number(a.AnnualRevenue) : 0,
    NumberOfEmployees: a.NumberOfEmployees ? Number(a.NumberOfEmployees) : 0,
    revtron_companies: _.get(a, 'to_revtron.to_revtron_companies', []) as RevtronCompany[],
    predict_proba: a.account_module_static?.predict_proba || 0
  })).filter(e => e.revtron_companies.length > 0).value()

  let filteredData = _.cloneDeep(allData)

  // Filter by employee count
  const getFilterdDataByEmployeeCount = (sourceData: ICPAccount[]) => {
    if (!filters?.employeeCount) {
      return sourceData
    }
    const min = filters.employeeCount[0]
    const max = filters.employeeCount[1]
    return sourceData.filter(e => {
      if (e.revtron_companies.length === 0) {
        return false
      }
      const empCount = e.revtron_companies[0].employee_counts
      return empCount >= min && empCount <= max
    })
  }

  // Filter by business age
  const getFilterdDataByBusinessAge = (sourceData: ICPAccount[]) => {
    if (!filters?.ageOfBusiness) {
      return sourceData
    }
    const min = filters.ageOfBusiness[0]
    const max = filters.ageOfBusiness[1]
    return sourceData.filter(e => {
      if (e.revtron_companies.length === 0) {
        return false
      }
      const age = new Date().getFullYear() - Number(e.revtron_companies[0].founded_year)
      return age >= min && age <= max
    })
  }

  // Filter by annaul revenue
  const getFilterdDataByAnnualRevenue = (sourceData: ICPAccount[]) => {
    if (!filters?.annualRevenue) {
      return sourceData
    }
    const min = filters.annualRevenue[0]
    const max = filters.annualRevenue[1]
    return sourceData.filter(e => e.AnnualRevenue >= min && e.AnnualRevenue <= max)
  }

  // Filter by company type
  const getFilterdDataByCompanyType = (sourceData: ICPAccount[]) => {
    if (!filters?.companyType) {
      return sourceData
    }
    return sourceData.filter(e => {
      if (e.revtron_companies.length === 0 || !e.revtron_companies[0].type) {
        return false
      }
      return e.revtron_companies[0].type.toLowerCase() === filters.companyType?.toLowerCase()
    })
  }

  // Filter by industry
  const getFilterdDataBySubCategory = (sourceData: ICPAccount[]) => {
    if (!filters?.subCategory) {
      return sourceData
    }
    return _.chain(sourceData).filter(e => {
      if (e.revtron_companies.length === 0 || !e.revtron_companies[0].industry) {
        return false
      }
      return e.revtron_companies[0].industry.toLowerCase() === filters.subCategory?.toLowerCase()
    }).value()
  }
  
  // Filter by state
  const getFilterdDataByState = (sourceData: ICPAccount[]) => {
    if (!filters?.state) {
      return sourceData
    }
    return _.chain(sourceData).filter(e => {
      if (e.revtron_companies.length === 0 || !e.revtron_companies[0].state) {
        return false
      }
      return e.revtron_companies[0].state.toLowerCase() === filters.state?.toLowerCase()
    }).value()
  }

  filteredData = getFilterdDataByEmployeeCount(filteredData)
  filteredData = getFilterdDataByBusinessAge(filteredData)
  filteredData = getFilterdDataByAnnualRevenue(filteredData)
  filteredData = getFilterdDataByState(filteredData)
  // Save data
  let filteredTreemapData = _.cloneDeep(filteredData)
  filteredData = getFilterdDataByCompanyType(filteredData)
  filteredData = getFilterdDataBySubCategory(filteredData)

  const revtronCompanies = _.chain(allData).map(e => e.revtron_companies).flatten().value()
  const filteredRevtronCompanies = _.chain(filteredData).map(e => e.revtron_companies).flatten().value()

  // Getting data for summary in ICP
  const icpScoreArr = filteredData.map(f => f.predict_proba * 100)
  const employeeCountArr = filteredRevtronCompanies.map(f => f.employee_counts)
  const businessAgeArr = filteredRevtronCompanies.map(f => new Date().getFullYear() - f.founded_year)
  const annualRevenueArr = filteredData.map(f => f.AnnualRevenue)

  const summaryData: ICPSummary = {
    businessCount: filteredRevtronCompanies.length,
    averageICPScore: calcMedian(icpScoreArr),
    averageEmployeeCount: calcMedian(employeeCountArr),
    averageBusinessAge: calcMedian(businessAgeArr),
    averageAnnualRevenue: calcMedian(annualRevenueArr),
  }

  // Getting data for histogram charts in ICP
  const isFilteredByHistogram = !!filters?.employeeCount || !!filters?.ageOfBusiness || !!filters?.annualRevenue
  const revtronCompaniesForHistogram = isFilteredByHistogram ? revtronCompanies : filteredRevtronCompanies
  const dataForHistogram = isFilteredByHistogram ? allData : filteredData
  const maxEmployeeCount = revtronCompaniesForHistogram.reduce((max: number, d) => max < d.employee_counts ? d.employee_counts : max, 0)
  const maxAnnualRevenue = dataForHistogram.reduce((max: number, d) => max < d.AnnualRevenue ? d.AnnualRevenue : max, 0)
  const numberOfEmployees = revtronCompaniesForHistogram.map(d => d.employee_counts / getFormatter(maxEmployeeCount).unitValue)
  const meanNumberOfEmployees = _.mean(numberOfEmployees)
  const annualRevenue = dataForHistogram.map(d => d.AnnualRevenue / getFormatter(maxAnnualRevenue).unitValue)
  const meanAnnualRevenue = _.mean(annualRevenue)
  const ageOfBusiness = revtronCompaniesForHistogram.map(d => new Date().getFullYear() - d.founded_year)
  const meanAgeOfBusiness = _.mean(ageOfBusiness)
  const chartData: ICPChartData = {
    maxEmployeeCountFormatter: getFormatter(maxEmployeeCount).formatter,
    employeeCountUnit: getFormatter(maxEmployeeCount).unitValue,
    maxAnnualRevenueFormatter: getFormatter(maxAnnualRevenue).formatter,
    annualRevenueUnit: getFormatter(maxAnnualRevenue).unitValue,
    ageOfBusiness: ageOfBusiness.filter(d => d < meanAgeOfBusiness),
    numberOfEmployees: numberOfEmployees.filter(d => d < meanNumberOfEmployees),
    annualRevenue: annualRevenue.filter(d => d < meanAnnualRevenue),
  }

  // Getting data for treemap chart in ICP
  const filteredRevtronCompaniesForTreemap = _.chain(filteredTreemapData).map(e => e.revtron_companies).flatten().value()
  const isFilteredByCategoryTreemap = !!filters?.subCategory
  const revtronCompaniesForCategoryTreemap = isFilteredByCategoryTreemap ? filteredRevtronCompaniesForTreemap : filteredRevtronCompanies
  const categories = _.uniq(revtronCompaniesForCategoryTreemap.map(d => d.industry))
  const industryData: ICPTreemapChartData = {
    name: 'industry',
    children: categories.map(cat => ({
      name: cat,
      value: revtronCompaniesForCategoryTreemap.filter(r => r.industry === cat).length
    })).sort((a: TreemapChart, b: TreemapChart) => b.value - a.value)
  }
  const isFilteredByCompanyTypeTreemap = !!filters?.companyType
  const revtronCompaniesForCompanyTypeTreemap = isFilteredByCompanyTypeTreemap ? filteredRevtronCompaniesForTreemap : filteredRevtronCompanies
  const companyTypes = _.uniq(revtronCompaniesForCompanyTypeTreemap.map(d => d.type))
  const companyTypesData: ICPTreemapChartData = {
    name: 'industry',
    children: companyTypes.map(type => ({
      name: type,
      value: revtronCompaniesForCompanyTypeTreemap.filter(r => r.type === type).length
    })).sort((a: TreemapChart, b: TreemapChart) => b.value - a.value)
  }
  const treemapData = {
    industry: industryData,
    companyType: companyTypesData
  }

  // Getting data for geo map
  const dataGroupByState = _.groupBy(filteredData, d => d.revtron_companies[0].state)
  const dataGroupByCountry = _.groupBy(allData, d => d.revtron_companies[0].country)
  const geoMapData: GeoMapData = {
    us: [],
    world: []
  }
  Object.keys(dataGroupByCountry).forEach((state) => {
    let companies = 0
    let employees = 0
    let amount = 0
    dataGroupByCountry[state].forEach((d: ICPAccount) => {
      companies += 1
      employees += d.NumberOfEmployees
      amount += d.AnnualRevenue
    })
    geoMapData.world.push({location: state, companies, employees, amount})
  })
  Object.keys(dataGroupByState).forEach((state) => {
    let companies = 0
    let employees = 0
    let amount = 0
    dataGroupByState[state].forEach((d: ICPAccount) => {
      companies += 1
      employees += d.NumberOfEmployees
      amount += d.AnnualRevenue
    })
    geoMapData.us.push({location: state, companies, employees, amount})
  })

  let accounts = data ? data.Account as ICPAccount[] : []

  // Getting data for detail table in ICP
  let bussinessDetails: AccountRecord[] = filteredData.map(e => {
    const revtron_company = e.revtron_companies[0]
    return {
      uuid: revtron_company.revtron_id,
      annualRevenue: e.AnnualRevenue,
      name: revtron_company.natural_name,
      domain: revtron_company.website,
      city: revtron_company.city,
      empCount: revtron_company.employee_counts,
      predictProba: e.predict_proba,
      companyType: revtron_company.type,
      state: revtron_company.state,
      country: revtron_company.country,
      foundingYear: revtron_company.founded_year,
      industry: revtron_company.industry,
      specialities: revtron_company.tags,
    }
  })

  return { summaryData, chartData, treemapData, geoMapData, accounts, bussinessDetails }
}
