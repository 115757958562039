import { FC, Fragment, useState, useEffect, Key } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline';
import { UserAddIcon } from '@heroicons/react/solid';
import TextField from '../../molecules/textField';
import Select from '../../molecules/select';
import {
  useRemoveTeamMutation,
  useUpdateTeamMutation,
  useRemoveTeamMemberMutation
} from '../../../graphql/__generated__/graphql'
import { teamEmptyState } from './constant'
import { alert } from '../../../utils/functions'

const teamTypes = [
  {
    name: 'Sales Development (SDR)',
    value: 'Sales Development (SDR)'
  },
  {
    name: 'Closing Business (AE)',
    value: 'Closing Business (AE)'
  },
  {
    name: 'Account Management (AM)',
    value: 'Account Management (AM)'
  }
]

const emptyState = {
  ...teamEmptyState,
  members: []
}

const EditTeamModal: FC<EditTeamModalProps> = ({ team, isOpen, closeModal, openAddMemberModal }) => {
  const [removeTeam, { data, loading, error }] = useRemoveTeamMutation();
  const [updateTeam, { data: data2, loading: loading2, error: error2 }] = useUpdateTeamMutation();
  const [removeTeamMember, { data: data3, loading: loading3, error: error3 }] = useRemoveTeamMemberMutation();
  const [state, setState] = useState(emptyState)
  useEffect(() => {
    if (team) {
      setState({
        teamName: team.name,
        teamType: {
          name: team.type,
          value: team.type
        },
        opportunities: team.opportunity_target,
        emailsSent: team.email_target,
        calls: team.call_target,
        social: team.social_target,
        members: team.to_members
      })
    }
  }, [team, isOpen])
  const onCloseModal = () => {
    setState(emptyState)
    closeModal()
  }
  const onSaveTeam = () => {
    if (team.id) {
      updateTeam({
        variables: {
          id: team.id,
          name: state.teamName,
          type: state.teamType.value,
          opportunity_target: state.opportunities,
          email_target: state.emailsSent,
          call_target: state.calls,
          social_target: state.social,
        }
      }).then((res) => {
        alert('success', 'Successful in updating team!')
        onCloseModal()
      }).catch((err) => {
        alert('error', 'Failed in updating team.')
      })
    }
  }
  const onDeleteTeam = () => {
    if (team.id) {
      removeTeam({
        variables: { id: team.id }
      }).then((res) => {
        alert('success', 'Successful in removing a team!')
        onCloseModal()
      }).catch((err) => {
        alert('error', 'Failed in removing a team.')
      })
    }
  }
  const onRemoveTeamMember = (memberId: any) => {
    removeTeamMember({variables: { id: memberId }})
  }
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onCloseModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black dark:bg-gray-700 !bg-opacity-30" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-lg bg-white text-left align-middle shadow-xl transition-all dark:bg-header-dark">
                <div className='px-4 py-6 border-b border-gray-300 dark:border-neutral-700'>
                  <div className='flex justify-between items-center mb-2'>
                    <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-200">Edit Team</h3>
                    <XIcon className='w-4 cursor-pointer dark:text-gray-200' onClick={onCloseModal} />
                  </div>
                  <div className="text-sm font-medium leading-6 text-gray-500 dark:text-gray-400">Change team settings</div>
                </div>
                <div className="px-4 py-6 border-b border-gray-300 dark:border-neutral-700">
                  <TextField
                    className='mb-6'
                    label="Team name"
                    placeholder="Select team"
                    id="team-name"
                    name="team-name"
                    type="text"
                    value={state.teamName}
                    onChange={(value: any) => setState({...state, teamName: value})}
                  />
                  <Select
                    className='mb-6'
                    placeholder="Select type"
                    label="Team type"
                    list={teamTypes}
                    selected={state.teamType}
                    onSelect={e => setState({...state, teamType: e})}
                  />
                  <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-200 mb-4">Weekly Activity Targets</h3>
                  <div className='flex mb-4'>
                    <TextField
                      className='flex-1 mr-4'
                      label="Opportunities Created"
                      placeholder="0"
                      id="opportunities-created"
                      name="opportunities-created"
                      type="number"
                      value={state.opportunities}
                      onChange={(value: any) => setState({...state, opportunities: value})}
                    />
                    <TextField
                      className='flex-1'
                      label="Emails Sent"
                      placeholder="0"
                      id="emails-sent"
                      name="emails-sent"
                      type="number"
                      value={state.emailsSent}
                      onChange={(value: any) => setState({...state, emailsSent: value})}
                    />
                  </div>
                  <div className='flex mb-4'>
                    <TextField
                      className='flex-1 mr-4'
                      label="Calls Made"
                      placeholder="0"
                      id="calls-made"
                      name="calls-made"
                      type="number"
                      value={state.calls}
                      onChange={(value: any) => setState({...state, calls: value})}
                    />
                    <TextField
                      className='flex-1'
                      label="Social"
                      placeholder="0"
                      id="social"
                      name="social"
                      type="number"
                      value={state.social}
                      onChange={(value: any) => setState({...state, social: value})}
                    />
                  </div>
                  {state.members.length > 0 && (
                    <>
                      <h3 className="text-lg font-medium leading-6 text-gray-900 dark:text-gray-200 mb-4">Team Members</h3>
                      <div className='flex flex-wrap'>
                        {state.members.map((member: any, index: Key) => (
                          <div key={index} className='flex border border-gray-300 dark:border-neutral-700 dark:bg-revtron-dark1 rounded-md mr-2 mb-4'>
                            <div className='flex items-center p-1'>
                              <img className="inline-block h-8 w-8 rounded-md" src={member.to_users?.avatarUrl} alt="" />
                              <div className='dark:text-gray-200 text-sm mx-2'>{member.to_users?.displayName}</div>
                            </div>
                            <div className='flex items-center border-l border-gray-300 dark:border-neutral-700 cursor-pointer px-2' onClick={() => onRemoveTeamMember(member.id)}>
                              <XIcon className='w-4 dark:text-gray-200' />
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent bg-cyan-400 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-cyan-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={openAddMemberModal}
                  >
                    <UserAddIcon
                      className="mr-1 h-5 w-5 text-gray-900"
                      aria-hidden="true"
                    />
                    Add New Team Member
                  </button>
                </div>

                <div className="px-4 py-6">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent bg-gray-100 dark:bg-gray-400 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200 hover:dark:bg-gray-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={onSaveTeam}
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 mt-3 text-sm font-medium text-red-500 hover:bg-red-300 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={onDeleteTeam}
                  >
                    Delete
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}

interface EditTeamModalProps {
  team: any
  isOpen: boolean
  closeModal: () => void
  openAddMemberModal: ()=> void
}

export default EditTeamModal
