import { Fragment, useCallback } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { ChevronDownIcon, CheckIcon } from '@heroicons/react/solid'

interface MultipleSelectorProps {
  currents: string[]
  options: string[]
  setOption: (option: string[]) => void
  placeholder: string
}

function MultipleSelector({ currents, options, placeholder, setOption }: MultipleSelectorProps) {
  const onSelectOption = useCallback((opt: string) => {
    const _currents = [...currents]

    const index = _currents.findIndex(c => c === opt)
    if (index > -1) {
      _currents.splice(index, 1)
    } else {
      _currents.push(opt)
    }

    setOption(_currents)
  }, [currents, setOption])

  return (
    <div>
      <Listbox value={options} multiple>
        <Listbox.Button className="inline-flex items-center justify-between w-full rounded-md border-0 py-1.5 pl-1.5 text-gray-900 dark:text-gray-300 bg-transparent ring-1 ring-inset ring-sidebar-border dark:ring-revtron-dark3 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-revtron-dark3 dark:focus:ring-gray-500 sm:text-sm sm:leading-6 text-left">
          {currents.length === 0 ? (
            <span className="block truncate text-gray-500">{placeholder}</span>
          ) : (
            <div>
              {currents.map((current, index) => (
                <span key={`badge-${index}`} className="inline-flex items-center gap-x-0.5 rounded-md bg-revtron-cyan1 px-2 py-1 text-xs font-medium text-white ring-1 ring-inset ring-indigo-700/10 mr-1">
                  {current}
                  <div className="group relative -mr-1 h-3.5 w-3.5 cursor-pointer rounded-sm hover:bg-indigo-600/20" onClick={() => onSelectOption(current)}>
                    <span className="sr-only">Remove</span>
                    <svg viewBox="0 0 14 14" className="h-3.5 w-3.5 stroke-indigo-600/50 group-hover:stroke-indigo-600/75">
                      <path d="M4 4l6 6m0-6l-6 6" />
                    </svg>
                    <span className="absolute -inset-1" />
                  </div>
                </span>
              ))}
            </div>
          )}
          <span className="pointer-events-none mx-1">
            <ChevronDownIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="bg-revtron-dark3 text-left mt-1 max-h-36 overflow-auto rounded-sm">
            {options.map((option, index) => {
              const isSelected = currents.includes(option)
              return (
                <Listbox.Option
                  key={index}
                  value={option}
                  onClick={() => onSelectOption(option)}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-2 pr-4 ${
                      active ? 'bg-revtron-cyan text-gray-900' : 'text-gray-100'
                    }`
                  }
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          isSelected && selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        {option}
                      </span>
                      {isSelected && selected ? (
                        <span className="absolute inset-y-0 right-0 flex items-center pr-3 text-white">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              )
            })}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  )
}

export default MultipleSelector
