import { FC, memo, useMemo } from 'react'

import Spin from '../../../atoms/spin'
import { intoRGB, calculateDefaultAvtarBg } from '../../../../utils/functions'
import { ActivityByUser } from '../../../../containers/activity/ActivityLookBack/ActivityLookBack.util'
import { getAvatarUrl, getUserAvatarByName } from '../../../../utils/functions'
import { numberWithCommas } from '../../../../utils/functions'

const Card: FC<{item: ActivityByUser, onCardClick: (id: string) => void, selectedId?: string}> = ({ item, onCardClick, selectedId }) => {
  const photoUrl = useMemo(() => {
    return getAvatarUrl(item.avatarUrl, item.name)
  }, [item.avatarUrl, item.name])

  return (
    <div
      className={`col-span-1 flex flex-col divide-y divide-gray-100 dark:divide-neutral-500 rounded-lg bg-white dark:bg-neutral-800 shadow cursor-pointer border border-revtron-cyan ${item.id === selectedId ? 'border-solid' : 'border-none'} hover:border-solid`}
      onClick={() => onCardClick(item.id)}
    >
    <div className='relative flex item-center space-x-3 rounded-lg p-4'>
      <div className='flex-shrink-0'>
      {
        !!photoUrl
          ? (
            <div className='w-10 h-10 rounded-full relative z-10 before:absolute before:top-1 before:right-1 before:w-full before:h-full before:rounded-full before:bg-revtron-teal'>
              <img src={photoUrl} alt='' className='w-full rounded-full absolute z-10 inset-0 object-cover border border-collapse border-revtron-teal' />
            </div>
            )
          : (
            <div
              className='w-10 h-10 rounded-full flex justify-center items-center'
              style={{
                backgroundColor: `#${intoRGB(calculateDefaultAvtarBg(item.name))}`
              }}
            >
              <span>{getUserAvatarByName(item.name)}</span>
            </div>
            )
      }
      </div>
      <div className='min-w-0 flex-1'>
          <span className='absolute inset-0' aria-hidden='true' />
          <p className='truncate font-semibold text-revtron-dark3 dark:text-revtron-white'>{item.name}</p>
          <p className='truncate text-sm text-revtron-dark2 dark:text-revtron-white'>{item.team_name}</p>
      </div>
    </div>
      <div className='overflow-hidden rounded-sm px-2'>
        <div className='flex items-center justify-between p-4'>
          <p className='truncate text-revtron-dark2 dark:text-revtron-white text-xs'>Opps Created</p>
          <p className='text-revtron-dark2 dark:text-revtron-white font-semibold text-base'>{`${numberWithCommas(item.opportunitiesCreated.toFixed(0))}`}</p>
        </div>
        <div className='flex items-center justify-between p-4'>
          <p className="truncate text-revtron-dark2 dark:text-revtron-white text-xs">Meetings Created</p>
          <p className="text-revtron-dark2 dark:text-revtron-white font-semibold text-base">{`${numberWithCommas(item.meetingsCreated.toFixed(0))}`}</p>
        </div>
        <div className='flex items-center justify-between p-4'>
          <p className="truncate text-revtron-dark2 dark:text-revtron-white text-xs">Accounts Touched</p>
          <p className="text-revtron-dark2 dark:text-revtron-white font-semibold text-base">{`${numberWithCommas(item.accountsTouched.toFixed(0))}`}</p>
        </div>
        <div className='flex items-center justify-between p-4'>
          <p className="truncate text-revtron-dark2 dark:text-revtron-white text-xs">Contacts Touched</p>
          <p className="text-revtron-dark2 dark:text-revtron-white font-semibold text-base">{`${numberWithCommas(item.contactsTouched.toFixed(0))}`}</p>
        </div>
        <div className='flex items-center justify-between p-4'>
          <p className="truncate text-revtron-dark2 dark:text-revtron-white text-xs">Avg Contacts per Act</p>
          <p className="text-revtron-dark2 dark:text-revtron-white font-semibold text-base">{`${numberWithCommas(item.avgContactsPerAccount.toFixed(0))}`}</p>
        </div>
      </div>
    </div>
  )
}

const PlayerCards: FC<PlayerCardsProps> = ({ leadData, leadLoading, playerId, onChangePlayerId }) => {
  if (leadLoading) {
    return (
      <div className='flex justify-center bg-white dark:bg-header-dark shadow p-4 mb-6'>
        <Spin />
      </div>
    )
  }

  return (
    <div className='grid grid-cols-1 gap-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-5'>
      {
        leadData.map((item: ActivityByUser, index: number) => {
          return (
            <Card key={index.toString()} item={item} selectedId={playerId} onCardClick={onChangePlayerId} />
          )
        })
      }
    </div>
  )
}

interface PlayerCardsProps {
  leadData: ActivityByUser[]
  leadLoading: boolean
  playerId: string | undefined
  onChangePlayerId: (id: string) => void
}

export default memo(PlayerCards)
