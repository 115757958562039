import { FC } from 'react'
import AccountTable from '../../../components/organisms/account-table'
import { useFeatureContext } from '../../../hooks/featureContext'
import useGraphQL from './AccountRec.graphql'
import useGraphQLForGigpro from './AccountRec.gigpro.graphql'

const DefaultComponent: FC = () => {
  const {
    accountRecData: { bussinessDetails, totalRecordsCount },
    fields,
    filters,
    sendToCRM,
    isSendToCrmLoading,
    ...rest
  } = useGraphQL()

  return (
    <div className='account-recommendations-content'>
      <AccountTable
        tableData={bussinessDetails}
        fields={fields}
        totalRowsCount={totalRecordsCount}
        headerActionLoading={isSendToCrmLoading}
        headerActionText='Send to CRM'
        tableHeaderAction={sendToCRM}
        {...rest}
        filters={filters}
      />
    </div>
  );
}

const HarriComponent: FC = () => {
  return null
}

const GigProComponent: FC = () => {
  const {
    accountRecData: { bussinessDetails, totalRecordsCount },
    fields,
    sendToCRM,
    isSendToCrmLoading,
    ...rest
  } = useGraphQLForGigpro()

  return (
    <div className='account-recommendations-content'>
      <AccountTable
        tableData={bussinessDetails}
        fields={fields}
        totalRowsCount={totalRecordsCount}
        headerActionLoading={isSendToCrmLoading}
        headerActionText='Send to CRM'
        tableHeaderAction={sendToCRM}
        {...rest}
      />
    </div>
  );
}

const AccountRec: FC = () => {
  const { isHarry, isGigPro } = useFeatureContext()
  
  if (isHarry) {
    return <HarriComponent />
  } else if (isGigPro) {
    return <GigProComponent />
  } else {
    return <DefaultComponent />
  }
}

export default AccountRec
