import { FC, Fragment, useContext, useMemo } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Menu, Transition } from '@headlessui/react'
import { MenuAlt2Icon, UserIcon, LogoutIcon } from '@heroicons/react/outline'
import { useSignOut, useUserData } from '@nhost/react'
import { BannerUnderDevelopment } from '../../components/common/banner'
import { InDevelopmentFlagType } from '../../hooks/featureFlags/pipelineFilter'
import { useFeatureContext } from '../../hooks/featureContext'

import SwitchButton from '../../components/molecules/themeSwitchButton'
import { ThemeContext } from '../themeContext'

const Header: FC<HeaderProps> = ({ sidebarOpen, setSidebarOpen }) => {
  const { theme, setTheme } = useContext<any>(ThemeContext)
  const { signOut } = useSignOut()
  const location = useLocation()
  const user = useUserData()
  const { developmentFlags } = useFeatureContext()
  
  const banner = useMemo(() => {
    if (!developmentFlags) {
      return {
        flag: false,
        module: ''
      }
    }
    const page = location.pathname.split('/')[1].replace('-', '_').toLowerCase() as keyof InDevelopmentFlagType
    const module = location.pathname.split('/')[1].replace('-', ' ').toUpperCase()
    return {
      flag: developmentFlags[page],
      module,
    }
  }, [developmentFlags, location.pathname])

  const headerTitle = useMemo(() => {
    return location.pathname.split('/')[1].replace('-', ' ').toUpperCase()
  }, [location.pathname])
  
  return (
    <Fragment>
      {banner.flag && (
        <div className="sticky top-0 h-16 bg-white dark:bg-revtron-dark2 z-50">
          <BannerUnderDevelopment module={banner.module} />
        </div>
      )}
      <div className="sticky top-0 flex flex-shrink-0 h-16 bg-white dark:bg-revtron-dark2 z-50">
        <button
          type="button"
          className="px-4 text-gray-500 border-r border-gray-300 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-revtron-cyan md:hidden"
          onClick={() => setSidebarOpen(!sidebarOpen)}
        >
          <span className="sr-only">Open sidebar</span>
          <MenuAlt2Icon className="w-6 h-6" aria-hidden="true" />
        </button>
        <div className="flex justify-between items-center flex-1 px-14">
          <div className="flex flex-1">
            <p className='text-chart-tooltip-text dark:text-white text-2xl capitalize'>{headerTitle}</p>
            {/* <form className="flex w-full md:ml-0" action="#" method="GET">
              <label htmlFor="search-field" className="sr-only">
                Search
              </label>
              <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                  <SearchIcon className="w-5 h-5" aria-hidden="true" />
                </div>
                <input
                  id="search-field"
                  className="block w-full h-full py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 border-transparent focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                  placeholder="Search"
                  type="search"
                  name="search"
                />
              </div>
            </form> */}
          </div>
          <div className="flex items-center ml-4 md:ml-6">
            <div className='p-1'>
              <SwitchButton enabled={theme === 'dark'} setEnabled={setTheme} theme={theme} />
            </div>
            {/* <button
              type="button"
              className="p-1 text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              <span className="sr-only">View notifications</span>
              <BellIcon className="w-6 h-6" aria-hidden="true" />
            </button> */}

            {/* Profile dropdown */}
            <Menu as="div" className="relative ml-3">
              <div>
                <Menu.Button className="flex items-center max-w-xs text-sm bg-gray-100 dark:bg-neutral-700 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-revtron-cyan">
                  <span className="sr-only">Open user menu</span>
                  <img 
                    className="h-9 w-9 rounded-full object-cover"
                    src={user?.avatarUrl}
                    alt=""
                  />
                  {/*  This is to use the avatar with nhost package from the user table I think
                  <img
                    className="w-8 h-8 rounded-full"
                    src={nhost.auth.getUser()?.avatarUrl}
                    alt="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  /> */}
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 py-1 mt-2 origin-top-right bg-white dark:bg-header-dark rounded-md shadow-lg ring-1 ring-revtron-cyan ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    <div className="px-4 py-2">
                      <div className="text-base text-black dark:text-white mb-1">{user && user.displayName}</div>
                      <div className="text-sm text-gray-400 mr-3">{user && user.email}</div>
                    </div>
                  </Menu.Item>
                  <div className="mx-4 my-2 border-b dark:border-gray-600" />
                  <Menu.Item>
                    {({ active }: { active: any }) => (
                      <NavLink
                        className="flex items-center px-4 py-2 text-sm text-gray-600 cursor-pointer"
                        to={"/profile"}
                      >
                        <UserIcon className={`w-4 h-4 mr-3 ${active ? 'text-revtron-cyan' : ''}`} />
                        <div className={active ? "text-black dark:text-white" : ""}>Profile</div>
                      </NavLink>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }: { active: any }) => (
                      <div
                        className="flex items-center px-4 py-2 text-sm text-gray-600 cursor-pointer"
                        onClick={signOut}
                      >
                        <LogoutIcon className={`w-4 h-4 mr-3 ${active ? 'text-revtron-cyan' : ''}`} />
                        <div className={active ? "text-black dark:text-white" : ""}>Sign out</div>
                      </div>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

interface HeaderProps {
  sidebarOpen: boolean
  setSidebarOpen: (status: boolean) => void
}

export default Header
