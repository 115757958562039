import { FC } from 'react'

type ButtonProps = {
  title: string,
  classnames?: string, 
  onButtonClick: () => void, 
  disabled?: boolean
  Icon?: (props: React.ComponentProps<'svg'>) => JSX.Element
}

const BorderedButton: FC<ButtonProps> = ({ title, Icon, disabled = false, onButtonClick }) => {
  return (
    <button
      disabled={disabled}
      type="button"
      onClick={onButtonClick}
      className={`inline-flex items-center justify-center rounded-md px-4 py-2 text-sm text-neutral-500 dark:text-neutral-200 font-medium border border-neutral-400 dark:border-neutral-700 bg-white dark:bg-header-dark focus:outline-none sm:w-auto ${disabled ? '' : 'hover:bg-gray-50 dark:hover:bg-revtron-dark3'}`}
    >
      {Icon && <Icon className="mr-1 h-5 w-5 text-neutral-400 dark:text-neutral-200" aria-hidden="true" />}
      {title}
    </button>
  )
}

export default BorderedButton
