import { FC, useState } from 'react'
import { PencilIcon } from '@heroicons/react/solid';
import TeamCard from './teamCard'
import CrateTeamModal from './createTeamModal'
import Spin from '../../atoms/spin'
import { BorderedButton as Button } from '../../../components/common/buttons'

import { useTeamListSubscription } from '../../../graphql/__generated__/graphql'

const TeamsSection: FC<TeamsSectionProps> = () => {
  const [createTeamModalOpened, toggleCreateTeamModal] = useState(false)
  const { data, loading } = useTeamListSubscription()
  
  return (
    <>
      <div className="sm:flex sm:items-center mb-6">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900 dark:text-neutral-300">Teams</h1>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <Button
            title='Create Team'
            Icon={PencilIcon}
            onButtonClick={() => toggleCreateTeamModal(true)}
          />
        </div>
      </div>
      {loading ? (
        <div className='w-full flex justify-center items-center my-4'>
          <Spin />
        </div>
      ) : (
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 mb-4">
          {data?.teams.map((team, index) => (
            <TeamCard key={index} team={team} />
          ))}
        </dl>
      )}

      <CrateTeamModal isOpen={createTeamModalOpened} closeModal={() => toggleCreateTeamModal(false)} />
    </>
  )
}

interface TeamsSectionProps {
}

export default TeamsSection
