import { FC, useMemo, useState, useCallback } from 'react'

import ShadowCard from '../../templates/shadowCard'
import CustomTable from '../../molecules/table'
import { convertUTCToLocal, sortAlphabetically, numberWithCommas, isInteger } from '../../../utils/functions'
import { WinsTableData } from '../../../containers/leaderboard_v1/Leaderboard.util'

type WinsTableProps = {
  data: WinsTableData[]
  ownerId?: string 
}

const WinsTable: FC<WinsTableProps> = ({ data, ownerId }) => {
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const tableData = useMemo(() => {
    if (!!ownerId) {
      setPageNumber(1)
      return data.filter(m => m.ownerId === ownerId)
    }
    return data
  }, [data, ownerId])

  const paginatedData = useMemo(() => {
    return tableData.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
  }, [pageNumber, pageSize, tableData])

  const onPaginationChange = useCallback((pageNumber: number, pageSize: number) => {
    setPageNumber(pageNumber)
    setPageSize(pageSize)
  }, [])

  const columns = [
    {
      title: 'Opportunity Name',
      dataIndex: 'oppName',
      sorter: (a: WinsTableData, b: WinsTableData) => sortAlphabetically(a.oppName, b.oppName)
    },
    {
      title: 'Opportunity Owner',
      dataIndex: 'oppOwner',
      sorter: (a: WinsTableData, b: WinsTableData) => sortAlphabetically(a.oppOwner, b.oppOwner)
    },
    {
      title: 'Lead Source',
      dataIndex: 'leadsource',
      sorter: (a: WinsTableData, b: WinsTableData) => sortAlphabetically(a.leadsource, b.leadsource)
    },
    {
      title: 'Age',
      dataIndex: 'age',
      sorter: (a: WinsTableData, b: WinsTableData) => a.age - b.age,
    },
    {
      title: 'Close Date',
      dataIndex: 'closedate',
      sorter: (a: WinsTableData, b: WinsTableData) => (a.closedate < b.closedate) ? -1 : ((a.closedate > b.closedate) ? 1 : 0),
      render: (text: string) => {
        return (
          <p>{convertUTCToLocal(text)}</p>
        )
      }
    },
    {
      title: 'ARR',
      dataIndex: 'arr',
      sorter: (a: WinsTableData, b: WinsTableData) => a.arr - b.arr,
      render: (text: string) => {
        return (
          <p>${numberWithCommas(isInteger(text))}</p>
        )
      }
    },
  ]

  return (
    <ShadowCard label='Wins Details' className='mt-5'>
      <CustomTable
        columns={columns}
        dataSource={paginatedData}
        pagination={{
          total: data.length,
          current: pageNumber,
          pageSize,
          onChange: onPaginationChange
        }}
      />
    </ShadowCard>
  )
}

export default WinsTable
