import { Fragment, FC } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'
import { classNames } from '../../../utils/functions'

const PAGE_SIZES = [5, 10, 25, 50, 100]

const PageSelectionDropdown: FC<{ pageSize: number, onPageSizeSelect: (size: number) => void }> = ({ pageSize, onPageSizeSelect }) => {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex justify-between gap-x-1.5 rounded-md bg-transparent hover:bg-gray-100 dark:hover:bg-revtron-dark3 px-3 py-2 text-sm font-semibold shadow-sm text-gray-600 w-18">
          {pageSize}
          <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-1 w-24 origin-top-right rounded-md bg-gray-100 dark:bg-revtron-dark3 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {
            PAGE_SIZES.map(option => (
              <div className="py-1" key={`option_${option}`}>
                <Menu.Item>
                  {({ active }) => (
                    <span
                      onClick={() => onPageSizeSelect(option)}
                      className={classNames(
                        active ? 'bg-gray-50 dark:bg-revtron-dark2 text-gray-900 dark:text-gray-200' : 'text-gray-700 dark:text-gray-100',
                        'block px-4 py-2 text-sm cursor-pointer'
                      )}
                    >
                      {option}
                    </span>
                  )}
                </Menu.Item>
              </div>
            ))
          }
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default PageSelectionDropdown